import React from 'react';

import { USER_TYPES } from 'configs/Constants';
import UserStandard from './components/UserStandard';

const User = (props) => {
  return <UserStandard {...props} userType={USER_TYPES.USER} />;
};

export default User;
