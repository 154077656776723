import React, { useEffect } from 'react';
import { Tabs, Input, Form, Button, Spin, Result, message } from 'antd';

import { useGetSetting, useUpdateSetting } from 'redux/Hooks/concierge';

const { TabPane } = Tabs;

function Concierge() {
  const [form] = Form.useForm();

  const tabConfigs = [
    {
      key: 'trainer',
      name: 'トレーナー',
    },
    {
      key: 'training',
      name: 'ユーザー',
    },
  ];

  const [{ data, isLoading, isSuccess }] = useGetSetting();
  const [updateSetting] = useUpdateSetting({});

  useEffect(() => {
    if (!isLoading && data) {
      const { trainer, training } = JSON.parse(data?.value || '{}');
      form.setFieldsValue({
        trainer,
        training,
      });
    }
  }, [isLoading]);

  if (isLoading) {
    return <Spin />;
  }

  if (!isSuccess && message) {
    console.log(message);
    return <Result status="error" title="フェッチに失敗する" />;
  }

  async function handleSubmit() {
    const formValues = form.getFieldsValue();
    try {
      await updateSetting({
        body: {
          key: 'concierge_intro',
          value: JSON.stringify({
            trainer: formValues?.trainer || '',
            training: formValues?.training || '',
          }),
        },
      });
      message.success('更新成功');
    } catch (e) {
      console.log(e);
      message.error('更新に失敗する');
    }
  }

  return (
    <Tabs tabPosition="left">
      {tabConfigs.map(({ name, key }) => (
        <TabPane tab={name} key={key}>
          <Form form={form}>
            <Form.Item
              name={key.toLowerCase()}
              labelCol={{ span: 24 }}
              label="コンシェルジュ自動メッセージ"
              rules={[{ required: true }]}
            >
              <Input.TextArea rows={6} placeholder="ここに入力してください..." />
            </Form.Item>

            <Button block type="primary" onClick={handleSubmit}>
              保存
            </Button>
          </Form>
        </TabPane>
      ))}
    </Tabs>
  );
}

export default Concierge;
