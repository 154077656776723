import React from 'react';
import PropTypes from 'prop-types';
import AlertItem from './AlertItem';

const TotalReportPending = ({ loading, data }) => (
  <AlertItem title="未処理レポート" value={data} loading={loading} path="/report?todayReport=1" />
);

TotalReportPending.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.number,
};
export default TotalReportPending;
