import React, { useEffect, forwardRef, useState, useImperativeHandle, useRef, useMemo } from 'react';
import { Spin, Avatar, Empty, Modal, message, Divider } from 'antd';
// import dayjs from 'dayjs';
import moment from 'moment';
import cn from 'classnames';

import InfiniteScroll from 'react-infinite-scroll-component';

import isEmpty from 'lodash/isEmpty';
import { UserOutlined } from '@ant-design/icons';
import { parse } from 'qs';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useGetMessage } from 'redux/Hooks/conversation';
import { useMessagesAdd } from 'utils/SocketIO';
import { API } from 'configs';
import { request } from 'utils/Request';
import { toObjectKeys } from 'utils/Function';

import Message from 'containers/MessageList/components/Message';
import MessageTyping from '../MessageType';

import styles from './styles.module.css';

const ConciergeBox = forwardRef((props, ref) => {
  const messageRef = useRef();
  const { onUpdateRead, refresh } = props;
  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);
  const [modal, contextHolder] = Modal.useModal({});
  const [ids, setIds] = useState({});
  const location = useLocation();

  const parseQuery = parse(location.search, { ignoreQueryPrefix: true }) || {};
  const convId = Number(parseQuery?.id) || props?.convId;

  const [joinConversation, setMessages, messages, disconnect] = useMessagesAdd(refresh);
  const [apiMsg, isFetching, getMessage, , hasMore] = useGetMessage(convId, {
    success: () => {
      if (onUpdateRead) props.onUpdateRead(convId);
      if (refresh) props.refresh();
    },
  });

  const loadFunc = () => {
    setIsFetchingNextPage(true);
    const lastMsg = messages[messages.length - 1];
    if (lastMsg?.id) {
      getMessage(lastMsg.id);
    }
  };
  const refreshMessage = () => {
    getMessage();
    joinConversation(convId);
  };

  useImperativeHandle(ref, () => ({
    refreshMessage,
  }));

  useEffect(() => {
    refreshMessage();
    return () => {
      disconnect();
    };
  }, []);

  useEffect(() => {
    refreshMessage();
  }, [convId]);

  useEffect(() => {
    if (apiMsg?.length) {
      setMessages((prevMessage) => [...prevMessage, ...apiMsg.filter((item) => !ids[item.id])]);
      setTimeout(() => {
        setIsFetchingNextPage(false);
      }, [500]);
    }
  }, [apiMsg]); // eslint-disable-line

  useEffect(() => {
    setIds(toObjectKeys(messages));
  }, [messages]);

  useEffect(() => {
    setMessages([]);
  }, [props?.inquiriUserId]);

  const deleteMessage = (id) => {
    request({
      method: 'delete',
      url: API.ADMIN.CONVERSATION.DELETE.replace(':id', id),
      success: (response) => {
        if (response) {
          setMessages(messages.filter((item) => item.id !== id));
          message.success('メッセージ削除が完了しました');
        }
      },
      failure: () => {
        //
      },
    });
  };

  const modalDeleteConfig = (idMess) => {
    return {
      title: '確認',
      content: <h1>削除しますか？</h1>,
      onOk: (close) => {
        deleteMessage(idMess);
        close();
      },
      onCancel: (close) => {
        close();
      },
      okText: '削除',
      okType: 'primary',
      cancelText: 'キャンセル',
      autoFocusButton: 'ok',
    };
  };

  const _renderMessageItem = (item) => {
    const isMine = item?.sender?.role === 'admin';
    return (
      <div
        key={item.id}
        className={styles.listItem}
        style={{
          alignItems: !isMine ? 'flex-start' : 'flex-end',
        }}
      >
        <div
          className={`flex flex-row relative justify-start items-center flex-wrap ${
            !isMine ? 'flex-row' : 'flex-row-reverse'
          }`}
        ></div>
        <Message
          isAttachment={!!item?.attachmentId}
          isLeft={!isMine}
          item={item}
          isDelete
          deleteMessage={() => {
            modal.confirm(modalDeleteConfig(item?.id));
          }}
        />
        <div className="flex mt-2 items-center">
          {!isMine && (
            <Avatar
              src={item?.sender?.avatar}
              size="large"
              icon={<UserOutlined />}
              className="flex justify-center items-center mb-2"
            />
          )}
          <div className="text-xs text-gray-600 ml-2">{moment(item?.updated).format('MM月DD日（ddd）HH:mm')}</div>
        </div>
      </div>
    );
  };
  const loader = (height = 'h-full') => (
    <div className={cn(height, 'w-full flex justify-center items-center')}>
      <Spin />
    </div>
  );
  const renderContent = () => {
    if (isFetching && !isFetchingNextPage) {
      return loader;
    }
    if (isEmpty(messages)) {
      return (
        <div className="w-full h-full flex justify-center items-center">
          <Empty />
        </div>
      );
    }

    return (
      <div className="h-full p-4 w-full absolute inset-0">
        <div
          id="messages"
          ref={messageRef}
          className={cn(
            'flex h-full w-full overflow-y-auto overscroll-contain',
            // eslint-disable-next-line no-self-compare
            messageRef?.current?.clientHeight < messageRef?.current?.scrollHeight ? 'flex-col-reverse' : 'flex-col'
          )}
        >
          <InfiniteScroll
            dataLength={messages?.length || 0}
            next={loadFunc}
            hasMore={hasMore}
            inverse
            className="flex w-full flex-col-reverse"
            loader={<div key={0}>{isFetchingNextPage && loader('h-4')}</div>}
            scrollableTarget="messages"
          >
            {messages?.map((item) => (
              <div key={item?.id}>{_renderMessageItem(item)}</div>
            ))}
          </InfiniteScroll>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col w-full h-full justify-between">
      <div className="flex-1 h-full w-full relative">
        <div className="w-full h-full overflow-x-hidden relative">{renderContent()}</div>
        {contextHolder}
        <Divider type="horizontal" />
      </div>
      <MessageTyping setMessages={setMessages} convId={convId} sendSuccess={props?.sendSuccess} />
    </div>
  );
});

ConciergeBox.propTypes = {
  convId: PropTypes.number,
  sendSuccess: PropTypes.func,
  onUpdateRead: PropTypes.func,
  refresh: PropTypes.func,
};

export default ConciergeBox;
