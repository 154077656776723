import { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { DEFAULT_PAGE_SIZE } from 'configs/Constants';

export const useFilter = (setQuery) => {
  const [filter, setFilter] = useState({});

  useEffect(() => {
    if (!isEmpty(filter)) {
      setQuery({
        pageSize: DEFAULT_PAGE_SIZE,
        page: filter?.page || 0,
        ...filter,
      });
    }
  }, [filter]);

  return [filter, setFilter];
};
