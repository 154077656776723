import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Tabs, Upload, Form, Button, Spin, Result, message, Input, Carousel, Image, Modal, Switch } from 'antd';

import { useGetSetting, useUpdateSetting } from 'redux/Hooks/banner';
import { useUpload } from 'utils/hooks/useUpload';
import { getToken } from 'utils/Function';
import { PictureOutlined, PlusOutlined, LeftOutlined, RightOutlined, CloseOutlined } from '@ant-design/icons';
import DotIcon from 'components/DotIcon';
import styles from './styles.module.css';

const { TabPane } = Tabs;

const { REACT_APP_API_URI } = process.env;

const UPLOAD_PATH = `${REACT_APP_API_URI}/api/v1/upload`;

const tabConfigs = [
  {
    key: 'trainer',
    name: 'トレーナー',
  },
  {
    key: 'training',
    name: 'ユーザー',
  },
];

const BANNERS_KEY = 'banners';

const UploadButton = ({ children, loading }) => {
  return loading ? <Spin /> : children;
};

function Banner() {
  const [form] = Form.useForm();
  const [imageSelected, setImageSelected] = useState(0);
  const [activeKey, setActiveKey] = useState(tabConfigs[0].key);
  const elementRef = useRef(null);
  const [carouselRefState, setCarouselRef] = useState(elementRef);

  const [{ data, isLoading, isSuccess }, doRequest] = useGetSetting();
  const [updateSetting] = useUpdateSetting({});
  const { state, uploadProps } = useUpload();
  const [modal, contextHolder] = Modal.useModal({});

  const onCarouselRef = useCallback(
    (ref) => {
      const carouselRef = carouselRefState;
      carouselRef.current = ref;
      setCarouselRef(carouselRef);
    },
    [carouselRefState?.current]
  );

  const onOk = (close, onDelete) => {
    close();
    onDelete();
  };

  const onCancel = (close) => {
    close();
  };
  const modalDeleteConfig = (onDelete) => {
    return {
      className: '',
      title: '確認',
      content: <h1>削除しますか？</h1>,
      onOk: (close) => onOk(close, onDelete),
      onCancel,
      okButtonProps: {},
      cancelButtonProps: {},
      // centered: true,
      okText: '削除',
      okType: 'primary',
      cancelText: 'キャンセル',
      icon: null,
      mask: true,
      maskClosable: true,
      // zIndex: 100,
      // okCancel: true,
      style: {},
      maskStyle: {},
      // type: '',
      keyboard: true,
      autoFocusButton: 'ok',
      // direction: '',
    };
  };

  const initialValues = () => {
    try {
      const { trainer = { [BANNERS_KEY]: [] }, training = { [BANNERS_KEY]: [] } } = JSON.parse(data?.value) || {
        trainer: { [BANNERS_KEY]: [] },
        training: { [BANNERS_KEY]: [] },
      };
      return { trainer, training };
    } catch {
      return {
        trainer: {
          [BANNERS_KEY]: [],
        },
        training: {
          [BANNERS_KEY]: [],
        },
      };
    }
  };

  const authorization = getToken() ? `Bearer ${getToken()}` : null;

  if (isLoading) {
    return <Spin />;
  }

  if (!isSuccess && message) {
    console.log(message);
    return <Result status="error" title="フェッチに失敗する" />;
  }

  async function handleSubmit(formValues) {
    const allValues = initialValues();
    const result = {
      ...allValues,
      ...formValues,
    };

    try {
      await updateSetting({
        body: {
          key: 'banner',
          value: JSON.stringify(result),
        },
      });
      doRequest();
      message.success('更新成功');
    } catch (e) {
      console.log(e);
      message.error('更新に失敗する');
    }
  }

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    return isJpgOrPng;
  };

  const handleAdd = async (info) => {
    uploadProps.onChange(info);

    if (info.file.status === 'uploading') {
      return;
    }
    if (info?.file?.status === 'done' && info?.file?.response?.uploaded) {
      const values = form.getFieldsValue();
      if (info?.file?.response?.url) {
        const newBanner = { image: info?.file?.response?.url, url: '' };
        form.setFields([
          {
            name: [activeKey, BANNERS_KEY],
            value: [newBanner].concat(values?.[activeKey]?.[BANNERS_KEY] || []),
          },
        ]);
        setImageSelected(0);
      }
    }
  };

  const renderSwiper = (fields = [], selectedIndex = 0) => {
    return (
      <div className={styles.rowSwiper}>
        <ul className={[styles.swiper, fields?.length <= 12 && 'justify-center'].join(' ')}>
          {fields?.map((field) => {
            return (
              <li key={[BANNERS_KEY, field.key]}>
                <DotIcon
                  selected={selectedIndex === field.key}
                  onClick={() => {
                    if (carouselRefState.current) {
                      carouselRefState.current.goTo(field.key);
                      setImageSelected(field.key);
                    }
                  }}
                  size={15}
                  className="flex items-center justify-center object-contain object-center  "
                />
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <Tabs
      tabPosition="left"
      activeKey={activeKey}
      onChange={(active) => {
        setActiveKey(active);
        setImageSelected(0);
      }}
    >
      {tabConfigs.map(({ name, key }) => (
        <TabPane tab={name} key={key}>
          <Form
            form={form}
            initialValues={initialValues()}
            onFinish={handleSubmit}
            layout="vertical"
            className="space-y-8"
          >
            <Form.List name={[key.toLowerCase(), BANNERS_KEY]}>
              {(fields, { remove }) => {
                return (
                  <div className="flex flex-col items-center w-full">
                    <div className="w-full space-y-4">
                      <Carousel
                        ref={onCarouselRef}
                        dots={false}
                        draggable
                        arrows
                        className="flex flex-row"
                        beforeChange={(_, nextIndex) => setImageSelected(nextIndex)}
                      >
                        {fields.map((field) => {
                          return (
                            <React.Fragment key={field.key}>
                              <Form.Item
                                {...field}
                                key={[field.key, 'image'].join('_')}
                                name={[field.key, 'image']}
                                label="バナー管理"
                                valuePropName="src"
                                className="flex items-center justify-center pl-32 pr-32"
                              >
                                <Image
                                  wrapperclassname="flex items-center justify-center "
                                  className="object-contain bg-contain object-center flex items-center justify-center"
                                  placeholder={<PictureOutlined style={{ fontSize: 50 }} />}
                                  preview={false}
                                  height="50vh"
                                />
                              </Form.Item>
                              <Form.Item
                                {...field}
                                key={[field.key, 'url'].join('_')}
                                name={[field.key, 'url']}
                                label="URL"
                                className="w-full"
                              >
                                <Input type="url" />
                              </Form.Item>
                            </React.Fragment>
                          );
                        })}
                      </Carousel>
                      <div className="flex flex-row justify-center space-x-2">
                        <Upload
                          disabled={state?.loading}
                          name="upload"
                          multiple={false}
                          showUploadList={false}
                          beforeUpload={beforeUpload}
                          action={UPLOAD_PATH}
                          headers={{ authorization }}
                          {...uploadProps}
                          onChange={(info) => handleAdd(info)}
                        >
                          <UploadButton loading={state?.loading}>
                            <Button
                              shape="circle"
                              size="large"
                              icon={<PlusOutlined size={25} />}
                              className="flex items-center justify-center object-contain object-center"
                            />
                          </UploadButton>
                        </Upload>
                        {fields?.length ? (
                          <Button
                            shape="circle"
                            size="large"
                            onClick={() => {
                              modal.confirm(modalDeleteConfig(() => remove(imageSelected)));
                            }}
                            icon={<CloseOutlined size={25} />}
                            className="flex items-center justify-center object-contain object-center"
                          />
                        ) : null}
                      </div>
                      {renderSwiper(fields, imageSelected)}
                    </div>
                  </div>
                );
              }}
            </Form.List>

            <Button block type="primary" htmlType="submit">
              保存
            </Button>
          </Form>
        </TabPane>
      ))}
      {contextHolder}
    </Tabs>
  );
}

export default Banner;
