import React from 'react';
import PropTypes from 'prop-types';
import { Table, Avatar, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { formatPoint } from 'utils/helpers';
import { useHistory, Link } from 'react-router-dom';

const FIELD = {
  AVATAR: {
    title: '',
    name: 'avatar',
  },
  FULLNAME: {
    title: 'フルネーム',
    name: 'fullName',
  },
  POINT: {
    title: 'ポイント',
    name: 'total',
  },
  RATING: {
    title: '評価',
    name: 'avgRating',
  },
};

const UnverifyTrainersTable = ({ showType = 'points', rankData = {} }) => {
  const history = useHistory();
  const { data, isLoading } = rankData;
  const dataSource = (data || {})?.[showType] || [];

  const pointColumns = [
    {
      title: '#',
      width: '10%',
      render: (value, item, index) => <span>{index + 1}</span>,
    },
    {
      width: '30%',
      render: ({ id, [FIELD.AVATAR.name]: avt, userType }) => (
        <Button
          type="text"
          size="small"
          className="bg-transparent"
          onClick={() => {
            const query = new URLSearchParams(history.location.search);
            query.set('action', 'edit');
            query.set('id', id);
            history.push({
              pathname: `/${userType === 'training' ? 'user' : 'trainer'}`,
              search: query.toString(),
            });
          }}
          block
          icon={<Avatar className="flex justify-center items-center" src={avt} icon={<UserOutlined />} />}
        />
      ),
    },
    {
      title: <p className="text-center whitespace-no-wrap">{FIELD.FULLNAME.title}</p>,
      key: FIELD.FULLNAME.name,
      width: '30%',
      render: ({ id, [FIELD.FULLNAME.name]: name, userType }) => (
        <Link to={`/${userType === 'training' ? 'user' : 'trainer'}?action=edit&id=${id}`}>
          <span className="">{name}</span>
        </Link>
      ),
    },
    {
      title: <p className="text-center whitespace-no-wrap">{FIELD.POINT.title}</p>,
      key: FIELD.POINT.name,
      width: '30%',
      render: ({ [FIELD.POINT.name]: value }) => formatPoint(value),
    },
  ];
  const ratingColumns = [
    {
      title: '#',
      width: '10%',
      render: (value, item, index) => <span>{index + 1}</span>,
    },
    {
      width: '30%',
      render: ({ id, [FIELD.AVATAR.name]: avt, userType }) => (
        <Button
          type="text"
          size="small"
          className="bg-transparent"
          onClick={() => {
            const query = new URLSearchParams(history.location.search);
            query.set('action', 'edit');
            query.set('id', id);
            history.push({
              pathname: `/${userType === 'training' ? 'user' : 'trainer'}`,
              search: query.toString(),
            });
          }}
          block
          icon={<Avatar className="flex justify-center items-center" src={avt} icon={<UserOutlined />} />}
        />
      ),
    },
    {
      title: <p className="text-center whitespace-no-wrap">{FIELD.FULLNAME.title}</p>,
      key: FIELD.FULLNAME.name,
      width: '30%',
      render: ({ id, [FIELD.FULLNAME.name]: name, userType }) => (
        <Link to={`/${userType === 'training' ? 'user' : 'trainer'}?action=edit&id=${id}`}>
          <span>{name}</span>
        </Link>
      ),
    },
    {
      title: <p className="text-center whitespace-no-wrap">{FIELD.RATING.title}</p>,
      key: FIELD.RATING.name,
      width: '30%',
      render: ({ [FIELD.RATING.name]: value }) => value,
    },
  ];
  return (
    <div className="w-full">
      <Table
        className="w-full bg-white overflow-y-scroll"
        rowClassName="align-top whitespace-pre-wrap break-words fixBreakWordsJapan text-center"
        components={{
          header: {
            cell: ({ className, style, ...restProps }) => {
              return <th className={className} style={{ ...style, textAlign: 'center' }} {...restProps} />;
            },
          },
        }}
        bordered
        columns={showType === 'points' ? pointColumns : ratingColumns}
        dataSource={dataSource}
        loading={isLoading}
        rowKey="id"
        pagination={false}
      />
    </div>
  );
};

UnverifyTrainersTable.propTypes = {
  showType: PropTypes.string,
  rankData: PropTypes.objectOf(PropTypes.any),
};

export default UnverifyTrainersTable;
