import React, { useEffect } from 'react';
import { Select } from 'antd';
import { useGetBranch } from 'redux/Hooks/bank';
import PropTypes from 'prop-types';

const BranchSelect = (props) => {
  const {
    results: branchList,
    setQuery: setQueryBranchList,
    isFetching: isFetchingBranchList,
    isRefreshing: isRefreshingBranchList,
  } = useGetBranch(false, 2000, { bankNumber: props?.currentBank?.bankNumber });

  useEffect(() => {
    if (!isFetchingBranchList) {
      setQueryBranchList({ bankNumber: props?.currentBank?.bankNumber });
    }

    return () => {
      setQueryBranchList({});
    };
  }, [props?.currentBank?.bankNumber]);

  return (
    <Select
      placeholder="支店名"
      showSearch
      allowClear
      loading={isFetchingBranchList || isRefreshingBranchList}
      options={branchList?.map((bank) => ({ label: bank?.branchName, value: bank?.[props?.valueprop] }))}
      {...props}
    />
  );
};

BranchSelect.propTypes = {
  valueprop: PropTypes.string,
};

BranchSelect.defaultProps = {
  valueprop: 'branchName',
};

export default BranchSelect;
