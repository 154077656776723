import React from 'react';
import { useGetStatistics } from 'redux/Hooks/statistics';
import TrainerNeedVerify from './TrainerNeedVerify';
import MessageUnread from './MessageUnread';
import LessionOverTime from './LessionOverTime';
import TotalPaymentRequest from './TotalPaymentRequest';
import TotalReportPending from './TotalReportPending';
import AlertItem from './AlertItem';
import GenderPercent from './GenderPercent';

const AlertDashboard = () => {
  const { data: alert, fetch } = useGetStatistics();

  React.useEffect(() => {
    fetch();
  }, []);

  return (
    <>
      <div className="flex w-full">
        <TrainerNeedVerify loading={alert?.isLoading} data={alert?.data?.trainerNeedVerify} />
        <LessionOverTime loading={alert?.isLoading} data={alert?.data?.lessonOverTime} />
        <MessageUnread loading={alert?.isLoading} data={alert?.data?.messageUnRead} />
        <TotalReportPending loading={alert?.isLoading} data={alert?.data?.totalReportPending} />
      </div>
      <div className="flex w-full">
        <AlertItem title="ユーザー数" value={alert?.data?.totalUser} loading={alert?.isLoading} path="/user" />
        <AlertItem title="トレーナー数" value={alert?.data?.totalTrainer} loading={alert?.isLoading} path="/trainer" />
      </div>
      <div className="flex w-full">
        <GenderPercent value={alert?.data?.gender} />
      </div>
    </>
  );
};

export default AlertDashboard;
