import React, { useState, useEffect } from 'react';
import {
  Layout,
  Card,
  Input,
  Divider,
  Space,
  Button,
  DatePicker,
  List,
  Avatar,
  Modal,
  Form,
  Image,
  message,
  Typography,
} from 'antd';
import { SearchOutlined, CloseOutlined, UserOutlined, PictureOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { useTweets, useDeleteTweet } from 'redux/Hooks/tweet';
import { pickBy, identity, pick } from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';
import qs from 'qs';

const { RangePicker } = DatePicker;

const Tweet = (props) => {
  const { location } = props;
  const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [page, setPage] = useState(parsedQuery?.page && Number(parsedQuery?.page) ? Number(parsedQuery?.page) - 1 : 0);
  const [pageSize, setPageSize] = useState(30);
  const [searchFilter, setSearchFilter] = useState({});

  const [formEdit] = Form.useForm();

  const initialValues = {};

  const [modal, contextHolder] = Modal.useModal({});

  const { results = [], currentPage = 0, totalPages = 0, isFetching: loading, refresh, setQuery } = useTweets();
  const { onDelete, prevIsFetching, isFetching } = useDeleteTweet();

  useEffect(() => {
    setQuery({
      ...searchFilter,
      pageSize,
      page,
    });
  }, [pageSize, page]);

  useEffect(() => {
    setQuery({
      ...searchFilter,
      pageSize,
      page: 0,
    });
  }, [searchFilter]);

  useEffect(() => {
    if (!isFetching && prevIsFetching) {
      refresh();
    }
  }, [isFetching]);

  const handleFinish = async (values) => {
    const inputValues = pickBy(values, identity);
    const inputUsername = pick(inputValues, ['fullName']);
    const inputContent = pick(inputValues, ['content']);
    const inputCreated = pick(inputValues, ['created']);

    const filters = {};

    if (inputUsername?.fullName) {
      filters.fullName = inputUsername?.fullName;
    }
    if (inputContent?.content) {
      filters.search = inputContent?.content;
    }
    if (inputCreated?.created) {
      const [begin, end] = inputCreated?.created;
      filters.from = moment(begin).utc().startOf('day').format();
      filters.to = moment(end).utc().endOf('day').format();
    }
    setSearchFilter(filters);
  };

  const onOk = async (close, record) => {
    try {
      onDelete(record.id);
      message.success('実行完了');
    } catch (deleteError) {
      message.error('エラー');
    } finally {
      close();
    }
  };

  const onCancel = (close) => {
    close();
  };

  const modalDeleteConfig = (record) => {
    return {
      className: '',
      title: '確認',
      content: <h1>削除しますか？</h1>,
      onOk: (close) => onOk(close, record),
      onCancel,
      okButtonProps: {},
      cancelButtonProps: {},
      okText: '削除',
      okType: 'primary',
      cancelText: 'キャンセル',
      icon: null,
      mask: true,
      maskClosable: true,
      style: {},
      maskStyle: {},
      keyboard: true,
      autoFocusButton: 'ok',
    };
  };

  const _renderMedia = (media) => {
    if (media?.link) {
      switch (media?.type) {
        case 'video':
          return (
            <Image
              width="60%"
              src={media?.thumbnailUrl}
              placeholder={<VideoCameraOutlined style={{ fontSize: '24em', width: '100%' }} />}
              preview={false}
              className="max-w-full"
            />
          );
        case 'image':
        default:
          return (
            <Image
              width="60%"
              src={media?.link}
              placeholder={<PictureOutlined style={{ fontSize: '24em', width: '100%' }} />}
              preview={false}
            />
          );
      }
    }
    return null;
  };

  const _renderItem = (item, index) => {
    return (
      <List.Item key={index} className="flex flex-row flex-no-wrap">
        <div className="flex flex-row justify-start flex-grow flex-nowrap w-full">
          <div style={{ width: 50, height: 50 }}>
            <Avatar
              className="object-contain "
              src={item?.user?.avatar || ''}
              size="large"
              style={{ fontSize: 50 }}
              icon={<UserOutlined className="object-contain " />}
            />
          </div>

          <div className="flex-grow flex-col justify-start flex-wrap space-y-4 overflow-hidden px-4 ">
            <p className="flex whitespace-pre-wrap break-words fixBreakWords space-x-4">
              <span className="font-black truncate">{item?.user?.fullName}</span>
              <span className="whitespace-pre">
                {item?.created ? moment.utc(item?.created).local().format('YYYY-MM-DD HH:mm') : ''}
              </span>
            </p>
            {_renderMedia(item?.attachment)}
            <p className="font-light whitespace-pre-wrap break-words fixBreakWords">{item?.content}</p>
          </div>
          <Button type="text" onClick={() => modal.confirm(modalDeleteConfig(item))} icon={<CloseOutlined />} />
        </div>
      </List.Item>
    );
  };

  const handleChangePage = (pageNumber) => {
    const query = new URLSearchParams(props.history.location.search);
    query.set('page', pageNumber);
    setPage(pageNumber - 1);
    props.history.push({
      pathname: props.location.pathname,
      search: query.toString(),
    });
  };

  const handleChangePageSize = (current, size) => {
    const query = new URLSearchParams(props.history.location.search);
    query.set('page', 1);
    query.set('limit', size);
    setPageSize(size);
    props.history.push({
      pathname: props.location.pathname,
      search: query.toString(),
    });
  };

  const pagination = {
    showSizeChanger: true,
    showQuickJumper: true,
    onChange: handleChangePage,
    onShowSizeChange: handleChangePageSize,
    current: currentPage + 1,
    pageSize,
    total: (totalPages || 0) * pageSize,
  };

  return (
    <Layout.Content className="flex flex-col p-4">
      <Typography.Title level={3}>つぶやき管理</Typography.Title>
      <Card>
        <Form form={formEdit} layout="vertical" name="search" initialValues={initialValues} onFinish={handleFinish}>
          <div className="flex py-4">
            <div className="flex flex-row flex-grow justify-between space-x-4">
              <div className="flex flex-col flex-grow space-y-4">
                <div className="flex flex-row flex-grow justify-start space-x-4 ">
                  <Form.Item name="fullName" className="w-4/12">
                    <Input placeholder="カスタマー" />
                  </Form.Item>
                  <Form.Item name="created" className="w-4/12">
                    <RangePicker size="middle" />
                  </Form.Item>
                </div>
                <div className="flex flex-row">
                  <Form.Item name="content" className="w-4/12">
                    <Input placeholder="つぶやき内容" className=" " />
                  </Form.Item>
                </div>
              </div>
              <div className="flex ">
                <Button icon={<SearchOutlined />} htmlType="submit" type="ghost">
                  検索
                </Button>
              </div>
            </div>
          </div>
        </Form>
        <Divider type="horizontal" />
        <List
          loading={loading}
          pagination={pagination}
          rowKey="id"
          itemLayout="vertical"
          dataSource={results}
          renderItem={_renderItem}
        />
      </Card>
      {contextHolder}
    </Layout.Content>
  );
};

Tweet.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  location: PropTypes.objectOf(PropTypes.any),
};

export default Tweet;
