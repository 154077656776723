import { API } from 'configs';
import { usePaging, usePost, useDelete } from 'utils/AxiosHooks';

const useNotifications = () => usePaging(API.ADMIN.NOTIFICATIONS.LIST, false);

const useSendNotifications = () => {
  // eslint-disable-next-line no-unused-vars
  const [doPost, prevLoading, requestState] = usePost({
    url: API.ADMIN.NOTIFICATIONS.SEND,
  });
  const doSendNoti = (body) => doPost({ body });
  return [doSendNoti, requestState];
};

const useDeleteNoti = (onSuccess) => useDelete(API.ADMIN.NOTIFICATIONS.DELETE, onSuccess);

export { useSendNotifications, useNotifications, useDeleteNoti };
