import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Menu, Spin } from 'antd';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router-dom';
import { authState } from 'store/atom';
import { useLogin } from 'utils/hooks/useLogin';

import HeaderDropdown from './HeaderDropdown';
import styles from './styles.module.css';

const AvatarDropdown = () => {
  const history = useHistory();
  const { logoutUser } = useLogin();
  const auth = useRecoilValue(authState);
  const onMenuClick = (event) => {
    const { key } = event;

    if (key === 'logout') {
      logoutUser();
      return;
    }

    history.push(`/account/${key}`);
  };

  const menuHeaderDropdown = (
    <Menu className={styles.menu} selectedKeys={[]} onClick={onMenuClick}>
      <Menu.Item key="logout">
        <LogoutOutlined />
        ログアウト
      </Menu.Item>
    </Menu>
  );
  return (
    <HeaderDropdown overlay={menuHeaderDropdown}>
      <span className={`${styles.action} ${styles.account}`}>
        {auth?.admin?.id ? (
          <>
            <span className={styles.name}>{auth?.admin?.username || ''} &nbsp;&nbsp;</span>
            <Avatar size="small" className={styles.avatar} icon={<UserOutlined />} alt="avatar" />
          </>
        ) : (
          <span className={`${styles.action} ${styles.account}`}>
            <Avatar size="small" className={styles.avatar} icon={<UserOutlined />} alt="avatar" />
          </span>
        )}
      </span>
    </HeaderDropdown>
  );
};

export default AvatarDropdown;
