import { API } from 'configs';
import { usePaging, useRequest, useAddEdit, useDelete } from 'utils/AxiosHooks';

const useCompanies = () => usePaging(API.ADMIN.COMPANY.LIST);

const useGetCompany = (id) =>
  useRequest({
    url: id && Number(id) ? API.ADMIN.COMPANY.DETAIL.replace(':id', Number(id)) : API.ADMIN.COMPANY.DETAIL,
    method: 'get',
    fetchOnMount: false,
  });

const useEditCompany = (onSuccess, onFail) => useAddEdit(API.ADMIN.COMPANY.DETAIL, 'edit', onSuccess, onFail);
const useEditCompanyImport = (onSuccess, onFail) => useAddEdit(API.ADMIN.COMPANY.IMPORT, 'add', onSuccess, onFail);
const useAddCompany = (onSuccess, onFail) => useAddEdit(API.ADMIN.COMPANY.CREATE, 'add', onSuccess, onFail);
const useDeleteCompany = (onSuccess, onFail) => useDelete(API.ADMIN.COMPANY.DELETE, onSuccess, onFail);

export { useCompanies, useGetCompany, useEditCompany, useAddCompany, useDeleteCompany, useEditCompanyImport };
