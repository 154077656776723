import { useState } from 'react';
import { getBase64 } from '../helpers';

export function useUpload() {
  const [state, setState] = useState({ loading: false, imageUrls: [] });

  async function onChange({ fileList }) {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));

      const getImageUrls = [...fileList].map((file) => getBase64(file.originFileObj));

      const imageUrls = await Promise.all(getImageUrls);

      setState({
        loading: false,
        imageUrls,
      });
    } catch (e) {
      throw new Error(e);
    }
  }

  return {
    state,
    setState,
    uploadProps: { onChange },
  };
}
