import React, { useEffect, useRef } from 'react';
import AgoraRTC from 'agora-rtc-sdk';
import Hls from 'hls.js';
import { AGORA_ID } from 'configs/API';

const client = AgoraRTC.createClient({
  mode: 'live',
});

client.setClientRole('audience');
client.init(AGORA_ID);

export default (props) => {
  const videoRef = useRef();
  const { livestream: data } = props;
  const { livestream } = data;
  const hls = new Hls();

  useEffect(() => {
    if (videoRef.current) {
      if (!livestream?.livestreaming && livestream?.streamUrl) {
        if (Hls.isSupported()) {
          hls.loadSource(livestream?.streamUrl);
          hls.attachMedia(videoRef?.current);
          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            const isPlaying =
              videoRef.current.currentTime > 0 &&
              !videoRef.current.paused &&
              !videoRef.current.ended &&
              videoRef.current.readyState > 2;
            if (!isPlaying) {
              videoRef.current.play();
            }
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    return function cleanup() {
      if (videoRef?.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    };
  });

  return (
    <div>
      <video ref={videoRef} controls autoPlay muted="muted" style={{ height: 480, width: '100%' }}>
        <source type="application/x-mpegURL" />;
      </video>
    </div>
  );
};
