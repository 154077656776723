/* eslint-disable react/prop-types */
import React from 'react';
import { Result } from 'antd';
import { useRecoilValue } from 'recoil';
import { authState } from 'store/atom';
import { useSyncingMe } from 'redux/Hooks/me';
import check from './checkPermissions';

const Authorized = ({
  children,
  authority,
  permission,
  noMatch = <Result status="403" title="403" subTitle="このウェブページにアクセスできません" />,
}) => {
  const { user = {}, permissions = [] } = useRecoilValue(authState);
  const childrenRender = typeof children === 'undefined' ? null : children;
  const dom = check(authority, user?.role, childrenRender, noMatch);

  if (permission) {
    const found = permissions.find((item) => item.code === permission);

    if (found && found.allow) {
      return dom;
    }

    return noMatch;
  }

  return <>{dom}</>;
};

export default Authorized;
