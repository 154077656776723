import { API } from 'configs';
import { usePaging, useAddEdit, useDelete } from 'utils/AxiosHooks';

const useTweets = () => usePaging(API.ADMIN.TWEET.LIST, false);

const useEditTweet = (id) => useAddEdit(API.ADMIN.TWEET.EDIT, 'edit');
const useAddTweet = (id) => useAddEdit(API.ADMIN.TWEET.ADD, 'add');
const useDeleteTweet = (onSuccess) => useDelete(API.ADMIN.TWEET.EDIT, onSuccess);

export { useTweets, useEditTweet, useAddTweet, useDeleteTweet };
