import { POINT_HISTORY, REPORT } from 'utils/constant';

export const USER_TYPES = {
  TRAINER: 'trainer',
  USER: 'training',
};

export const USER_TYPES_JP = {
  [USER_TYPES.TRAINER]: 'トレーナー',
  [USER_TYPES.USER]: 'トレーニー',
};

export const USER_STATUS = {
  ACTIVE: 'active',
  DELETED: 'deleted',
  BANNED: 'banned',
};

export const POINT_HISTORY_TYPE_JP = {
  [POINT_HISTORY.TYPE.SEND_BONUS]: 'ボーナス付与',
  [POINT_HISTORY.TYPE.RECEIVE_BONUS]: 'ボーナス受け取り',
  [POINT_HISTORY.TYPE.BUY_POINT]: '購入',
  [POINT_HISTORY.TYPE.PAY_LESSON]: 'トレーニングポイント付与',
  [POINT_HISTORY.TYPE.RECEIVE_LESSON]: 'トレーニングポイント受け取り。',
  [POINT_HISTORY.TYPE.REFUND_LESSON]: 'トレーニングキャンセル',
  [POINT_HISTORY.TYPE.PURCHASE_GIFT]: 'ギフト購入。',
  [POINT_HISTORY.TYPE.SEND_GIFT]: 'ギフト付与。',
  [POINT_HISTORY.TYPE.RECEIVE_GIFT]: 'ギフト受け取り。',
  [POINT_HISTORY.TYPE.WITHDRAW]: '振込み',
  [POINT_HISTORY.TYPE.ADMIN_CHANGE]: '管理者が付与',
};

export const REPORT_TYPE_JP = {
  [REPORT.TYPE.CONVERSATION]: 'メッセージ',
  [REPORT.TYPE.USER]: 'ユーザー',
};

const GENDERS = [
  {
    label: '男性',
    value: 'male',
  },
  {
    label: '女性',
    value: 'female',
  },
];

export const LANGUAGES_CODE = {
  ja: 'languages.japanese',
  en: 'languages.english',
  cn: 'languages.chinese',
  kr: 'languages.korean',
  fr: 'languages.french',
  gr: 'languages.german',
  ot: 'languages.other',
};

export const LANGUAGES_LANG = {
  [LANGUAGES_CODE.ja]: {
    ja: '日本語',
    en: 'Japanese',
  },
  [LANGUAGES_CODE.en]: {
    ja: '英語',
    en: 'English',
  },
  [LANGUAGES_CODE.cn]: {
    ja: '中国語',
    en: 'Chinese',
  },
  [LANGUAGES_CODE.kr]: {
    ja: '韓国語',
    en: 'Korean',
  },
  [LANGUAGES_CODE.fr]: {
    ja: 'フランス語',
    en: 'French',
  },
  [LANGUAGES_CODE.gr]: {
    ja: 'ドイツ語',
    en: 'German',
  },
  [LANGUAGES_CODE.ot]: {
    ja: 'その他',
    en: 'other',
  },
};

export const OPTION_LANGUAGES = Object.values(LANGUAGES_CODE).map((code) => ({
  title: LANGUAGES_LANG[code],
  tag: code,
}));

export const PROFILE_JP = {
  height: '身長',
  weight: '体重',
  drink: 'お酒',
  hometown: '出身地',
  brothers_and_sisters: '兄弟姉妹',
  language: '話せる言語',
  motto: '座右の銘',
  nickname: 'ニックネーム',
  pets: 'ペット',
  title: 'ひとこと',
  trainer_history: 'トレーニング歴',
  where_u_live: '居住地',
  trainingType: '実践できるトレーニング',
  arrTrainingType: '実践できるトレーニング',
  certificate: '保有資格',
  // tags
  appear_point: 'アピールポイント',
  horoscope: '星座',
  body_type: '体型・タイプゴ',
  blood_type: '血液型',
};

export const TAGS_PROFILE = {
  BODY_TYPE: 'body_type',
  HEIGHT: 'height',
  HOROSCOPE: 'horoscope',
  APPEAR_POINT: 'appear_point',
};

export const FIELDS_PROFILE = {
  NICKNAME: 'nickname',
  ONE_THING: 'title',
  HEIGHT: 'height',
  WEIGHT: 'weight',
  LIVE: 'where_u_live',
  HOMETOWN: 'hometown',
  BLOOD_TYPE: 'blood_type',
  // BROTHER_SISTER: 'brothers_and_sisters',
  LANGUAGE: 'language',
  CERTIFICATE: 'certificate',
  TRAINING_TYPE: 'trainingType',
  TRAINING_TYPE_LIST: 'arrTrainingType',
  TRAINER_HISTORY: 'trainer_history',
  // Tags
  BODY_TYPE: 'body_type',
  HOROSCOPE: 'horoscope',
  APPEAR_POINT: 'appear_point',
};

export const SCHEDULE_STATUS = {
  cancelled: 'cancelled',
  active: 'active',
  done: 'done',
};

export const SCHEDULE_STATUS_JP = {
  [SCHEDULE_STATUS.cancelled]: 'キャンセル',
  [SCHEDULE_STATUS.active]: '成立',
  [SCHEDULE_STATUS.done]: '終了',
};

export default {
  GENDERS,
  USER_TYPES,
};

export const DEFAULT_PAGE_SIZE = 30;

export const CONCIERGE_VIEW = {
  CONCIERGE: 'concierge',
  INQUIRY: 'inquiry',
};
