import { API } from 'configs';
import { usePaging, useRequest } from 'utils/AxiosHooks';

const useGetReports = (fetchOnMount = false) => usePaging(API.ADMIN.REPORT.LIST, fetchOnMount);

const useResolveReport = () =>
  useRequest({
    method: 'get',
    fetchOnMount: false,
  });

const useDeleteReport = () =>
  useRequest({
    method: 'delete',
    fetchOnMount: false,
  });

export { useGetReports, useResolveReport, useDeleteReport };
