import { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { getToken, getLatestUnique } from 'utils/Function';
import { SOCKET_IO } from 'configs/API';

const SocketIO = (() => {
  let instance;

  const init = (query) => {
    return io(SOCKET_IO, {
      transports: ['polling', 'websocket'],
      query,
      jsonp: false,
    });
  };

  return {
    getInstance: (query) => {
      if (!instance) instance = init(query);
      if (instance.disconnected || !instance.connected) {
        instance.connect();
      }
      return instance;
    },
  };
})();

const useMessagesAdd = (refresh = () => null) => {
  const token = getToken() ? `Bearer ${getToken()}` : null;
  const [currentConv, setCurrentConv] = useState(null);
  const [messages, setMessages] = useState([]);

  const joinConversation = (convId) => {
    const socket = SocketIO.getInstance({ token });
    socket.emit('joinRoom', `conversation_${convId}`);
    setCurrentConv(convId);
  };

  const disconnect = () => {
    const socket = SocketIO.getInstance({ token });

    socket.emit('outRoom', `conversation_${currentConv}`);
    socket.removeListener('connect');
    socket.removeListener('message');
  };

  useEffect(() => {
    const socket = SocketIO.getInstance({ token });
    socket.on('connect', () => {
      if (currentConv) {
        joinConversation(currentConv);
      }
    });

    socket.on('message', (message) => {
      if (message?.message?.conversationId === currentConv) {
        setMessages((prevMessages) => [message.message, ...prevMessages.filter((item) => !item.temp)]);
      } else {
        refresh();
      }
    });

    return disconnect;
  }, [currentConv]);
  return [joinConversation, setMessages, messages, disconnect];
};

const useConversationAdd = () => {
  const token = getToken() ? `Bearer ${getToken()}` : null;
  const [conversation, setConversation] = useState([]);
  const addListeners = () => {
    const socket = SocketIO.getInstance({ token });

    socket.on('conversation', (conv) => {
      setConversation((prevConversation) => getLatestUnique(prevConversation, [conv.conversation]));
    });
  };

  const connect = () => {
    addListeners();
  };

  const disconnect = () => {
    const socket = SocketIO.getInstance({ token });
    socket.removeListener('conversation');
  };

  useEffect(() => {
    addListeners();
    return disconnect;
  }, []);

  return [conversation, connect, disconnect, setConversation];
};

export { useMessagesAdd, useConversationAdd };
