import { useAddEdit, usePaging, useDelete } from 'utils/AxiosHooks';
import { API } from 'configs';

const useTagCategories = () => usePaging(API.ADMIN.TAG_CATEGORY.LIST, false, 10000, { page: 0 });

const useCreateTagCategory = () => useAddEdit(API.ADMIN.TAG_CATEGORY.ADD, 'add');

const useEditTagCategory = (id) =>
  useAddEdit(id ? API.ADMIN.TAG_CATEGORY.EDIT.replace(':id', id) : API.ADMIN.TAG_CATEGORY.EDIT, 'edit');

const useDeleteTagCategory = (id, onSuccess = () => {}, onFail = () => {}) =>
  useDelete(id ? API.ADMIN.TAG_CATEGORY.EDIT.replace(':id', id) : API.ADMIN.TAG_CATEGORY.EDIT, onSuccess, onFail);

export { useTagCategories, useCreateTagCategory, useEditTagCategory, useDeleteTagCategory };
