import React from 'react';
import { Image, Input } from 'antd';
import { PictureOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const TrainingTypeItem = ({
  className = 'flex-col space-y-1',
  classNameForName = '',
  classNameForTrainingType = '',
  classNameForTrainingTypeImage = '',
  value,
  size = '4rem',
  showName = true,
}) => {
  const { image, name } = value || {};
  if (!value) {
    return null;
  }

  return (
    <div className={classnames('flex items-center ', className)}>
      <div className={classnames('flex justify-center items-center rounded-full ', classNameForTrainingType)}>
        <Image
          className={classnames('flex justify-center items-center object-contain', classNameForTrainingTypeImage)}
          width={size}
          height={size}
          src={image}
          placeholder={<PictureOutlined style={{ fontSize: size }} />}
          preview={false}
        />
      </div>
      {showName && name && <p className={classnames(classNameForName)}>{name}</p>}
    </div>
  );
};

TrainingTypeItem.propTypes = {
  classNameForName: PropTypes.string,
  classNameForTrainingType: PropTypes.string,
  classNameForTrainingTypeImage: PropTypes.string,
  value: PropTypes.object,
  size: PropTypes.string,
  showName: PropTypes.object,
};

export default TrainingTypeItem;
