import React, { useState, useEffect } from 'react';
import { Layout, Space, Button, Table, Modal, message, Input } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { useStaticContents, useEditStaticContent, useAddStaticContent } from 'redux/Hooks/staticContent';
import EditModal from './EditModal';

const StaticContent = () => {
  const [{ data: results }, refresh] = useStaticContents();

  const {
    prevFetching: prevIsFetching,
    isFetching,
    success,
    message: successResponse,
    onSave,
  } = useEditStaticContent();
  const {
    prevFetching: prevIsFetchingAdd,
    isFetching: isFetchingAdd,
    success: successAdd,
    message: errorResponse,
    onSave: onAdd,
  } = useAddStaticContent();

  const [visibleEdit, setVisibleEdit] = useState(false);
  const [editSelected, setEditSelected] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [modal, contextHolder] = Modal.useModal({});

  useEffect(() => {
    if (isFetching !== prevIsFetching && prevIsFetching) {
      if (success) {
        message.success('実行完了');
        refresh();
      } else {
        message.error('エラー');
        console.error(successResponse?.message);
      }
    }
  }, [isFetching]);

  useEffect(() => {
    if (isFetchingAdd !== prevIsFetchingAdd && prevIsFetchingAdd) {
      if (successAdd) {
        message.success('実行完了');
        refresh();
      } else {
        message.error('エラー');
        console.error(errorResponse?.message);
      }
    }
  }, [isFetchingAdd]);

  const dataSource = results || [];

  const onChangeItem = async (values, recordId) => {
    try {
      if (recordId) {
        onSave(values, values?.id);
      } else {
        onAdd(values);
      }
    } catch (createError) {
      message.error('エラー');
    } finally {
      setVisibleEdit(false);
      setEditSelected(null);
    }
  };

  const _renderAction = (record) => (
    <Space size="middle">
      <Button
        onClick={() => {
          setVisibleEdit(true);
          setEditSelected(record);
        }}
        icon={<EditOutlined />}
      />
    </Space>
  );

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'コード名',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: '名称',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '',
      key: 'action',
      render: _renderAction,
    },
  ];

  const editInputs = [
    {
      title: 'コード名',
      key: 'code',
      InputComponent: Input,
      inputProps: { disabled: true },
      formItemProps: {
        rules: [{ required: true }],
        hidden: true,
      },
    },
    {
      title: '名称',
      key: 'title',
      InputComponent: Input,
      inputProps: {},
      formItemProps: {
        rules: [{ required: true }],
      },
    },
  ];

  return (
    <Layout.Content className="flex flex-col p-4">
      <Table
        className="bg-white overflow-x-scroll"
        rowClassName="align-top whitespace-pre-wrap break-words fixBreakWordsJapan text-center"
        components={{
          header: {
            cell: ({ className, style, ...restProps }) => {
              return <th className={className} style={{ ...style, textAlign: 'center' }} {...restProps} />;
            },
          },
        }}
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        rowKey="id"
      />
      {contextHolder}
      {visibleEdit && (
        <EditModal
          visible={visibleEdit}
          onCreate={onChangeItem}
          onCancel={() => {
            setVisibleEdit(false);
            setEditSelected(null);
          }}
          modalProps={{
            width: '80%',
          }}
          record={editSelected}
          editInputs={editInputs}
          headerTitle="利用規約編集"
        />
      )}
    </Layout.Content>
  );
};

export default StaticContent;
