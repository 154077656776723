import { useRecoilValue } from 'recoil';
import { authState } from 'store/atom';

export const usePermission = (permission) => {
  const { permissions = [] } = useRecoilValue(authState);

  if (permission) {
    const found = permissions.find((item) => item.code === permission);

    if (!found || !found.allow) {
      window.location = '/dashboard';
    }
  }
};

export const useCheckPermission = (permission) => {
  const { permissions = [] } = useRecoilValue(authState);

  if (permission) {
    const found = permissions.find((item) => item.code === permission);

    if (found && found.allow) {
      return true;
    }
  }
  return false;
};
