import React, { useState, useEffect } from 'react';
import { Card, Button, notification } from 'antd';
import qs from 'qs';
import PropTypes from 'prop-types';
import { API } from 'configs';
import { useLocation, useHistory } from 'react-router-dom';
import BankAccount from 'containers/User/components/BankAccount';
import Edit from 'containers/User/components/Edit';
import Add from 'containers/User/components/Add';
import Detail from 'containers/User/components/Detail';
import Profile from 'containers/User/components/Profile';
import AddPointModal from 'containers/Point/components/AddPointModal';
import { useGetUser } from 'redux/Hooks/user';
import { useAddPoint } from 'redux/Hooks/pointHistory';
import { useConversationDetailByUserId } from 'redux/Hooks/conversation';
import { useCheckPermission } from 'utils/hooks/usePermission';
import { USER_TYPES } from 'configs/Constants';

export const TABS = {
  profile: 'profile',
  detail: 'detail',
  add: 'add',
  edit: 'edit',
  bankAccount: 'bankAccount',
};

const Tabs = (props) => {
  const { item, userType } = props;
  const location = useLocation();
  const history = useHistory();
  const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
  const action = parsedQuery?.action || 'edit';
  const [tab, setTab] = useState(action);
  const [modalVisible, setModalVisible] = useState(false);
  const [target, setTarget] = useState(null);
  const [{ data: dataUser }, doRequest] = useGetUser(parsedQuery?.id);
  const [{ data: dataConv }, doRequestConv] = useConversationDetailByUserId(parsedQuery?.id);
  const {
    prevFetching: prevIsFetchingAdd,
    isFetching: isFetchingAdd,
    success: successAdd,
    message: errorResponse,
    onSave: onSaveAdd,
  } = useAddPoint();
  const isAllow = useCheckPermission('update-point');
  const isAdminManage = useCheckPermission('manage-admin');

  useEffect(() => {
    try {
      if (parsedQuery?.id) {
        doRequest({
          url: API.ADMIN.USER.EDIT.replace(':id', Number(parsedQuery?.id)),
        });
        doRequestConv();
      }
    } catch (error) {
      //
    }
  }, [parsedQuery?.id]);

  useEffect(() => {
    if (prevIsFetchingAdd && !isFetchingAdd) {
      if (successAdd) {
        doRequest({
          url: API.ADMIN.USER.EDIT.replace(':id', Number(parsedQuery?.id)),
        });
        notification.success({
          message: '成功',
          description: '追加しました',
        });
      } else {
        notification.error({
          message: 'エラー',
          description: errorResponse?.message || '後でもう一度やり直してください',
        });
      }
    }
  }, [successAdd, isFetchingAdd, prevIsFetchingAdd, errorResponse?.message]);

  const onOpenModal = (t) => {
    setModalVisible(true);
    setTarget(t);
  };

  const onCancel = () => {
    setModalVisible(false);
    setTarget(null);
  };

  const handleSubmit = async (values) => {
    try {
      onSaveAdd(values);
      onCancel();
    } catch (updateErr) {
      if (updateErr?.errorFields) return null;
      notification.error({
        message: 'エラー',
        description: '後でもう一度やり直してください',
      });
      console.error(updateErr.message);
    }
  };

  const _renderTab = (tabKey) => {
    switch (tabKey) {
      case TABS.bankAccount:
        return <BankAccount item={item} {...props} />;
      case TABS.profile:
        return <Profile item={item} {...props} />;
      case TABS.detail:
        return <Detail item={item} {...props} />;
      case TABS.add:
        return <Add {...props} />;
      case TABS.edit:
      default:
        return <Edit item={item} {...props} />;
    }
  };

  const tabList = {
    [USER_TYPES.TRAINER]: [
      { key: TABS.edit, tab: '編集' },
      { key: TABS.detail, tab: '詳細', disabled: !item },
      { key: TABS.profile, tab: 'プロフィール', disabled: !item },
      { key: TABS.bankAccount, tab: '銀行情報', disabled: !isAdminManage || !item },
    ],
    [USER_TYPES.USER]: [
      { key: TABS.edit, tab: '編集' },
      { key: TABS.detail, tab: '詳細', disabled: !item },
      { key: TABS.profile, tab: 'プロフィール', disabled: !item },
    ],
  };

  return (
    <div className="flex flex-col p-4">
      <Card
        key="host"
        style={{ width: '100%' }}
        className="bg-white overflow-x-scroll"
        tabList={tabList[userType]}
        bodyStyle={
          tab === TABS.detail
            ? {
                backgroundColor: '#f0f0f7',
                padding: 0,
              }
            : {}
        }
        activeTabKey={tab}
        onTabChange={(activeKey) => {
          setTab(activeKey);
        }}
        tabBarExtraContent={
          // eslint-disable-next-line react/jsx-no-comment-textnodes
          // eslint-disable-next-line react/jsx-wrap-multilines
          <div className="flex flex-row space-x-4">
            <Button
              type="ghost"
              onClick={() => {
                const query = new URLSearchParams(history.location.search);
                query.set('id', dataConv?.id);
                history.push({
                  pathname: '/concierge',
                  search: query.toString(),
                });
              }}
            >
              メッセージ
            </Button>
            {isAllow && (
              <Button type="primary" onClick={() => onOpenModal({ label: dataUser?.fullName, value: dataUser?.id })}>
                ポイントを追加
              </Button>
            )}
          </div>
        }
      >
        {_renderTab(tab)}
      </Card>
      {modalVisible && (
        <AddPointModal target={target} onSubmit={handleSubmit} onCancel={onCancel} visible={modalVisible} />
      )}
    </div>
  );
};

Tabs.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
};
export default Tabs;
