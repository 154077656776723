import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import TrainingTypeItem from 'components/TrainingTypeItem';
import { useTrainingType } from 'redux/Hooks/trainingType';

const TrainingTypeListInput = ({ className = 'scale-y-150', value, onChange }) => {
  const { results, setQuery } = useTrainingType(false);
  useEffect(() => {
    setQuery({
      pageSize: 10000,
    });
  }, []);
  if (!value) {
    return null;
  }

  return (
    <Select
      mode="multiple"
      size="large"
      className={className}
      onChange={(selectedIds) => {
        const EXPEND = results?.reduce(
          (res, currentTrainingType) => ({
            ...res,
            [currentTrainingType?.id]: currentTrainingType,
          }),
          {}
        );
        onChange(selectedIds.map((id) => EXPEND[id]));
      }}
      value={value?.map((trainingType) => trainingType.id)}
    >
      {results.map((trainingType) => (
        <Select.Option className="" value={trainingType.id} key={trainingType.id}>
          <TrainingTypeItem
            className="flex-row space-x-1 "
            classNameForTrainingType=""
            size="1rem"
            value={trainingType}
          />
        </Select.Option>
      ))}
    </Select>
  );
};

TrainingTypeListInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,
};

export default TrainingTypeListInput;
