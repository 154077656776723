import { API } from 'configs';
import { usePost, usePaging } from 'utils/AxiosHooks';

const useUpdateBank = (id) => {
  // eslint-disable-next-line no-unused-vars
  const [doPost, prevLoading, requestState] = usePost({
    url: id && Number(id) ? API.ADMIN.BANK.UPDATE.replace(':id', Number(id)) : API.ADMIN.BANK.UPDATE,
  });

  const handleUpdate = ({ body }, onSuccess, onError) => doPost({ body }, onSuccess, onError);
  return [handleUpdate, requestState];
};

const useGetBanks = (fetchOnMount, pageSize = 30, queryParams) =>
  usePaging(API.ADMIN.BANK.LIST, fetchOnMount, pageSize, queryParams);

const useGetBranch = (fetchOnMount, pageSize = 30, queryParams) =>
  usePaging(API.ADMIN.BANK.BRANCH, fetchOnMount, pageSize, queryParams);

export { useUpdateBank, useGetBanks, useGetBranch };
