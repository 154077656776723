import { API } from 'configs';
import { usePost, useRequest } from 'utils/AxiosHooks';

const useGetSetting = () =>
  useRequest({
    url: API.ADMIN.BANNER.INFO,
    fetchOnMount: true,
  });

const useUpdateSetting = () => {
  // eslint-disable-next-line no-unused-vars
  const [doPost, prevLoading, requestState] = usePost({ url: API.ADMIN.BANNER.UPDATE });

  const handleUpdate = ({ body }) => doPost({ body });
  return [handleUpdate, requestState];
};

export { useGetSetting, useUpdateSetting };
