import React from 'react';
import { Result, Form, Input, Button, Spin, message, Radio } from 'antd';
import PropTypes from 'prop-types';
import { SaveOutlined } from '@ant-design/icons';
import { pick } from 'lodash';
import { useUpdateBank } from 'redux/Hooks/bank';
import BankSelect from 'components/BankSelect';
import BranchSelect from 'components/BranchSelect';

const BankAccount = ({ item, doGetUser }) => {
  const userId = item?.id;
  const isUpdate = !!item?.bankAccount;
  const [formCreate] = Form.useForm();
  const [onUpdate, dataBankAccount] = useUpdateBank(item?.id);
  const getTitle = () => {
    if (item?.businessId) {
      return '振込先口座情報';
    }
    return isUpdate ? '更新' : '追加';
  };

  const normalizeAccountNo = (value, prevValue) => {
    const reg = /^\d*$/;
    const isValid = (!Number.isNaN(value) && reg.test(value)) || value === '';

    return isValid ? value : prevValue;
  };

  if (!userId) {
    return <Result status="404" title="ユーザーが見つかりませんでした" />;
  }

  if (item?.businessId) {
    return (
      <div className="flex flex-col space-y-8">
        <Form
          form={formCreate}
          layout="horizontal"
          className="flex flex-col p-8 shadow-lg rounded-xl border"
          initialValues={dataBankAccount?.data?.bankAccount || item?.business || {}}
        >
          <div className="uppercase text-xl pb-8">{getTitle()}</div>
          <Form.Item name={['bankName']} label="金融機関名">
            <Input disabled />
          </Form.Item>
          <Form.Item name={['bankNumber']} label="銀行コード">
            <Input disabled />
          </Form.Item>
          <Form.Item name={['bankBranch']} label="支店名">
            <Input disabled />
          </Form.Item>
          <Form.Item name={['bankAccountName']} label="口座番号">
            <Input disabled />
          </Form.Item>
          <Form.Item name={['bankAccountType']} label="口座タイプ">
            <Input disabled />
          </Form.Item>
          <Form.Item name={['note']} label="注意">
            <Input.TextArea disabled />
          </Form.Item>
        </Form>
      </div>
    );
  }

  return (
    <div className="flex flex-col space-y-8">
      <Form
        form={formCreate}
        layout="horizontal"
        className="flex flex-col p-8 shadow-lg rounded-xl border"
        onFinish={async (values) => {
          const input = pick(values, ['bank', 'branch', 'accountNo', 'accountName', 'accountType']);
          onUpdate(
            {
              body: input,
            },
            () => {
              message.success('実行完了');
            },
            () => {
              message.error('エラー');
            }
          );
          doGetUser();
        }}
        initialValues={item?.bankAccount || {}}
      >
        <div className="uppercase text-xl pb-8">{getTitle()}</div>
        <Form.Item name={['bank']} label="銀行名" rules={[{ required: true }]}>
          <BankSelect placeholder="入力してください" form={formCreate} />
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => {
            if (prevValues.bank !== currentValues.bank) {
              formCreate.resetFields(['branch']);
            }
            return prevValues.bank !== currentValues.bank;
          }}
        >
          {() => {
            return (
              <Form.Item name={['branch']} label="支店名" rules={[{ required: true }]}>
                <BranchSelect placeholder="入力してください" currentBank={formCreate.getFieldValue('currentBank')} />
              </Form.Item>
            );
          }}
        </Form.Item>

        <Form.Item
          name={['accountNo']}
          label="口座番号"
          rules={[{ required: true }, { pattern: new RegExp(/^[0-9]*$/g), message: '数字を入力してください' }]}
          normalize={normalizeAccountNo}
        >
          <Input placeholder="入力してください" />
        </Form.Item>

        <Form.Item
          name={['accountName']}
          label="名義"
          rules={[
            { required: true, whitespace: true },
            {
              pattern: new RegExp(/^(?:([ァ-ン]|ー)|([ｧ-ﾝﾞﾟ]))+$/),
              message: '無効な値',
            },
          ]}
        >
          <Input placeholder="入力してください" />
        </Form.Item>
        <Form.Item name="accountType" label="口座種別" rules={[{ required: true }]}>
          <Radio.Group
            options={[
              {
                label: '当座',
                value: '当座',
              },
              {
                label: '普通',
                value: '普通',
              },
            ]}
            placeholder="口座種別"
          />
        </Form.Item>
        <Button type="primary" htmlType="submit" icon={<SaveOutlined className="text-white fill-current text-xl" />}>
          {isUpdate ? 'アカウントを更新する' : '振込口座更新'}
        </Button>
      </Form>
    </div>
  );
};

BankAccount.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
};
export default BankAccount;
