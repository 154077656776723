const checkPermissions = (authority, currentAuthority, target, Exception) => {
  if (!authority) {
    return target;
  }
  if (Array.isArray(authority)) {
    if (Array.isArray(currentAuthority)) {
      if (currentAuthority.some((item) => authority.includes(item))) {
        return target;
      }
    } else if (authority.includes(currentAuthority)) {
      return target;
    }
    return Exception;
  }
  if (typeof authority === 'string') {
    if (Array.isArray(currentAuthority)) {
      if (currentAuthority.some((item) => authority === item)) {
        return target;
      }
    } else if (authority === currentAuthority) {
      return target;
    }
    return Exception;
  }

  throw new Error('unsupported parameters');
};

export { checkPermissions };

function check(authority, currentAuthority = 'NULL', target, Exception) {
  return checkPermissions(authority, currentAuthority, target, Exception);
}

export default check;
