import { API } from 'configs';
import { useRequest, useAddEdit, useDelete } from 'utils/AxiosHooks';

const useStaticContents = () => useRequest({ url: API.ADMIN.STATIC_CONTENT.LIST, fetchOnMount: true });

const useEditStaticContent = (id) =>
  useAddEdit(id ? API.ADMIN.STATIC_CONTENT.EDIT.replace(':id', id) : API.ADMIN.STATIC_CONTENT.EDIT, 'edit');
const useAddStaticContent = (id) => useAddEdit(API.ADMIN.STATIC_CONTENT.ADD, 'add');
const useDeleteStaticContent = (onSuccess) => useDelete(API.ADMIN.STATIC_CONTENT.EDIT, onSuccess);

export { useStaticContents, useEditStaticContent, useAddStaticContent, useDeleteStaticContent };
