import React from 'react';
import { Form, Input, Modal, InputNumber, Tag } from 'antd';
import { POINT_HISTORY } from 'utils/constant';
import { useCheckPermission } from 'utils/hooks/usePermission';

const FormItem = Form.Item;

const AddPointModal = ({ target, refererId, onCancel, onSubmit, visible }) => {
  const [form] = Form.useForm();
  const isAllow = useCheckPermission('update-point');

  const onFinish = (values) => {
    onSubmit(
      Object.assign(
        values,
        ['training', 'user'].includes(target.value) ? { type: target.value } : { userId: target.value },
        refererId ? { refererId } : {},
        { type: POINT_HISTORY.TYPE.ADMIN_CHANGE }
      )
    );
  };

  return (
    <Modal
      visible={visible}
      title="ポイント付与"
      onCancel={onCancel}
      onOk={() => form.submit()}
      okButtonProps={{ disabled: !isAllow }}
    >
      <Form layout="horizontal" form={form} onFinish={onFinish}>
        <FormItem label="対象">
          <Tag className="align-top whitespace-pre-wrap break-words fixBreakWords">{target.label}</Tag>
        </FormItem>
        <FormItem label="ポイント" name="amount" rules={[{ required: true, message: 'ポイントを入力してください' }]}>
          <InputNumber placeholder="入力してください。" className="w-full" step={1000} />
        </FormItem>
        <FormItem label="詳細" name="description" rules={[{ required: true, message: '内容を入力してください。' }]}>
          <Input placeholder="入力してください。" />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default AddPointModal;
