import React, { useState, useEffect } from 'react';
import { Layout, Space, Button, Table, notification, message, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import qs from 'qs';
import PropTypes from 'prop-types';
import { useUsers } from 'redux/Hooks/user';
import { useAddPoint } from 'redux/Hooks/pointHistory';
import { useCheckPermission } from 'utils/hooks/usePermission';

import UserPointSearchForm from './components/UserPointSearchForm';
import AddPointModal from './components/AddPointModal';

const DEFAULT_PAGE_SIZE = 30;

const Point = ({ history, location }) => {
  const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [modalVisible, setModalVisible] = useState(false);
  const [target, setTarget] = useState(null);
  const [searchForm, setSearchForm] = useState({});
  const [pageSize, setPageSize] = useState(30);
  const [page, setPage] = useState(parsedQuery?.page ? parseInt(parsedQuery?.page, 10) - 1 : 0);

  const isAllow = useCheckPermission('update-point');
  const { results, isFetchingList, currentPage, totalPages, refresh, setQuery } = useUsers();

  const {
    prevFetching: prevIsFetchingAdd,
    isFetching: isFetchingAdd,
    success: successAdd,
    message: errorResponse,
    onSave: onSaveAdd,
  } = useAddPoint();

  useEffect(() => {
    if (prevIsFetchingAdd && !isFetchingAdd) {
      if (successAdd) {
        refresh();
        notification.success({
          message: '成功',
          description: 'ポイント追加が成功しました。',
        });
      } else {
        notification.error({
          message: 'エラー',
          description: '後でもう一度やり直してください',
        });
        console.error(errorResponse?.message);
      }
    }
  }, [successAdd, isFetchingAdd, prevIsFetchingAdd, errorResponse?.message]);

  const onCancel = () => {
    setModalVisible(false);
    setTarget(null);
  };

  const onOpenModal = (t) => {
    setModalVisible(true);
    setTarget(t);
  };

  const handleSearch = (filter) => {
    setSearchForm(filter);
  };

  useEffect(() => {
    setQuery({
      ...searchForm,
      pageSize,
      page,
    });
  }, [pageSize, searchForm, page]);

  const handleChangePage = (p, pS) => {
    setPage(p - 1);
    setPageSize(pS);
  };

  const handleSubmit = async (values) => {
    await onSaveAdd(values);
    onCancel();
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      width: '10%',
    },
    {
      title: '名前',
      dataIndex: 'fullName',
      width: '20%',
      render: (value, record) => <div className="">{record.isSystem ? 'システム' : value || ''}</div>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '20%',
      render: (value) => <div className="">{value || ''}</div>,
    },
    {
      title: 'ポイント',
      dataIndex: 'point',
      width: '30%',
    },
    {
      title: 'タイプ',
      dataIndex: 'userType',
      width: '10%',
      render: (val) => (val === 'training' ? 'ユーザー' : 'トレナー'),
    },
    {
      title: '',
      width: '10%',
      render: (val, record) => (
        <Space size="middle">
          <Button
            disabled={!isAllow}
            onClick={() => onOpenModal({ label: record.fullName, value: record.id })}
            icon={<PlusOutlined />}
          />
        </Space>
      ),
    },
  ];

  const pagination = {
    onChange: (p, pS) => handleChangePage(p, pS),
    current: currentPage + 1 || 1,
    pageSize: pageSize || DEFAULT_PAGE_SIZE,
    total: totalPages * pageSize || 0,
  };

  return (
    <Layout.Content className="flex flex-col p-4">
      <div className="flex flex-row justify-start">
        <Typography.Title level={3}>ポイント一覧</Typography.Title>
        <Space className="px-4" direction="horizontal" size="large">
          &nbsp;
        </Space>
        <Button onClick={() => history.push('/point/history')} type="primary">
          履歴
        </Button>
      </div>

      <div className="formControl py-4">
        <UserPointSearchForm onSubmit={handleSearch} />
      </div>
      {/* <div className="flex flex-row justify-end py-4">
        <Button onClick={() => onOpenModal({ label: 'ユーザー', value: 'user' })} type="primary">
          一括追加(カスタマー)
        </Button>
        <Space className="px-4" direction="horizontal" size="large">
          &nbsp;
        </Space>
        <Button onClick={() => onOpenModal({ label: 'トレーナー', value: 'training' })} type="primary">
          一括追加(ホスト)
        </Button>
      </div> */}
      <Table
        className="bg-white overflow-x-scroll"
        rowClassName="align-top whitespace-pre-wrap break-words fixBreakWordsJapan text-center"
        components={{
          header: {
            cell: ({ className, style, ...restProps }) => {
              return <th className={className} style={{ ...style, textAlign: 'center' }} {...restProps} />;
            },
          },
        }}
        bordered
        loading={isFetchingList}
        dataSource={results || []}
        columns={columns}
        rowKey="id"
        pagination={pagination}
      />
      {modalVisible && (
        <AddPointModal target={target} onSubmit={handleSubmit} onCancel={onCancel} visible={modalVisible} />
      )}
    </Layout.Content>
  );
};

Point.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  location: PropTypes.objectOf(PropTypes.any),
};
export default Point;
