import React from 'react';
import qs from 'qs';
import PropTypes from 'prop-types';

import Tabs from './components/Tabs';

const MasterSetting = (props) => {
  const { location } = props;
  const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
  const tab = parsedQuery?.tab || 'faq';

  switch (tab) {
    case 'faq':
    default:
      return <Tabs {...props} />;
  }
};

MasterSetting.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export default MasterSetting;
