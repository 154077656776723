import { useAddEdit, usePaging, useDelete } from 'utils/AxiosHooks';
import { API } from 'configs';

const useCreateLesson = () => useAddEdit(API.ADMIN.LESSON.CREATE, 'add');
const useSearchLesson = () => usePaging(API.ADMIN.LESSON.LIST, true);
const useEditLesson = (id) => useAddEdit(id ? API.ADMIN.LESSON.EDIT.replace(':id', id) : API.ADMIN.LESSON.EDIT, 'edit');
const useDeleteLesson = (id, onSuccess, onFail) =>
  useDelete(id ? API.ADMIN.LESSON.EDIT.replace(':id', id) : API.ADMIN.LESSON.EDIT, onSuccess, onFail);

export { useCreateLesson, useSearchLesson, useEditLesson, useDeleteLesson };
