import { useAddEdit, usePaging, useDelete, useMethod } from 'utils/AxiosHooks';
import { API } from 'configs';

const useCreateLessonRequest = () => useAddEdit(API.ADMIN.LESSON_REQUEST.CREATE, 'add');
const useSearchLessonRequest = () => usePaging(API.ADMIN.LESSON_REQUEST.LIST, true);
const useEditLessonRequest = (id, onSuccess, onFail) =>
  useAddEdit(
    id ? API.ADMIN.LESSON_REQUEST.EDIT.replace(':id', id) : API.ADMIN.LESSON_REQUEST.EDIT,
    'edit',
    onSuccess,
    onFail
  );
const useDeleteLessonRequest = (id, onSuccess, onFail) =>
  useDelete(id ? API.ADMIN.LESSON_REQUEST.EDIT.replace(':id', id) : API.ADMIN.LESSON_REQUEST.EDIT, onSuccess, onFail);

const useJoinLessonRequest = (id, method, onSuccess, onFail) =>
  useMethod(
    id ? API.ADMIN.LESSON_REQUEST.JOIN.replace(':id', id) : API.ADMIN.LESSON_REQUEST.JOIN,
    method,
    onSuccess,
    onFail
  );

export {
  useCreateLessonRequest,
  useSearchLessonRequest,
  useEditLessonRequest,
  useDeleteLessonRequest,
  useJoinLessonRequest,
};
