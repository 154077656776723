import React, { useState, useEffect } from 'react';
import { Layout, Space, Button, Table, Modal, message, Input } from 'antd';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import { useRoles, useEditRole, useAddRole, useDeleteRole } from 'redux/Hooks/role';
import EditModal from './EditModal';

const Role = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [pageSize, setPageSize] = useState(30);

  const [{ data: results }, refresh] = useRoles();

  const { prevFetching: prevIsFetching, isFetching, success, message: successResponse, onSave } = useEditRole();
  const {
    prevFetching: prevIsFetchingAdd,
    isFetching: isFetchingAdd,
    success: successAdd,
    message: errorResponse,
    onSave: onAdd,
  } = useAddRole();

  const { onDelete } = useDeleteRole(() => refresh());

  const [visibleEdit, setVisibleEdit] = useState(false);
  const [editSelected, setEditSelected] = useState(null);

  const [modal, contextHolder] = Modal.useModal({});

  useEffect(() => {
    if (isFetching !== prevIsFetching && prevIsFetching) {
      if (success) {
        message.success('実行完了');
        refresh();
      } else {
        message.error('エラー');
        console.error(successResponse?.message);
      }
    }
  }, [isFetching]);

  useEffect(() => {
    if (isFetchingAdd !== prevIsFetchingAdd && prevIsFetchingAdd) {
      if (successAdd) {
        message.success('実行完了');
        refresh();
      } else {
        message.error('エラー');
        console.error(errorResponse?.message);
      }
    }
  }, [isFetchingAdd]);

  const dataSource = results || [];

  const onChangeItem = async (values, recordId) => {
    try {
      if (recordId) {
        onSave(values, values?.id);
      } else {
        onAdd(values);
      }
    } catch (createError) {
      message.error('エラー');
    } finally {
      setVisibleEdit(false);
      setEditSelected(null);
    }
  };

  const onOk = async (close, record) => {
    try {
      onDelete(record?.id);
      const query = new URLSearchParams(props.history.location.search);
      query.set('page', 1);
      message.success('実行完了');
    } catch (deleteError) {
      message.error('エラー');
    } finally {
      close();
    }
  };

  const onCancel = (close) => {
    close();
  };

  const modalDeleteConfig = (record) => {
    return {
      className: '',
      title: '確認',
      content: <h1>削除しますか？</h1>,
      onOk: (close) => onOk(close, record),
      onCancel,
      okButtonProps: {},
      cancelButtonProps: {},
      okText: '削除',
      okType: 'primary',
      cancelText: 'キャンセル',
      icon: null,
      mask: true,
      maskClosable: true,
      style: {},
      maskStyle: {},
      keyboard: true,
      autoFocusButton: 'ok',
    };
  };

  const _renderAction = (record) => (
    <Space size="middle">
      <Button
        onClick={() => {
          setVisibleEdit(true);
          setEditSelected(record);
        }}
        icon={<EditOutlined />}
      />
      <Button
        onClick={() => {
          modal.confirm(modalDeleteConfig(record));
        }}
        icon={<CloseOutlined />}
      />
    </Space>
  );

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'コード名',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '',
      key: 'action',
      render: _renderAction,
    },
  ];

  const editInputs = [
    {
      title: 'コード名',
      key: 'code',
      InputComponent: Input,
      inputProps: {},
      formItemProps: {
        rules: [{ required: true }],
      },
    },
    {
      title: '名称',
      key: 'name',
      InputComponent: Input,
      inputProps: {},
      formItemProps: {
        rules: [{ required: true }],
      },
    },
  ];

  return (
    <Layout.Content className="flex flex-col p-4">
      <div className="flex flex-row justify-end py-4">
        <Space>
          <Button
            onClick={() => {
              setVisibleEdit(true);
              setEditSelected(null);
            }}
            type="primary"
          >
            追加
          </Button>
        </Space>
      </div>

      <Table
        className="bg-white overflow-x-scroll"
        rowClassName="align-top whitespace-pre-wrap break-words fixBreakWordsJapan text-center"
        components={{
          header: {
            cell: ({ className, style, ...restProps }) => {
              return <th className={className} style={{ ...style, textAlign: 'center' }} {...restProps} />;
            },
          },
        }}
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        rowKey="id"
      />
      {contextHolder}
      {visibleEdit && (
        <EditModal
          visible={visibleEdit}
          onCreate={onChangeItem}
          onCancel={() => {
            setVisibleEdit(false);
            setEditSelected(null);
          }}
          record={editSelected}
          editInputs={editInputs}
          headerTitle={!editSelected ? 'ロール追加' : '管理者権限'}
          modalProps={{ width: '65vw' }}
        />
      )}
    </Layout.Content>
  );
};

Role.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
};

export default Role;
