import { useRequest, useAddEdit, usePaging, useDelete } from 'utils/AxiosHooks';
import { API } from 'configs';

const useCreateLivestream = () => useAddEdit(API.ADMIN.LIVESTREAM.CREATE, 'add');
const useSearchLivestream = () => usePaging(API.ADMIN.LIVESTREAM.LIST, true);
const useEditLivestream = (id) =>
  useAddEdit(id ? API.ADMIN.LIVESTREAM.EDIT.replace(':id', id) : API.ADMIN.LIVESTREAM.EDIT, 'edit');
const useDeleteLivestream = (id, onSuccess, onFail) =>
  useDelete(id ? API.ADMIN.LIVESTREAM.EDIT.replace(':id', id) : API.ADMIN.LIVESTREAM.EDIT, onSuccess, onFail);

const useOffLivestream = (id) => useRequest(API.ADMIN.LIVESTREAM.OFFSTREAM.replace(':id', id));

export { useCreateLivestream, useSearchLivestream, useEditLivestream, useDeleteLivestream, useOffLivestream };
