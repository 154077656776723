import React from 'react';
import { List, Avatar } from 'antd';
import { parse } from 'qs';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';

const ConversationItem = ({ item }) => {
  const location = useLocation();
  const history = useHistory();
  const { id } = parse(location.search, { ignoreQueryPrefix: true }) || {};

  return (
    <List.Item
      key={item.id}
      className={`${
        item?.id === Number(id) && 'bg-gray-200'
      } flex flex-col flex-nowrap cursor-pointer hover:bg-gray-200 px-3 rounded-lg`}
      onClick={() => {
        const query = new URLSearchParams(history.location.search);
        query.set('id', item?.id);
        history.push({
          pathname: location.pathname,
          search: query.toString(),
        });
      }}
    >
      <div className="w-full text-gray-500 text-xs">{moment(item?.updated).format('YYYY年 MM月DD日（ddd）HH:mm')}</div>
      <div className="flex flex-row w-full justify-between items-center flex-nowrap space-x-2 ">
        <div className="flex flex-row space-x-2 justify-start items-center" style={{ maxWidth: 'calc(50% - 1rem)' }}>
          <div style={{ width: 40, height: 40 }}>
            <Avatar
              className="flex justify-center items-center "
              src={item?.users?.[0]?.avatar}
              size="large"
              icon={<UserOutlined />}
            />
          </div>
          <div className="flex-grow whitespace-no-wrap font-bold text-sm truncate">{item?.users?.[0]?.fullName}</div>
        </div>
        <div className="flex flex-row space-x-2 justify-end items-center" style={{ maxWidth: 'calc(50% - 1rem)' }}>
          <div className="flex-grow whitespace-no-wrap font-bold text-sm truncate">{item?.users?.[1]?.fullName}</div>
          <div style={{ width: 40, height: 40 }}>
            <Avatar
              className="flex justify-center items-center"
              src={item?.users?.[1]?.avatar}
              size="large"
              icon={<UserOutlined />}
            />
          </div>
        </div>
      </div>
    </List.Item>
  );
};

ConversationItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
};
export default ConversationItem;
