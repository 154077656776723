import React from 'react';
import { Form, Input, Checkbox, Button, DatePicker } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';

const FormItem = Form.Item;

const LivestreamSearchForm = ({ onSubmit }) => {
  const [form] = Form.useForm();

  const targetOptions = [
    { label: '生放送', value: 1 },
    { label: 'オフライン', value: 0 },
  ];

  const onFinish = (values) => {
    const filter = {};
    // filter.livestreaming = values.livestreaming?.length > 1 ? '' : values.livestreaming;
    filter.livestreaming =
      Array.isArray(values.livestreaming) && values?.livestreaming?.length ? values.livestreaming?.join(',') : '';
    filter.start = values.start ? moment(values.start).utc().startOf('day').format() : '';
    filter.end = values.end ? moment(values.end).utc().endOf('day').format() : '';
    filter.search = values.username;
    onSubmit(filter);
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <div className="flex flex-row justify-between">
        <FormItem name="livestreaming">
          <Checkbox.Group options={targetOptions} />
        </FormItem>
        <FormItem name="username" className="w-1/2">
          <Input placeholder="タイトル、フルネーム" />
        </FormItem>
        <Form.Item className="rounded mr-1 ml-1" name="start">
          <DatePicker
            size="middle"
            placeholder="開始日付"
            format="YYYY/MM/DD HH:mm"
            showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
          />
        </Form.Item>
        <Form.Item name="end" className="rounded mr-1 ml-1">
          <DatePicker size="middle" placeholder="終了日付" format="YYYY/MM/DD HH:mm" />
        </Form.Item>
        <Form.Item shouldUpdate>
          {() => (
            <Button icon={<SearchOutlined />} htmlType="submit" type="ghost">
              検索
            </Button>
          )}
        </Form.Item>
      </div>
    </Form>
  );
};

export default LivestreamSearchForm;
