import React, { useEffect, useState } from 'react';
import { Input, Modal, Form, Switch, Collapse } from 'antd';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import { useEditRole } from 'redux/Hooks/role';
import { PERMISSIONS_JP } from 'utils/constant';

const EditModal = ({
  visible,
  record,
  headerTitle,
  editInputs = [],
  hiddenVariables = {},
  onCreate = () => {},
  onCancel = () => {},
  modalProps = {},
  formProps = {},
  loading,
}) => {
  const [formEdit] = Form.useForm();
  const isEditing = !!record;
  const [permissions, setPermissions] = useState([]);

  const { data } = useEditRole(record?.id);

  useEffect(() => {
    setPermissions(data?.permissions);
  }, [data?.id]);

  const initialValues = omit(
    {
      ...hiddenVariables,
      ...(record || {}),
    },
    '__typename'
  );

  const editInputsValidated = editInputs?.filter((item) => item?.InputComponent) || [];

  const _renderEditInputs = (inputs) => {
    return inputs?.map(
      ({ title, key, name, InputComponent = Input, inputProps = {}, formItemProps = {}, inputChildren }) => (
        <Form.Item key={key} name={name || key} label={title} {...formItemProps}>
          <InputComponent name={name || key} {...inputProps}>
            {inputChildren}
          </InputComponent>
        </Form.Item>
      )
    );
  };

  const handleFinish = async (values) => {
    onCreate({ ...data, ...values, permissions }, record?.id);
    formEdit.resetFields();
  };

  useEffect(() => {
    if (visible && record?.id && formEdit) {
      formEdit.resetFields();
    }
  }, [visible, record?.id]);

  const onChangePermission = (permissionId, allow) => {
    const p = permissions.findIndex((item) => item.permissionId === permissionId);
    if (p !== -1) {
      permissions[p] = { ...permissions[p], allow };

      setPermissions(permissions);
    }
  };

  return (
    <Modal
      visible={visible}
      title={headerTitle}
      okText="変更"
      cancelText="キャンセル"
      onCancel={onCancel}
      onOk={() => {
        formEdit.submit();
      }}
      okButtonProps={{ form: 'edit', key: 'submit', htmlType: 'submit' }}
      {...modalProps}
    >
      {loading ? (
        <div className="flex items-center justify-center w-full">
          <LoadingOutlined />
        </div>
      ) : (
        <Form
          form={formEdit}
          layout="vertical"
          name={isEditing ? 'edit' : 'add'}
          initialValues={initialValues}
          onFinish={handleFinish}
          {...formProps}
        >
          <div className="flex flex-col">
            {Object.keys(hiddenVariables).map((key) => (
              <Form.Item key={key} name={key} className="hidden">
                <Input type="hidden" />
              </Form.Item>
            ))}
            {_renderEditInputs(editInputsValidated)}
            <div className="mb-6">
              <p className="text-left whitespace-no-wrap text-sm font-normal">基本管理</p>
              <div className="flex flex-row justify-around space-x-8">
                <p>
                  1.ダッシュボード閲覧
                  <br />
                  2.ユーザー管理
                  <br />
                  3.トレーナー管理
                  <br />
                  4.法人一覧管理
                  <br />
                  5.紹介管理
                  <br />
                  6.トレーナー認証
                  <br />
                  7.レッスン管理
                </p>
                <p>
                  8.レッスリクエスト管理
                  <br />
                  9.ホストレライブ管理
                  <br />
                  10.メッセージ管理
                  <br />
                  11. つぶやき管理
                  <br />
                  12.通報管理
                  <br />
                  13．ポイント管理（検索可能・ポイント履歴の閲覧可能・ポイント一覧の閲覧可能）
                </p>
              </div>
            </div>
            {data?.permissions?.map((permission) => (
              <div key={`permissions[${permission.id}]`} className="flex flex-row justify-between items-start">
                <Form.Item
                  label={
                    <p className="text-left whitespace-no-wrap">
                      {PERMISSIONS_JP?.[permission?.permission?.code]?.title || permission?.permission?.name}
                    </p>
                  }
                >
                  <Switch
                    defaultChecked={permission.allow}
                    onChange={(checked) => onChangePermission(permission.permissionId, checked)}
                  />
                </Form.Item>
                <Collapse className="w-1/2" bordered destroyInactivePanel>
                  <Collapse.Panel header={<p className="text-center whitespace-no-wrap">権限説明</p>}>
                    <p className="align-top whitespace-pre-wrap break-words fixBreakWordsJapan text-left">
                      {PERMISSIONS_JP?.[permission?.permission?.code]?.guide || ''}
                    </p>
                  </Collapse.Panel>
                </Collapse>
              </div>
            ))}
          </div>
        </Form>
      )}
    </Modal>
  );
};

EditModal.propTypes = {
  visible: PropTypes.bool,
  record: PropTypes.object,
  loading: PropTypes.bool,
  headerTitle: PropTypes.string,
  editInputs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
      InputComponent: PropTypes.elementType,
      inputProps: PropTypes.object,
      formItemProps: PropTypes.object,
      inputChildren: PropTypes.element,
    })
  ),
  hiddenVariables: PropTypes.object,
  onCreate: PropTypes.func,
  onCancel: PropTypes.func,
  modalProps: PropTypes.object,
  formProps: PropTypes.object,
};

export default EditModal;
