import { API } from 'configs';
import { usePaging, useAddEdit } from 'utils/AxiosHooks';

const useTrainerRequests = () => usePaging(API.ADMIN.TRAINER_VERIFY.LIST, true);

const useUpdateTrainerRequest = (id, onSuccess, onFail) =>
  useAddEdit(
    `${id ? API.ADMIN.TRAINER_VERIFY.UPDATE.replace(':id', id) : API.ADMIN.TRAINER_VERIFY.UPDATE}`,
    'edit',
    onSuccess,
    onFail
  );

export { useTrainerRequests, useUpdateTrainerRequest };
