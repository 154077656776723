import { atom } from 'recoil';
import { RECOIL_KEY } from 'utils/constant';

const userAuthDefault = {
  isSyncing: false,
  token: null,
  user: null,
  admin: null,
  permissions: [],
  unread: {},
};

export const authState = atom({
  key: RECOIL_KEY.AUTH, // unique ID (with respect to other atoms/selectors)
  default: userAuthDefault,
});

export const settingState = atom({
  key: RECOIL_KEY.SETTING, // unique ID (with respect to other atoms/selectors)
  default: {
    collapse: false,
  }, // default value (aka initial value)
});

export default [authState, settingState];
