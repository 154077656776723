import React from 'react';
import { List, Avatar, Badge } from 'antd';
import cls from 'classnames';
import { parse } from 'qs';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';

const ConciegeItem = ({ item, onClick, setInquiriUserId }) => {
  const location = useLocation();
  const history = useHistory();
  const { id } = parse(location.search, { ignoreQueryPrefix: true }) || {};
  const user = item?.users?.find((us) => us?.role !== 'admin');
  return (
    <List.Item
      key={item.id}
      className={`${
        item?.id === Number(id) && 'bg-gray-200'
      } flex flex-col flex-wrap cursor-pointer hover:bg-gray-200 p-0 px-3 py-2 rounded-lg`}
      onClick={() => {
        const query = new URLSearchParams(history.location.search);
        query.set('id', item?.id);
        query.set('userId', user?.id);
        query.set('userType', user?.userType);
        setInquiriUserId(user?.id);
        history.push({
          pathname: location.pathname,
          search: query.toString(),
        });
        onClick();
      }}
    >
      <Badge dot count={item?.unread}>
        <div className="flex flex-row w-full justify-between items-center flex-wrap overflow-hidden mt-2">
          <div className="flex items-center">
            <Avatar
              className="flex justify-center flex-none items-center"
              src={user?.avatar}
              size="large"
              icon={<UserOutlined />}
            />
            <div className="ml-3">
              <div
                className={cls(
                  'font-bold text-sm whitespace-no-wrap overflow-hidden overflow-ellipsis whitespace-nowrap',
                  { 'text-black': item?.unread > 0 }
                )}
              >
                {user?.fullName}
              </div>

              <p className={cls('whitespace-no-wrap w-48 truncate', { 'font-bold text-black': item?.unread > 0 })}>
                {item?.lastMessage || `はじめまして`}
              </p>
            </div>
          </div>
        </div>
      </Badge>
    </List.Item>
  );
};

ConciegeItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
};
export default ConciegeItem;
