import React, { useState, useEffect } from 'react';
import { PageLoading } from '@ant-design/pro-layout';
import { Redirect } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { authState } from 'store/atom';
import { stringify } from 'qs';
import { useSyncingMe } from 'redux/Hooks/me';

const SecurityLayout = ({ children, loading }) => {
  const [isReady, setReady] = useState(false);
  const [auth] = useSyncingMe(true, 10000);

  useEffect(() => {
    setReady(true);
  }, []);
  // You can replace it to your authentication rule (such as check token exists)
  const isLogin = auth.user;
  const queryString = stringify({
    redirect: window.location.href,
  });

  if ((!isLogin && loading) || !isReady) {
    return <PageLoading />;
  }
  if (!isLogin && window.location.pathname !== '/login') {
    return <Redirect to={`/login?${queryString}`} />;
  }
  return children;
};

export default SecurityLayout;
