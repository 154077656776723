import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Form, Input, Select, Radio, Button, Switch, Carousel, DatePicker, Spin, Modal, message } from 'antd';
import PropTypes from 'prop-types';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import moment from 'moment';
import { useCompanies } from 'redux/Hooks/company';
import { USER_TYPES, USER_STATUS } from 'configs/Constants';
import { useEditUser } from 'redux/Hooks/user';

import UserAvatar from '../UserAvatar';

const FIELD = {
  AVATAR: 'avatar',
  AVATARS: 'avatars',
  USERNAME: 'username',
  USER_TYPE: 'userType',
  FULLNAME: 'fullName',
  EMAIL: 'email',
  GENDER: 'gender',
  BIRTHDAY: 'birthday',
  STATUS: 'status',
  IS_PREMIUM: 'isPremium',
  INTRODUCTION: 'introduction',
  PASSWORD: 'password',
  EASE_OF_SPEAKING: 'ease_of_speaking',
  LOOKS: 'looks',
  TENSHION: 'tenshion',
  LEADERSHIP: 'leadership',
  MUSCLE_MASS: 'muscle_mass',
  FRESSNESS: 'freshness',
  BUSINESS: 'businessId',
};

const { TextArea } = Input;
const { Option } = Select;

const UserForm = ({ form, item, userType, onSubmit, goBack, action = 'add', loading, refresh = () => {} }) => {
  useEffect(() => {
    form.resetFields();
  }, [item?.id]);

  const [imageSelected, setImageSelected] = useState(0);
  const [search, setSearch] = useState('');
  const [carouselRefState, setCarouselRef] = useState(useRef(null));

  const isInActive = item?.status === USER_STATUS.DELETED;

  const onCarouselRef = useCallback(
    (ref) => {
      const carouselRef = carouselRefState;
      carouselRef.current = ref;
      setCarouselRef(carouselRef);
    },
    [carouselRefState?.current]
  );

  const { results, isFetching, setQuery } = useCompanies();

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current >= moment().subtract(16, 'year').startOf('year');
  };

  const { onSave: onDeleteUser, isFetching: isDeleteLoading } = useEditUser(
    ':id',
    (res) => {
      if (res?.success) {
        message.success('削除しました');
        refresh();
        goBack();
      } else {
        message.error('ユーザーの削除が完了しました');
      }
    },
    () => {
      message.error('ユーザーの削除が完了しました');
    }
  );

  const handleDeleteUser = (userId) => {
    Modal.confirm({
      title: 'ユーザーを削除しますか。',
      content: 'このアクションは元に戻せません。',
      onOk: () => onDeleteUser({ status: 'deleted' }, userId),
      onCancel() {},
    });
  };

  useEffect(() => {
    setQuery({
      search,
    });
  }, [search]);
  const businessOptions = results?.map((business) => ({ label: business?.name, value: business?.id }));
  const allAvatars = item?.avatar ? [item.avatar].concat(item.avatars.map((a) => a.url)) : [];

  if (loading) {
    return <Spin className="flex items-center justify-center" size="large" />;
  }

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      initialValues={{
        [FIELD.AVATAR]: item?.[FIELD.AVATAR] || '',
        [FIELD.AVATARS]: item?.[FIELD.AVATARS] || [],
        [FIELD.INTRODUCTION]: item?.[FIELD.INTRODUCTION] || '',
        [FIELD.FULLNAME]: item?.[FIELD.FULLNAME] || '',
        [FIELD.EMAIL]: item?.[FIELD.EMAIL] || '',
        [FIELD.BIRTHDAY]:
          item?.[FIELD.BIRTHDAY] && moment(item?.[FIELD.BIRTHDAY]).isValid() ? moment(item[FIELD.BIRTHDAY]) : '',
        [FIELD.STATUS]: item?.[FIELD.STATUS] || 'active',
        [FIELD.GENDER]: item?.[FIELD.GENDER] || '',
        ...(item?.[FIELD.USER_TYPE] === 'trainer'
          ? {
              [FIELD.IS_PREMIUM]: item?.[FIELD.IS_PREMIUM] || false,
            }
          : {}),
        [FIELD.EASE_OF_SPEAKING]: item?.[FIELD.EASE_OF_SPEAKING],
        [FIELD.LOOKS]: item?.[FIELD.LOOKS],
        [FIELD.LEADERSHIP]: item?.[FIELD.LEADERSHIP],
        [FIELD.TENSHION]: item?.[FIELD.TENSHION],
        [FIELD.MUSCLE_MASS]: item?.[FIELD.MUSCLE_MASS],
        [FIELD.FRESSNESS]: item?.[FIELD.FRESSNESS],
        [FIELD.BUSINESS]: item?.[FIELD.BUSINESS],
      }}
    >
      <div className="w-full flex">
        <div style={{ maxWidth: '280px' }} className="w-full">
          <div className="px-4 space-y-4">
            <Carousel ref={onCarouselRef} draggable arrows beforeChange={(_, nextIndex) => setImageSelected(nextIndex)}>
              {allAvatars.map((k, i) => (
                <UserAvatar avatar={k} key={i} />
              ))}
            </Carousel>

            <Form.Item name={FIELD.INTRODUCTION}>
              <TextArea placeholder="自己紹介" autoSize={{ minRows: 5, maxRows: 10 }} />
            </Form.Item>
            <div className="flex justify-between">
              <Button className="rounded" type="primary" size="large" htmlType="submit" style={{ minWidth: '120px' }}>
                更新
              </Button>
              <Button
                className="rounded text-white"
                type="default"
                size="large"
                style={{ background: '#A5A4BF' }}
                onClick={() => goBack()}
              >
                キャンセル
              </Button>
            </div>
            <Button
              // eslint-disable-next-line max-len
              className="w-full rounded justify-center bg-red-500 text-white flex items-center mx-auto hover:text-red-400 hover:bg-red-200 hover:border-red-400"
              type="default"
              size="large"
              onClick={() => {
                if (isInActive) return;
                handleDeleteUser(item?.id);
              }}
              disabled={isInActive || isDeleteLoading}
            >
              {isInActive ? '退会済' : '退会'}
            </Button>
          </div>
        </div>
        <div className="flex-1 w-full">
          <div className="flex w-full">
            <div className="w-1/2 mr-4">
              <Form.Item
                label="お名前"
                labelAlign="left"
                name={FIELD.FULLNAME}
                rules={[{ type: 'string', required: action === 'add' }]}
                className="flex flex-col pb-4 mb-8"
              >
                <Input placeholder="お名前" />
              </Form.Item>

              <Form.Item
                label="メール"
                name={FIELD.EMAIL}
                labelAlign="left"
                className="flex flex-col pb-4 mb-8"
                rules={[{ type: 'email', required: true }]}
              >
                <Input placeholder="メール" />
              </Form.Item>
              <Form.Item
                label="生年月日"
                name={FIELD.BIRTHDAY}
                labelAlign="left"
                className="flex flex-col pb-4 mb-8"
                rules={[{ type: 'date', required: true }]}
              >
                <DatePicker
                  placeholder="生年月日"
                  format="YYYY-MM-DD"
                  disabledDate={disabledDate}
                  defaultPickerValue={moment().subtract(16, 'year').startOf('year')}
                />
              </Form.Item>

              {item?.[FIELD.USER_TYPE] === 'trainer' ? (
                <Form.Item
                  labelAlign="left"
                  className="flex flex-col pb-4 mb-4"
                  name={FIELD.IS_PREMIUM}
                  label="プレミアムアカウント"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              ) : null}
            </div>

            <div className="w-1/2 ml-4">
              <Form.Item
                name={FIELD.PASSWORD}
                label="パスワード"
                labelAlign="left"
                className="flex flex-col pb-4 mb-8"
                rules={[{ required: action === 'add' }]}
              >
                <Input.Password
                  placeholder="パスワード"
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              </Form.Item>

              <Form.Item name={FIELD.GENDER} labelAlign="left" label="性別" className="flex flex-col pb-4 mb-8">
                <Radio.Group
                  options={[
                    { label: '男性', value: 'male' },
                    { label: '女性', value: 'female' },
                  ]}
                  value="male"
                />
              </Form.Item>
              <Form.Item labelAlign="left" className="flex flex-col pb-4 mb-4" name={FIELD.STATUS} label="ステータス">
                <Select style={{ width: 150 }} placeholder="ステータス">
                  <Option key="active" value="active">
                    有効
                  </Option>
                  <Option key="banned" value="banned">
                    禁止中
                  </Option>
                  <Option key="deleted" value="deleted">
                    削除
                  </Option>
                </Select>
              </Form.Item>
              {userType === USER_TYPES.TRAINER && (
                <Form.Item label="法人名" name={FIELD.BUSINESS} labelAlign="left" className="flex flex-col pb-4 mb-8">
                  <Select
                    showSearch
                    onSearch={(value) => setSearch(value)}
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    options={businessOptions}
                    placeholder="法人名"
                    allowClear
                    size="large"
                    loading={isFetching}
                  />
                </Form.Item>
              )}
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

UserForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any),
  item: PropTypes.objectOf(PropTypes.any),
  onSubmit: PropTypes.func,
  goBack: PropTypes.func,
  action: PropTypes.string,
  loading: PropTypes.bool,
  refresh: PropTypes.func,
};
export default UserForm;
