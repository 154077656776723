import React from 'react';
import { Form, message } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import { useAddUser } from 'redux/Hooks/user';
import { pick, pickBy } from 'lodash';
import PropTypes from 'prop-types';
import { USER_TYPES } from 'configs/Constants';

import UserForm from '../UserForm';

const UserAdd = ({ userType, refresh }) => {
  const [form] = Form.useForm();
  const [avatar, setAvatar] = React.useState('');
  const location = useLocation();
  const history = useHistory();

  const {
    prevFetching: prevIsFetchingAdd,
    isFetching: isFetchingAdd,
    success: successAdd,
    message: errorResponse,
    onSave: onSaveAdd,
  } = useAddUser();

  React.useEffect(() => {
    if (isFetchingAdd !== prevIsFetchingAdd && prevIsFetchingAdd) {
      if (successAdd) {
        message.success('実行完了');
        refresh();
        history.replace({
          pathname: location.pathname,
        });
      } else {
        message.error('エラー');
        console.error(errorResponse?.message);
      }
    }
  }, [isFetchingAdd]);

  const handleBack = () => {
    history.goBack();
  };

  const onSubmitAdd = async () => {
    form.validateFields().then((values) => {
      const input = pickBy(
        pick(
          { ...values, ...{ userType, avatar } },
          userType === USER_TYPES.USER
            ? [
                'avatar',
                'avatars',
                'userType',
                'birthday',
                'email',
                'introduction',
                'gender',
                'password',
                'fullName',
                'status',
                'height',
                'weight',
                'isPremium',
              ]
            : [
                'avatar',
                'avatars',
                'userType',
                'birthday',
                'email',
                'introduction',
                'gender',
                'password',
                'fullName',
                'status',
                'height',
                'weight',
                'isPremium',
                'businessId',
              ]
        ),
        (value) => value === false || value
      );
      onSaveAdd(input);
    });
  };
  return <UserForm form={form} userType={userType} onSubmit={onSubmitAdd} goBack={handleBack} />;
};

UserAdd.propTypes = {
  userType: PropTypes.string,
  refresh: PropTypes.func,
};
export default UserAdd;
