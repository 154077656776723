import React from 'react';
import { Button, message, Spin } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import { jsonToCSV } from 'react-papaparse';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';
import { usePostExportCsv } from 'redux/Hooks/payment';

const ButtonExportCSV = ({ ids = [], onAfter }) => {
  const [{ isLoading }, doExport] = usePostExportCsv();

  const handleExportCSV = () => {
    doExport({
      params: { ids },
      success: (res) => {
        if (res?.success) {
          const link = document.createElement('a');
          document.body.appendChild(link);
          link.setAttribute('href', res?.url);
          link.click();
          onAfter();
        } else {
          message.error('エラー');
        }
      },
      failure: () => {
        message.error('エラー');
      },
    });
  };

  return (
    <Button className="flex justify-center items-center rounded" disabled={isLoading} onClick={handleExportCSV}>
      {isLoading ? <Spin size="small" /> : <FileExcelOutlined size={24} />} CSVをエクスポート
    </Button>
  );
};

ButtonExportCSV.propTypes = {
  ids: PropTypes.array,
  onAfter: PropTypes.func,
};

export default ButtonExportCSV;
