import React from 'react';
import { Form, Input, InputNumber, Select } from 'antd';
import { FIELDS_PROFILE, OPTION_LANGUAGES, PROFILE_JP } from 'configs/Constants';
import { DISTRICTS, LOCATION_JP } from 'utils/constant';
import { get } from 'lodash';

const BasicInformation = ({ userType }) => {
  return (
    <div className="w-full flex flex-col md:flex-row">
      <div className="flex flex-col md:w-1/2 w-full mr-4">
        <span className="py-2 font-semibold">{PROFILE_JP[FIELDS_PROFILE.HEIGHT]}</span>
        <Form.Item
          name={FIELDS_PROFILE.HEIGHT}
          rules={[
            {
              type: 'number',
              message: 'データ形が間違います。',
            },
          ]}
        >
          <InputNumber className="w-full" />
        </Form.Item>

        <span className="py-2 font-semibold">{PROFILE_JP[FIELDS_PROFILE.WEIGHT]}</span>
        <Form.Item
          name={FIELDS_PROFILE.WEIGHT}
          rules={[
            {
              type: 'number',
              message: 'データ形が間違います。',
            },
          ]}
        >
          <InputNumber className="w-full" />
        </Form.Item>

        <span className="py-2 font-semibold ">{PROFILE_JP[FIELDS_PROFILE.LIVE]}</span>
        <Form.Item noStyle name={FIELDS_PROFILE.LIVE}>
          <Select>
            {DISTRICTS.map((key) => (
              <Select.Option key={key}>{get(LOCATION_JP, key)}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <span className="py-2 font-semibold ">{PROFILE_JP[FIELDS_PROFILE.HOMETOWN]}</span>
        <Form.Item noStyle name={FIELDS_PROFILE.HOMETOWN}>
          <Select>
            {DISTRICTS.map((key) => (
              <Select.Option key={key}>{get(LOCATION_JP, key)}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <div className="flex flex-col w-1/2 ml-4">
        <span className="py-2 font-semibold ">{PROFILE_JP[FIELDS_PROFILE.LANGUAGE]}</span>
        <Form.Item noStyle name={FIELDS_PROFILE.LANGUAGE}>
          <Select mode="multiple" placeholder="言語を選択">
            {OPTION_LANGUAGES.map((lang) => (
              <Select.Option key={lang?.tag} value={lang?.tag}>
                {lang?.title?.ja}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <span className="py-2 font-semibold ">{PROFILE_JP[FIELDS_PROFILE.BLOOD_TYPE]} </span>
        <Form.Item noStyle name={FIELDS_PROFILE.BLOOD_TYPE}>
          <Select>
            <Select.Option key="A">A</Select.Option>
            <Select.Option key="B">B</Select.Option>
            <Select.Option key="AB">AB</Select.Option>
            <Select.Option key="O">O</Select.Option>
          </Select>
        </Form.Item>

        {/* <span className="py-2 font-semibold ">{PROFILE_JP[FIELDS_PROFILE.BROTHER_SISTER]}</span>
        <Form.Item noStyle name={FIELDS_PROFILE.BROTHER_SISTER}>
          <Input />
        </Form.Item> */}
        {userType === 'trainer' && (
          <>
            <span className="py-2 font-semibold">{PROFILE_JP[FIELDS_PROFILE.TRAINER_HISTORY]}</span>
            <Form.Item noStyle name={FIELDS_PROFILE.TRAINER_HISTORY}>
              <Input />
            </Form.Item>
          </>
        )}
      </div>
    </div>
  );
};

export default BasicInformation;
