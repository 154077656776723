import React from 'react';
import PropTypes from 'prop-types';
import AlertItem from './AlertItem';

const LessionOverTime = ({ loading, data }) => (
  <AlertItem title="開始時刻超過" value={data} loading={loading} path="/lesson?lessonOvertime=1" />
);

LessionOverTime.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.number,
};

export default LessionOverTime;
