import React from 'react';
import { Form, Input, Button, DatePicker, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { POINT_HISTORY } from 'utils/constant';
import { POINT_HISTORY_TYPE_JP } from 'configs/Constants';

const { RangePicker } = DatePicker;
const FormItem = Form.Item;

const PointHistorySearchForm = ({ onSubmit }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    const filter = {};
    if (values.type) {
      filter.type = values.type;
    }
    if (values.username) {
      filter.search = values.username;
    }
    if (Array.isArray(values.created)) {
      filter.from = moment(values?.created?.[0]).utc().startOf('day').format();
      filter.to = moment(values?.created?.[1]).utc().endOf('day').format();
    }
    onSubmit(filter);
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <div className="flex flex-row">
        <FormItem name="username" className="w-1/3 pr-4">
          <Input placeholder="名前" />
        </FormItem>
        <FormItem name="created" className="w-1/3">
          <RangePicker size="middle" />
        </FormItem>
      </div>
      <div className="flex flex-row">
        <FormItem name="type" className="w-1/3  pr-4">
          <Select placeholder="詳細" allowClear>
            {Object.entries(POINT_HISTORY.TYPE).map(([k, v]) => (
              <Select.Option key={v} value={v}>
                {POINT_HISTORY_TYPE_JP[v]}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
        <Form.Item shouldUpdate>
          {() => (
            <Button icon={<SearchOutlined />} htmlType="submit" type="ghost">
              検索
            </Button>
          )}
        </Form.Item>
      </div>
    </Form>
  );
};

export default PointHistorySearchForm;
