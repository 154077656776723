import { useCallback, useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import axios from 'axios';

import { request } from 'utils/Request';
import { getToken } from 'utils/Function';
import { API_ROOT } from 'configs/API';
import { usePrevious } from './hooks/usePrevious';
import { useAsyncState } from './hooks';

const useDelete = (API, onSuccess = () => {}, onFail = () => {}) => {
  const [editState, setEditState] = useState({
    isFetching: false,
    success: false,
  });
  const prevEditState = usePrevious(editState);
  const setWithPreviousState = (state) =>
    // eslint-disable-next-line no-shadow
    setEditState((prevEditState) => ({
      ...prevEditState,
      ...state,
    }));

  const onDelete = useCallback(
    (id, callback) => {
      setWithPreviousState({ isFetching: true });
      request({
        method: 'delete',
        url: API.replace(':id', id),
        success: (response) => {
          setWithPreviousState({
            isFetching: false,
            success: true,
            response,
          });
          onSuccess(response);
        },
        failure: (response) => {
          setWithPreviousState({ isFetching: false, success: false, response });
          onFail(response);
        },
      });
    },
    [API]
  );

  return {
    prevIsFetching: prevEditState?.isFetching || false,
    isFetching: editState.isFetching,
    success: editState.success,
    message: editState.message,
    onDelete,
  };
};

const useMethod = (API, method, onSuccess = () => {}, onFail = () => {}) => {
  const [editState, setEditState] = useState({
    isFetching: false,
    success: false,
  });
  const prevEditState = usePrevious(editState);
  const setWithPreviousState = (state) =>
    // eslint-disable-next-line no-shadow
    setEditState((prevEditState) => ({
      ...prevEditState,
      ...state,
    }));

  const onMethod = useCallback(
    (id, params, callback) => {
      setWithPreviousState({ isFetching: true });
      request({
        method,
        url: API.replace(':id', id),
        params,
        success: (response) => {
          setWithPreviousState({
            isFetching: false,
            success: true,
            response,
          });
          onSuccess(response);
        },
        failure: (response) => {
          setWithPreviousState({ isFetching: false, success: false, response });
          onFail(response);
        },
      });
    },
    [API]
  );

  return {
    prevIsFetching: prevEditState?.isFetching || false,
    isFetching: editState.isFetching,
    success: editState.success,
    message: editState.message,
    onMethod,
  };
};

const useAddEdit = (API, type = 'add', onSuccess = () => {}, onFail = () => {}) => {
  const [data, setData] = useState({});
  const [editState, setEditState] = useState({
    isFetching: false,
    success: false,
  });
  const prevEditState = usePrevious(editState);
  const setWithPreviousState = (state) =>
    // eslint-disable-next-line no-shadow
    setEditState((prevEditState) => ({
      ...prevEditState,
      ...state,
    }));
  const onSave = useCallback(
    (params, id = null, onSuccessSave = () => {}, onFailSave = () => {}) => {
      setWithPreviousState({ isFetching: true });
      request({
        method: type === 'add' ? 'post' : 'put',
        url: `${id && API?.includes(':id') ? API?.replace(':id', id) : API}`,
        params,
        success: (response) => {
          setWithPreviousState({
            isFetching: false,
            success: true,
            response,
          });
          onSuccess(response);
          onSuccessSave(response);
        },
        failure: (response) => {
          setWithPreviousState({ isFetching: false, success: false, response });
          onFail(response);
          onFailSave(response);
        },
      });
    },
    [API, data]
  );

  const refetch = (altUrl = '') => {
    request({
      method: 'get',
      url: altUrl || API,
      success: (result) => {
        setData(result);
      },
      failure: (response) => {
        setWithPreviousState({ isFetching: false, success: false, response });
      },
    });
  };

  useEffect(() => {
    if (type === 'edit' && API.indexOf(':id') === -1) {
      refetch();
    }
  }, [API, type]);

  return {
    data,
    setData,
    prevFetching: prevEditState?.isFetching || false,
    isFetching: editState.isFetching,
    success: editState.success,
    messsage: editState.message,
    onSave,
    refetch,
  };
};

const usePagingAppend = (API, fetchOnMount = true, pageSize = 30, queryParams) => {
  const [dataIds, setDataIds] = useAsyncState([]);
  const [dataObject, setDataObject] = useAsyncState({});
  const [pagingState, setPagingState] = useState({
    isFetching: false,
    isRefreshing: false,
    totalPages: 0,
    currentPage: 0,
    pageSize,
  });
  const [query, setQuery] = useState(queryParams);
  const setWithPreviousState = (state) =>
    setPagingState((prevPagingState) => ({
      ...prevPagingState,
      ...state,
    }));

  const fetch = useCallback(
    (page = 0) => {
      const resPage = Number(query?.page || page);
      setWithPreviousState({
        isFetching: true,
      });
      request({
        method: 'get',
        url: API,
        query: {
          ...query,
          page: resPage,
          pageSize: pagingState.pageSize,
        },
        // eslint-disable-next-line no-shadow
        success: async (data) => {
          const { totalPages, results } = data;
          await setDataObject((prevDataObject) => {
            return Object.assign(
              prevDataObject,
              results?.reduce(
                (res, item) => ({
                  ...res,
                  [item?.id]: item,
                }),
                {}
              )
            );
          });
          await setDataIds((prevDataIds = []) => {
            return prevDataIds?.concat(
              results?.map((item) => item?.id).filter((id) => !prevDataIds.includes(id)) || []
            );
          });
          setWithPreviousState({
            isFetching: false,
            currentPage: page,
            totalPages,
          });
        },
        failure: () => {
          setWithPreviousState({ isFetching: false });
        },
      });
    },
    [API, pagingState.currentPage, query]
  );

  const fetchMore = useCallback(() => {
    if (
      pagingState?.currentPage !== -1 &&
      !pagingState?.isFetching &&
      !pagingState?.isRefreshing &&
      Number(pagingState?.currentPage) < pagingState?.totalPages - 1
    ) {
      fetch(Number(pagingState.currentPage) + 1);
    }
  }, [fetch, pagingState]);

  const refresh = useCallback(
    (nextQuery) => {
      if (nextQuery) {
        setQuery(nextQuery);
      }
      setWithPreviousState({
        isRefreshing: true,
      });
      request({
        method: 'get',
        url: API,
        query: nextQuery || query,
        // eslint-disable-next-line no-shadow
        success: async (data) => {
          const { totalPages, currentPage, results } = data;
          await setDataObject(
            results?.reduce(
              (res, item) => ({
                ...res,
                [item?.id]: item,
              }),
              {}
            ) || {}
          );
          await setDataIds(results?.map((item) => item?.id) || []);
          setWithPreviousState({
            isRefreshing: false,
            currentPage,
            totalPages,
          });
        },
        failure: () => {
          setWithPreviousState({ isRefreshing: false });
        },
      });
    },
    [API, query]
  );

  useEffect(() => {
    if (fetchOnMount || query) {
      fetch();
    }
  }, [query]);

  // useEffect(() => {
  //   setIds(toObjectKeys(data));
  // }, [data]);

  return {
    dataIds,
    setDataIds,
    dataObject,
    setDataObject,
    results: dataIds?.map((id) => dataObject?.[id])?.filter((res) => res),
    isFetching: pagingState.isFetching,
    isRefreshing: pagingState.isRefreshing,
    currentPage: pagingState.currentPage,
    pageSize: pagingState.pageSize,
    totalPages: pagingState.totalPages,
    fetch,
    fetchMore,
    refresh,
    setQuery,
  };
};

const usePaging = (
  API,
  fetchOnMount = true,
  pageSize = 30,
  queryParams = {},
  { success = () => {}, failure = () => {} } = { success: () => {}, failure: () => {} }
) => {
  const [data, setData] = useState([]);
  const [pagingState, setPagingState] = useState({
    isFetching: false,
    isRefreshing: false,
    totalPages: 0,
    currentPage: 0,
    pageSize,
  });
  const [query, setQuery] = useState(queryParams);

  const setWithPreviousState = (state) =>
    setPagingState((prevPagingState) => ({
      ...prevPagingState,
      ...state,
    }));

  const fetch = useCallback(
    (page = 0) => {
      page = query?.page || page;
      setWithPreviousState({ isFetching: true });
      request({
        method: 'get',
        url: API,
        query: {
          page,
          pageSize: pagingState?.pageSize || 30,
          ...query,
        },
        // eslint-disable-next-line no-shadow
        success: (data) => {
          const { totalPages, results } = data;
          setWithPreviousState({
            isFetching: false,
            currentPage: page,
            totalPages,
          });
          setData(results);
          success(data);
        },
        failure: () => {
          setWithPreviousState({ isFetching: false });
          failure();
        },
      });
    },
    [API, pagingState.currentPage, query]
  );

  const fetchMore = useCallback(() => {
    if (
      pagingState.currentPage !== -1 &&
      !pagingState.isFetching &&
      !pagingState.isRefreshing &&
      pagingState.currentPage < pagingState.totalPages - 1
    ) {
      fetch(pagingState.currentPage + 1);
    }
  }, [fetch, pagingState]);

  const refresh = useCallback(
    (nextQuery) => {
      setWithPreviousState({ isRefreshing: true });
      request({
        method: 'get',
        url: API,
        query: query || nextQuery,
        // eslint-disable-next-line no-shadow
        success: (data) => {
          const { totalPages, currentPage, results } = data;
          setWithPreviousState({
            isRefreshing: false,
            currentPage,
            totalPages,
          });
          setData(results);
        },
        failure: () => {
          setWithPreviousState({ isRefreshing: false });
        },
      });
    },
    [API, query]
  );

  useEffect(() => {
    if (fetchOnMount || !isEmpty(query)) {
      fetch();
    }
  }, [query]);

  // useEffect(() => {
  //   setIds(toObjectKeys(data));
  // }, [data]);

  return {
    results: data,
    isFetching: pagingState.isFetching,
    isRefreshing: pagingState.isRefreshing,
    currentPage: pagingState.currentPage,
    pageSize: pagingState.pageSize,
    totalPages: pagingState.totalPages,
    fetch,
    fetchMore,
    refresh,
    setQuery,
    setData,
  };
};

const useRequest = ({ url, method = 'get', fetchOnMount, mountParams }) => {
  const [requestState, setRequestState] = useState({
    data: null,
    isLoading: false,
    isSuccess: false,
    isCalled: false,
    message: '',
  });

  const doRequest = ({ url: altUrl, params, query, success, failure, ignoreResponse } = {}) => {
    setRequestState((prevState) => ({
      ...prevState,
      isLoading: true,
      isSuccess: false,
    }));

    request({
      url: url || altUrl,
      method,
      params,
      query,
      success: (result) => {
        if (ignoreResponse) return;
        setRequestState((prevState) => ({
          ...prevState,
          data: result,
          isLoading: false,
          isSuccess: true,
          isCalled: true,
        }));
        if (typeof success === 'function') {
          success(result);
        } else if (mountParams?.success) {
          mountParams.success(result);
        }
      },
      failure: (result) => {
        if (ignoreResponse) return;
        setRequestState((prevState) => ({
          ...prevState,
          isLoading: false,
          isSuccess: false,
          isCalled: true,
          message: result,
        }));
        if (typeof failure === 'function') {
          failure(result);
        } else if (mountParams?.failure) {
          mountParams.failure(result);
        }
      },
    });
  };

  useEffect(() => {
    if (fetchOnMount) {
      doRequest(mountParams);
    }
  }, []); // eslint-disable-line

  return [requestState, doRequest];
};

const usePost = ({ url }) => {
  const [requestState, setRequestState] = useState({
    data: null,
    isLoading: false,
    isSuccess: false,
    message: '',
  });
  const prevLoading = usePrevious(requestState.isLoading);
  const authorization = getToken() ? `Bearer ${getToken()}` : null;

  const doPost = ({ id, body } = {}, onSuccess = () => {}, onError = () => {}) => {
    setRequestState((prevState) => ({
      ...prevState,
      isLoading: true,
      isSuccess: false,
    }));
    axios
      .post(`${API_ROOT}${id && url?.includes(':id') ? url?.replace(':id', id) : url}`, body, {
        headers: {
          'Content-type': 'application/json',
          'Cache-Control': 'no-cache',
          ...(authorization ? { Authorization: authorization } : {}),
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setRequestState((prevState) => ({
            ...prevState,
            data: response.data,
            isLoading: false,
            isSuccess: true,
          }));
          onSuccess(response.data);
        }
      })
      .catch((error) => {
        setRequestState((prevState) => ({
          ...prevState,
          isLoading: false,
          isSuccess: false,
          message: error,
        }));
        onError(error);
      });
  };
  return [doPost, prevLoading, requestState];
};

export { usePaging, useAddEdit, useDelete, useRequest, usePost, useMethod, usePagingAppend };
