import moment from 'moment';

export function getDayOfMonth(datetime, format = 'MM月DD') {
  let daysInMonth = moment(datetime).daysInMonth();
  const arrDaysInMonth = [];
  while (daysInMonth) {
    const current = moment(datetime).date(daysInMonth);
    arrDaysInMonth.push({ [moment(current).format(format)]: 0 });
    daysInMonth -= 1;
  }
  return arrDaysInMonth.reduce((a, b) => Object.assign(a, b), {});
}

export function getMonthOfYear(year = moment().format('YYYY'), format = 'MM月') {
  return Array.apply(0, Array(12))
    .map((_, i) => Object({ [moment().month(i).year(year).format(format)]: 0 }))
    .reduce((a, b) => Object.assign(a, b), {});
}
