import React from 'react';
import Icon from '@ant-design/icons';

const DotIcon = (props) => (
  <Icon
    component={() =>
      props.selected ? (
        <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 10} height={props.size || 10} viewBox="0 0 10 10">
          <circle cx="5" cy="5" r="5" fill="#3b86ff" />
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width={props.size || 10} height={props.size || 10} viewBox="0 0 10 10">
          <circle cx="5" cy="5" r="5" fill="#3b86ff" opacity="0.3" />
        </svg>
      )
    }
    {...props}
  />
);

export default DotIcon;
