import React from 'react';
import { Layout } from 'antd';

import ChartIncomeRegion from './components/ChartIncomeRegion';
import NewUsersRegion from './components/NewUsersRegion';
import UnverifyTrainersRegion from './components/UnverifyTrainersRegion';
import TrainerRankingRegion from './components/TrainerRankingRegion';
import AlertDashboard from './components/Alert';

const Dashboard = () => {
  return (
    <Layout.Content className="flex flex-col p-4 space-y-12">
      <AlertDashboard />
      <ChartIncomeRegion />
      <NewUsersRegion />
      <UnverifyTrainersRegion />
      <TrainerRankingRegion />
    </Layout.Content>
  );
};

export default Dashboard;
