import { API } from 'configs';
import { useRequest, useAddEdit, useDelete } from 'utils/AxiosHooks';

const useRoles = () => useRequest({ url: API.ADMIN.ROLE.LIST, fetchOnMount: true });

const useEditRole = (id) => useAddEdit(id ? API.ADMIN.ROLE.EDIT.replace(':id', id) : API.ADMIN.ROLE.EDIT, 'edit');
const useAddRole = (id) => useAddEdit(API.ADMIN.ROLE.ADD, 'add');
const useDeleteRole = (onSuccess) => useDelete(API.ADMIN.ROLE.EDIT, onSuccess);

export { useRoles, useEditRole, useAddRole, useDeleteRole };
