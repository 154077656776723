import queryString from 'query-string';
import axios from './Axios';

function buildURL(url, query) {
  let _url = url;
  if (query) {
    _url += /\?/.test(url) ? '&' : '?';
    if (typeof query === 'object') {
      _url += queryString.stringify(query);
    } else {
      _url += query;
    }
  }
  return _url;
}

// eslint-disable-next-line consistent-return
async function request({ method = 'get', url, query, params, success, failure, headers }) {
  // if (__DEV__) {
  console.log(method, buildURL(url, query), params);
  // }

  const axiosMethod = axios[method];
  if (typeof axiosMethod === 'function') {
    try {
      const result =
        method === 'get'
          ? await axiosMethod(buildURL(url, query), { headers })
          : await axiosMethod(buildURL(url, query), params, { headers });

      if (result.status === 200 || result.status === 201) {
        if (typeof success === 'function') {
          return success(result.data);
        }
      } else {
        return failure({ message: result?.data });
      }
    } catch (err) {
      // const result = err.toJSON();
      if (typeof failure === 'function') {
        return failure({ message: err?.message });
      }
      console.log(err);
    }
  }
}

export { request };
