import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, Button, Radio } from 'antd';
import { useSendMessageBulk } from 'redux/Hooks/conversation';

const FormItem = Form.Item;
const { TextArea } = Input;

const FormSendBulk = ({ isVisibleSendMessBulkwrite, setVisibleSendMessBulk }) => {
  const onCancel = () => {
    setVisibleSendMessBulk(false);
  };
  const [doSend, requestState] = useSendMessageBulk();

  React.useEffect(() => {
    if (requestState.isSuccess) {
      onCancel();
    }
  }, [requestState.isSuccess]);

  const sendMessagesBulk = (values) => {
    doSend(values);
  };

  return (
    <Modal
      centered
      footer={null}
      visible={isVisibleSendMessBulkwrite}
      title="一斉送信メッセージ"
      onCancel={() => onCancel()}
      destroyOnClose
    >
      <Form layout="vertical" onFinish={sendMessagesBulk} style={{ marginTop: 8 }}>
        <FormItem label="内容" name="content" rules={[{ required: true, message: '内容を入力してください。' }]}>
          <TextArea placeholder="入力してください" />
        </FormItem>
        <FormItem label="送信先" name="userType">
          <Radio.Group>
            <Radio value="all">全員</Radio>
            <Radio value="trainer">トレーナー</Radio>
            <Radio value="training">ユーザー</Radio>
          </Radio.Group>
        </FormItem>
        <FormItem style={{ marginTop: 28, textAlign: 'center' }}>
          <Button type="primary" htmlType="submit" loading={requestState.isLoading}>
            送信
          </Button>
        </FormItem>
      </Form>
    </Modal>
  );
};

FormSendBulk.propTypes = {
  isVisibleSendMessBulkwrite: PropTypes.bool.isRequired,
  setVisibleSendMessBulk: PropTypes.func,
};
export default FormSendBulk;
