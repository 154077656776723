import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { SendOutlined, FileImageOutlined, CloseOutlined } from '@ant-design/icons';
import { useSendMessage } from 'redux/Hooks/conversation';
import { useRecoilValue } from 'recoil';
import { authState } from 'store/atom';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

import styles from 'containers/Concierge/styles.module.css';

const { TextArea } = Input;

const MessageType = ({ setMessages, convId, sendSuccess = () => {} }) => {
  const [content, setContent] = React.useState('');
  const [fileImage, setFileImage] = React.useState({
    value: '',
    file: null,
    fileName: '',
  });
  const [doSend] = useSendMessage(convId);
  const { user } = useRecoilValue(authState);

  const handleSend = () => {
    if (content) {
      const newMsg = {
        id: Math.random().toString(),
        content,
        senderId: user.id,
        sender: user,
        temp: true,
      };
      setMessages((prevMessage) => [newMsg, ...prevMessage]);
    }

    const formData = new FormData();
    formData.append('content', content);
    if (fileImage.file) {
      formData.append('image', fileImage.file);
    }
    doSend(formData, sendSuccess);
  };

  const resetField = () => {
    setContent('');
    setFileImage({ value: '', file: null, fileName: '' });
  };
  const handleSendMessages = () => {
    handleSend();
    resetField();
  };

  const handleTextarea = (ev) => {
    if (ev.keyCode === 13) {
      ev.preventDefault();
      //
      handleSendMessages();
    }
  };

  const handleChangeUpload = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    setFileImage({
      file: e.target.files[0],
      value: e.target.value,
      fileName: URL.createObjectURL(e.target.files[0]),
    });
  };

  return (
    <>
      {fileImage.file && (
        <div className="flex items-center p-2">
          <img style={{ width: '20%' }} src={fileImage.fileName} alt="img-send" />
          <CloseOutlined
            type="delete"
            className="text-2xl pl-6"
            onClick={() => {
              setFileImage({ value: '', file: null, fileName: '' });
            }}
          />
        </div>
      )}

      <div className="bg-white flex flex-row relative overflow-hidden items-end pt-4 pb-4 space-x-4">
        <div>
          <label htmlFor="file-input">
            <FileImageOutlined className="text-2xl mb-3 cursor-pointer" />
            <input
              value={fileImage.value}
              id="file-input"
              type="file"
              onChange={handleChangeUpload}
              className="hidden"
              accept="image/*"
            />
          </label>
        </div>

        <div className={classnames(styles.childFullWidth, 'flex flex-grow')}>
          <TextArea
            className="border-none resize-none py-4 px-4"
            style={{ borderRadius: '18px', backgroundColor: 'rgba(0, 0, 0, 0.05)' }}
            bordered={false}
            placeholder="メッセージを入力してください"
            value={content}
            autoSize={{ minRows: 1, maxRows: 6 }}
            onChange={(e) => setContent(e.target.value)}
            onKeyDown={handleTextarea}
          />
        </div>
        <div>
          <SendOutlined
            className="text-2xl mb-3"
            onClick={() => {
              handleSendMessages();
            }}
          />
        </div>
      </div>
    </>
  );
};

MessageType.propTypes = {
  setMessages: PropTypes.func,
  convId: PropTypes.number,
};
export default MessageType;
