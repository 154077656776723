import React, { useEffect, useState } from 'react';
import { Layout, Table, Tag, Space, Button, Modal, Input, Select, message, Typography } from 'antd';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import qs from 'qs';
import moment from 'moment';
import omit from 'lodash/omit';

import {
  useSearchLessonRequest,
  useEditLessonRequest,
  useDeleteLessonRequest,
  useJoinLessonRequest,
} from 'redux/Hooks/lesson_request';
import { useFilter } from 'utils/hooks';
import EditModal from 'components/EditModal';
import TrainingTypeItem from 'components/TrainingTypeItem';

import LessonSearchForm from './components/LessonSearchForm';
import SelectUserFormInput from './components/SelectUserFormInput';

const DEFAULT_PAGE_SIZE = 30;

const statusinJP = {
  active: 'アクティブ',
  pending: '保留',
  draft: 'ドラフト',
  done: '完了',
};
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */

const LessonRequest = (props) => {
  const { location, history } = props;
  const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
  const action = parsedQuery?.action;
  const id = parsedQuery?.id;
  const [visibleEdit, setVisibleEdit] = useState(false);

  const [modal, contextHolder] = Modal.useModal({});

  const { results, currentPage, totalPages, refresh, setQuery } = useSearchLessonRequest();

  const [filter, setFilter] = useFilter(setQuery);

  const {
    data: dataLessonById,
    prevFetching: prevIsFetching,
    isFetching,
    success,
    message: successResponse,
    onSave,
  } = useEditLessonRequest(
    id,
    ({ success }) => {
      if (success) {
        message.success('実行完了');
        refresh();
        return;
      }
      message.error('エラー');
    },
    () => {
      message.error('エラー');
    }
  );
  const { onDelete } = useDeleteLessonRequest(
    id,
    ({ success }) => {
      if (success) {
        message.success('実行完了');
        refresh();
        return;
      }
      message.error('エラー');
    },
    () => {
      message.error('エラー');
    }
  );
  const { onMethod: onJoin } = useJoinLessonRequest(
    id,
    'post',
    ({ success }) => {
      if (success) {
        message.success('トレーナーが参加しました。');
        return;
      }
      message.error('エラー');
    },
    // eslint-disable-next-line no-unused-vars
    (e) => {
      message.error('エラー');
    }
  );
  useEffect(() => {
    if (isFetching !== prevIsFetching && prevIsFetching) {
      if (success) {
        refresh();
        props.history.push({
          pathname: props.location.pathname,
        });
      } else {
        message.error('エラー');
        console.error(successResponse?.message);
      }
    }
  }, [isFetching]);

  const onHiddenEdit = () => {
    setVisibleEdit(false);
    props.history.push({
      pathname: props.location.pathname,
      search: '',
    });
  };

  useEffect(() => {
    if (id && action === 'edit') {
      if (dataLessonById) {
        setVisibleEdit(true);
      }
    }
  }, [id, action]);

  const handleSearch = (f) => {
    setFilter({ ...filter, ...f });
  };

  const handleChangePage = (page, pageSize) => {
    setFilter({ ...filter, page: page - 1, pageSize });
  };

  const onOkDelete = async (close, id) => {
    onDelete(id);
    close();
  };

  const onCancel = (close) => {
    close();
  };
  const modalDeleteConfig = (id) => {
    return {
      className: '',
      title: '確認',
      content: <h1>削除しますか？</h1>,
      onOk: (close) => onOkDelete(close, id),
      onCancel,
      okButtonProps: {},
      cancelButtonProps: {},
      okText: '削除',
      okType: 'primary',
      cancelText: 'キャンセル',
      icon: null,
      mask: true,
      maskClosable: true,
      keyboard: true,
      autoFocusButton: 'ok',
    };
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      width: '10%',
    },
    {
      title: 'レッスン名',
      dataIndex: ['title'],
      width: '20%',
      render: (value) => <div className="">{value}</div>,
    },
    {
      title: 'リクエストしたユーザー',
      width: '15%',
      dataIndex: ['from', 'fullName'],
      render: (value) => <div className="">{value}</div>,
    },
    {
      title: '応募したトレーナー',
      width: '15%',
      dataIndex: ['to', 'fullName'],
      render: (value) => <div className="">{value}</div>,
    },
    {
      title: '開始日',
      dataIndex: ['startDate'],
      width: '15%',
      render: (value, record) => {
        return value ? moment.utc(value).local().format('DD/MM/Y HH:mm') : '';
      },
    },
    {
      title: '終了日',
      dataIndex: ['endDate'],
      width: '15%',
      render: (value, record) => {
        return value ? moment.utc(value).local().format('DD/MM/Y HH:mm') : '';
      },
    },
    {
      title: 'ステータス',
      dataIndex: ['status'],
      width: '5%',
      render: (value, record) => {
        const isPaid = value !== 'draft';
        return (
          <Tag
            style={{ cursor: value === 'active' ? 'default' : 'pointer' }}
            color={value === 'active' ? 'green' : '#f50'}
          >
            {statusinJP[value] || value}
          </Tag>
        );
      },
    },
    {
      title: 'アクション',
      dataIndex: 'status',
      width: '5%',
      render: (value, record) => {
        return (
          <Space size="middle">
            <Button
              onClick={() => {
                const query = new URLSearchParams(history.location.search);
                query.set('action', 'edit');
                query.set('id', record.id);
                history.push({
                  pathname: location.pathname,
                  search: query.toString(),
                });
              }}
              icon={<EditOutlined />}
            />
            <Button
              disabled={record?.status !== 'pending'}
              onClick={() => {
                modal.confirm(modalDeleteConfig(record.id));
              }}
              icon={<CloseOutlined />}
            />
          </Space>
        );
      },
    },
  ];

  const editInputs = [
    {
      title: 'レッスン名',
      key: 'title',
      name: ['title'],
      InputComponent: Input,
      inputProps: {},
      formItemProps: {
        className: 'my-4',
        rules: [{ required: true }],
      },
    },
    {
      title: '説明',
      key: 'description',
      name: ['description'],
      InputComponent: Input.TextArea,
      inputProps: {},
      formItemProps: {
        className: 'my-4',
        rules: [{ required: true }],
      },
    },
    {
      title: 'ステータス',
      key: 'status',
      name: ['status'],
      InputComponent: Select,
      inputProps: {
        options: Object.keys(statusinJP)?.map((key) => ({ label: statusinJP[key], value: key })),
      },
      formItemProps: {
        className: 'my-4',
        rules: [{ required: true }],
      },
    },
    {
      key: 'price',
      name: ['price'],
      InputComponent: Input,
      inputProps: {
        className: 'hidden',
      },
      formItemProps: {
        hidden: true,
      },
    },
    {
      key: 'startDate',
      name: ['startDate'],
      InputComponent: Input,
      inputProps: {
        className: 'hidden',
      },
      formItemProps: {
        hidden: true,
      },
    },
    {
      key: 'endDate',
      name: ['endDate'],
      InputComponent: Input,
      inputProps: {
        className: 'hidden',
      },
      formItemProps: {
        hidden: true,
      },
    },
    {
      title: '実践できるトレーニング',
      key: 'trainingType',
      name: ['trainingType'],
      InputComponent: TrainingTypeItem,
      inputProps: {
        disabled: true,
      },
      formItemProps: {
        className: 'my-4',
      },
    },
    {
      title: 'リクエストしたユーザー',
      key: 'fromId',
      name: ['from'],
      InputComponent: SelectUserFormInput,
      inputProps: {
        disabled: true,
        className: 'border',
      },
      formItemProps: {},
    },
    {
      title: '応募したトレーナー',
      key: 'toId',
      name: ['to'],
      InputComponent: SelectUserFormInput,
      inputProps: {
        className: 'border',
        disabled: dataLessonById?.to?.id || dataLessonById?.status !== 'pending',
      },
      formItemProps: {
        rules: [{ required: true }],
      },
    },
  ];

  const pagination = {
    onChange: handleChangePage,
    current: currentPage + 1 || 1,
    pageSize: DEFAULT_PAGE_SIZE,
    total: totalPages * DEFAULT_PAGE_SIZE || 0,
  };

  return (
    <Layout.Content className="flex flex-col p-4">
      <Typography.Title level={3}>レッスンリクエスト</Typography.Title>
      <div className="formControl py-4 justify-between">
        <LessonSearchForm onSubmit={handleSearch} />
      </div>
      <Table
        className="bg-white overflow-x-scroll"
        rowClassName="align-top whitespace-pre-wrap break-words fixBreakWordsJapan text-center"
        components={{
          header: {
            cell: ({ className, style, ...restProps }) => {
              return <th className={className} style={{ ...style, textAlign: 'center' }} {...restProps} />;
            },
          },
        }}
        bordered
        dataSource={results || []}
        columns={columns}
        rowKey="id"
        pagination={pagination}
      />
      <EditModal
        visible={visibleEdit}
        onCreate={(values, recordId) => {
          if (values?.to?.id && !dataLessonById?.to?.id) {
            onJoin(id, { toId: values?.to?.id });
          }
          const input = omit(values, ['trainingType']);
          onSave(input, recordId);
          onHiddenEdit();
        }}
        onCancel={() => {
          onHiddenEdit();
        }}
        record={dataLessonById}
        loading={isFetching}
        editInputs={editInputs}
        headerTitle="編集"
        modalProps={{ width: '80%' }}
        formProps={{
          encType: 'multipart/form-data',
        }}
      />
      {contextHolder}
    </Layout.Content>
  );
};

LessonRequest.propTypes = {
  location: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
};
export default LessonRequest;
