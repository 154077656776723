import React from 'react';
import PropTypes from 'prop-types';
import AlertItem from './AlertItem';

const MessageUnread = ({ loading, data }) => (
  <AlertItem title="未読メッセージ" value={data} loading={loading} path="/concierge" />
);
MessageUnread.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.number,
};
export default MessageUnread;
