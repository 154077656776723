import React from 'react';

import { USER_TYPES } from 'configs/Constants';
import UserStandard from './components/UserStandard';

const Trainer = (props) => {
  return <UserStandard {...props} userType={USER_TYPES.TRAINER} />;
};

export default Trainer;
