import React, { useState } from 'react';
import { CONCIERGE_VIEW } from 'configs/Constants';
import PropTypes from 'prop-types';
import Concierge from './Concierge';
import Help from 'containers/Help';

const ConciergeContainer = ({ location, history }) => {
  const [view, setView] = useState(CONCIERGE_VIEW.CONCIERGE);
  return (
    <>
      {view === CONCIERGE_VIEW.CONCIERGE && <Concierge location={location} history={history} setView={setView} />}
      {view === CONCIERGE_VIEW.INQUIRY && <Help setView={setView} />}
    </>
  );
};

ConciergeContainer.propTypes = {
  location: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
};
export default ConciergeContainer;
