import React, { useState, useEffect, forwardRef } from 'react';
import { Input, Image } from 'antd';
import { PictureOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import UploadImage from 'components/UploadImage';
import { getBase64 } from '../../utils/helpers';

export const UploadImageInput = ({
  name,
  className = '',
  style = {},
  value,
  onChange,
  size = '6rem',
  loadingComponent = null,
  uploadPath,
  customUploadProps = {},
}) => {
  const [stateUrl, setStateUrl] = useState(value);
  useEffect(() => {
    if (value) {
      setStateUrl(value);
    }
  }, [value]);

  return (
    <div className={className} style={style}>
      <UploadImage
        name={name}
        uploadPath={uploadPath}
        onFinish={(url, info) => {
          setStateUrl(url);
          onChange(url);
        }}
        loadingComponent={loadingComponent || <PictureOutlined style={{ fontSize: size }} />}
        className="rounded-full h-full w-full flex items-center justify-center"
        customUploadProps={customUploadProps}
      >
        <Image
          className="flex items-center justify-center object-contain"
          width={size}
          height={size}
          src={stateUrl}
          placeholder={!stateUrl && (loadingComponent || <PictureOutlined style={{ fontSize: size }} />)}
          preview={false}
        />
      </UploadImage>
      <Input name={name} hidden value={stateUrl} />
    </div>
  );
};

const UploadImageFormItem = forwardRef(
  ({ key, name, className = '', classNameImage = '', style = {}, value, onChange }, ref) => {
    const [stateUrl, setStateUrl] = useState(value);

    return (
      <div className={className} style={style}>
        <UploadImageInput
          className={classNames('flex flex-grow', classNameImage)}
          value={stateUrl}
          customUploadProps={{ disabled: true }}
        />
        <input
          ref={ref}
          type="file"
          name={name}
          id={key}
          accept="image/*"
          onChange={async (file) => {
            const base64Url = await getBase64(file?.target?.files?.[0]);
            setStateUrl(base64Url);
            onChange(base64Url);
          }}
        />
      </div>
    );
  }
);

export default UploadImageFormItem;
