import React, { useEffect } from 'react';
import { Button, Card, Layout, Typography, Form, Input, Radio, Skeleton, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { useAddCompany, useEditCompany, useEditCompanyImport } from 'redux/Hooks/company';
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import BranchSelect from 'components/BranchSelect';
import BankSelect from 'components/BankSelect';
import Trainer from '../Trainer';
import CSVImportGroup from '../CSVImportGroup';

const CreateAndEdit = ({ initialValues = {}, loading }) => {
  const history = useHistory();
  const currentInitialValues = cloneDeep(initialValues);
  const [formEdit] = Form.useForm();

  const useAction = initialValues?.id ? useEditCompany : useAddCompany;
  const {
    onSave: onImport,
    isFetching: isLoadingImport,
    prevFetching: isLoadingImportPrev,
  } = useEditCompanyImport(
    ({ success }) => {
      if (success) {
        notification.success({
          message: '成功',
          description: 'CSVファイルのインポートに成功しました',
        });
      }
    },
    (fail) => {
      notification.error({
        message: 'エラー',
        description: 'CSVファイルのインポートに失敗しました',
      });
    }
  );
  const refresh = isLoadingImportPrev && isLoadingImport !== isLoadingImportPrev;
  const { onSave } = useAction(
    ({ success, business }) => {
      if (success) {
        notification.success({
          message: '成功',
          description: '実行完了',
        });
        history.goBack();
      }
    },
    (fail) => {
      notification.error({
        message: 'エラー',
        description: '後でもう一度やり直してください',
      });
    }
  );

  useEffect(() => {
    formEdit.resetFields();
  }, [currentInitialValues]);

  const onFinish = (values) => {
    onSave(omit(values, ['id', 'currentBank']), values?.id);
  };

  if (loading) {
    return (
      <Card className="shadow-sm border-solid border-2 w-full h-full" title="">
        <Skeleton active size="large" paragraph title />
      </Card>
    );
  }

  return (
    <Layout.Content className="flex flex-col space-y-6 relative">
      <Typography.Title level={3}>一括登録用銀行口座入力画面</Typography.Title>
      <Form form={formEdit} layout="horizontal" onFinish={onFinish} initialValues={initialValues} className="space-y-6">
        {initialValues?.id && (
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
        )}
        <Card className="shadow-sm border-solid border-2 relative" title="法人名登録">
          <Form.Item
            label="法人名"
            name="name"
            labelAlign="left"
            rules={[
              {
                required: true,
                whitespace: true,
              },
              {
                pattern: new RegExp(/^(?:([ァ-ン]|ー)|([ｧ-ﾝﾞﾟ]))+$/),
                message: '無効な値',
              },
            ]}
          >
            <Input placeholder="法人名" />
          </Form.Item>
        </Card>
        <Card className="shadow-sm border-solid border-2 relative" title="振込先口座情報">
          <Form.Item label="金融機関名" name="bankName" labelAlign="left" rules={[{ required: true }]} shouldUpdate>
            <BankSelect form={formEdit} />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              if (prevValues.bankName !== currentValues.bankName) {
                formEdit.resetFields(['bankBranch']);
              }
              return prevValues.bankName !== currentValues.bankName;
            }}
          >
            {() => {
              return (
                <Form.Item label="支店名" name="bankBranch" labelAlign="left" rules={[{ required: true }]}>
                  <BranchSelect currentBank={formEdit.getFieldValue('currentBank')} />
                </Form.Item>
              );
            }}
          </Form.Item>

          <Form.Item
            label="口座番号"
            name="bankNumber"
            labelAlign="left"
            rules={[{ required: true }, { pattern: new RegExp(/^[0-9]*$/g), message: '数字を入力してください' }]}
          >
            <Input placeholder="口座番号" />
          </Form.Item>
          <Form.Item
            label="口座名義人名（カナ）"
            name="bankAccountName"
            labelAlign="left"
            rules={[
              { required: true },
              { whitespace: true },
              {
                pattern: new RegExp(/^(?:([ァ-ン]|ー)|([ｧ-ﾝﾞﾟ]))+$/),
                message: '無効な値',
              },
            ]}
          >
            <Input placeholder="口座名義人名（カナ）" />
          </Form.Item>
          <Form.Item label="口座種別" name="bankAccountType" labelAlign="left" rules={[{ required: true }]}>
            <Radio.Group
              options={[
                {
                  label: '当座',
                  value: '当座',
                },
                {
                  label: '普通',
                  value: '普通',
                },
              ]}
              placeholder="口座種別"
              allowClear
            />
          </Form.Item>
          <Form.Item label="備考" name="note" labelAlign="left">
            <Input.TextArea placeholder="備考" autoSize={{ minRows: 3 }} allowClear />
          </Form.Item>
        </Card>
      </Form>
      {initialValues?.id && (
        <Card className="shadow-sm border-solid border-2 relative">
          <CSVImportGroup initialValues={initialValues} onImport={onImport} />
        </Card>
      )}
      {initialValues?.id && (
        <Card className="shadow-sm border-solid border-2 relative">
          <Trainer businessId={initialValues?.id} refresh={refresh} />
        </Card>
      )}

      <div className="flex flex-row justify-end space-x-4">
        <Button
          className="rounded w-32"
          type="primary"
          htmlType="submit"
          size="large"
          onClick={() => formEdit.submit()}
        >
          次へ
        </Button>
        <Button className="rounded w-32" type="default" size="large" onClick={() => history.goBack()}>
          キャンセル
        </Button>
      </div>
    </Layout.Content>
  );
};

export default CreateAndEdit;
