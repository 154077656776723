import { API } from 'configs';
import { usePaging, useAddEdit, useDelete, useRequest } from 'utils/AxiosHooks';

const useUsers = (isMount = false, queryParams) =>
  usePaging(API.ADMIN.USER.LIST, isMount, queryParams?.pageSize || 30, queryParams);
const useGetUserPointHistory = (userId) => usePaging(API.ADMIN.POINT_HISTORY.USER.replace(':id', userId));
const useGetUserPaymentRequest = (userId) => usePaging(API.ADMIN.PAYMENT_REQUEST.USER.replace(':id', userId));
const useGetUserRating = (userId) => usePaging(API.ADMIN.USER.RATING.replace(':id', userId));
const useGetLogHistory = (userId) => usePaging(API.ADMIN.USER.LOG_HISTORY.replace(':id', userId));
const useGetUserTrainingHistory = (userId) => usePaging(API.ADMIN.USER.TRAINING_HISTORY.replace(':id', userId));

const useEditUser = (id, onSuccess, onFail) =>
  useAddEdit(API.ADMIN.USER.EDIT.replace(':id', id), 'edit', onSuccess, onFail);
const useAddUser = (id) => useAddEdit(API.ADMIN.USER.ADD, 'add');
const useDeleteUser = (onSuccess, onFail) => useDelete(API.ADMIN.USER.EDIT, onSuccess, onFail);

const useGetUser = (id) =>
  useRequest({
    url: id && Number(id) ? API.ADMIN.USER.EDIT.replace(':id', Number(id)) : API.ADMIN.USER.EDIT,
    method: 'get',
    fetchOnMount: false,
  });

const useGetGroupTags = () => useRequest({ url: API.ADMIN.USER.TAGS, fetchOnMount: true });
const useGetGroupTagsV2 = () => useRequest({ url: API.ADMIN.USER.GROUP_TAGS_V2, fetchOnMount: true });

export {
  useUsers,
  useGetLogHistory,
  useGetUserTrainingHistory,
  useEditUser,
  useAddUser,
  useDeleteUser,
  useGetUser,
  useGetUserPaymentRequest,
  useGetUserPointHistory,
  useGetUserRating,
  useGetGroupTags,
  useGetGroupTagsV2,
};
