import React, { useState, useEffect } from 'react';
import { Layout, Space, Button, Table, Modal, message, Input, InputNumber, Switch, Image, Select } from 'antd';
import { CloseOutlined, EditOutlined, PictureOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import qs from 'qs';

import EditModal from 'components/EditModal';
import UploadImage from 'components/UploadImage';
import { useGifts, useEditGift, useAddGift, useDeleteGift } from 'redux/Hooks/gift';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const SIZE_GIFT = { s: '小', m: '中', l: '大' };

const UploadImageInput = ({ name, className = '', value, onChange }) => {
  const [stateUrl, setStateUrl] = useState(value);

  return (
    <div className={className}>
      <UploadImage
        onFinish={(url) => {
          setStateUrl(url);
          onChange(url);
        }}
        loadingComponent={<PictureOutlined style={{ fontSize: '6rem' }} />}
        className="rounded-full h-full w-full flex items-center justify-center"
      >
        <Image
          width="6rem"
          src={stateUrl}
          placeholder={<PictureOutlined style={{ fontSize: '6rem' }} />}
          preview={false}
        />
      </UploadImage>
      <Input name={name} hidden value={stateUrl} />
    </div>
  );
};

const Gift = (props) => {
  const location = useLocation();
  const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
  const page = parsedQuery?.page ? parseInt(parsedQuery?.page, 10) : 1;
  const pageSize = parsedQuery?.pageSize ? parseInt(parsedQuery?.pageSize, 10) - 1 : 30;

  const { results, currentPage, totalPages, refresh, setQuery } = useGifts();

  const {
    prevFetching: prevIsFetchingEdit,
    isFetching: isFetchingEdit,
    success,
    message: successResponse,
    onSave,
  } = useEditGift();
  const {
    prevFetching: prevIsFetchingAdd,
    isFetching: isFetchingAdd,
    success: successAdd,
    message: errorResponse,
    onSave: onAdd,
  } = useAddGift();

  const { onDelete } = useDeleteGift(() => refresh());

  const [visibleEdit, setVisibleEdit] = useState(false);
  const [editSelected, setEditSelected] = useState(null);

  const [modal, contextHolder] = Modal.useModal({});

  useEffect(() => {
    if (isFetchingEdit !== prevIsFetchingEdit && prevIsFetchingEdit) {
      if (success) {
        message.success('実行完了');
        refresh();
      } else {
        message.error('エラー');
        console.error(successResponse?.message);
      }
    }
  }, [isFetchingEdit]);

  useEffect(() => {
    if (isFetchingAdd !== prevIsFetchingAdd && prevIsFetchingAdd) {
      if (successAdd) {
        message.success('実行完了');
        refresh();
      } else {
        message.error('エラー');
        console.error(errorResponse?.message);
      }
    }
  }, [isFetchingAdd]);

  const dataSource = results || [];

  const onChangeItem = async (values, recordId) => {
    try {
      if (recordId) {
        onSave(values, recordId);
      } else {
        onAdd(values);
      }
    } catch (createError) {
      message.error('エラー');
    } finally {
      setVisibleEdit(false);
      setEditSelected(null);
    }
  };

  const onOk = async (close, record) => {
    try {
      onDelete(record?.id);
      const query = new URLSearchParams(props.history.location.search);
      query.set('page', 1);
      message.success('実行完了');
    } catch (deleteError) {
      message.error('エラー');
    } finally {
      close();
    }
  };

  const onCancel = (close) => {
    close();
  };

  const modalDeleteConfig = (record) => {
    return {
      className: '',
      title: '確認',
      content: <h1>削除しますか？</h1>,
      onOk: (close) => onOk(close, record),
      onCancel,
      okButtonProps: {},
      cancelButtonProps: {},
      okText: '削除',
      okType: 'primary',
      cancelText: 'キャンセル',
      icon: null,
      mask: true,
      maskClosable: true,
      style: {},
      maskStyle: {},
      keyboard: true,
      autoFocusButton: 'ok',
    };
  };

  const _renderIsEnabled = (record, row) => {
    return (
      <Switch
        checked={record}
        onChange={(checked) => {
          const input = omit(row, ['id', '__typename']);
          input.isEnabled = checked;
          onChangeItem(input, row.id);
        }}
      />
    );
  };

  const _renderAction = (record) => (
    <Space size="middle">
      <Button
        onClick={() => {
          setVisibleEdit(true);
          setEditSelected(record);
        }}
        icon={<EditOutlined />}
      />
      <Button
        onClick={() => {
          modal.confirm(modalDeleteConfig(record));
        }}
        icon={<CloseOutlined />}
      />
    </Space>
  );

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
    },
    {
      title: '画像',
      dataIndex: 'image',
      key: 'image',
      width: '25%',
      render: (record) => {
        return (
          <Image
            width="6rem"
            src={record}
            placeholder={<PictureOutlined style={{ fontSize: '6rem' }} />}
            preview={false}
          />
        );
      },
    },
    {
      title: 'ギフト名',
      dataIndex: 'name',
      key: 'name',
      width: '25%',
      render: (value) => <div className="">{value || ''}</div>,
    },
    {
      title: 'ポイント',
      dataIndex: 'point',
      key: 'point',
      width: '25%',
      render: (value) => `${value}P`,
    },
    {
      title: '表示',
      dataIndex: 'isEnabled',
      key: 'isEnabled',
      width: '10%',
      render: _renderIsEnabled,
    },
    {
      title: 'ギフトサイズ',
      dataIndex: 'size',
      key: 'size',
      width: '10%',
      render: (size) => SIZE_GIFT[size],
    },
    {
      title: '',
      key: 'action',
      width: '10%',
      render: _renderAction,
    },
  ];

  const editInputs = [
    {
      title: 'ギフト名',
      key: 'name',
      InputComponent: Input,
      inputProps: {},
      formItemProps: {
        rules: [{ required: true }],
      },
    },
    {
      title: 'ポイント',
      key: 'point',
      InputComponent: InputNumber,
      inputProps: { min: 0, step: 1000 },
      formItemProps: {
        rules: [{ required: true }],
      },
    },
    {
      title: '画像',
      key: 'image',
      InputComponent: UploadImageInput,
      inputProps: { className: 'flex flex-grow items-center justify-center' },
      formItemProps: {
        rules: [{ required: true }],
      },
    },
    {
      title: '表示',
      key: 'isEnabled',
      InputComponent: Switch,
      inputProps: {},
      formItemProps: {
        valuePropName: 'checked',
      },
    },
    {
      title: 'ギフトサイズ',
      key: 'size',
      InputComponent: Select,
      inputProps: {
        options: Object.entries(SIZE_GIFT).map(([key, value]) => ({
          key,
          label: value,
          value: key,
        })),
      },
      formItemProps: {
        rules: [{ required: true }],
      },
    },
  ];

  const handleChangePage = (pageNumber) => {
    const query = new URLSearchParams(props.history.location.search);
    query.set('page', pageNumber);
    props.history.push({
      pathname: props.location.pathname,
      search: query.toString(),
    });
  };

  const handleChangePageSize = (current, size) => {
    const query = new URLSearchParams(props.history.location.search);
    query.set('page', 1);
    query.set('pageSize', size);
    props.history.push({
      pathname: props.location.pathname,
      search: query.toString(),
    });
  };

  useEffect(() => {
    setQuery((prevQuery) => ({ ...prevQuery, page: page - 1, pageSize }));
  }, [pageSize, page]);

  const pagination = {
    showSizeChanger: true,
    showQuickJumper: true,
    onChange: handleChangePage,
    onShowSizeChange: handleChangePageSize,
    current: currentPage + 1,
    pageSize,
    total: totalPages * pageSize,
  };

  return (
    <Layout.Content className="flex flex-col p-4">
      <div className="flex flex-row justify-end py-4">
        <Space>
          <Button
            onClick={() => {
              setVisibleEdit(true);
              setEditSelected(null);
            }}
            type="primary"
          >
            追加
          </Button>
        </Space>
      </div>

      <Table
        className="bg-white overflow-x-scroll"
        rowClassName="align-top whitespace-pre-wrap break-words fixBreakWordsJapan text-center"
        components={{
          header: {
            cell: ({ className, style, ...restProps }) => {
              return <th className={className} style={{ ...style, textAlign: 'center' }} {...restProps} />;
            },
          },
        }}
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={pagination}
        rowKey="id"
      />
      {contextHolder}
      {visibleEdit && (
        <EditModal
          visible={visibleEdit}
          onCreate={onChangeItem}
          onCancel={() => {
            setVisibleEdit(false);
            setEditSelected(null);
          }}
          record={editSelected}
          editInputs={editInputs}
          headerTitle="ギフト"
        />
      )}
    </Layout.Content>
  );
};
UploadImageInput.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
};
Gift.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
};

export default Gift;
