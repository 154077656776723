import UserLayout from 'layouts/UserLayout';
import BasicLayout from 'layouts/BasicLayout';
import SecurityLayout from 'layouts/SecurityLayout';
import NotFound from 'components/NotFound';
import Auth from 'containers/Auth';
import Forgot from 'containers/Auth/Forgot';
import Recover from 'containers/Auth/Recover';
import Training from 'containers/User/User';
import Trainer from 'containers/User/Trainer';
import ManageInvition from 'containers/User/ManageInvition';
import Company from 'containers/Company';

import MessageList from 'containers/MessageList';
import Dashboard from 'containers/Dashboard';
import Tweet from 'containers/Tweet';
import Payment from 'containers/Payment';
import ListDownload from 'containers/Payment/ListDownload';
import Point from 'containers/Point';
import Notification from 'containers/Notification';
import Admin from 'containers/Admin';
import Livestream from 'containers/Livestream';
import Concierge from 'containers/Concierge';
import MasterSetting from 'containers/MasterSetting';
import PointHistory from 'containers/Point/PointHistory';
import VerifyTrainer from 'containers/VerifyTrainer';
import Lesson from 'containers/Lesson';
import LessonRequest from 'containers/LessonRequest';
import Report from 'containers/Report';
import Help from 'containers/Help';

const routes = [
  {
    path: '/login',
    component: UserLayout,
    routes: [
      {
        name: 'Login',
        path: '/login',
        component: Auth,
      },
    ],
  },
  {
    path: '/password',
    component: UserLayout,
    routes: [
      {
        name: 'Forgot',
        path: '/password/forgot',
        component: Forgot,
      },
      {
        name: 'Recover',
        path: '/password/recover',
        component: Recover,
      },
    ],
  },
  {
    path: '/',
    component: SecurityLayout,
    routes: [
      {
        path: '/',
        component: BasicLayout,
        authority: ['admin'],
        routes: [
          {
            path: '/',
            exact: true,
            redirect: '/dashboard',
          },
          {
            path: '/dashboard',
            name: 'ダッシュボード',
            icon: 'bar-chart',
            component: Dashboard,
          },
          {
            path: '/user',
            name: 'ユーザー',
            icon: 'user',
            component: Training,
            authority: ['admin'],
          },
          {
            path: '/trainer',
            name: 'トレーナー',
            icon: 'user',
            component: Trainer,
            authority: ['admin'],
          },
          {
            path: '/company',
            name: '法人一覧',
            icon: 'user',
            component: Company,
            authority: ['admin'],
          },
          {
            path: '/manage_invition',
            name: '紹介管理',
            icon: 'user',
            component: ManageInvition,
            authority: ['admin'],
          },
          {
            path: '/verify_trainer',
            name: 'トレーナー認証',
            icon: 'user',
            component: VerifyTrainer,
            authority: ['admin'],
            unread: 'verifyAccounts',
          },
          {
            path: '/lesson',
            name: 'レッスン',
            icon: 'message',
            component: Lesson,
            authority: ['admin'],
          },
          {
            path: '/lesson_request',
            name: 'レッスンリクエスト',
            icon: 'message',
            component: LessonRequest,
            authority: ['admin'],
          },
          {
            path: '/livestream',
            name: 'ホストレライブ管理',
            icon: 'message',
            component: Livestream,
            authority: ['admin'],
          },
          {
            path: '/message',
            //Message management
            name: 'メッセージ管理',
            icon: 'message',
            component: MessageList,
            authority: ['admin'],
          },
          {
            path: '/tweet',
            //Tweet management
            name: 'つぶやき管理',
            icon: 'message',
            component: Tweet,
            authority: ['admin'],
          },
          {
            path: '/concierge',
            //Concierge management
            // コンシェルジュ管理
            name: 'コンシェルジュ/お問い合わせ管理',
            icon: 'message',
            component: Concierge,
            authority: ['admin'],
            permission: 'manage-concierge',
            unread: 'concierge',
          },
          {
            path: '/report',
            name: '通報',
            icon: 'warning',
            component: Report,
            authority: ['admin'],
            unread: 'reports',
          },
          // {
          //   path: '/matching',
          //   //Matching management
          //   name: 'マッチング管理',
          //   icon: 'calendar',
          //   component: Matching,
          //   authority: ['admin'],
          // },
          {
            path: '/payment',
            //Payment management
            name: '支払い管理',
            icon: 'pay',
            authority: ['admin'],
            permission: 'manage-payment-request',
            routes: [
              {
                path: '/payment/list',
                name: '支払い一覧',
                component: Payment,
              },
              {
                path: '/payment/download',
                name: 'CSVダウンロード',
                component: ListDownload,
              },
            ],
          },
          {
            path: '/point',
            //Point management
            name: 'ポイント管理',
            icon: 'aim',
            authority: ['admin'],
            routes: [
              {
                path: '/point/list',
                name: 'ポイント一覧',
                component: Point,
              },
              {
                path: '/point/history',
                name: 'ポイント履歴',
                component: PointHistory,
              },
            ],
          },
          {
            path: '/notification',
            //Notification management
            name: 'お知らせ管理',
            icon: 'notification',
            component: Notification,
            authority: ['admin'],
            permission: 'manage-notification',
          },
          // {
          //   path: '/help',
          //   //Notification management
          //   name: 'お問い合わせ',
          //   icon: 'help',
          //   component: Help,
          //   authority: ['admin'],
          //   permission: 'manage-admin',
          //   unread: 'helps',
          // },
          // {
          //   path: '/admin/notification',
          //   //Administrator notification management
          //   name: '管理者お知らせ管理',
          //   icon: 'notification',
          //   component: AdminNotification,
          //   authority: ['admin'],
          // },
          {
            path: '/admin',
            //Admin management
            name: '管理者管理',
            icon: 'user',
            component: Admin,
            authority: ['admin'],
            permission: 'manage-admin',
          },
          {
            path: '/master/admin',
            //Master Admin
            name: 'マスタ管理',
            icon: 'setting',
            component: MasterSetting,
            authority: ['admin', 'master'],
            permission: 'manage-admin',
          },
          {
            component: NotFound,
          },
        ],
      },
      {
        component: NotFound,
      },
    ],
  },
  {
    component: NotFound,
  },
];

export default routes;
