import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';

function render({ route, opts, props }) {
  // eslint-disable-next-line
  const routes = renderRoutes({
    ...opts,
    routes: route.routes || [],
    rootRoutes: opts.rootRoutes,
  });

  const { component: Component, wrappers } = route;
  if (Component) {
    const newProps = {
      ...props,
      ...opts.extraProps,
      route,
      routes: opts.rootRoutes,
    };
    let ret = <Component {...newProps}>{routes}</Component>;

    // route.wrappers
    if (wrappers) {
      let len = wrappers.length - 1;
      while (len >= 0) {
        ret = React.createElement(wrappers[len], newProps, ret);
        len -= 1;
      }
    }

    return ret;
  }
  return routes;
}

function getRouteElement({ route, index, opts }) {
  const routeProps = {
    key: route.key || index,
    exact: route.exact,
    strict: route.strict,
    sensitive: route.sensitive,
    path: route.path,
  };

  if (route.redirect) {
    return <Redirect {...routeProps} from={route.path} to={route.redirect} />;
  }
  // avoid mount and unmount with url hash change
  return (
    <Route
      {...routeProps}
      render={(props) => {
        return render({ route, opts, props });
      }}
    />
  );
}

function renderRoutes(opts) {
  return opts.routes ? (
    <Switch>
      {opts.routes.map((route, index) =>
        getRouteElement({
          route,
          index,
          opts: {
            ...opts,
            rootRoutes: opts.rootRoutes || opts.routes,
          },
        })
      )}
    </Switch>
  ) : null;
}

export default renderRoutes;
