import React, { useEffect } from 'react';
import { Card, Empty, Layout, Result, Typography } from 'antd';
import qs from 'qs';
import PropTypes from 'prop-types';
import TagManagement from 'containers/MasterSetting/components/TagManagement';
import TagCategory from 'containers/MasterSetting/components/TagCategory';
import NGWord from 'containers/MasterSetting/components/NGWord';
import Gift from 'containers/MasterSetting/components/Gift';
import Role from 'containers/MasterSetting/components/Role';
import TrainingType from 'containers/MasterSetting/components/TrainingType';
import Tutorial from 'containers/MasterSetting/components/Tutorial';
import MyBank from 'containers/MasterSetting/components/MyBank';
import { useCheckPermission } from 'utils/hooks/usePermission';
import Faq from '../Faq';
import Concierge from '../Concierge';
import Banner from '../Banner';
import StaticContent from '../StaticContent';

export const TABS = {
  area: 'area',
  listProfile: 'listProfile',
  NGWord: 'NGWord',
  rank: 'rank',
  faq: 'faq',
  rating: 'rating',
  tagCategory: 'tagCategory',
  tag: 'tag',
  gift: 'gift',
  role: 'role',
  staticContent: 'staticContent',
  hostType: 'hostType',
  concierge: 'concierge',
  banner: 'banner',
  trainingType: 'trainingType',
  tutorial: 'tutorial',
  myBank: 'my-bank',
};

const Tabs = (props) => {
  const { location, history } = props;
  const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
  const tab = parsedQuery?.tab || 'faq';

  const hasManageAdmin = useCheckPermission('manage-admin');

  const _renderTab = (tabKey) => {
    switch (tabKey) {
      case TABS.NGWord:
        return <NGWord />;
      case TABS.tagCategory:
        return <TagCategory {...props} />;
      case TABS.tag:
        return <TagManagement {...props} />;
      case TABS.gift:
        return <Gift {...props} />;
      case TABS.role:
        return hasManageAdmin ? (
          <Role {...props} />
        ) : (
          <Result status="403" title="403" subTitle="このウェブページにアクセスできません" />
        );
      case TABS.faq:
        return <Faq {...props} />;
      case TABS.concierge:
        return <Concierge />;
      case TABS.banner:
        return <Banner />;
      case TABS.staticContent:
        return <StaticContent />;
      case TABS.trainingType:
        return <TrainingType {...props} />;
      case TABS.tutorial:
        return <Tutorial {...props} />;
      case TABS.myBank:
        return hasManageAdmin ? (
          <MyBank {...props} />
        ) : (
          <Result status="403" title="403" subTitle="このウェブページにアクセスできません" />
        );
      default:
        return tabKey;
    }
  };

  const setTab = (currentTab) => {
    const query = new URLSearchParams(history.location.search);
    query.set('tab', currentTab);
    history.push({
      pathname: props.location.pathname,
      search: query.toString(),
    });
  };

  return (
    <Layout.Content className="flex flex-col p-4">
      <Typography.Title level={3}>マスタ管理</Typography.Title>
      <Card
        key="master"
        style={{ width: '100%' }}
        tabList={[
          { key: TABS.faq, tab: 'ヘルプ' },
          { key: TABS.tagCategory, tab: 'マッチングカテゴリー' },
          { key: TABS.tag, tab: 'マッチング項目' },
          { key: TABS.gift, tab: 'ギフト' },
          { key: TABS.role, tab: '役割設定', disabled: !hasManageAdmin },
          { key: TABS.NGWord, tab: 'NGワード' },
          { key: TABS.concierge, tab: 'コンシェルジュ' },
          { key: TABS.banner, tab: 'バナー管理' },
          { key: TABS.staticContent, tab: '利用規約' },
          { key: TABS.trainingType, tab: '実践できるトレーニング' },
          { key: TABS.tutorial, tab: 'チュートリアル' },
          { key: TABS.myBank, tab: '運営銀行情報', disabled: !hasManageAdmin },
        ]}
        activeTabKey={tab}
        onTabChange={(activeKey) => {
          setTab(activeKey);
        }}
      >
        {_renderTab(tab)}
      </Card>
    </Layout.Content>
  );
};

Tabs.propTypes = {
  location: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
};

export default Tabs;
