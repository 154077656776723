import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import UserPointHistory from './UserPointHistory';
import UserRating from './UserRating';
import UserLogHistory from './UserLogHistory';
import UserTrainingHistory from './UserTrainingHistory';
import { detail } from './styles.module.css';

const UserDetail = ({ userType, item, ...props }) => {
  return (
    <div className={cn(detail, 'flex flex-row')}>
      <div className="flex-grow">
        <UserLogHistory userId={item?.id} />
        <UserPointHistory userId={item?.id} />
        {userType === 'trainer' && <UserRating userId={item?.id} />}
        <UserTrainingHistory userId={item?.id} {...props} />
      </div>
    </div>
  );
};

UserDetail.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  userType: PropTypes.string,
};
export default UserDetail;
