import React from 'react';
import { Tag, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useUpdateNGWord, useGetNGWord } from 'redux/Hooks/NGWord';

const NGWord = () => {
  const [inputVisible, setInputVisible] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');

  const { data: ngWord, fetch } = useGetNGWord();
  const { prevFetching, isFetching, onSave } = useUpdateNGWord();

  React.useEffect(() => {
    fetch();
  }, []);

  React.useEffect(() => {
    if (!isFetching && prevFetching) {
      fetch();
    }
  }, [isFetching]);

  let blackList = [];
  try {
    blackList = ngWord?.data ? JSON.parse(ngWord?.data?.value) : [];
  } catch (e) {
    // empty json server
  }

  const handleInputConfirm = async () => {
    if (inputValue && blackList.indexOf(inputValue) === -1) {
      await onSave({
        badwords: JSON.stringify([...blackList, inputValue]),
      });
    }
    setInputValue('');
    setInputVisible(false);
  };

  const removeWord = async (word) => {
    const newBlacklist = blackList.filter((w) => w !== word);
    await onSave({
      badwords: JSON.stringify(newBlacklist),
    });
  };

  const forMap = (tag) => {
    const tagElem = (
      <Tag
        className="flex justify-center items-center align-top whitespace-pre-wrap break-words fixBreakWords"
        closable
        onClose={(e) => {
          e.preventDefault();
          removeWord(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span key={tag} className="inline-block">
        {tagElem}
      </span>
    );
  };

  const tagChild = blackList.map(forMap);

  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        <div className="font-bold">NGワード</div>
        <div className="mt-4">{tagChild}</div>
      </div>
      {!inputVisible ? (
        <Tag
          onClick={() => setInputVisible(true)}
          className="inline-block border-dotted border border-blue-primary bg-white my-2 cursor-pointer text-blue-primary"
        >
          <div className="flex justify-center items-center">
            <PlusOutlined className="mr-2" /> 新しいNGワードを入力してください
          </div>
        </Tag>
      ) : (
        <Input
          type="text"
          size="small"
          className="max-w-xs my-2"
          autoFocus
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      )}
    </div>
  );
};

export default NGWord;
