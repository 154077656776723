import React from 'react';
import { Form, Input, Button, Select, AutoComplete } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { REASON_IN_JP } from 'utils/constant';

const FormItem = Form.Item;

const ReportSearchForm = ({ onSubmit }) => {
  const [form] = Form.useForm();

  const typeOptions = [
    { label: 'メッセージ', value: 'conversation' },
    { label: 'ユーザー', value: 'user' },
  ];

  const targetOptions = [
    { label: '未処理', value: 'pending' },
    { label: '完了', value: 'done' },
  ];

  const reasonTargetOptions = Object.entries(REASON_IN_JP).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  const onFinish = (values) => {
    const filter = {};
    if (values.fullName) {
      filter.fullName = values.fullName;
    }
    if (values.reason) {
      filter.reason = values.reason;
    }
    if (values.status) {
      filter.status = values.status;
    }
    // filter.status = Array.isArray(values.status) && values?.status?.length ? values.status?.join(',') : '';
    if (values.type) {
      filter.type = values.type;
    }
    onSubmit(filter);
  };

  return (
    <Form form={form} onFinish={onFinish} className="flex flex-row">
      <div className="flex flex-row flex-grow flex-no-wrap space-x-4">
        <FormItem name="fullName" className="flex flex-30-percent">
          <Input placeholder="フルネーム" allowClear />
        </FormItem>
        <FormItem name="type" className="flex flex-30-percent">
          <Select options={typeOptions} placeholder="タイプ" allowClear />
        </FormItem>
        <FormItem name="reason" className="flex flex-30-percent">
          <Select options={reasonTargetOptions} placeholder="理由" allowClear />
        </FormItem>
        <FormItem name="status" className="flex flex-30-percent">
          <Select options={targetOptions} placeholder="ステータス" allowClear />
        </FormItem>
        <Button icon={<SearchOutlined />} htmlType="submit" type="ghost">
          検索
        </Button>
      </div>
    </Form>
  );
};

export default ReportSearchForm;
