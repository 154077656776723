import React from 'react';
import { Layout, Typography, Button } from 'antd';
import PropTypes from 'prop-types';
import Help from 'containers/MasterSetting/components/Help';
import { CONCIERGE_VIEW } from 'configs/Constants';

const HelpPage = ({ setView }) => {
  return (
    <Layout.Content className="flex flex-col">
      <Typography.Title level={3}>お問い合わせ</Typography.Title>
      <div className="flex flex justify-end pr-4">
        <Button
          className="px-12 text-white hover:text-white rounded"
          style={{ background: '#3B86FF' }}
          onClick={() => setView(CONCIERGE_VIEW.CONCIERGE)}
        >
          ユーザー一覧
        </Button>
      </div>
      <Help />
    </Layout.Content>
  );
};

HelpPage.propTypes = {
  setView: PropTypes.func,
};

export default HelpPage;
