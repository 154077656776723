const { REACT_APP_API_URI, REACT_APP_WS_URI } = process.env;

export const API_ROOT = `${REACT_APP_API_URI}/api/v1/`;
export const SOCKET_IO = `${REACT_APP_WS_URI}/`;

// export const API_ROOT = 'http://localhost:4000/api/v1/';
export const API_ADMIN_PREFIX = 'admin';
export const TIMEOUT = 10000;
export const AGORA_ID = '2eee151ddb794964aaf10096991ab7bd';

export default {
  AUTH: {
    LOGIN: '/auth/login',
    REGISTER: '/auth/register',
  },
  ADMIN: {
    ME: {
      DETAIL: API_ADMIN_PREFIX + '/me',
    },
    USER: {
      LIST: API_ADMIN_PREFIX + '/user',
      ADD: API_ADMIN_PREFIX + '/user',
      EDIT: API_ADMIN_PREFIX + '/user/:id',
      DELETE: API_ADMIN_PREFIX + '/user/:id',
      TRAINING_HISTORY: API_ADMIN_PREFIX + '/lesson/me?userId=:id',

      RATING: API_ADMIN_PREFIX + '/rating?toId=:id',
      LOG_HISTORY: API_ADMIN_PREFIX + '/user/:id/log',
      TAGS: '/tag/groups?category=profile,body_type,appear_point,height,blood_type,sign,horoscope,tattoo',
      GROUP_TAGS_V2: '/tag/groups_v2',
    },
    NOTIFICATIONS: {
      LIST: API_ADMIN_PREFIX + '/announcement',
      DELETE: API_ADMIN_PREFIX + '/announcement/:id',
      SEND: API_ADMIN_PREFIX + '/announcement',
    },
    ADMIN: {
      LIST: API_ADMIN_PREFIX + '/admin',
      ADD: API_ADMIN_PREFIX + '/admin',
      EDIT: API_ADMIN_PREFIX + '/admin/:id',
    },
    STATIC_CONTENT: {
      LIST: API_ADMIN_PREFIX + '/static_content',
      ADD: API_ADMIN_PREFIX + '/static_content',
      EDIT: API_ADMIN_PREFIX + '/static_content/:id',
    },
    TRAINING_TYPE: {
      LIST: API_ADMIN_PREFIX + '/training_type',
      ADD: API_ADMIN_PREFIX + '/training_type',
      EDIT: API_ADMIN_PREFIX + '/training_type/:id',
    },
    ROLE: {
      LIST: API_ADMIN_PREFIX + '/role',
      ADD: API_ADMIN_PREFIX + '/role',
      EDIT: API_ADMIN_PREFIX + '/role/:id',
    },
    STATISTICS: API_ADMIN_PREFIX + '/statistic',
    POINT_HISTORY: {
      LIST: API_ADMIN_PREFIX + '/point_history',
      ADD: API_ADMIN_PREFIX + '/point_history',
      USER: API_ADMIN_PREFIX + '/point_history?userId=:id',
    },
    LIVESTREAM: {
      LIST: API_ADMIN_PREFIX + '/livestream',
      CREATE: API_ADMIN_PREFIX + '/livestream',
      EDIT: API_ADMIN_PREFIX + '/livestream/:id',
      OFFSTREAM: API_ADMIN_PREFIX + '/livestream/:id/offstream',
    },
    LESSON: {
      LIST: API_ADMIN_PREFIX + '/lesson',
      CREATE: API_ADMIN_PREFIX + '/lesson',
      EDIT: API_ADMIN_PREFIX + '/lesson/:id',
    },
    LESSON_REQUEST: {
      LIST: API_ADMIN_PREFIX + '/lesson_request',
      CREATE: API_ADMIN_PREFIX + '/lesson_request',
      EDIT: API_ADMIN_PREFIX + '/lesson_request/:id',
      JOIN: API_ADMIN_PREFIX + '/lesson_request/:id/join',
    },
    TRAINER_VERIFY: {
      LIST: API_ADMIN_PREFIX + '/trainer_verification',
      UPDATE: API_ADMIN_PREFIX + '/trainer_verification/:id',
    },
    PAYMENT_REQUEST: {
      LIST: API_ADMIN_PREFIX + '/payment_request',
      CSV: API_ADMIN_PREFIX + '/payment_request/csv',
      EXPORT_CSV: API_ADMIN_PREFIX + '/payment_request/export_csv',
      USER: API_ADMIN_PREFIX + '/payment_request?userId=:id',
      UPDATE: API_ADMIN_PREFIX + '/payment_request/:id',
      UPDATE_BULK: API_ADMIN_PREFIX + '/payment_request/bulk',
    },
    GIFT: {
      LIST: API_ADMIN_PREFIX + '/gift',
      ADD: API_ADMIN_PREFIX + '/gift',
      EDIT: API_ADMIN_PREFIX + '/gift/:id',
    },
    FAQ: {
      LIST: API_ADMIN_PREFIX + '/faq',
      ADD: API_ADMIN_PREFIX + '/faq',
      EDIT: API_ADMIN_PREFIX + '/faq/:id',
    },
    HELP: {
      LIST: API_ADMIN_PREFIX + '/help',
      ADD: API_ADMIN_PREFIX + '/help',
      EDIT: API_ADMIN_PREFIX + '/help/:id',
      RESOLVE: API_ADMIN_PREFIX + '/help/:id/status',
      DETAIL_BY_USER_ID: API_ADMIN_PREFIX + '/help?userId=:id',
    },
    NGWORD: API_ADMIN_PREFIX + '/badwords',
    TWEET: {
      LIST: API_ADMIN_PREFIX + '/tweet',
      ADD: API_ADMIN_PREFIX + '/tweet',
      EDIT: API_ADMIN_PREFIX + '/tweet/:id',
    },
    TUTORIAL: {
      LIST: API_ADMIN_PREFIX + '/tutorial',
      ADD: API_ADMIN_PREFIX + '/tutorial',
      EDIT: API_ADMIN_PREFIX + '/tutorial/:id',
    },
    CONVERSATION: {
      LIST: API_ADMIN_PREFIX + '/conversation',
      DETAIL: API_ADMIN_PREFIX + '/conversation/:id',
      DETAIL_BY_USER_ID: API_ADMIN_PREFIX + '/conversation/admin?userId=:id',
      LIST_MESSAGE: API_ADMIN_PREFIX + '/conversation/:id/messages',
      DELETE: API_ADMIN_PREFIX + '/message/:id',
      SEND_MESSAGE: API_ADMIN_PREFIX + '/conversation/:id',
      SEND_BULK: API_ADMIN_PREFIX + '/conversation/bulk',
    },
    TAG: {
      LIST: API_ADMIN_PREFIX + '/tag',
      ADD: API_ADMIN_PREFIX + '/tag',
      DETAIL: API_ADMIN_PREFIX + '/tag/:id',
      EDIT: API_ADMIN_PREFIX + '/tag/:id',
    },
    TAG_CATEGORY: {
      LIST: API_ADMIN_PREFIX + '/tag_category',
      ADD: API_ADMIN_PREFIX + '/tag_category',
      DETAIL: API_ADMIN_PREFIX + '/tag_category/:id',
      EDIT: API_ADMIN_PREFIX + '/tag_category/:id',
    },
    TRANSACTION: {
      LIST: API_ADMIN_PREFIX + '/transaction',
    },
    CONCIERGE: {
      UPDATE: API_ADMIN_PREFIX + '/core_setting',
      INTRO: API_ADMIN_PREFIX + '/core_setting?key=concierge_intro',
    },
    BANNER: {
      UPDATE: API_ADMIN_PREFIX + '/core_setting',
      INFO: API_ADMIN_PREFIX + '/core_setting?key=banner',
    },
    MY_BANK: {
      INFO: API_ADMIN_PREFIX + '/core_setting/bank',
      UPDATE: API_ADMIN_PREFIX + '/core_setting/bank',
    },
    REPORT: {
      LIST: API_ADMIN_PREFIX + '/report',
      RESOLVE: API_ADMIN_PREFIX + '/report/:id/done',
      DELETE: API_ADMIN_PREFIX + '/report/:id',
    },
    BANK: {
      LIST: API_ADMIN_PREFIX + '/banks',
      BRANCH: API_ADMIN_PREFIX + '/branch',
      UPDATE: API_ADMIN_PREFIX + '/user/:id/bank',
    },
    COMPANY: {
      LIST: API_ADMIN_PREFIX + '/business',
      DETAIL: API_ADMIN_PREFIX + '/business/:id',
      CREATE: API_ADMIN_PREFIX + '/business',
      DELETE: API_ADMIN_PREFIX + '/business/:id',
      IMPORT: API_ADMIN_PREFIX + '/business/:id/import',
    },
  },

  ME: {
    DETAIL: '/me',
    UPDATE: '/me',
    POINT_HISTORY: '/me/point_history',
    CHANGE_PASSWORD: '/me/change_password',
  },
  USER_LESSON: {
    LIST: '/user_lesson',
    CANCEL: '/user_lesson/:id/join',
  },
  USERS: {
    SEARCH: '/user',
  },
  TWEET: {
    LIST: '/tweet',
    CREATE: '/tweet',
  },
  RANK: {
    LIST: '/rank',
  },
};
