import React, { useEffect, useState } from 'react';
import { Input, Modal, Form } from 'antd';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import { useEditStaticContent } from 'redux/Hooks/staticContent';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const EditModal = ({
  visible,
  record,
  headerTitle,
  editInputs = [],
  hiddenVariables = {},
  onCreate = () => {},
  onCancel = () => {},
  modalProps = {},
  formProps = {},
  loading,
}) => {
  const [formEdit] = Form.useForm();
  const isEditing = !!record;
  const [content, setContent] = useState('');

  const { data } = useEditStaticContent(record?.id);

  const initialValues = omit(
    {
      ...hiddenVariables,
      ...(record || {}),
    },
    '__typename'
  );

  const editInputsValidated = editInputs?.filter((item) => item?.InputComponent) || [];

  const _renderEditInputs = (inputs) => {
    return inputs?.map(
      ({ title, key, name, InputComponent = Input, inputProps = {}, formItemProps = {}, inputChildren }) => (
        <Form.Item key={key} name={name || key} label={title} {...formItemProps}>
          <InputComponent name={name || key} {...inputProps}>
            {inputChildren}
          </InputComponent>
        </Form.Item>
      )
    );
  };

  const handleFinish = async (values) => {
    onCreate({ ...data, ...values, content }, record?.id);
    formEdit.resetFields();
  };

  useEffect(() => {
    if (visible && record?.id && formEdit) {
      formEdit.resetFields();
    }
  }, [visible, record?.id]);

  useEffect(() => {
    setContent(data?.content);
  }, [data?.id]);

  return (
    <Modal
      visible={visible}
      title={headerTitle}
      okText="変更"
      cancelText="キャンセル"
      onCancel={onCancel}
      onOk={() => {
        formEdit.submit();
      }}
      okButtonProps={{ form: 'edit', key: 'submit', htmlType: 'submit' }}
      {...modalProps}
    >
      {loading ? (
        <div className="flex items-center justify-center w-full">
          <LoadingOutlined />
        </div>
      ) : (
        <Form
          form={formEdit}
          layout="vertical"
          name={isEditing ? 'edit' : 'add'}
          initialValues={initialValues}
          onFinish={handleFinish}
          {...formProps}
        >
          <div className="flex flex-col">
            {Object.keys(hiddenVariables).map((key) => (
              <Form.Item key={key} name={key} className="hidden">
                <Input type="hidden" />
              </Form.Item>
            ))}
            {_renderEditInputs(editInputsValidated)}
            <CKEditor
              editor={ClassicEditor}
              data={content}
              config={{
                height: 200,
              }}
              onReady={(editor) => {
                // You can store the "editor" and use when it is needed.
                // eslint-disable-next-line no-console
                console.log('Editor is ready to use!', editor);
              }}
              onChange={(event, editor) => {
                const res = editor.getData();
                setContent(res);
                // console.log({ event, editor, res });
              }}
            />
          </div>
        </Form>
      )}
    </Modal>
  );
};

EditModal.propTypes = {
  loading: PropTypes.bool,
  visible: PropTypes.bool,
  record: PropTypes.object,
  headerTitle: PropTypes.string,
  editInputs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
      InputComponent: PropTypes.elementType,
      inputProps: PropTypes.object,
      formItemProps: PropTypes.object,
      inputChildren: PropTypes.element,
    })
  ),
  hiddenVariables: PropTypes.object,
  onCreate: PropTypes.func,
  onCancel: PropTypes.func,
  modalProps: PropTypes.object,
  formProps: PropTypes.object,
};

export default EditModal;
