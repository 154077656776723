import React, { useState, useEffect } from 'react';
import { Layout, Typography, Space, Button, Table, Avatar, Modal, Tag, Popconfirm, message } from 'antd';
import { UserOutlined, CheckOutlined, CloseOutlined, MessageOutlined } from '@ant-design/icons';
import qs, { stringify } from 'qs';
import { useGetReports, useResolveReport, useDeleteReport } from 'redux/Hooks/report';
import { useEditUser } from 'redux/Hooks/user';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { API } from 'configs';
import { REASON_IN_JP, REPORT } from 'utils/constant';
import { REPORT_TYPE_JP, USER_STATUS } from 'configs/Constants';

import ReportSearchForm from './components/ReportSearchForm';

const DEFAULT_PAGE_SIZE = 30;

const STATUS = {
  done: '完了',
  pending: '未処理',
};

const Report = ({ history, location }) => {
  const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [searchForm, setSearchForm] = useState({});
  const [page, setPage] = useState(parsedQuery?.page && Number(parsedQuery?.page) ? Number(parsedQuery?.page) - 1 : 0);
  const [modal, contextHolder] = Modal.useModal({});

  const { results, isFetching, currentPage, totalPages, refresh, setQuery } = useGetReports();
  const [{ data: responseResolve }, doResolve] = useResolveReport();
  const [{ data: responseDelete }, doDelete] = useDeleteReport();

  const { onSave } = useEditUser(
    ':id',
    (res) => {
      if (res?.success) {
        message.success('禁止されました');
        refresh();
      } else {
        message.error('ユーザーの削除が完了しました');
      }
    },
    (err) => {
      message.error('ユーザーの削除が完了しました');
    }
  );

  const onOk = (close, record) => {
    doDelete({
      url: API.ADMIN.REPORT.DELETE.replace(':id', record?.id),
    });
    close();
  };

  const onBanUser = (close, id) => {
    onSave({ status: 'banned' }, id);
    close();
  };

  const onCancel = (close) => {
    close();
  };

  const modalDeleteConfig = (record) => ({
    className: '',
    title: '確認',
    content: <h1>削除しますか？</h1>,
    onOk: (close) => onOk(close, record),
    onCancel,
    okButtonProps: {},
    cancelButtonProps: {},
    okText: '削除',
    okType: 'primary',
    cancelText: 'キャンセル',
    icon: null,
    mask: true,
    maskClosable: true,
    style: {},
    maskStyle: {},
    keyboard: true,
    autoFocusButton: 'ok',
  });

  const modalBan = (id) => {
    return {
      className: '',
      title: '確認',
      content: <h1>停止にするしますか？</h1>,
      onOk: (close) => onBanUser(close, id),

      onCancel,
      okButtonProps: {},
      cancelButtonProps: {},
      okText: '停止にする',
      okType: 'primary',
      cancelText: 'キャンセル',
      icon: null,
      mask: true,
      maskClosable: true,
      style: {},
      maskStyle: {},
      keyboard: true,
      autoFocusButton: 'ok',
    };
  };

  useEffect(() => {
    setQuery({
      ...searchForm,
      pageSize: DEFAULT_PAGE_SIZE,
      page,
    });
  }, [page]);

  useEffect(() => {
    setQuery({
      ...searchForm,
      pageSize: DEFAULT_PAGE_SIZE,
      page: 0,
    });
  }, [searchForm]);

  useEffect(() => {
    if (Number(parsedQuery?.todayReport) === 1)
      setQuery({
        status: 'pending',
      });
  }, [parsedQuery?.todayReport]);

  useEffect(() => {
    if (responseResolve) {
      refresh();
    }
  }, [responseResolve]);

  useEffect(() => {
    if (responseDelete) {
      refresh();
    }
  }, [responseDelete]);

  const handleSearch = (filter) => {
    setSearchForm(filter);
  };

  const handleChangePage = (pageNumber) => {
    const query = new URLSearchParams(history.location.search);
    const queryString = stringify({
      ...query,
      page: pageNumber === 1 ? undefined : pageNumber,
      id: undefined,
    });
    setPage(pageNumber - 1);
    history.push({
      pathname: location.pathname,
      search: queryString,
    });
  };

  const pagination = {
    onChange: handleChangePage,
    current: currentPage + 1 || 1,
    pageSize: DEFAULT_PAGE_SIZE,
    total: totalPages * DEFAULT_PAGE_SIZE || 0,
    showSizeChanger: false,
    hideOnSinglePage: true,
  };
  /* eslint-disable no-unused-vars */
  /* eslint-disable react/prop-types */

  const columns = [
    {
      title: '#',
      dataIndex: ['id'],
      key: 'id',
      width: '5%',
      render: (value, item, index) => <span>{value}</span>,
    },
    {
      title: <p className="text-center whitespace-no-wrap">クリエーター</p>,
      dataIndex: ['creator'],
      key: 'creator',
      width: '10%',
      render: (value, item, index) => (
        <Link
          to={`${history.location.search}/${value?.userType === 'training' ? 'user' : 'trainer'}?action=edit&id=${
            value?.id
          }`}
          className="flex flex-col items-center"
        >
          <Avatar className="flex justify-center items-center" src={value?.avatar} icon={<UserOutlined />} />
          <span className="bg-transparent">{value?.fullName}</span>
        </Link>
      ),
    },
    {
      title: '',
      render: (value) => (
        <Button
          icon={<MessageOutlined className="text-center" />}
          className="flex items-center justify-center text-center"
          onClick={() => {
            const query = new URLSearchParams(history.location.search);
            query.set('id', value?.conversationCreator?.id);
            query.set('userType', value?.user?.userType);
            history.push({
              pathname: '/concierge',
              search: query.toString(),
            });
          }}
        />
      ),
    },
    {
      title: <p className="text-center whitespace-no-wrap">タイプ</p>,
      dataIndex: ['type'],
      key: 'type',
      width: '10%',
      render: (value, item, index) => (
        <Button
          type="link"
          onClick={() => {
            if (value === REPORT.TYPE.CONVERSATION) {
              history.push({
                pathname: `/message`,
                search: `id=${item?.conversationId}`,
              });
            }
          }}
        >
          <p className="text-center">{REPORT_TYPE_JP?.[value] || value}</p>
        </Button>
      ),
    },
    {
      title: <p className="text-center whitespace-no-wrap">違反者</p>,
      dataIndex: ['user'],
      key: 'user',
      width: '10%',
      render: (value, item, index) => (
        <Link
          to={`${history.location.search}/${value?.userType === 'training' ? 'user' : 'trainer'}?action=edit&id=${
            value?.id
          }`}
          className="flex flex-col items-center"
        >
          <Avatar className="flex justify-center items-center" src={value?.avatar} icon={<UserOutlined />} />
          <span className="bg-transparent">{value?.fullName}</span>
        </Link>
      ),
    },
    {
      title: '停止',
      render: (value) => (
        <Button
          danger
          className="flex justify-center items-center"
          disabled={value?.user?.status !== USER_STATUS.ACTIVE}
          onClick={() => {
            modal.confirm(modalBan(value.userId));
          }}
        >
          {value?.user?.status !== USER_STATUS.ACTIVE ? '停止済' : '停止にする'}
        </Button>
      ),
    },
    {
      title: <p className="text-center whitespace-no-wrap">理由</p>,
      dataIndex: ['reason'],
      key: 'reason',
      width: '30%',
      render: (value) => <p className="break-words whitespace-no-wrap">{REASON_IN_JP?.[value] || value}</p>,
    },
    {
      title: <p className="text-center whitespace-no-wrap">ステータス</p>,
      dataIndex: ['status'],
      key: 'status',
      width: '10%',
      render: (value) => <Tag color={value === 'done' ? 'green' : 'orange'}>{STATUS?.[value] || value}</Tag>,
    },
    {
      title: <p className="text-center whitespace-no-wrap">作成日</p>,
      dataIndex: 'created',
      key: 'created',
      width: '25%',
      render: (value) => <p>{moment.utc(value).local().format('llll')}</p>,
    },
    {
      title: <p className="text-center whitespace-no-wrap">アクション</p>,
      key: 'action',
      width: '10%',
      render: (_, dataRow) => (
        <Space size="middle">
          <Popconfirm
            title="このレポートを解決してもよろしいですか？"
            onConfirm={() => {
              doResolve({
                url: API.ADMIN.REPORT.RESOLVE.replace(':id', dataRow?.id),
              });
            }}
            onCancel={() => {}}
            okText="はい"
            cancelText="キャンセル"
            placement="left"
          >
            <Button icon={<CheckOutlined />} />
          </Popconfirm>
          <Button
            onClick={() => {
              modal.confirm(modalDeleteConfig(dataRow));
            }}
            icon={<CloseOutlined />}
          />
        </Space>
      ),
    },
  ];

  return (
    <Layout.Content className="flex flex-col">
      <Typography.Title level={3}>通報</Typography.Title>

      <ReportSearchForm onSubmit={handleSearch} />

      <div className="formControl py-4"></div>
      <Table
        className="bg-white overflow-x-scroll"
        rowClassName="align-top whitespace-pre-wrap break-words fixBreakWordsJapan text-center"
        components={{
          header: {
            cell: ({ className, style, ...restProps }) => {
              return <th className={className} style={{ ...style, textAlign: 'center' }} {...restProps} />;
            },
          },
        }}
        bordered
        loading={isFetching}
        dataSource={results}
        columns={columns}
        rowKey="id"
        pagination={pagination}
      />
      {contextHolder}
    </Layout.Content>
  );
};

Report.propTypes = {
  location: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
};

export default Report;
