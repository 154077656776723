import React from 'react';
import { Upload, message } from 'antd';
import { useUpload } from 'utils/hooks/useUpload';
import { getToken } from 'utils/Function';
import PropTypes from 'prop-types';

const { REACT_APP_API_URI } = process.env;

const UPLOAD_PATH = `${REACT_APP_API_URI}/api/v1/upload`;

export const UploadWrapper = ({ className = '', children, loading, loadingComponent }) => {
  return <div className={className}>{loading ? loadingComponent : children}</div>;
};

UploadWrapper.propTypes = {
  children: PropTypes.any,
  loading: PropTypes.bool,
};

const UploadImage = (props) => {
  const {
    onFinish = () => {},
    loadingComponent,
    className,
    uploadPath,
    name = 'upload',
    customUploadProps = {},
    children,
  } = props;
  const { state, uploadProps } = useUpload();

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    return isJpgOrPng;
  };

  const handleAdd = async (info) => {
    uploadProps.onChange(info);

    if (info.file.status === 'uploading') {
      return;
    }
    if (info?.file?.status === 'done' && info?.file?.response?.uploaded) {
      onFinish(info?.file?.response?.url, info);
    }
  };

  const authorization = getToken() ? `Bearer ${getToken()}` : null;

  return (
    <Upload
      disabled={state?.loading}
      name={name}
      multiple={false}
      showUploadList={false}
      beforeUpload={beforeUpload}
      action={uploadPath || UPLOAD_PATH}
      headers={{ authorization }}
      accept="image/png, image/gif, image/jpeg"
      {...uploadProps}
      {...customUploadProps}
      onChange={handleAdd}
    >
      <UploadWrapper className={className} loading={state?.loading} loadingComponent={loadingComponent}>
        {children}
      </UploadWrapper>
    </Upload>
  );
};

UploadImage.propTypes = {
  onFinish: PropTypes.func,
  loadingComponent: PropTypes.element,
  children: PropTypes.element,
};

export default UploadImage;
