import React, { useRef, useEffect, useState } from 'react';
import { parse, stringify } from 'qs';
import PropTypes from 'prop-types';
import { Layout, Card, Divider, Empty, Button, Typography, Row, Col } from 'antd';
import dayjs from 'dayjs';
import { useSyncingMe } from 'redux/Hooks/me';

import { useConversations } from 'redux/Hooks/conversation';
import { SearchMessage } from 'components/Message';
import { USER_TYPES, CONCIERGE_VIEW } from 'configs/Constants';
import ConciergeList from './components/ConciergeList';
import ConciergeBox from './components/ConciergeBox';
import FormSendBulk from './components/FormSendBulk';
import InquiryBox from './components/InquiryBox';

const DEFAULT_CONVERSATION_SIZE = 9;
const DATE_FORMAT = 'YYYY/MM/DD HH:mm';

const FIELD_SEARCH = {
  SEARCH: 'search',
  START: 'start',
  END: 'end',
};

const Concierge = ({ location, history, setView }) => {
  const boxRef = useRef();
  const [inquiriUserId, setInquiriUserId] = useState();
  const [, , onSyncAuth] = useSyncingMe();
  const [isVisibleSendMessBulk, setVisibleSendMessBulk] = useState(false);
  const parsedQuery = parse(location.search, { ignoreQueryPrefix: true });
  const {
    results,
    isFetching,
    isRefreshing,
    currentPage,
    totalPages,
    setQuery,
    refresh,
    updateRead,
  } = useConversations(parsedQuery);

  const [searchForm, setSearchForm] = React.useState({});
  const [page, setPage] = React.useState(
    parsedQuery?.page && Number(parsedQuery?.page) ? Number(parsedQuery?.page) - 1 : 0
  );

  const parseQuery = parse(location.search, { ignoreQueryPrefix: true }) || {};
  const convId = Number(parseQuery?.id);

  const userType = parsedQuery?.userType || USER_TYPES.USER;

  const filterMappingConv = () => {
    const filters = {};
    Object.keys(searchForm).forEach((field) => {
      const value = searchForm?.[field];
      if (value !== (undefined || null)) {
        switch (field) {
          case FIELD_SEARCH.SEARCH:
            filters.search = value;
            break;
          case FIELD_SEARCH.START:
            filters.start = dayjs(value).format(DATE_FORMAT);
            break;
          case FIELD_SEARCH.END:
            filters.end = dayjs(value).format(DATE_FORMAT);
            break;
          default:
          //
        }
      }
      return null;
    });
    return filters;
  };

  const onUpdateRead = (id) => {
    updateRead(id);
    onSyncAuth();
  };

  useEffect(() => {
    setQuery({
      ...filterMappingConv(),
      pageSize: DEFAULT_CONVERSATION_SIZE,
      page,
      userType,
    });
  }, [page]);

  useEffect(() => {
    setPage(0);
    setQuery({
      ...filterMappingConv(),
      pageSize: DEFAULT_CONVERSATION_SIZE,
      page: 0,
      userType,
    });
  }, [searchForm, userType]);

  const setTab = (currentTab) => {
    const query = new URLSearchParams(history.location.search);
    const queryString = stringify({
      ...query,
      userType: currentTab,
      id: undefined,
    });
    setPage(0);
    history.push({
      pathname: location.pathname,
      search: queryString,
    });
  };

  const changeView = () => {
    history.push({
      pathname: location.pathname,
      search: '',
    });
    setView(CONCIERGE_VIEW.INQUIRY);
  };

  const buttonSendMessBulk = (
    <div>
      <Button
        className="px-12 text-white hover:text-white rounded mr-2"
        style={{ background: '#3B86FF' }}
        onClick={() => changeView()}
      >
        お問い合わせ一覧
      </Button>
      <Button
        className="px-12 text-white hover:text-white rounded"
        style={{ background: '#3B86FF' }}
        onClick={() => setVisibleSendMessBulk(true)}
      >
        一斉送信メッセージ
      </Button>
    </div>
  );

  const emptyMessages = (
    <div className="flex justify-center h-full items-center w-full">
      <Empty />
    </div>
  );
  const boxMessages = parsedQuery?.id ? (
    <ConciergeBox
      ref={boxRef}
      sendSuccess={onSyncAuth}
      onUpdateRead={onUpdateRead}
      refresh={refresh}
      inquiriUserId={inquiriUserId}
    />
  ) : (
    emptyMessages
  );

  return (
    <Layout className="flex flex-col h-full w-full p-2">
      <Typography.Title level={3}>コンシェルジュ/お問い合わせ管理</Typography.Title>
      <div className="h-full w-full relative ">
        <div className="absolute w-full h-full inset-0">
          <Card
            style={{ height: '100%' }}
            bodyStyle={{ backgroundColor: 'white', height: '100%', flexWrap: 'wrap' }}
            tabList={[
              { key: USER_TYPES.USER, tab: 'ユーザー' },
              { key: USER_TYPES.TRAINER, tab: 'トレーナー ' },
            ]}
            activeTabKey={userType}
            onTabChange={(activeKey) => {
              setTab(activeKey);
            }}
            tabBarExtraContent={buttonSendMessBulk}
          >
            <div className="flex flex-col h-full w-full">
              <SearchMessage setSearchForm={setSearchForm} />
              <Row className="flex flex-row items-stretch h-full ">
                <Col className="h-full" flex="2">
                  <ConciergeList
                    dataSource={results}
                    isFetching={isFetching}
                    currentPage={currentPage + 1}
                    pageSize={DEFAULT_CONVERSATION_SIZE}
                    totalPages={totalPages}
                    setPage={setPage}
                    setInquiriUserId={setInquiriUserId}
                    refresh={() => {
                      if (boxRef.current?.refreshMessage) {
                        boxRef.current.refreshMessage();
                      }
                    }}
                  />
                </Col>
                <Divider type="vertical" orientation="center" style={{ height: 'auto' }} />
                <Col flex="4" className="h-full">
                  <div className="h-full w-full">{boxMessages}</div>
                </Col>
                <Divider type="vertical" orientation="center" style={{ height: 'auto' }} />
                <Col flex="3" className="h-full w-full">
                  <div className="h-full w-full">
                    <InquiryBox id={inquiriUserId || parsedQuery?.userId} />
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      </div>
      <FormSendBulk
        isVisibleSendMessBulkwrite={isVisibleSendMessBulk}
        setVisibleSendMessBulk={setVisibleSendMessBulk}
      />
    </Layout>
  );
};

Concierge.propTypes = {
  location: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
};
export default Concierge;
