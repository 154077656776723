import React from 'react';
import PropTypes from 'prop-types';
import { Empty } from 'antd';

const EmptyData = ({ description, image, imageStyle }) => {
  return (
    <div className="flex justify-center items-center">
      <Empty description={description} imageStyle={imageStyle} image={image} />
    </div>
  );
};

EmptyData.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  imageStyle: PropTypes.string,
};

export default EmptyData;
