import React from 'react';
import { Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const Message = ({ isAttachment, isLeft, item, deleteMessage, isDelete = false }) => {
  const renderMessage = (content) => {
    if (content === '__INITIAL_LIKE__')
      return (
        <div>
          <img src="/like02.png" width="100" alt="" />
        </div>
      );
    return (
      <div
        className={`px-4 py-2 rounded  inline-block ${
          isLeft ? 'rounded-bl-none bg-gray-2 text-black' : 'rounded-br-none text-white'
        }`}
        style={{
          backgroundColor: isLeft ? '#EDF0F5' : '#3B86FF',
        }}
      >
        <p className="whitespace-pre-wrap break-words fixBreakWords">{content}</p>
      </div>
    );
  };

  return (
    <div style={{ maxWidth: '50%' }} className="relative flex items-center">
      {!isAttachment ? (
        renderMessage(item?.content)
      ) : (
        <div
          className={`px-4 py-2 rounded inline-block space-y-4 ${
            isLeft ? 'rounded-bl-none bg-gray-2 text-black' : 'rounded-br-none text-white'
          }`}
          style={{
            backgroundColor: isLeft ? '#EDF0F5' : '#3B86FF',
          }}
        >
          <div className="max-w-xs">
            <img src={item?.attachment?.link} alt="" className="border border-solid border-gray-400 rounded-md" />
          </div>
          {item?.content && <p className="whitespace-pre-wrap break-words fixBreakWords">{item?.content}</p>}
        </div>
      )}
      {isDelete && (
        <Tooltip title="削除" placement="right">
          <CloseOutlined
            className="absolute cursor-pointer"
            style={isLeft ? { right: -30 } : { left: -30 }}
            onClick={deleteMessage}
          />
        </Tooltip>
      )}
    </div>
  );
};

Message.propTypes = {
  isAttachment: PropTypes.bool,
  isLeft: PropTypes.bool,
  item: PropTypes.objectOf(PropTypes.any),
  deleteMessage: PropTypes.func,
  isDelete: PropTypes.bool,
};
export default Message;
