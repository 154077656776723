import { useAddEdit, usePaging, useDelete } from 'utils/AxiosHooks';
import { API } from 'configs';

const useTags = () => usePaging(API.ADMIN.TAG.LIST, false);

const useCreateTags = () => useAddEdit(API.ADMIN.TAG.ADD, 'add');

const useEditTags = (id) => useAddEdit(id ? API.ADMIN.TAG.EDIT.replace(':id', id) : API.ADMIN.TAG.EDIT, 'edit');

const useDeleteTags = (id, onSuccess = () => {}, onFail = () => {}) =>
  useDelete(id ? API.ADMIN.TAG.EDIT.replace(':id', id) : API.ADMIN.TAG.EDIT, onSuccess, onFail);

export { useTags, useCreateTags, useEditTags, useDeleteTags };
