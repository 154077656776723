import React, { useEffect, useState } from 'react';
import { Layout, Table, Tag, Popconfirm, Modal, Typography } from 'antd';
import { parse } from 'qs';
import { useTrainerRequests, useUpdateTrainerRequest } from 'redux/Hooks/verifyTrainer';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import isEmpty from 'lodash/isEmpty';
import { authState } from 'store/atom';
import { useSyncingMe } from 'redux/Hooks/me';

import TrainerRequestSearchForm from './components/TrainerRequestSearchForm';

const DEFAULT_PAGE_SIZE = 30;

const statusinJP = {
  declined: '非承認',
  pending: '保留',
  approved: '承認する',
};

const Payment = () => {
  const location = useLocation();
  const [, , onSyncAuth] = useSyncingMe();
  const parsedQuery = parse(location.search, { ignoreQueryPrefix: true });

  const [filter, setFilter] = useState({
    ...(Number(parsedQuery?.todayVerify) === 1
      ? {
          status: 'pending',
        }
      : {}),
  });
  const { results, currentPage, totalPages, refresh, setQuery } = useTrainerRequests();
  const { onSave, prevFetching, isFetching } = useUpdateTrainerRequest();

  const [showModal, setShowModal] = useState(false);
  const [current, setCurrent] = useState(null);
  const [reason, setReason] = useState('');

  useEffect(() => {
    if (Number(parsedQuery?.todayVerify) === 1) setFilter({ ...filter, status: 'pending' });
  }, [parsedQuery?.todayVerify]);

  useEffect(() => {
    if (!isFetching && prevFetching) {
      refresh();
    }
  }, [isFetching]);

  const handleSearch = (f) => {
    setFilter({ ...filter, ...f, page: 0 });
  };

  const handleChangePage = (page, pageSize) => {
    setFilter({ ...filter, page: page - 1, pageSize });
  };

  useEffect(() => {
    if (!isEmpty(filter)) setQuery(filter);
  }, [filter]);

  const _renderAvatars = (avatar) => {
    const url = avatar?.link;
    if (!url) return <div />;
    return (
      <a href={url} data-fancybox="gallery">
        <img src={url} width="100" alt="" />
      </a>
    );
  };

  const onOkDeclined = () => {
    onSave(
      {
        type: 'declined',
        reason,
      },
      current.id,
      onSyncAuth,
      onSyncAuth
    );
    setShowModal(false);
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      width: '10%',
    },
    {
      title: 'フルネーム',
      dataIndex: ['user', 'fullName'],
      width: '13%',
      render: (record, dataRow) => (
        <Link to={`/trainer?action=edit&id=${dataRow?.user?.id}`}>
          <span className="">{record}</span>
        </Link>
      ),
    },
    {
      title: 'Email',
      dataIndex: ['user', 'email'],
      width: '12%',
      render: (record) => <div className="">{record}</div>,
    },
    {
      title: '',
      dataIndex: 'image',
      key: 'image',
      width: '12%',
      render: _renderAvatars,
    },
    {
      title: '',
      dataIndex: 'image2',
      key: 'image2',
      width: '13%',
      render: _renderAvatars,
    },
    {
      title: '',
      dataIndex: 'image3',
      key: 'image3',
      width: '13%',
      render: _renderAvatars,
    },
    {
      title: '非承認理由',
      dataIndex: 'reason',
      key: 'reason',
      width: '10%',
    },
    {
      title: 'ステータス',
      dataIndex: ['status'],
      width: '5%',
      // eslint-disable-next-line no-unused-vars
      render: (value, record) => {
        return (
          <Tag
            style={{ cursor: value === 'approved' ? 'default' : 'pointer' }}
            color={value === 'approved' ? 'green' : '#f50'}
          >
            {statusinJP[value]}
          </Tag>
        );
      },
    },
    {
      title: 'アクション',
      dataIndex: 'status',
      width: '10%',
      render: (value, record) => {
        const isPaid = value !== 'pending';
        if (isPaid) {
          return <div />;
        }
        return (
          <div>
            {value === 'pending' && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                role="presentation"
                onClick={() => {
                  setShowModal(true);
                  setCurrent(record);
                }}
              >
                <Tag style={{ cursor: 'pointer' }} color="#f50">
                  {statusinJP.declined}
                </Tag>
              </a>
            )}
            <Popconfirm
              title="このトレーナーを承認しますか。"
              disabled={isPaid}
              onConfirm={() => {
                onSave(
                  {
                    type: 'approved',
                  },
                  record.id,
                  onSyncAuth,
                  onSyncAuth
                );
              }}
            >
              <Tag style={{ cursor: 'pointer' }} color="green">
                {statusinJP.approved}
              </Tag>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const pagination = {
    onChange: handleChangePage,
    current: currentPage + 1 || 1,
    pageSize: DEFAULT_PAGE_SIZE,
    total: totalPages * DEFAULT_PAGE_SIZE || 0,
    showSizeChanger: false,
    hideOnSinglePage: true,
    showQuickJumper: false,
  };

  return (
    <Layout.Content className="flex flex-col p-4">
      <Typography.Title level={3}>トレーナー認証</Typography.Title>
      <Modal title="却下理由" visible={showModal} onOk={onOkDeclined} onCancel={() => setShowModal(false)}>
        <textarea
          className="ant-input"
          placeholder="理由を入力してください"
          onChange={(ref) => setReason(ref.target.value)}
        ></textarea>
      </Modal>
      <div className="formControl py-4 justify-between">
        <TrainerRequestSearchForm onSubmit={handleSearch} />
      </div>
      <Table
        className="bg-white overflow-x-scroll"
        rowClassName="align-top whitespace-pre-wrap break-words fixBreakWordsJapan text-center"
        components={{
          header: {
            cell: ({ className, style, ...restProps }) => {
              return <th className={className} style={{ ...style, textAlign: 'center' }} {...restProps} />;
            },
          },
        }}
        bordered
        dataSource={results || []}
        columns={columns}
        rowKey="id"
        pagination={pagination}
      />
    </Layout.Content>
  );
};

export default Payment;
