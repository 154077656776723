import React, { useState } from 'react';
import { Input, DatePicker } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

const InputDateTime = (props) => {
  const [date, setDate] = useState(moment(props.value).format());
  return (
    <>
      <DatePicker
        value={moment.utc(date).local()}
        onChange={(value) => {
          setDate(moment(value).format());
        }}
        showTime
        disabled={props.disabled}
      />
      <Input {...props} value={moment(date).utc().format()} hidden />
    </>
  );
};

InputDateTime.propTypes = {
  value: PropTypes.any,
};

export default InputDateTime;
