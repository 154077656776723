import { API } from 'configs';
import { usePaging, useAddEdit, useDelete } from 'utils/AxiosHooks';

const useTutorials = (isMount) => usePaging(API.ADMIN.TUTORIAL.LIST, isMount);

const useEditTutorial = (onSuccess, onFail) => useAddEdit(API.ADMIN.TUTORIAL.EDIT, 'edit', onSuccess, onFail);
const useAddTutorial = (onSuccess, onFail) => useAddEdit(API.ADMIN.TUTORIAL.ADD, 'add', onSuccess, onFail);
const useDeleteTutorial = (onSuccess, onFail) => useDelete(API.ADMIN.TUTORIAL.EDIT, onSuccess, onFail);

export { useTutorials, useEditTutorial, useAddTutorial, useDeleteTutorial };
