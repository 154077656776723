import React, { useEffect } from 'react';
import { stringify, parse } from 'qs';
import { Table, Card, Rate } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useGetUserRating } from 'redux/Hooks/user';

const UserRating = ({ userId }) => {
  const history = useHistory();
  const location = useLocation();
  const parsedQuery = parse(location.search, { ignoreQueryPrefix: true });
  const [page, setPage] = React.useState(Number(parsedQuery?.ratingPage) - 1 || 0);

  const { results, isFetching, totalPages, currentPage, setQuery, pageSize } = useGetUserRating(userId);

  useEffect(() => {
    setQuery({
      page,
    });
  }, [page]);

  const columns = [
    {
      title: '日付',
      dataIndex: 'updated',
      key: 'updated',
      width: '15%',
      render: (record) => <div>{moment.utc(record).local().format('YYYY/MM/DD HH:mm')}</div>,
    },
    {
      title: 'レビューア',
      width: '25%',
      render: (record) => (
        <Link to={`/${record.userType === 'training' ? 'user' : 'trainer'}?action=edit&id=${record.id}`}>
          <span>{record.from?.fullName}</span>
        </Link>
      ),
    },
    {
      title: '評価',
      width: '20%',
      render: (record) => <Rate disabled defaultValue={record.rating} />,
    },
    {
      title: '注意',
      key: 'note',
      width: '40%',
      dataIndex: 'note',
    },
  ];

  const handleChangePage = (pageNumber) => {
    const query = new URLSearchParams(history.location.search);
    const queryString = stringify({
      ...query,
      ...parsedQuery,
      ratingPage: pageNumber === 1 ? undefined : pageNumber,
    });

    setPage(pageNumber - 1);
    history.push({
      pathname: location.pathname,
      search: queryString,
    });
  };

  const pagination = {
    onChange: handleChangePage,
    current: currentPage + 1,
    pageSize,
    total: totalPages * pageSize,
    showSizeChanger: false,
    hideOnSinglePage: true,
  };

  return (
    <div className="flex flex-col my-4">
      <Card title="評価">
        <Table
          className="bg-white overflow-x-scroll"
          rowClassName="align-top whitespace-pre-wrap break-words fixBreakWordsJapan text-center"
          components={{
            header: {
              // eslint-disable-next-line react/prop-types
              cell: ({ className, style, ...restProps }) => {
                return <th className={className} style={{ ...style, textAlign: 'center' }} {...restProps} />;
              },
            },
          }}
          bordered
          pagination={pagination}
          dataSource={results}
          loading={isFetching}
          columns={columns}
          rowKey="id"
        />
      </Card>
    </div>
  );
};

UserRating.propTypes = {
  userId: PropTypes.number,
};
export default UserRating;
