import React, { useState, useEffect } from 'react';
import qs from 'qs';
import { Layout, Table, Button, Typography } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { usePointHistories } from 'redux/Hooks/pointHistory';
import { POINT_HISTORY_TYPE_JP } from 'configs/Constants';

import PointHistorySearchForm from './components/PointHistorySearchForm';

const DEFAULT_PAGE_SIZE = 30;

const Point = ({ history, location }) => {
  const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { results, isFetchingList, currentPage, totalPages, setQuery } = usePointHistories();
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [page, setPage] = useState(parsedQuery?.page ? parseInt(parsedQuery?.page, 10) - 1 : 0);
  const [searchForm, setSearchForm] = useState({});
  const handleSearch = (filter) => {
    setSearchForm(filter);
  };

  const handleChangePage = (p, pS) => {
    setPage(p - 1);
    setPageSize(pS);
  };

  useEffect(() => {
    setQuery({
      ...searchForm,
      pageSize,
      page,
    });
  }, [pageSize, searchForm, page]);

  const columns = [
    {
      title: '日時',
      dataIndex: 'created',
      render: (value, record) => {
        return moment.utc(value).local().format('YYYY/MM/DD HH:mm');
      },
    },
    {
      title: '対象',
      dataIndex: ['user', 'fullName'],
      width: '10%',
      render: (value, record) => <div className="">{record.isSystem ? 'システム' : value || ''}</div>,
    },
    {
      title: 'ポイント',
      dataIndex: 'amount',
    },
    {
      title: '詳細',
      dataIndex: 'type',
      render: (val) => POINT_HISTORY_TYPE_JP?.[val] || val,
    },
    {
      title: '内容',
      dataIndex: 'description',
      width: '20%',
      render: (value) => <div className="">{value || ''}</div>,
    },
  ];

  const pagination = {
    onChange: handleChangePage,
    current: currentPage + 1 || 1,
    pageSize: pageSize || DEFAULT_PAGE_SIZE,
    total: totalPages * pageSize || 0,
  };

  return (
    <Layout.Content className="flex flex-col p-4">
      <Typography.Title level={3}>ポイント履歴</Typography.Title>
      <div className="formControl py-4">
        <PointHistorySearchForm onSubmit={handleSearch} />
      </div>
      <div className="flex flex-row justify-end py-4">
        <Button onClick={() => history.push('/point/list')} type="primary">
          ポイント一覧
        </Button>
      </div>
      <Table
        className="bg-white overflow-x-scroll"
        rowClassName="align-top whitespace-pre-wrap break-words fixBreakWordsJapan text-center"
        components={{
          header: {
            cell: ({ className, style, ...restProps }) => {
              return <th className={className} style={{ ...style, textAlign: 'center' }} {...restProps} />;
            },
          },
        }}
        bordered
        loading={isFetchingList}
        dataSource={results || []}
        columns={columns}
        pagination={pagination}
        rowKey="id"
      />
    </Layout.Content>
  );
};

Point.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  location: PropTypes.objectOf(PropTypes.any),
};
export default Point;
