import React from 'react';
import PropTypes from 'prop-types';
import { Statistic, Card, Skeleton } from 'antd';

const StatisticProfit = React.memo(({ totalValue, title, unit, isLoading }) => {
  return (
    <Card
      className="shadow-sm border-solid border-2 ml-12 pl-3 pr-5 relative"
      style={{ borderColor: '#CACED5', minWidth: '240px' }}
    >
      {isLoading ? (
        <Skeleton paragraph={{ rows: 1 }} loading={isLoading} />
      ) : (
        <Statistic
          className="py-1"
          title={title}
          value={totalValue}
          valueStyle={{ color: '#000', fontWeight: 600 }}
          prefix={unit}
        />
      )}
    </Card>
  );
});
StatisticProfit.propTypes = {
  totalValue: PropTypes.number,
  title: PropTypes.string,
  unit: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default StatisticProfit;
