import React from 'react';
import { Avatar, Button, Upload, Modal, message } from 'antd';
import { CloseOutlined, UserOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import { useUpload } from 'utils/hooks/useUpload';
import { getToken } from 'utils/Function';

const UPLOAD_PATH = `${process.env.REACT_APP_API_URI}/api/v1/upload`;

const UploadButton = ({ children, loading }) => {
  return loading ? (
    <div>
      <LoadingOutlined shape="circle" size="large" />
    </div>
  ) : (
    children
  );
};

UploadButton.propTypes = {
  children: PropTypes.any,
  loading: PropTypes.bool,
};

const UserAvatar = ({ onDeleteAvatar, onAddAvatar, avatar = '' }) => {
  const { state, uploadProps } = useUpload();
  const [modal, contextHolder] = Modal.useModal({});

  const authorization = getToken() ? `Bearer ${getToken()}` : null;

  const onOk = (close) => {
    close();
    onDeleteAvatar();
  };

  const onCancel = (close) => {
    close();
  };

  const modalDeleteConfig = (img) => {
    return {
      title: '確認',
      content: <h1>削除しますか？</h1>,
      onOk: (close) => onOk(close, img),
      onCancel,
      okText: '削除',
      okType: 'primary',
      cancelText: 'キャンセル',
      icon: null,
      mask: true,
      maskClosable: true,
      keyboard: true,
      autoFocusButton: 'ok',
    };
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('アップロードできるのはJPG / PNGファイルのみです！');
    }
    return isJpgOrPng;
  };

  const handleAdd = async (info) => {
    uploadProps.onChange(info);
    if (info.file.status === 'uploading') {
      return;
    }
    if (info?.file?.status === 'done' && info?.file?.response?.uploaded) {
      onAddAvatar(info?.file?.response?.url);
    }
  };

  const _renderControl = () => {
    return (
      <div className="py-2 ">
        <div className="flex justify-end">
          <Upload
            disabled={state?.loading}
            name="upload"
            multiple={false}
            showUploadList={false}
            beforeUpload={beforeUpload}
            action={UPLOAD_PATH}
            headers={{ authorization }}
            {...uploadProps}
            onChange={handleAdd}
          >
            <UploadButton loading={state?.loading}>
              <Button
                shape="circle"
                size="small"
                icon={<EditOutlined />}
                className="flex justify-center items-center"
              />
            </UploadButton>
          </Upload>

          {avatar && (
            <Button
              className="flex justify-center items-center mx-2"
              shape="circle"
              size="small"
              onClick={() => {
                modal.confirm(modalDeleteConfig(avatar));
              }}
              icon={<CloseOutlined />}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="pb-4 pl-4">
      <Avatar src={avatar} className="flex justify-center items-center mb-4" size={220} icon={<UserOutlined />} />
      {contextHolder}
    </div>
  );
};

UserAvatar.propTypes = {
  onDeleteAvatar: PropTypes.func,
  onAddAvatar: PropTypes.func,
  avatar: PropTypes.string,
};
export default UserAvatar;
