import { API } from 'configs';
import { useRequest } from 'utils/AxiosHooks';

const useGetStatistics = () => {
  const [state, doRequest] = useRequest({
    url: API.ADMIN.STATISTICS,
  });

  return {
    data: state,
    fetch: doRequest,
  };
};

export { useGetStatistics };
