import React, { useEffect } from 'react';
import AgoraRTC from 'agora-rtc-sdk';
import { useRecoilValue } from 'recoil';
import { authState } from 'store/atom';
import { AGORA_ID } from 'configs/API';

const client = AgoraRTC.createClient({
  mode: 'live',
});

client.setClientRole('audience');
client.init(AGORA_ID);

export default (props) => {
  /* eslint-disable no-console */
  /* eslint-disable react/prop-types */

  const { livestream: data } = props;
  const { token, livestream } = data;
  const auth = useRecoilValue(authState);

  useEffect(() => {
    client.on('first-audio-frame-decode', (evt) => {
      console.log(evt.stream);
    });
    client.on('stream-added', (e) => {
      const { stream } = e;

      client.subscribe(stream, {
        video: !0,
        audio: !0,
      });

      client.on('stream-subscribed', (evt) => {
        const t = evt.stream;
        t.unmuteVideo();
        t.play('video', { fit: 'contain' }); // plays the stream into a DIV element
      });
    });

    return () => {
      client.leave();
    };
  }, []);

  useEffect(() => {
    if (!livestream?.playbackId) return;
    client.join(
      token,
      livestream?.playbackId,
      auth?.user?.id,
      (uid) => {
        // Create a local stream
        console.log('success', uid);
      },
      (e) => {
        console.log('eeee', e);
      }
    );
    return () => {};
  }, [token]);

  if (!livestream?.livestreaming) {
    return <div />;
  }
  return <div style={{ height: 480, width: '100%' }} id="video"></div>;
};
