import React from 'react';
import { Form, Select } from 'antd';

const { Option } = Select;

const UserTags = ({ groupTags }) => {
  const renderSelectTag = (id, label) => {
    const isMultiple = groupTags?.[id]?.selectType === 'multiple';
    return (
      <div className="w-full flex flex-col">
        <span className="font-semibold py-2">
          {label}
          <span>{isMultiple ? ' (複数選択できます)' : ''}</span>
        </span>
        <Form.Item name={['tags', id]} noStyle>
          <Select {...(isMultiple && { mode: 'multiple' })} allowClear>
            {groupTags?.[id]?.tags?.map((tag) => (
              <Option key={`key_${tag?.id}`} value={tag?.id}>
                {tag?.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>
    );
  };

  return (
    <div>
      {Object.entries(groupTags)?.map(([key, item]) => (
        <div key={`key_${key}`}>{renderSelectTag(item?.id, item?.name_jap)}</div>
      ))}
    </div>
  );
};

export default UserTags;
