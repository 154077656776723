import React, { useState, useEffect } from 'react';
import { Layout, Space, Button, Table, Modal, Input, message, Select } from 'antd';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { TARGET_TYPE_TAG_CATEGORY, SELECT_TYPE_TAG_CATEGORY } from 'utils/constant';
import EditModal from 'components/EditModal';
import {
  useDeleteTagCategory,
  useTagCategories,
  useEditTagCategory,
  useCreateTagCategory,
} from 'redux/Hooks/tag_category';
import { SortableItem, SortableContainer, DragHandle } from 'components/Sortable';
import { LexoRank } from 'lexorank';

const TagManagement = (props) => {
  const { results, refresh } = useTagCategories();

  const [visibleEdit, setVisibleEdit] = useState(false);
  const [editSelected, setEditSelected] = useState(null);
  const [modal, contextHolder] = Modal.useModal({});
  const dataSource = results || [];

  const {
    onSave: updateItem,
    prevFetching: prevIsFetchingUpdate,
    isFetching: isFetchingUpdate,
    success: successUpdate,
    message: successResponseUpdate,
  } = useEditTagCategory(editSelected?.id);

  const {
    onSave: createItem,
    prevFetching: prevIsFetchingCreate,
    isFetching: isFetchingCreate,
    success: successCreate,
    message: successResponseCreate,
  } = useCreateTagCategory();

  const { onDelete: deleteItem } = useDeleteTagCategory(
    editSelected?.id,
    (response) => {
      if (response?.success) {
        message.success('実行完了');
        refresh();
        props.history.push({
          pathname: props.location.pathname,
          search: props.history.location.search,
        });
      } else {
        message.error('エラー');
      }
    },
    () => message.error('エラー')
  );

  useEffect(() => {
    if (isFetchingUpdate !== prevIsFetchingUpdate && prevIsFetchingUpdate) {
      if (successUpdate) {
        message.success('実行完了');
        refresh();
        props.history.push({
          pathname: props.location.pathname,
          search: props.history.location.search,
        });
      } else {
        message.error('エラー');
        console.error(successResponseUpdate?.message);
      }
    }
  }, [isFetchingUpdate]);

  useEffect(() => {
    if (isFetchingCreate !== prevIsFetchingCreate && prevIsFetchingCreate) {
      if (successCreate) {
        message.success('実行完了');
        refresh();
        props.history.push({
          pathname: props.location.pathname,
          search: props.history.location.search,
        });
      } else {
        message.error('エラー');
        console.error(successResponseCreate?.message);
      }
    }
  }, [isFetchingCreate]);

  // useEffect(() => {
  //   setQuery({
  //     pageSize: 10000,
  //     page: 0,
  //   });
  // }, []);

  const onChangeItem = async (values, recordId) => {
    if (recordId) {
      updateItem(values, recordId);
    } else {
      createItem(values);
    }
    setVisibleEdit(false);
    setEditSelected(null);
  };
  const onSort = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newOrder = dataSource?.[newIndex]?.order;
      const newRank = newOrder ? LexoRank.parse(newOrder) : LexoRank.middle();
      let resultRank = null;
      if (newIndex === 0) {
        resultRank = newRank.genPrev();
      } else if (newIndex === dataSource?.length - 1) {
        resultRank = newRank.genNext();
      } else {
        const nearOrder = dataSource?.[newIndex - oldIndex > 0 ? newIndex + 1 : newIndex - 1]?.order;
        const nearRank = nearOrder ? LexoRank.parse(nearOrder) : LexoRank.middle();
        if (newRank.equals(nearRank)) {
          resultRank = newIndex - oldIndex > 0 ? newRank.genNext() : newRank.genPrev();
        } else {
          resultRank = newRank.between(nearRank);
        }
      }
      if (dataSource?.[oldIndex] && resultRank) {
        onChangeItem({ ...dataSource?.[oldIndex], order: resultRank.toString() }, dataSource?.[oldIndex]?.id);
      }
    }
  };
  const onOk = async (close, record) => {
    deleteItem(record?.id);
    close();
  };
  const onCancel = (close) => {
    close();
  };
  const modalDeleteConfig = (record) => {
    return {
      className: '',
      title: '確認',
      content: <h1>削除しますか？</h1>,
      onOk: (close) => onOk(close, record),
      onCancel,
      okButtonProps: {},
      cancelButtonProps: {},
      okText: '削除',
      okType: 'primary',
      cancelText: 'キャンセル',
      icon: null,
      mask: true,
      maskClosable: true,
      style: {},
      maskStyle: {},
      keyboard: true,
      autoFocusButton: 'ok',
    };
  };
  const _selectType = (record) => SELECT_TYPE_TAG_CATEGORY[record];
  const _targetType = (record) => TARGET_TYPE_TAG_CATEGORY[record];
  const _renderAction = (record) => (
    <Space size="middle">
      <Button
        onClick={() => {
          setVisibleEdit(true);
          setEditSelected(record);
        }}
        icon={<EditOutlined />}
      />
      <Button
        onClick={() => {
          modal.confirm(modalDeleteConfig(record));
        }}
        icon={<CloseOutlined />}
      />
    </Space>
  );
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource.findIndex((x) => x.id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  const DraggableContainer = (containerProps) => (
    <SortableContainer
      lockAxis="y"
      useDragHandle
      disableAutoscroll
      helperClass="flex flex-row items-center justify-between border bg-white pr-2 pl-2"
      onSortEnd={onSort}
      {...containerProps}
    />
  );
  const genaratedColumns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: <p className="text-center whitespace-no-wrap">移動</p>,
      dataIndex: 'order',
      key: 'order',
      className: 'text-center w-2/12',
      render: () => <DragHandle />,
    },
    {
      title: '英語名',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '日本名',
      dataIndex: 'name_jap',
      key: 'name_jap',
    },
    {
      title: '選択肢種類',
      dataIndex: 'selectType',
      key: 'selectType',
      render: _selectType,
    },
    {
      title: '対象',
      dataIndex: 'targetType',
      key: 'targetType',
      render: _targetType,
    },
    {
      title: '',
      key: 'action',
      render: _renderAction,
    },
  ];

  const editInputs = [
    {
      key: 'name',
      title: '英語名',
      InputComponent: Input,
      inputProps: {},
      formItemProps: {
        rules: [{ required: true }],
      },
    },
    {
      key: 'name_jap',
      title: '日本語名',
      InputComponent: Input,
      inputProps: {},
      formItemProps: {
        rules: [{ required: true }],
      },
    },
    {
      key: 'selectType',
      title: '選択肢種類',
      InputComponent: Select,
      inputProps: {
        options: Object.entries(SELECT_TYPE_TAG_CATEGORY).map(([key, label]) => ({ value: key, label })),
      },
      formItemProps: {
        rules: [{ required: true }],
      },
    },
    {
      key: 'targetType',
      title: '対象',
      InputComponent: Select,
      inputProps: {
        options: Object.entries(TARGET_TYPE_TAG_CATEGORY).map(([key, label]) => ({ value: key, label })),
      },
      formItemProps: {
        rules: [{ required: true }],
      },
    },
    {
      key: 'order',
      InputComponent: Input,
      inputProps: {},
      formItemProps: {
        hidden: true,
      },
    },
  ];
  return (
    <Layout.Content className="flex flex-col p-4">
      <div className="flex flex-row justify-end py-4">
        <Space>
          <Button
            onClick={() => {
              setVisibleEdit(true);
              setEditSelected(null);
            }}
            type="primary"
          >
            追加
          </Button>
        </Space>
      </div>
      <Table
        className="bg-white overflow-x-scroll"
        rowClassName="align-top whitespace-pre-wrap break-words fixBreakWordsJapan text-center"
        bordered
        dataSource={dataSource}
        columns={genaratedColumns}
        pagination={false}
        components={{
          header: {
            cell: ({ className, style, ...restProps }) => {
              return <th className={className} style={{ ...style, textAlign: 'center' }} {...restProps} />;
            },
          },
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
        rowKey="id"
      />
      {contextHolder}
      {visibleEdit && (
        <EditModal
          visible={visibleEdit}
          onCreate={onChangeItem}
          onCancel={() => {
            setVisibleEdit(false);
            setEditSelected(null);
          }}
          record={editSelected}
          editInputs={editInputs}
          headerTitle="項目管理"
        />
      )}
    </Layout.Content>
  );
};

TagManagement.propTypes = {
  location: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
};
export default TagManagement;
