import React from 'react';
import { Statistic, Card, Skeleton, Divider } from 'antd';
import PropTypes from 'prop-types';

const GenderPercent = ({ loading, value }) => {
  return loading ? (
    <Skeleton paragraph={{ rows: 1 }} loading={loading} />
  ) : (
    <>
      <Card className="flex flex-col flex-1 mx-2" style={{ flexBasis: '100%' }}>
        <div className="flex flex-col">
          <div className="pb-4">ユーザー</div>
          <div className="flex flex-row justify-between">
            <Statistic title="女性" value={`${value?.femaleTraining || 0}％`} valueStyle={{ fontWeight: 600 }} />
            <Statistic title="男性" value={`${value?.maleTraining || 0}％`} valueStyle={{ fontWeight: 600 }} />
            <Statistic title="その他" value={`${value?.unknownTraining || 0}％`} valueStyle={{ fontWeight: 600 }} />
          </div>
        </div>
      </Card>
      <Card className="flex flex-col flex-1 mx-2" style={{ flexBasis: '100%' }}>
        <div className="flex flex-col">
          <div className="pb-4">トレーナー</div>
          <div className="flex flex-row justify-between">
            <Statistic title="女性" value={`${value?.femaleTrainer || 0}％`} valueStyle={{ fontWeight: 600 }} />
            <Statistic title="男性" value={`${value?.maleTrainer || 0}％`} valueStyle={{ fontWeight: 600 }} />
            <Statistic title="その他" value={`${value?.unknownTrainer || 0}％`} valueStyle={{ fontWeight: 600 }} />
          </div>
        </div>
      </Card>
    </>
  );
};
GenderPercent.propTypes = {
  loading: PropTypes.bool,
  value: PropTypes.any,
};

export default GenderPercent;
