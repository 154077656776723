export const RECOIL_KEY = {
  AUTH: 'auth',
  SETTING: 'setting',
  CUSTOMER: 'customer',
};

export const OPERATOR = {
  or: 'or',
  in: 'in',
  nin: 'nin',
  eq: 'eq',
  ne: 'ne',
  lt: 'lt',
  lte: 'lte',
  gt: 'gt',
  gte: 'gte',
  contains: 'contains',
  ncontains: 'ncontains',
  containss: 'containss',
  like: 'like',
  ncontainss: 'ncontainss',
  null: 'null',
};

export const TODOFUKEN = {
  // 居住地, 出身地
  HOKKAIDO: '北海道',
  AOMORI: '青森',
  IWATE: '岩手',
  MIYAGI: '宮城',
  AKITA: '秋田',
  YAMAGATA: '山形',
  FUKUSHIMA: '福島',
  IBARAKI: '茨城',
  TOCHIGI: '栃木',
  GUNMA: '群馬',
  SAITAMA: '埼玉',
  CHIBA: '千葉',
  TOKYO: '東京',
  KANAGAWA: '神奈川',
  NIIGATA: '新潟',
  TOYAMA: '富山',
  ISHIKAWA: '石川',
  FUKUI: '福井',
  YAMANASHI: '山梨',
  NAGANO: '長野',
  GIFU: '岐阜',
  SHIZUOKA: '静岡',
  AICHI: '愛知',
  MIE: '三重',
  SHIGA: '滋賀',
  KYOTO: '京都',
  OSAKA: '大阪',
  HYOGO: '兵庫',
  NARA: '奈良',
  WAKAYAMA: '和歌山',
  TOTTORI: '鳥取',
  SHIMANE: '島根',
  OKAYAMA: '岡山',
  HIROSHIMA: '広島',
  YAMAGUCHI: '山口',
  TOKUSHIMA: '徳島',
  KAGAWA: '香川',
  EHIME: '愛媛',
  KOCHI: '高知',
  FUKUOKA: '福岡',
  SAGA: '佐賀',
  NAGASAKI: '長崎',
  KUMAMOTO: '熊本',
  OITA: '大分',
  MIYAZAKI: '宮崎',
  KAGOSHIMA: '鹿児島',
  OKINAWA: '沖縄',
  FOREIGN: '海外',
};

export const PROFILE_TYPE = {
  CUSTOMER: 'profile_customer',
  HOST: 'profile_host',
};

export const TAG_CATEGORY = {
  category: {
    body_shape: '体型',
    food: '料理',
    music: '音楽',
    movie: '映画',
    entertainer: '芸能人',
    drink: 'お酒',
    game: 'ゲーム',
    manga: 'マンガ',
    art: 'アート',
    sport: 'スポーツ',
    car: 'くるま',
    watch: '時計',
    travel: '旅行',
    animal: '動物・ペット',
    personality: '性格',
    type: 'タイプ',
    sociability: '社交性',
    educational_background: '学歴',
    holiday: '休日',
    marriage_history: '結婚歴',
    children: '子供の有無',
    // drink: 'お酒',
    roomate: '同居',
    hobby: '趣味',
    job: '仕事',
  },
};

export const SELECT_TYPE_TAG_CATEGORY = {
  single: '単数選択',
  multiple: '複数選択',
};

export const TARGET_TYPE_TAG_CATEGORY = {
  trainer: 'トレーナー',
  training: 'トレーニー',
  all: '両方',
};

export const POINT_HISTORY = {
  TYPE: {
    SEND_BONUS: 'send_bonus',
    RECEIVE_BONUS: 'receive_bonus',
    ADMIN_CHANGE: 'admin_change',
    BUY_POINT: 'buy_point',
    PAY_LESSON: 'pay_lesson',
    RECEIVE_LESSON: 'receive_lesson',
    REFUND_LESSON: 'refund_lesson',
    PURCHASE_GIFT: 'purchase_gift',
    SEND_GIFT: 'send_gift',
    RECEIVE_GIFT: 'receive_gift',
    WITHDRAW: 'withdraw',
  },
};

export const REPORT = {
  TYPE: {
    LESSON: 'lesson',
    LIVESTREAM: 'livestream',
    LESSON_REQUEST: 'lesson_request',
    CONVERSATION: 'conversation',
    USER: 'user',
  },
  STATUS: {
    PENDING: 'pending',
    DONE: 'done',
  },
};

export const DAY_OF_WEEK = {
  0: '日曜日',
  1: '月曜日',
  2: '火曜日',
  3: '水曜日',
  4: '木曜日',
  5: '金曜日',
  6: '土曜日',
};

export const REASON_IN_JP = {
  spam: 'スパム',
  inappropriate: '不適切',
};

export const LOCATION_JP = {
  districts: {
    aichi_prefecture: '愛知県',
    akita: '秋田県',
    aomori_prefecture: '青森県',
    chiba_prefecture: '千葉県',
    ehime_prefecture: '愛媛県',
    fukui_prefecture: '福井県',
    fukuoka_prefecture: '福岡県',
    fukushima_prefecture: '福島県',
    gifu_prefecture: '岐阜県',
    gunma_prefecture: '群馬県',
    hiroshima_prefecture: '広島県',
    hokkaido: '北海道',
    hyogo_prefecture: '兵庫県',
    ibaraki_prefecture: '茨城県',
    ishikawa_prefecture: '石川県',
    iwate_prefecture: '岩手県',
    kagawa_prefecture: '香川県',
    kagoshima_prefecture: '鹿児島県',
    kanagawa_prefecture: '神奈川県',
    kochi_prefecture: '高知県',
    kumamoto_prefecture: '熊本県',
    kyoto: '京都府',
    mie_prefecture: '三重県',
    miyagi_prefecture: '宮城県',
    miyazaki_prefecture: '宮崎県',
    nagano_prefecture: '長野県',
    nagasaki_prefecture: '長崎県',
    nara_prefecture: '奈良県',
    niigata_prefecture: '新潟県',
    oita_prefecture: '大分県',
    okayama_prefecture: '岡山県',
    okinawa_prefecture: '沖縄県',
    osaka_prefecture: '大阪府',
    saga_prefecture: '佐賀県',
    saitama: '埼玉県',
    shiga_prefecture: '滋賀県',
    shimane_prefecture: '島根県',
    shizuoka_prefecture: '静岡県',
    tochigi_prefecture: '栃木県',
    tokushima_prefecture: '徳島県',
    tokyo: '東京都',
    tottori_prefecture: '鳥取県',
    toyama_prefecture: '富山県',
    wakayama_prefecture: '和歌山県',
    yamagata_prefecture: '山形県',
    yamaguchi_prefecture: '山口県',
    yamanashi_prefecture: '山梨県',
  },
};

export const DISTRICTS = [
  'districts.hokkaido',
  'districts.aomori_prefecture',
  'districts.iwate_prefecture',
  'districts.miyagi_prefecture',
  'districts.akita',
  'districts.yamagata_prefecture',
  'districts.fukushima_prefecture',
  'districts.ibaraki_prefecture',
  'districts.tochigi_prefecture',
  'districts.gunma_prefecture',
  'districts.saitama',
  'districts.chiba_prefecture',
  'districts.tokyo',
  'districts.kanagawa_prefecture',
  'districts.niigata_prefecture',
  'districts.toyama_prefecture',
  'districts.ishikawa_prefecture',
  'districts.fukui_prefecture',
  'districts.yamanashi_prefecture',
  'districts.nagano_prefecture',
  'districts.gifu_prefecture',
  'districts.shizuoka_prefecture',
  'districts.aichi_prefecture',
  'districts.mie_prefecture',
  'districts.shiga_prefecture',
  'districts.kyoto',
  'districts.osaka_prefecture',
  'districts.hyogo_prefecture',
  'districts.nara_prefecture',
  'districts.wakayama_prefecture',
  'districts.tottori_prefecture',
  'districts.shimane_prefecture',
  'districts.okayama_prefecture',
  'districts.hiroshima_prefecture',
  'districts.yamaguchi_prefecture',
  'districts.tokushima_prefecture',
  'districts.kagawa_prefecture',
  'districts.ehime_prefecture',
  'districts.kochi_prefecture',
  'districts.fukuoka_prefecture',
  'districts.saga_prefecture',
  'districts.nagasaki_prefecture',
  'districts.kumamoto_prefecture',
  'districts.oita_prefecture',
  'districts.miyazaki_prefecture',
  'districts.kagoshima_prefecture',
  'districts.okinawa_prefecture',
];

export const PERMISSIONS_JP = {
  'update-point': {
    title: 'ポイントアップデート',
    guide: '・ポイント追加可能',
  },
  'manage-notification': {
    title: '通知管理',
    guide: '・お知らせの一覧の閲覧可能\n・お知らせ追加可能\n・送ったお知らせの削除可能',
  },
  'manage-concierge': {
    title: 'コンシェルジュ/お問い合わせ管理',
    guide:
      '・メッセージ内容、日付で検索可能\n・コンシェルジュの一覧の閲覧可能\n・一斉送信メッセージ送信可能\n・メッセージの削除可能\n・ユーザーとトレーナーにメッセージ送信可能',
  },
  'manage-admin': {
    title: '管理者設定',
    guide:
      '①管理者管理　\n②マスタ管理\n・ヘルプ管理\n・フィートバック管理\n・マッチングカテゴリー管理\n・マッチング項目管理\n・ギフト管理\n・役割設定\n・NGワード管理\n・コンシェルジュ管理\n・バナー管理管理\n・利用規則管理\n・実践できるトレーニング管理\n・チュートリアル管理\n・運営銀行情報管理',
  },
  'manage-payment-request': {
    title: '支払いリクエスト管理',
    guide:
      '・支払リクエストの状態、トレーナー名で検索可能\n・支払リクエストの一覧の閲覧可能\n・払い可能\n・CSV出力済可能',
  },
};
