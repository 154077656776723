import React, { useState, useEffect } from 'react';
import { Layout, Button, Table, Tag, Popconfirm, Typography } from 'antd';
import { stringify, parse } from 'qs';
import moment from 'moment';
import PropTypes from 'prop-types';
import { CloseOutlined } from '@ant-design/icons';
import { useDebouncedCallback } from 'use-debounce';

import { useNotifications, useDeleteNoti } from 'redux/Hooks/announcement';
import SendNotificationForm from './components/SendNotificationForm';

const USER_TYPE_JP = {
  all: '全員',
  trainer: 'トレーナー',
  training: 'ユーザー',
};

const USER_TYPE_COLOR = {
  all: 'magenta',
  trainer: 'blue',
  training: 'green',
};

const Notification = (props) => {
  const { location, history } = props;
  const parsedQuery = parse(location.search, { ignoreQueryPrefix: true });

  const [isVisible, setVisible] = useState(false);
  const [page, setPage] = React.useState(Number(parsedQuery?.page) - 1 || 0);

  const { results, isFetching, currentPage, totalPages, refresh, pageSize, setQuery } = useNotifications();
  const { onDelete } = useDeleteNoti(() => refresh());

  useEffect(() => {
    setQuery({
      page,
    });
  }, [page]);

  const handleDelete = useDebouncedCallback(
    (id) => {
      onDelete(id);
    },
    250,
    { leading: true, trailing: false, maxWait: 5000 }
  );

  const handleChangePage = (pageNumber) => {
    const query = new URLSearchParams(history.location.search);
    const queryString = stringify({
      ...query,
      ...parsedQuery,
      page: pageNumber === 1 ? undefined : pageNumber,
    });

    setPage(pageNumber - 1);
    history.push({
      pathname: location.pathname,
      search: queryString,
    });
  };

  const columns = [
    {
      title: '公開日時',
      dataIndex: 'updated',
      width: '10%',
      render: (record) => <div className="">{moment.utc(record).local().format('YYYY/MM/DD HH:mm')}</div>,
    },
    {
      title: 'タイトル',
      dataIndex: 'title',
      width: '30%',
      render: (value) => <div className="">{value || ''}</div>,
    },
    {
      title: '内容',
      dataIndex: 'content',
      width: '40%',
      render: (value) => <div className="">{value || ''}</div>,
    },
    {
      title: '対象',
      width: '10%',
      render: (record) => <Tag color={USER_TYPE_COLOR[record?.userType]}>{USER_TYPE_JP[record?.userType]}</Tag>,
    },
    {
      title: '',
      width: '10%',
      render: (record) => (
        <div>
          <Popconfirm title="本当に削除しますか。" onConfirm={() => handleDelete(record.id)}>
            <Button danger className="flex justify-center items-center " icon={<CloseOutlined />} />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const pagination = {
    onChange: handleChangePage,
    current: currentPage + 1,
    pageSize,
    total: totalPages * pageSize,
    showSizeChanger: false,
    hideOnSinglePage: true,
  };

  return (
    <Layout.Content className="flex flex-col p-4">
      <Typography.Title level={3}>お知らせ管理</Typography.Title>
      <div className="flex justify-end m-2">
        <Button
          // eslint-disable-next-line max-len
          className="bg-blue-primary text-white border border-solid border-blue-primary w-48 rounded-md flex items-center justify-center h-10"
          onClick={() => setVisible(true)}
        >
          お知らせを送る
        </Button>
      </div>
      <Table
        className="bg-white overflow-x-scroll"
        rowClassName="align-top whitespace-pre-wrap break-words fixBreakWordsJapan"
        components={{
          header: {
            // eslint-disable-next-line react/prop-types
            cell: ({ className, style, ...restProps }) => {
              return <th className={className} style={{ ...style, textAlign: 'center' }} {...restProps} />;
            },
          },
        }}
        bordered
        pagination={pagination}
        dataSource={results}
        columns={columns}
        loading={isFetching}
        rowKey="id"
      />
      {isVisible && <SendNotificationForm isVisible={isVisible} refresh={refresh} setVisible={setVisible} />}
    </Layout.Content>
  );
};

Notification.propTypes = {
  location: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
};
export default Notification;
