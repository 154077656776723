import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, Button, Radio } from 'antd';
import { useSendNotifications } from 'redux/Hooks/announcement';

const FormItem = Form.Item;
const { TextArea } = Input;

const SendNotificationForm = ({ isVisible, refresh, setVisible }) => {
  const [form] = Form.useForm();
  const onCancel = () => setVisible(false);

  const [doSendNoti, requestState] = useSendNotifications();

  React.useEffect(() => {
    if (requestState.isSuccess) {
      refresh();
      onCancel();
    }
  }, [requestState.isSuccess]);

  const handleSendNotification = async (values) => {
    await doSendNoti(values);
  };

  return (
    <Modal
      form={form}
      centered
      footer={null}
      visible={isVisible}
      title="お知らせ追加"
      onCancel={() => onCancel()}
      destroyOnClose
    >
      <Form layout="vertical" onFinish={handleSendNotification} style={{ marginTop: 8 }}>
        <FormItem label="タイトル" name="title" rules={[{ required: true }]}>
          <TextArea placeholder="入力してください" />
        </FormItem>
        <FormItem label="内容" name="content" rules={[{ required: true }]}>
          <TextArea placeholder="入力してください" />
        </FormItem>
        <FormItem label="送信先" name="userType" initialValue="all">
          <Radio.Group>
            <Radio value="all">全員</Radio>
            <Radio value="trainer">トレーナー</Radio>
            <Radio value="training">ユーザー</Radio>
          </Radio.Group>
        </FormItem>
        <FormItem style={{ marginTop: 28, textAlign: 'center' }}>
          <Button type="primary" htmlType="submit" loading={requestState?.isLoading}>
            送信
          </Button>
        </FormItem>
      </Form>
    </Modal>
  );
};

SendNotificationForm.propTypes = {
  isVisible: PropTypes.bool,
  setVisible: PropTypes.func,
  refresh: PropTypes.func,
};
SendNotificationForm.defaultProps = {
  isVisible: false,
  setVisible: () => {},
  refresh: () => {},
};
export default SendNotificationForm;
