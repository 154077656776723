import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { parse } from 'qs';
import { Form, message } from 'antd';
import { useEditUser } from 'redux/Hooks/user';
import { pick, pickBy } from 'lodash';
import PropTypes from 'prop-types';
import { USER_TYPES } from 'configs/Constants';
import UserForm from '../UserForm';

const Edit = ({ userType, refresh }) => {
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();

  const parsedQuery = parse(location.search, { ignoreQueryPrefix: true });
  const action = parsedQuery?.action || 'edit';
  const id = parsedQuery?.id;

  const {
    data,
    setData,
    prevFetching: prevIsFetching,
    isFetching,
    success,
    message: successResponse,
    onSave,
  } = useEditUser(id);

  React.useEffect(() => {
    if (isFetching !== prevIsFetching && prevIsFetching) {
      if (success) {
        message.success('実行完了');
        refresh();
        history.push({
          pathname: location.pathname,
        });
      } else {
        message.error('エラー');
        console.error(successResponse?.message);
      }
    }
  }, [isFetching]);

  const onSubmitEdit = () => {
    form.validateFields().then((values) => {
      const input = pickBy(
        pick(
          { ...values, ...{ userType } },
          userType === USER_TYPES.USER
            ? [
                'userType',
                'username',
                'birthday',
                'email',
                'introduction',
                'nickname',
                'title',
                'gender',
                'password',
                'fullName',
                'location',
                'title',
                'status',
                'isPremium',
                'ease_of_speaking',
                'looks',
                'tenshion',
                'leadership',
                'muscle_mass',
                'freshness',
                'weight',
                'height',
                'avatar',
                'avatars',
              ]
            : [
                'userType',
                'username',
                'birthday',
                'email',
                'introduction',
                'nickname',
                'title',
                'gender',
                'password',
                'fullName',
                'location',
                'title',
                'status',
                'isPremium',
                'ease_of_speaking',
                'looks',
                'tenshion',
                'leadership',
                'muscle_mass',
                'freshness',
                'weight',
                'height',
                'avatar',
                'avatars',
                'businessId',
              ]
        ),
        (value) => value === false || value
      );
      const newData = {
        ...input,
      };
      onSave(newData, id);
    });
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <>
      <UserForm
        form={form}
        action={action}
        item={data}
        loading={!data?.id}
        userType={userType}
        onSubmit={onSubmitEdit}
        goBack={handleBack}
        setData={setData}
        refresh={refresh}
      />
    </>
  );
};

Edit.propTypes = {
  userType: PropTypes.string,
  refresh: PropTypes.func,
};
export default Edit;
