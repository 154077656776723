import { API } from 'configs';
import { usePaging, useAddEdit, useDelete } from 'utils/AxiosHooks';

const useTrainingType = (fetchOnMount = false) => usePaging(API.ADMIN.TRAINING_TYPE.LIST, fetchOnMount);

const useEditTrainingType = (id) =>
  useAddEdit(id ? API.ADMIN.TRAINING_TYPE.EDIT.replace(':id', id) : API.ADMIN.TRAINING_TYPE.EDIT, 'edit');
const useAddTrainingType = () => useAddEdit(API.ADMIN.TRAINING_TYPE.ADD, 'add');
const useDeleteTrainingType = (onSuccess, onFail) => useDelete(API.ADMIN.TRAINING_TYPE.EDIT, onSuccess, onFail);

export { useTrainingType, useEditTrainingType, useAddTrainingType, useDeleteTrainingType };
