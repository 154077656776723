import React from 'react';
import { Form, Input, Checkbox, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const FormItem = Form.Item;

const PaymentRequestSearchForm = ({ onSubmit }) => {
  const [form] = Form.useForm();

  const targetOptions = [
    { label: 'ドラフト', value: 'draft' },
    { label: '公開する', value: 'publish' },
  ];

  const onFinish = (values) => {
    const filter = {};
    filter.status = Array.isArray(values.status) && values?.status?.length ? values.status?.join(',') : '';
    filter.search = values.username;
    onSubmit(filter);
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <div className="flex flex-row justify-between">
        <FormItem name="status">
          <Checkbox.Group options={targetOptions} />
        </FormItem>
        <FormItem name="username" className="w-1/2">
          <Input placeholder="タイトル、詳細" />
        </FormItem>
        <Form.Item shouldUpdate>
          {() => (
            <Button icon={<SearchOutlined />} htmlType="submit" type="ghost">
              検索
            </Button>
          )}
        </Form.Item>
      </div>
    </Form>
  );
};

PaymentRequestSearchForm.propTypes = {
  onSubmit: PropTypes.func,
};
export default PaymentRequestSearchForm;
