import * as React from 'react';
import { IntlProvider } from 'react-intl';

// Translated messages in French with matching IDs to what you declared
const messagesInJap = {
  'menu.home': 'Home',
  'menu.dashboard': 'Dashboard',
};

const IntlPro = ({ children }) => {
  return (
    <IntlProvider messages={messagesInJap} locale="ja" defaultLocale="en">
      {children}
    </IntlProvider>
  );
};

export default IntlPro;
