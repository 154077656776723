import dayjs from 'dayjs';
import { RECOIL_KEY } from 'utils/constant';

const formatDayjs = (value, mode = 'date') => {
  if (value) {
    return dayjs(value).format(mode === 'date' ? 'YYYY-MM-DD' : 'HH:mm');
  }
  return null;
};

const getObjectCheck = (obj) =>
  Object.keys(obj)
    .filter((key) => obj[key])
    .join(',');

const toObjectKeys = (data = []) => {
  const result = {};
  // eslint-disable-next-line array-callback-return
  data.map((item) => {
    result[item?.id] = true;
  });
  return result;
};

const getToken = () => {
  try {
    const auth = window.localStorage.getItem(RECOIL_KEY.AUTH);
    return auth ? JSON.parse(auth)?.token : '';
  } catch (error) {
    return '';
  }
};

const getLatestUnique = (firstArray, secondArray, key = 'updated') => {
  const result = {};
  const filter = (item) => {
    if (result[item.id]) {
      if (result[item.id][key] <= item[key]) {
        result[item.id] = item;
      }
    } else {
      result[item.id] = item;
    }
  };
  firstArray.forEach(filter);
  secondArray.forEach(filter);
  return Object.values(result).sort((a, b) => (a[key] < b[key] ? 1 : -1));
};
export { formatDayjs, getObjectCheck, toObjectKeys, getToken, getLatestUnique };
