import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Empty, Modal, Avatar, Typography } from 'antd';
import { useInquiryDetailByUserId } from 'redux/Hooks/help';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { UserOutlined } from '@ant-design/icons';

const InquiryBox = ({ id }) => {
  const [{ data }, doRequestInquiry] = useInquiryDetailByUserId(id);
  const [isOpenModal, setisOpenModal] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const emptyInquiry = (
    <>
      <div className="font-bold mb-1">
        <Typography.Title level={5}>お問い合わせ履歴</Typography.Title>
      </div>
      <div className="flex justify-center h-full items-center w-full">
        <Empty />
      </div>
    </>
  );
  const renderMedia = (media) => {
    const url = media?.link;
    if (!url) return <div />;
    return <img src={url} width="200" alt="" />;
  };

  useEffect(() => {
    if (id) doRequestInquiry();
  }, [id]);

  if (!id || isEmpty(data?.results)) return emptyInquiry;

  return (
    <div className="text-black overflow-y-scroll h-full w-full absolute">
      <div className="font-bold mb-1">
        <Typography.Title level={5}>お問い合わせ履歴</Typography.Title>
      </div>
      {data?.results.map((item) => (
        <div
          key={item?.id}
          className="border-b-2 border-black py-2"
          onClick={() => {
            setisOpenModal(true);
            setCurrentItem(item);
          }}
        >
          <div className="font-bold">#{item?.id}</div>
          <div>{moment.utc(item?.updated).local().format('YYYY/MM/DD HH:mm')}</div>
          <div className="mt-2 mb-1">{item?.content}</div>
          {renderMedia(item?.media)}
        </div>
      ))}
      {isOpenModal && (
        <Modal
          visible={isOpenModal}
          onCancel={() => setisOpenModal(false)}
          footer={null}
          destroyOnClose
          width="80vw"
          style={{ maxWidth: '800px' }}
        >
          <div style={{ height: '75vh' }}>
            <div className="text-lg bg-gray-200 px-4 py-1" style={{ width: 'fit-content' }}>
              #{currentItem?.id}
            </div>
            <div className="flex mt-3">
              <Avatar
                className="flex justify-center flex-none items-center"
                src={currentItem?.creator?.avatar}
                size={70}
                icon={<UserOutlined />}
              />
              <div className="ml-3 w-full">
                <div className="flex">
                  <div className="font-bold mr-3 text-black text-lg">{currentItem?.creator?.fullName}</div>
                  <div>{moment.utc(currentItem?.updated).local().format('YYYY/MM/DD HH:mm')}</div>
                </div>
                <div className="bg-gray-200 p-3 mt-3">
                  <div className="mb-3">{currentItem?.content}</div>
                  {renderMedia(currentItem?.media)}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

InquiryBox.propTypes = {
  id: PropTypes.string,
};

export default InquiryBox;
