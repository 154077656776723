import React from 'react';
import { Input, Button, DatePicker, Form } from 'antd';
import PropTypes from 'prop-types';
import { SearchOutlined } from '@ant-design/icons';
import { pick, pickBy } from 'lodash';
import moment from 'moment';

const FIELD_SEARCH = {
  SEARCH: 'search',
  START: 'start',
  END: 'end',
};
const SearchMessage = ({ setSearchForm }) => {
  const submitSearch = (values) => {
    const inputSearch = pickBy(pick(values, Object.values(FIELD_SEARCH)), (value) => value);
    if (inputSearch[FIELD_SEARCH.START]) {
      inputSearch[FIELD_SEARCH.START] = moment(values[FIELD_SEARCH.START]).utc().startOf('day');
    }
    if (inputSearch[FIELD_SEARCH.END]) {
      inputSearch[FIELD_SEARCH.END] = moment(values[FIELD_SEARCH.END]).utc().endOf('day');
    }

    setSearchForm(inputSearch);
  };
  return (
    <div>
      <Form className="flex" onFinish={submitSearch}>
        <div className="flex-grow formControl mt-4">
          <div className="flex justify-between ">
            <Form.Item className="w-4/12 rounded" name={FIELD_SEARCH.SEARCH}>
              <Input placeholder="メッセージ内容" allowClear className="rounded" />
            </Form.Item>
            <div className="flex items-center">
              <Form.Item className="rounded mr-1" name={FIELD_SEARCH.START}>
                <DatePicker size="middle" placeholder="開始日付" format="YYYY/MM/DD" allowClear />
              </Form.Item>
              <Form.Item name={FIELD_SEARCH.END} className="rounded ml-1">
                <DatePicker size="middle" placeholder="終了日付" format="YYYY/MM/DD" allowClear />
              </Form.Item>
            </div>
            <Button
              htmlType="submit"
              style={{ background: '#C3DAFF', color: '#3B86FF', borderColor: '#3B86FF', width: '150px' }}
              className="rounded font-bold border border-solid flex justify-center items-center"
              icon={<SearchOutlined />}
            >
              検索
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

SearchMessage.propTypes = {
  setSearchForm: PropTypes.func,
};
export default SearchMessage;
