import React from 'react';
import { Statistic, Card, Skeleton, Button } from 'antd';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const AlertItem = ({ loading, value, path, title }) => {
  const history = useHistory();
  const handleRoute = () => {
    history.push(path);
  };

  return (
    <div className="flex flex-col flex-1 mx-2">
      <Card className="" style={{ flexBasis: '100%' }}>
        {loading ? (
          <Skeleton paragraph={{ rows: 1 }} loading={loading} />
        ) : (
          <div className="flex flex-col">
            <Statistic title={title} value={value} valueStyle={{ fontWeight: 600 }} />
            {value > 0 && (
              <Button
                // eslint-disable-next-line max-len
                className="px-2 border border-solid border-blue-primary bg-blue-primary text-white text-xs self-end"
                onClick={() => handleRoute()}
              >
                もっと見る
              </Button>
            )}
          </div>
        )}
      </Card>
    </div>
  );
};
AlertItem.propTypes = {
  loading: PropTypes.bool,
  value: PropTypes.number,
  path: PropTypes.string,
  title: PropTypes.string,
};

export default AlertItem;
