import React from 'react';
import { Select } from 'antd';
import { useGetBanks } from 'redux/Hooks/bank';
import PropTypes from 'prop-types';

const BankSelect = (props) => {
  const { onSelect, form, valueprop } = props;
  const { results: banksList, isFetching: isFetchingBankList, isRefreshing: isRefreshingBankList } = useGetBanks(
    true,
    2000
  );
  return (
    <Select
      placeholder="金融機関名"
      showSearch
      allowClear
      loading={isFetchingBankList || isRefreshingBankList}
      options={banksList?.map((bank) => ({ label: bank?.bankName, value: bank?.[valueprop] }))}
      onSelect={(value, option) => {
        if (onSelect) {
          onSelect(value, option);
        }
        if (form.setFields) {
          const currentBank = banksList?.find((bank) => bank?.[valueprop] === value);
          form.setFields([{ name: 'currentBank', value: currentBank }]);
        }
      }}
      {...props}
    />
  );
};

BankSelect.propTypes = {
  onSelect: PropTypes.objectOf(PropTypes.func),
  form: PropTypes.objectOf(PropTypes.any),
  valueprop: PropTypes.string,
};

BankSelect.defaultProps = {
  valueprop: 'bankName',
};

export default BankSelect;
