/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Layout, Space, Button, Table, Modal, message, Input, Image, Form } from 'antd';
import { EditOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { LexoRank } from 'lexorank';
import qs from 'qs';
import { omit, pickBy, pick, identity } from 'lodash';

import {
  useTrainingType,
  useAddTrainingType,
  useEditTrainingType,
  useDeleteTrainingType,
} from 'redux/Hooks/trainingType';
import EditModal from 'components/EditModal';
import UploadImageFormItem from 'components/UploadImageInput';

import styles from './styles.module.css';

const TrainingType = (props) => {
  const { location } = props;
  const fileEditRef = useRef();
  const [formEdit] = Form.useForm();
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [editSelected, setEditSelected] = useState(null);
  const [imageRequireAdd, setImageRequireAdd] = useState(true);
  const [searchFilter, setSearchFilter] = useState({});
  const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [page, setPage] = useState(parsedQuery?.page ? parseInt(parsedQuery?.page, 10) - 1 : 0);

  const { results, refresh, currentPage = 0, totalPages = 0, isFetching: loading, setQuery } = useTrainingType();

  const { prevFetching: prevIsFetching, isFetching, success, message: successResponse, onSave } = useEditTrainingType(
    editSelected?.id
  );
  const {
    prevFetching: prevIsFetchingAdd,
    isFetching: isFetchingAdd,
    success: successAdd,
    message: errorResponse,
    onSave: onAdd,
  } = useAddTrainingType();

  const { onDelete } = useDeleteTrainingType(
    () => {
      message.success('実行完了');
      refresh();
    },
    () => message.error('このトレーニング種類は利用されているので削除できません。')
  );

  // eslint-disable-next-line no-unused-vars
  const [modal, contextHolder] = Modal.useModal({});

  useEffect(() => {
    setQuery({
      ...searchFilter,
      pageSize: 30,
      page,
    });
  }, [page, searchFilter]);

  useEffect(() => {
    if (isFetching !== prevIsFetching && prevIsFetching) {
      if (success) {
        message.success('実行完了');
        refresh();
      } else {
        message.error('エラー');
        console.error(successResponse?.message);
      }
    }
  }, [isFetching]);

  useEffect(() => {
    if (isFetchingAdd !== prevIsFetchingAdd && prevIsFetchingAdd) {
      if (successAdd) {
        message.success('実行完了');
        refresh();
      } else {
        message.error('エラー');
        console.error(errorResponse?.message);
      }
    }
  }, [isFetchingAdd]);

  const dataSource = results || [];

  const handleChangeOrder = async (record, nextRank) => {
    const formData = new FormData();
    formData.append('ordering', nextRank);

    onSave(formData, record.id);
  };

  const handleFinish = async (values) => {
    const inputValues = pickBy(values, identity);
    const inputContent = pick(inputValues, ['content']);

    const filters = {};

    if (inputContent?.content) {
      filters.search = inputContent?.content;
    }
    setSearchFilter(filters);
  };

  const onChangeItem = async (values, recordId) => {
    try {
      const formData = new FormData();
      for (const [key, value] of Object.entries(omit(values, ['image']))) {
        formData.append(key, value);
      }
      if (fileEditRef?.current?.files?.[0]) {
        formData.append('file', fileEditRef?.current?.files?.[0]);
      }
      if (recordId) {
        onSave(formData, values?.id);
      } else {
        onAdd(formData);
      }
    } catch (createError) {
      message.error('エラー');
    } finally {
      setVisibleEdit(false);
      setEditSelected(null);
    }
  };

  const onOkDelete = async (close, id) => {
    onDelete(id);
    close();
  };

  const onCancel = (close) => {
    close();
  };

  const modalDeleteConfig = (id) => {
    return {
      title: '確認',
      content: <h1>削除しますか？</h1>,
      onOk: (close) => onOkDelete(close, id),
      onCancel,
      okText: '削除',
      okType: 'primary',
      cancelText: 'キャンセル',
      icon: null,
      mask: true,
      maskClosable: true,
      keyboard: true,
      autoFocusButton: 'ok',
    };
  };

  const _renderAction = (record) => (
    <Space size="middle">
      <Button
        onClick={() => {
          setVisibleEdit(true);
          setEditSelected(record);
        }}
        icon={<EditOutlined />}
      />
      <Button
        onClick={() => {
          modal.confirm(modalDeleteConfig(record?.id));
        }}
        icon={<CloseOutlined />}
      />
    </Space>
  );

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
    },
    {
      title: <p className="text-center whitespace-no-wrap">トレーニング名</p>,
      dataIndex: 'name',
      key: 'name',
      width: '15%',
    },
    {
      title: <p className="text-center whitespace-no-wrap">コード名</p>,
      dataIndex: 'code',
      key: 'code',
      width: '15%',
    },
    {
      title: <p className="text-center whitespace-no-wrap">内容</p>,
      dataIndex: 'description',
      width: '25%',
      render: (value) => <div className="">{value || ''}</div>,
    },
    {
      title: <p className="text-center whitespace-no-wrap">画像</p>,
      dataIndex: 'image',
      key: 'image',
      width: '25%',
      render: (record) => {
        return (
          <div className="flex justify-center items-center">
            <Image
              className="flex justify-center items-center object-contain"
              width="4rem"
              height="4rem"
              src={record}
            />
          </div>
        );
      },
    },
    {
      title: '',
      key: 'action',
      width: '10%',
      render: _renderAction,
    },
  ];

  const editInputs = [
    {
      title: 'トレーニング名',
      key: 'name',
      name: ['name'],
      InputComponent: Input,
      inputProps: {},
      formItemProps: {
        rules: [{ required: true }],
      },
    },
    {
      title: 'コード名',
      key: 'code',
      name: ['code'],
      InputComponent: Input,
      inputProps: {},
      formItemProps: {
        rules: [{ required: true }],
      },
    },
    {
      title: '内容',
      key: 'description',
      name: ['description'],
      InputComponent: Input.TextArea,
      inputProps: {
        style: { backgroundColor: 'rgba(0, 0, 0, 0.05)' },
        bordered: false,
        rows: 4,
        autoSize: { minRows: 1, maxRows: 6 },
      },
      formItemProps: {
        rules: [{ required: true }],
      },
    },
    {
      title: '画像',
      key: 'image',
      name: ['image'],
      InputComponent: UploadImageFormItem,
      inputProps: {
        key: 'image',
        name: 'image',
        className: 'flex flex-col flex-grow items-center justify-center',
        classNameImage: '',
        size: '4rem',
        customUploadProps: {
          isImageUrl: true,
        },
        ref: fileEditRef,
        onChange: () => {
          setImageRequireAdd(false);
        },
      },
      formItemProps: {
        rules: [{ required: !editSelected?.id || imageRequireAdd }],
      },
    },
  ];

  const DragableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
    const ref = useRef();
    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: 'DragableBodyRow',
      collect: (monitor) => {
        const { index: dragIndex } = monitor.getItem() || {};
        if (dragIndex === index) {
          return {};
        }
        return {
          isOver: monitor.isOver(),
          dropClassName: dragIndex < index ? styles.dropDownward : styles.dropUpward,
        };
      },
      drop: (item) => {
        moveRow(item.index, index);
      },
    });
    const [, drag] = useDrag({
      type: 'DragableBodyRow',
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
    drop(drag(ref));

    return (
      <tr
        ref={ref}
        className={`${className} ${isOver ? dropClassName : ''}`}
        style={{ cursor: 'move', ...style }}
        {...restProps}
      />
    );
  };

  const handleChangePage = (pageNumber) => {
    const query = new URLSearchParams(props.history.location.search);
    query.set('page', pageNumber);
    setPage(pageNumber - 1);
    props.history.push({
      pathname: props.location.pathname,
      search: query.toString(),
    });
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const record = dataSource[dragIndex];
      if (dragIndex !== hoverIndex) {
        let nextRank;
        if (hoverIndex === 0) {
          nextRank = LexoRank.parse(dataSource[0].ordering).genNext();
        } else if (hoverIndex === dataSource.length - 1) {
          nextRank = LexoRank.parse(dataSource[dataSource.length - 1].ordering).genPrev();
        } else {
          nextRank = LexoRank.parse(dataSource[hoverIndex - 1].ordering).between(
            LexoRank.parse(dataSource[hoverIndex + 1].ordering)
          );
        }
        handleChangeOrder(record, nextRank.toString());
      }
    },
    [dataSource]
  );

  const pagination = {
    showSizeChanger: false,
    showQuickJumper: true,
    onChange: handleChangePage,
    current: currentPage + 1,
    pageSize: 30,
    total: (totalPages || 0) * 30,
  };

  const renderTable = useMemo(
    () => (
      <Table
        loading={loading || isFetching}
        className="bg-white overflow-x-scroll"
        rowClassName="align-top whitespace-pre-wrap break-words fixBreakWordsJapan text-center"
        components={{
          header: {
            cell: ({ className, style, ...restProps }) => {
              return <th className={className} style={{ ...style, textAlign: 'center' }} {...restProps} />;
            },
          },
          body: { row: DragableBodyRow },
        }}
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={pagination}
        rowKey="id"
        onRow={(record, index) => ({
          index,
          moveRow,
        })}
      />
    ),
    [dataSource, loading, isFetching]
  );

  return (
    <Layout.Content className="flex flex-col p-4 space-y-4">
      <Form form={formEdit} layout="vertical" name="search" initialValues={{ content: '' }} onFinish={handleFinish}>
        <div className="flex py-4">
          <div className="flex flex-row flex-grow justify-between space-x-4">
            <div className="flex flex-col flex-grow space-y-4">
              <div className="flex flex-row">
                <Form.Item name="content" className="w-4/12">
                  <Input placeholder="検索内容を入力してください。" className=" " />
                </Form.Item>
              </div>
            </div>
            <div className="flex justify-end space-x-4">
              <Button icon={<SearchOutlined />} htmlType="submit" type="ghost">
                検索
              </Button>
              <Button onClick={() => setVisibleEdit(true)} type="primary">
                追加
              </Button>
            </div>
          </div>
        </div>
      </Form>
      <DndProvider backend={HTML5Backend}>{renderTable}</DndProvider>
      {contextHolder}
      {visibleEdit && (
        <EditModal
          visible={visibleEdit}
          onCreate={onChangeItem}
          onCancel={() => {
            setVisibleEdit(false);
            setEditSelected(null);
          }}
          record={editSelected}
          editInputs={editInputs}
          headerTitle="地域"
        />
      )}
    </Layout.Content>
  );
};

export default TrainingType;
