import { API } from 'configs';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { authState } from 'store/atom';
import { usePaging, useRequest } from 'utils/AxiosHooks';

const useMe = (mountParams, fetchOnMount = false) =>
  useRequest({
    url: API.ADMIN.ME.DETAIL,
    fetchOnMount,
    mountParams,
  });

const useSyncingMe = (fetchOnMount = false, timeInterval = 0) => {
  const [auth, setAuthState] = useRecoilState(authState);
  const { permissions = [] } = auth;
  const [_, doRequest] = useMe({
    success: (res) => {
      if (res?.success) {
        setAuthState((prevState) => ({
          ...prevState,
          ...res,
          permissions: res?.permissions || permissions,
        }));
      }
    },
  });

  const onSync = doRequest;

  useEffect(() => {
    let intervalID;
    if (fetchOnMount) {
      doRequest();
      if (timeInterval) {
        intervalID = setInterval(() => doRequest(), timeInterval);
      }
    }
    return () => intervalID && clearInterval(intervalID);
  }, []);

  return [auth, setAuthState, onSync];
};

export { useMe, useSyncingMe };
