import React from 'react';
import PropTypes from 'prop-types';
import AlertItem from './AlertItem';

const TrainerNeedVerify = ({ loading, data }) => (
  <AlertItem title="未認証トレーナー" value={data} loading={loading} path="/verify_trainer?todayVerify=1" />
);

TrainerNeedVerify.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.number,
};

export default TrainerNeedVerify;
