import React, { useRef } from 'react';
import ProLayout from '@ant-design/pro-layout';
import { Link } from 'react-router-dom';
// import { useIntl } from 'react-intl';
import { useScrollToTop } from 'utils/hooks/useScrollToTop';

import { Result, Button, Badge } from 'antd';
import Authorized from 'components/Authorized';
import GlobalHeader from 'components/GlobalHeader';
import GlobalFooter from 'components/GlobalFooter';
import { getAuthorityFromRouter } from 'utils/authority';
import { authState } from 'store/atom';
import { useRecoilValue } from 'recoil';
import { useSyncingMe } from 'redux/Hooks/me';

import {
  SmileOutlined,
  HeartOutlined,
  CrownOutlined,
  BarChartOutlined,
  MessageOutlined,
  UserOutlined,
  CalendarOutlined,
  SettingOutlined,
  NotificationOutlined,
  AimOutlined,
  PayCircleOutlined,
  WarningOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import styles from './userLayout.module.css';
import './basicLayout.css';

import logo from '../logo.svg';

const IconMap = {
  smile: <SmileOutlined />,
  heart: <HeartOutlined />,
  crown: <CrownOutlined />,
  'bar-chart': <BarChartOutlined />,
  message: <MessageOutlined />,
  user: <UserOutlined />,
  calendar: <CalendarOutlined />,
  setting: <SettingOutlined />,
  notification: <NotificationOutlined />,
  help: <QuestionCircleOutlined />,
  aim: <AimOutlined />,
  pay: <PayCircleOutlined />,
  warning: <WarningOutlined />,
};

const noMatch = (
  <Result
    status={403}
    title="403"
    subTitle="このウェブページにアクセスできません"
    extra={() => (
      <Button type="primary">
        <Link to="/user/login">ログイン</Link>
      </Button>
    )}
  />
);

/**
 * use Authorized check all menu item
 */

const menuDataRender = (menuList) =>
  menuList.map(({ icon, children, ...item }) => {
    const localItem = {
      ...item,
      icon: icon && IconMap[icon],
      children: children ? menuDataRender(children) : undefined,
    };
    // return Authorized.check(item.authority, localItem, null);
    return localItem;
  });

const defaultFooterDom = <GlobalFooter copyright={`${new Date().getFullYear()} HosuTore`} />;

const BasicLayout = (props) => {
  const {
    dispatch,
    children,
    settings,
    location = {
      pathname: '/',
    },
    route,
    history,
  } = props;

  const ref = useRef();
  useScrollToTop({ ref });
  const [auth] = useSyncingMe();
  const handleMenuCollapse = (payload) => {
    if (dispatch) {
      dispatch({
        type: 'global/changeLayoutCollapsed',
        payload,
      });
    }
  }; // get children authority

  const authorized = getAuthorityFromRouter(route.routes, location.pathname || '/') || {
    authority: undefined,
  };

  // const intl = useIntl();

  return (
    <ProLayout
      title=""
      logo={<img alt="logo" className={styles.logo} src={logo} />}
      navTheme="light"
      // formatMessage={intl.formatMessage}
      onCollapse={handleMenuCollapse}
      onMenuHeaderClick={() => history.push('/')}
      menuItemRender={(menuItemProps, defaultDom) => {
        if (menuItemProps.isUrl || !menuItemProps.path) {
          return defaultDom;
        }
        return (
          <Badge dot count={auth?.unread?.[menuItemProps?.unread] || 0}>
            <Link to={menuItemProps.path}>{defaultDom}</Link>
          </Badge>
        );
      }}
      breadcrumbRender={(routers = []) => [
        {
          path: '/',
          breadcrumbName: 'home',
        },
        ...routers,
      ]}
      itemRender={(r, params, routes, paths) => {
        const first = routes.indexOf(route) === 0;
        return first ? <Link to={paths.join('/')}>{r.breadcrumbName}</Link> : <span>{r.breadcrumbName}</span>;
      }}
      footerRender={() => defaultFooterDom}
      menuDataRender={menuDataRender}
      rightContentRender={() => <GlobalHeader />}
      {...props}
      {...settings}
    >
      <Authorized {...(authorized || {})} authority={authorized?.authority} noMatch={noMatch}>
        {children}
      </Authorized>
    </ProLayout>
  );
};

export default BasicLayout;
