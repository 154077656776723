import React, { useState, useEffect } from 'react';
import { stringify, parse } from 'qs';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, Card } from 'antd';
import moment from 'moment';
import numeral from 'numeral';

import { useGetUserTrainingHistory } from 'redux/Hooks/user';

const UserTrainingHistory = ({ userId, location, history }) => {
  const parsedQuery = parse(location.search, { ignoreQueryPrefix: true });
  const [page, setPage] = useState(Number(parsedQuery?.trainingHistoryPage) - 1 || 0);

  const { results, isFetching, totalPages, currentPage, setQuery, pageSize } = useGetUserTrainingHistory(userId);

  useEffect(() => {
    setQuery({
      page,
    });
  }, [page]);

  const columns = [
    {
      title: '日時',
      dataIndex: 'updated',
      key: 'updated',
      render: (record) => <span>{moment.utc(record).local().format('YYYY/MM/DD HH:mm')}</span>,
    },
    {
      title: 'トレーニング名',
      render: (item) => (
        <Link to={`/lesson?action=edit&id=${item?.id}`}>
          <span className="hover:underline hover:text-blue-primary">{item?.title}</span>
        </Link>
      ),
    },
    {
      title: '種類',
      dataIndex: 'trainingType',
      key: 'trainingType',
      render: (item) => <span>{item.name}</span>,
    },
    {
      title: 'ポイント',
      dataIndex: 'price',
      key: 'price',
      render: (item) => <span>¥{numeral(item).format('0,0')}</span>,
    },
  ];

  const handleChangePage = (pageNumber) => {
    const query = new URLSearchParams(history.location.search);
    const queryString = stringify({
      ...query,
      ...parsedQuery,
      trainingHistoryPage: pageNumber === 1 ? undefined : pageNumber,
    });

    setPage(pageNumber - 1);
    history.push({
      pathname: location.pathname,
      search: queryString,
    });
  };

  const pagination = {
    onChange: handleChangePage,
    current: currentPage + 1,
    pageSize,
    total: totalPages * pageSize,
    showSizeChanger: false,
    hideOnSinglePage: true,
  };

  return (
    <div className="flex flex-col my-4">
      <Card title="トレーニング履歴">
        <Table
          tableLayout="fixed"
          className="bg-white overflow-x-scroll"
          rowClassName="align-top whitespace-pre-wrap break-words fixBreakWordsJapan text-center"
          components={{
            header: {
              // eslint-disable-next-line react/prop-types
              cell: ({ className, style, ...restProps }) => {
                return <th className={className} style={{ ...style, textAlign: 'center' }} {...restProps} />;
              },
            },
          }}
          bordered
          pagination={pagination}
          dataSource={results}
          loading={isFetching}
          columns={columns}
          rowKey="id"
        />
      </Card>
    </div>
  );
};

UserTrainingHistory.propTypes = {
  userId: PropTypes.string,
  location: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
};

export default UserTrainingHistory;
