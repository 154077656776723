import React, { useState, useEffect } from 'react';
import omit from 'lodash/omit';
import { Layout, Form, Space, Button, Table, Modal, message, Typography } from 'antd';
import moment from 'moment';
import { useAddAdmin, useEditAdmin, useAdmins, useDeleteAdmin } from 'redux/Hooks/admin';

import { CloseOutlined, EditOutlined } from '@ant-design/icons';

import { useRoles } from 'redux/Hooks/role';
import AdminForm from './components/AdminForm';

const Admin = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedAdmin, selectAdmin] = useState(null);
  const [form] = Form.useForm();
  const [{ data: roles }] = useRoles();

  const { results, isFetchingList, refresh } = useAdmins();

  const { onSave } = useEditAdmin(
    () => {
      message.success('実行完了');
      refresh();
    },
    (error) => {
      message.error('エラー');
      console.error(error?.message);
    }
  );
  const { onSave: onAdd } = useAddAdmin(
    () => {
      message.success('実行完了');
      refresh();
    },
    (error) => {
      message.error('エラー');
      console.error(error?.message);
    }
  );

  const { onDelete } = useDeleteAdmin(
    () => {
      message.success('実行完了');
      refresh();
    },
    (error) => {
      message.error('エラー');
      console.error(error?.message);
    }
  );

  const onCancel = () => {
    setModalVisible(false);
    selectAdmin(null);
  };

  const handleSubmit = async (record) => {
    const isEdit = !!record;
    try {
      const fieldsValue = await form.validateFields();
      if (!isEdit) {
        onAdd(fieldsValue);
      } else {
        onSave(omit(fieldsValue, ['username', 'email']), selectedAdmin.id);
      }
      onCancel();
    } catch (updateErr) {
      if (updateErr?.errorFields) return null;
      Modal.error({
        title: 'エラー',
        content: '後でもう一度やり直してください',
      });
      console.error(updateErr?.message);
    }
  };

  const handleDeleteAdmin = (accountId) => {
    Modal.confirm({
      title: 'ユーザーを削除しますか。',
      content: 'このアクションは元に戻せません。',
      onOk: () => {
        onDelete(accountId);
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      width: '10%',
    },
    {
      title: '名前',
      dataIndex: 'username',
      width: '30%',
      render: (value) => <div className="">{value}</div>,
    },
    {
      title: 'メールアドレス',
      dataIndex: 'email',
      width: '30%',
      render: (value) => <div className="">{value}</div>,
    },
    {
      title: '役割',
      dataIndex: ['role', 'name'],
      width: '10%',
    },
    {
      title: <p className="text-center whitespace-no-wrap">最終ログイン日時</p>,
      dataIndex: 'lastActiveAt',
      width: '10%',
      render: (value) => (value ? <p>{moment(value).format('llll')}</p> : null),
    },
    {
      title: '',
      width: '10%',
      render: (val, record) => (
        <Space size="middle">
          <Button
            onClick={() => {
              selectAdmin(record);
              setModalVisible(true);
            }}
            icon={<EditOutlined />}
          />
          {record?.username !== 'admin' && (
            <Button
              onClick={() => {
                handleDeleteAdmin(record.id);
              }}
              icon={<CloseOutlined />}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <Layout.Content className="flex flex-col p-4">
      <Typography.Title level={3}>管理者管理</Typography.Title>
      <div className="flex flex-row justify-end py-4">
        <Space size="large">
          <Button onClick={() => setModalVisible(true)} type="primary">
            追加
          </Button>
        </Space>
      </div>
      <Table
        className="bg-white overflow-x-scroll"
        rowClassName="align-top whitespace-pre-wrap break-words fixBreakWordsJapan text-center"
        components={{
          header: {
            cell: ({ className, style, ...restProps }) => {
              return <th className={className} style={{ ...style, textAlign: 'center' }} {...restProps} />;
            },
          },
        }}
        bordered
        dataSource={results || []}
        columns={columns}
        rowKey="id"
        loading={isFetchingList}
      />
      {modalVisible && (
        <AdminForm
          form={form}
          data={selectedAdmin}
          roles={roles}
          onSubmit={handleSubmit}
          onCancel={onCancel}
          visible={modalVisible}
        />
      )}
    </Layout.Content>
  );
};

export default Admin;
