import { API } from 'configs';
import { usePaging, useAddEdit, useDelete } from 'utils/AxiosHooks';

const useFaq = () => usePaging(API.ADMIN.FAQ.LIST);

const useEditFaq = (id) => useAddEdit(API.ADMIN.FAQ.EDIT, 'edit');
const useAddFaq = (id) => useAddEdit(API.ADMIN.FAQ.ADD, 'add');
const useDeleteFaq = (onSuccess) => useDelete(API.ADMIN.FAQ.EDIT, onSuccess);

export { useFaq, useEditFaq, useAddFaq, useDeleteFaq };
