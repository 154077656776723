import React, { useState, useEffect } from 'react';
import qs from 'qs';
import PropTypes from 'prop-types';
import { useUsers, useGetUser } from 'redux/Hooks/user';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { API } from 'configs';
import Tabs from '../Tabs';
import View from '../View';

const FIELD_SEARCH = {
  USERNAME: 'username', //Email-Fullname
  USER_TYPE: 'userType',
  START_POINT: 'startPoint',
  END_POINT: 'endPoint',
  STATUS: 'status',
};

const ACTIONS = {
  CARD_INFO: 'cardInfo',
  PROFILE: 'profile',
  DETAIL: 'detail',
  EDIT: 'edit',
  VIEW: 'view',
  ADD: 'add',
};
const UserStandard = (props) => {
  const { location, userType } = props;
  const history = useHistory();

  const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
  const action = parsedQuery?.action || ACTIONS.VIEW;

  const { results, isFetching, currentPage, totalPages, refresh, setQuery } = useUsers();
  const [{ data: dataUser }, doRequest] = useGetUser(parsedQuery?.id);

  useEffect(() => {
    try {
      if (parsedQuery?.id) {
        doRequest({
          url: API.ADMIN.USER.EDIT.replace(':id', Number(parsedQuery?.id)),
          failure: () => {
            message.error('エラー');
            setTimeout(() => history.goBack(), 1000);
          },
        });
      }
    } catch (error) {
      message.error('エラー');
      setTimeout(() => history.goBack(), 1000);
    }
  }, [parsedQuery?.id]);

  const [searchForm, setSearchForm] = useState({});
  const [pageSize, setPageSize] = useState(30);
  const [page, setPage] = useState(Number(parsedQuery?.page) - 1 || 0);

  const filterMapping = () => {
    const filters = {};
    Object.keys(searchForm).forEach((field) => {
      const value = searchForm?.[field];
      if (value !== (undefined || null)) {
        switch (field) {
          case FIELD_SEARCH.START_POINT:
            filters.startPoint = value;
            break;
          case FIELD_SEARCH.END_POINT:
            filters.endPoint = value;
            break;
          case FIELD_SEARCH.USERNAME:
            filters.search = value;
            break;
          case FIELD_SEARCH.STATUS:
            filters.status = value;
            break;
          default:
          //
        }
      }
      return null;
    });
    return filters;
  };

  useEffect(() => {
    setQuery({
      ...filterMapping(),
      pageSize,
      page,
      userType,
    });
  }, [pageSize, searchForm, page]);

  const getCurrentItem = (actionRoute) => {
    switch (actionRoute) {
      case ACTIONS.CARD_INFO:
      case ACTIONS.PROFILE:
      case ACTIONS.DETAIL:
      case ACTIONS.EDIT: {
        return dataUser;
      }
      case ACTIONS.VIEW:
      default:
        return null;
    }
  };

  const item = getCurrentItem(action);
  switch (action) {
    case ACTIONS.CARD_INFO:
    case ACTIONS.PROFILE:
    case ACTIONS.DETAIL:
    case ACTIONS.EDIT:
      return <Tabs item={item} {...props} refresh={refresh} doGetUser={doRequest} />;
    case ACTIONS.ADD:
      return <Tabs {...props} refresh={refresh} />;
    case ACTIONS.VIEW:
    default:
      return (
        <View
          userType={userType}
          dataSource={results}
          currentPage={currentPage + 1}
          pageSize={pageSize}
          totalPage={totalPages}
          setPageSize={setPageSize}
          setPage={setPage}
          loading={isFetching}
          searchForm={searchForm}
          setSearchForm={setSearchForm}
          refresh={refresh}
          {...props}
        />
      );
  }
};

UserStandard.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  userType: PropTypes.string,
};

export default UserStandard;
