import React from 'react';
import { List } from 'antd';
import { stringify } from 'qs';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import ConversationItem from '../ConversationItem';

const ConversationList = ({ dataSource, isFetching, currentPage, totalPages, setPage, pageSize }) => {
  const history = useHistory();
  const location = useLocation();

  const handleChangePage = (pageNumber) => {
    const query = new URLSearchParams(history.location.search);
    const queryString = stringify({
      ...query,
      page: pageNumber === 1 ? undefined : pageNumber,
      id: undefined,
    });
    setPage(pageNumber - 1);
    history.push({
      pathname: location.pathname,
      search: queryString,
    });
  };

  const pagination = {
    onChange: handleChangePage,
    current: currentPage,
    pageSize,
    total: totalPages * pageSize,
    showSizeChanger: false,
    simple: true,
  };

  return (
    <div className="w-full h-full" style={{ maxWidth: '370px', minHeight: '780px' }}>
      <List
        className="h-full"
        itemLayout="vertical"
        pagination={pagination}
        dataSource={dataSource}
        loading={isFetching}
        renderItem={(item) => <ConversationItem item={item} />}
        rowKey="id"
      />
    </div>
  );
};

ConversationList.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.any),
  isFetching: PropTypes.bool,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  setPage: PropTypes.func,
  pageSize: PropTypes.number,
};

export default ConversationList;
