import React from 'react';
import { parse } from 'qs';
import PropTypes from 'prop-types';
import { Layout, Card, Divider, Empty, Typography } from 'antd';
import dayjs from 'dayjs';

import { useConversations } from 'redux/Hooks/conversation';
import { SearchMessage } from 'components/Message';

import ConversationList from './components/ConversationList';
import MessageBox from './components/MessageBox';

const DEFAULT_CONVERSATION_SIZE = 8;

const FIELD_SEARCH = {
  SEARCH: 'search',
  START: 'start',
  END: 'end',
};

const MessageList = (props) => {
  const { location } = props;
  const parsedQuery = parse(location.search, { ignoreQueryPrefix: true });
  const { results, isFetching, currentPage, totalPages, setQuery } = useConversations();

  const [searchForm, setSearchForm] = React.useState({});
  const [page, setPage] = React.useState(
    parsedQuery?.page && Number(parsedQuery?.page) ? Number(parsedQuery?.page) - 1 : 0
  );

  const filterMappingConv = () => {
    const filters = {};
    Object.keys(searchForm).forEach((field) => {
      const value = searchForm?.[field];
      if (value !== (undefined || null)) {
        switch (field) {
          case FIELD_SEARCH.SEARCH:
            filters.search = value;
            break;
          case FIELD_SEARCH.START:
            filters.start = dayjs(value).startOf('day').format('YYYY/MM/DD HH:mm');
            break;
          case FIELD_SEARCH.END:
            filters.end = dayjs(value).endOf('day').format('YYYY/MM/DD HH:mm');
            break;
          default:
          //
        }
      }
      return null;
    });
    return filters;
  };

  React.useEffect(() => {
    setQuery({
      ...filterMappingConv(),
      pageSize: DEFAULT_CONVERSATION_SIZE,
      page,
    });
  }, [page]);
  React.useEffect(() => {
    setQuery({
      ...filterMappingConv(),
      pageSize: DEFAULT_CONVERSATION_SIZE,
      page: 0,
    });
  }, [searchForm]);

  return (
    <Layout className="flex flex-col p-4">
      <Typography.Title level={3}>メッセージ管理</Typography.Title>
      <Card bodyStyle={{ backgroundColor: 'white', flexWrap: 'wrap' }}>
        <div className="flex flex-col">
          <SearchMessage setSearchForm={setSearchForm} />

          <div className="flex flex-row items-stretch">
            <ConversationList
              dataSource={results}
              isFetching={isFetching}
              currentPage={currentPage + 1}
              pageSize={DEFAULT_CONVERSATION_SIZE}
              totalPages={totalPages}
              setPage={setPage}
            />
            <Divider type="vertical" orientation="center" style={{ height: 'auto' }} />
            {parsedQuery?.id ? (
              <MessageBox />
            ) : (
              <div className="flex justify-center items-center w-full">
                <Empty />
              </div>
            )}
          </div>
        </div>
      </Card>
    </Layout>
  );
};

MessageList.propTypes = {
  location: PropTypes.objectOf(PropTypes.any),
};
export default MessageList;
