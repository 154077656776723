import { API } from 'configs';
import { usePaging, useAddEdit, usePost, usePagingAppend, useRequest } from 'utils/AxiosHooks';

const usePaymentRequests = (DEFAULT_PAGE_SIZE) =>
  usePagingAppend(API.ADMIN.PAYMENT_REQUEST.LIST, true, DEFAULT_PAGE_SIZE);
const usePaymentRequestsCsv = () => usePaging(API.ADMIN.PAYMENT_REQUEST.CSV, true);
const useUpdatePayment = ({ onSuccess = () => {}, onFail = () => {} }) =>
  useAddEdit(API.ADMIN.PAYMENT_REQUEST.UPDATE, 'edit', onSuccess, onFail);
const useUpdateBulkPayment = () => {
  const [doPost, requestState] = usePost({ url: API.ADMIN.PAYMENT_REQUEST.UPDATE_BULK });

  const handleUpdate = (body, onSuccess, onError) => doPost({ body }, onSuccess, onError);
  return [handleUpdate, requestState];
};
const usePostExportCsv = () =>
  useRequest({
    url: API.ADMIN.PAYMENT_REQUEST.EXPORT_CSV,
    method: 'post',
    fetchOnMount: false,
  });

export { usePaymentRequests, useUpdatePayment, usePaymentRequestsCsv, useUpdateBulkPayment, usePostExportCsv };
