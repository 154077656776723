import React, { useEffect, useState } from 'react';
import { Table, Space, Button, message } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useEditUser, useUsers } from 'redux/Hooks/user';
import { CloseOutlined } from '@ant-design/icons';
import qs from 'qs';

const DEFAULT_PAGE_SIZE = 30;

const Trainer = (props) => {
  const location = useLocation();
  const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [pageSize, setPageSize] = useState(30);
  const [page, setPage] = useState(parsedQuery?.page ? parseInt(parsedQuery?.page, 10) - 1 : 0);

  const { results, isFetching, isRefreshing, currentPage, totalPages, refresh, setQuery, setFilter } = useUsers(false);
  const { onSave } = useEditUser(
    ':id',
    (res) => {
      if (res?.success) {
        refresh({
          businessId: props?.businessId,
          pageSize,
          page: 0,
        });
        message.success('削除しました');
      } else {
        message.error('ユーザーの削除が完了しました');
      }
    },
    (err) => {
      message.error('ユーザーの削除が完了しました');
    }
  );

  const handleChangePage = (pageParam, pageSizeParam) => {
    setQuery((prevQuery) => ({ ...prevQuery, page: pageParam - 1, pageSize: pageSizeParam }));
    setPage(pageParam - 1);
    setPageSize(pageSizeParam);
  };

  const onDelete = (id) => {
    onSave({ businessId: null }, id);
  };

  useEffect(() => {
    if (props?.refresh) {
      refresh();
    }
  }, [props?.refresh]);

  useEffect(() => {
    setQuery({
      businessId: props?.businessId,
      pageSize,
      page,
    });
  }, [pageSize, page, props?.businessId]);

  const pagination = {
    onChange: handleChangePage,
    current: currentPage + 1 || 1,
    pageSize: DEFAULT_PAGE_SIZE,
    total: totalPages * DEFAULT_PAGE_SIZE || 0,
  };

  const columns = [
    {
      title: <p className="text-center whitespace-no-wrap">#</p>,
      dataIndex: ['id'],
      key: 'id',
      className: '',
      render: (value) => <span>{value}</span>,
    },
    {
      title: <p className="text-center whitespace-no-wrap">フルネーム</p>,
      dataIndex: 'fullName',
      key: 'fullName',
      className: '',
      render: (value) => <div className="">{value || ''}</div>,
    },
    {
      title: <p className="text-center whitespace-no-wrap">Email</p>,
      dataIndex: 'email',
      key: 'email',
      className: '',
      render: (value) => <div className="">{value || ''}</div>,
    },
    {
      title: <p>&nbsp;</p>,
      dataIndex: 'id',
      className: 'flex flex-row justify-end',
      render: (id) => (
        <Space size="middle">
          <Button
            onClick={() => {
              onDelete(id);
            }}
            icon={<CloseOutlined />}
          />
        </Space>
      ),
    },
  ];

  return (
    <Table
      className="bg-white overflow-x-scroll"
      rowClassName="align-top whitespace-pre-wrap break-words fixBreakWordsJapan text-center"
      components={{
        header: {
          cell: ({ className, style, ...restProps }) => {
            return <th className={className} style={{ ...style, textAlign: 'center' }} {...restProps} />;
          },
        },
      }}
      bordered
      loading={isFetching || isRefreshing}
      dataSource={results}
      columns={columns}
      pagination={pagination}
      rowKey="id"
    />
  );
};

export default Trainer;
