// Imports
import ky from 'ky';
import { RECOIL_KEY } from 'utils/constant';

const { REACT_APP_API_URI } = process.env;

let api = ky.create({ prefixUrl: REACT_APP_API_URI });

// login
export const login = (username, password) => {
  return api
    .post('api/v1/auth/admin', {
      headers: { 'content-type': 'application/json' },
      json: { username, password },
    })
    .json();
};

// forgot password
export const forgot = (username) => {
  return api
    .post('api/v1/auth/admin_forget_password', {
      headers: { 'content-type': 'application/json' },
      json: { username },
    })
    .json();
};

// recover password
export const recover = (token, password) => {
  return api
    .post('api/v1/auth/admin_reset_password', {
      headers: { 'content-type': 'application/json' },
      json: { token, password },
    })
    .json();
};

// login set localStorage
export const loginSet = (auth) => {
  // HTTP header
  api = api.extend({
    headers: {
      Authorization: `Bearer ${auth.token}`,
    },
  });

  // localStorage values
  window.localStorage.setItem(RECOIL_KEY.AUTH, JSON.stringify(auth));
};

// logout remove localStorage
export const logoutUnset = () => {
  // HTTP header
  api = api.extend({
    headers: {
      Authorization: undefined,
    },
  });

  // localStorage values
  window.localStorage.removeItem(RECOIL_KEY.AUTH);
  window.location.href = '/';
};
