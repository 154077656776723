import React from 'react';
import {} from 'antd';
import {} from 'redux/Hooks/transaction';
import UnverifyTrainersTable from './UnverifyTrainersTable';

const TrainersUnverifyRegion = () => {
  return (
    <div className="bg-white p-6 relative my-6">
      <div className="flex items-center justify-between mb-4">
        <div className="py-2 font-semibold">新規トレーナー</div>
      </div>
      <UnverifyTrainersTable />
    </div>
  );
};

export default TrainersUnverifyRegion;
