import React, { useEffect } from 'react';
import { Table, Avatar, Button, Switch } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useHistory, Link } from 'react-router-dom';
import { useUsers } from 'redux/Hooks/user';

const FIELD = {
  AVATAR: {
    title: '',
    name: 'avatar',
  },
  FULLNAME: {
    title: 'フルネーム',
    name: 'fullName',
  },

  CREATED: {
    title: '登録日',
    name: 'created',
  },
};

const UnverifyTrainersTable = () => {
  const history = useHistory();

  const { results, isFetchingList, setQuery } = useUsers();
  useEffect(() => {
    setQuery({
      isAvailable: false,
    });
  }, []);

  const columns = [
    {
      title: '#',
      className: 'w-1/12',
      render: (value, item, index) => <span>{index + 1}</span>,
    },
    {
      render: ({ id, [FIELD.AVATAR.name]: avt, userType }) => (
        <Button
          type="text"
          size="small"
          className="bg-transparent"
          onClick={() => {
            const query = new URLSearchParams(history.location.search);
            query.set('action', 'edit');
            query.set('id', id);
            history.push({
              pathname: `/${userType === 'training' ? 'user' : 'trainer'}`,
              search: query.toString(),
            });
          }}
          block
          icon={<Avatar className="flex justify-center items-center" src={avt} icon={<UserOutlined />} />}
        />
      ),
    },
    {
      title: <p className="text-center whitespace-no-wrap">{FIELD.FULLNAME.title}</p>,
      key: FIELD.FULLNAME.name,
      render: ({ id, [FIELD.FULLNAME.name]: name, userType }) => (
        <Link to={`/${userType === 'training' ? 'user' : 'trainer'}?action=edit&id=${id}`}>
          <span className="">{name}</span>
        </Link>
      ),
    },
    {
      title: <p className="text-center whitespace-no-wrap">{FIELD.CREATED.title}</p>,
      dataIndex: FIELD.CREATED.name,
      key: FIELD.CREATED.name,
      render: (value) => <span>{moment.utc(value).local().format('llll')}</span>,
    },
  ];
  return (
    <Table
      className="bg-white overflow-x-scroll"
      rowClassName="align-top whitespace-pre-wrap break-words fixBreakWordsJapan text-center"
      components={{
        header: {
          cell: ({ className, style, ...restProps }) => {
            return <th className={className} style={{ ...style, textAlign: 'center' }} {...restProps} />;
          },
        },
      }}
      bordered
      columns={columns}
      dataSource={results || []}
      loading={isFetchingList}
      rowKey="id"
      pagination={false}
    />
  );
};

UnverifyTrainersTable.propTypes = {};

export default UnverifyTrainersTable;
