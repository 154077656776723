import React from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import PropTypes from 'prop-types';

const ButtonControl = ({ children, onClick, disabled = false }) => {
  return (
    <button
      className="w-10 h-10 flex border border-solid justify-center items-center rounded-md shadow-md cursor-pointer focus:none"
      style={{ borderColor: '#E8E9EC' }}
      onClick={onClick}
      type="button"
      disabled={disabled}
    >
      {children}
    </button>
  );
};
ButtonControl.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

const CustomMonthControl = ({ timeSelect, setTimeSelect, isMonthControl, disabled }) => {
  const now = moment().format();
  const TIME_TYPE = isMonthControl ? 'month' : 'year';

  const onNext = () => {
    const nextTime = moment(timeSelect).add(1, TIME_TYPE).format();
    if (moment(nextTime).diff(moment(now), TIME_TYPE, true) <= 0) {
      setTimeSelect(nextTime);
    }
  };

  const onPrevious = () => {
    setTimeSelect(moment(timeSelect).subtract(1, TIME_TYPE).format());
  };

  const renderTime = () =>
    isMonthControl ? moment(timeSelect).format('YYYY年MM月') : moment(timeSelect).format('YYYY');

  return (
    <div className="flex flex-row mx-6">
      <ButtonControl onClick={() => onPrevious()} disabled={disabled}>
        <LeftOutlined color="#3B86FF" />
      </ButtonControl>
      <div className="font-bold flex justify-center items-center px-4 " style={{ color: '#3B86FF', minWidth: '150px' }}>
        {renderTime()}
      </div>
      <ButtonControl onClick={() => onNext()} disabled={disabled}>
        <RightOutlined color="#3B86FF" />
      </ButtonControl>
    </div>
  );
};

CustomMonthControl.propTypes = {
  timeSelect: PropTypes.string,
  isMonthControl: PropTypes.bool,
  disabled: PropTypes.bool,
  setTimeSelect: PropTypes.func,
};

export default CustomMonthControl;
