import React from 'react';
import { Tree, Avatar } from 'antd';
import { SmileOutlined, LinkOutlined } from '@ant-design/icons';
import { omit } from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { SCHEDULE_STATUS_JP } from 'configs/Constants';

const Schedules = ({ value = [] }) => {
  const renderValue = (dataValue) => {
    switch (typeof dataValue) {
      case 'string': {
        if (moment(dataValue).isValid()) {
          return moment(dataValue).format('LLL');
        }
        return dataValue;
      }
      default:
        return dataValue;
    }
  };
  const getTreeJoined = (item) => {
    const treeJoined = item?.users?.map((joined) => {
      const exstractUser = {
        ...omit(joined, ['id', 'user']),
        ...omit(joined?.user, ['avatar']),
      };

      return {
        title: (
          <p className="flex flex-row whitespace-nowrap items-center">
            <Avatar
              className="flex justify-center items-center "
              style={{ minWidth: 25 }}
              src={joined?.user?.avatar}
              size="small"
              icon={<SmileOutlined />}
            />
            <p className="truncate" style={{ maxWidth: '15vw', minWidth: '10vw' }}>
              {exstractUser?.fullName}
            </p>

            <Link
              to={`/${exstractUser?.userType === 'training' ? 'user' : 'trainer'}?action=edit&id=${exstractUser?.id}`}
              className="px-2 flex items-center jestify-center"
            >
              <LinkOutlined />
            </Link>
          </p>
        ),
        key: joined?.id,
        // eslint-disable-next-line no-shadow
        children: Object.entries(exstractUser).map(([key, value]) => ({
          title: (
            <p className="space-x-4 w-full align-top whitespace-pre-wrap break-words fixBreakWords">
              <span className="uppercase">{key}</span>:&nbsp;{renderValue(value)}
            </p>
          ),
          key: `${joined?.id}-${key}`,
        })),
      };
    });
    return treeJoined;
  };

  return (
    <div className="overflow-x-scroll">
      <table className="table-auto min-w-full divide-y divide-gray-200">
        <thead>
          <tr className="uppercase border text-center">
            <th scope="col" className="px-2 py-2 border">
              ID
            </th>
            <th scope="col" className="px-2 py-2 border">
              レッスンID
            </th>
            <th scope="col" className="px-2 py-2 border">
              開始日
            </th>
            <th scope="col" className="px-2 py-2 border">
              開始時刻
            </th>
            <th scope="col" className="px-2 py-2 border">
              終了時刻
            </th>
            <th scope="col" className="px-2 py-2 border">
              作成
            </th>
            <th scope="col" className="px-2 py-2 border">
              アップデート
            </th>
            <th scope="col" className="px-2 py-2 border">
              ステータス
            </th>
            <th scope="col" className="px-2 py-2 border">
              レッスンリクエストID
            </th>
            <th scope="col" className="px-2 py-2 border">
              ユーザー数
            </th>
            <th scope="col" className="px-2 py-2 border">
              参加ユーザー
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {Array.isArray(value) &&
            value.length > 0 &&
            value.map((item) => (
              <tr key={item?.id}>
                <td className="border px-4 py-2 align-text-top">{item.id}</td>
                <td className="border px-4 py-2 align-text-top">{item.lessonId}</td>
                <td className="border px-4 py-2 align-text-top">
                  {item?.startDate ? moment.utc(item?.startDate).local().format('DD/MM/Y') : ''}
                </td>
                <td className="border px-4 py-2 align-text-top">
                  {item?.startTime && item?.startDate
                    ? moment
                        .utc(`${moment.utc(item?.startDate).format('Y/MM/DD')} ${item.startTime}`)
                        .local()
                        .format('HH:mm:ss')
                    : ''}
                </td>
                <td className="border px-4 py-2 align-text-top">
                  {item?.endTime && item?.startDate
                    ? moment
                        .utc(`${moment.utc(item?.startDate).format('Y/MM/DD')} ${item.endTime}`)
                        .local()
                        .format('HH:mm:ss')
                    : ''}
                </td>
                <td className="border px-4 py-2 align-text-top">
                  {item?.created ? moment.utc(item.created).local().format('llll') : ''}
                </td>
                <td className="border px-4 py-2 align-text-top">
                  {item?.updated ? moment.utc(item.updated).local().format('llll') : ''}
                </td>
                <td className="border px-4 py-2 align-text-top">
                  {SCHEDULE_STATUS_JP?.[item?.status] || item?.status}
                </td>
                <td className="border px-4 py-2 align-text-top">{item.lessonRequestId}</td>
                <td className="border px-4 py-2 align-text-top">{item.totalUsers}</td>
                <td className="border px-4 py-2 space-y-4 whitespace-nowrap ">
                  <Tree
                    className="w-full"
                    showIcon={false}
                    showLine={{ showLeafIcon: false }}
                    treeData={getTreeJoined(item)}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default Schedules;
