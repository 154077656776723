import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';

const CONFIG = {
  COLOR_BAR: '#1890ff',
};

const BarChart = ({ labels, data }) => {
  const state = {
    labels,
    datasets: [
      {
        label: '売上ポイント',
        type: 'bar',
        backgroundColor: CONFIG.COLOR_BAR,
        borderColor: CONFIG.COLOR_BAR,
        hoverBackgroundColor: CONFIG.COLOR_BAR,
        hoverBorderColor: CONFIG.COLOR_BAR,
        data,
      },
    ],
  };

  return (
    <div>
      <Bar
        data={state}
        options={{
          title: {},
          elements: {
            line: {
              tension: 0, // disables bezier curves
            },
          },

          legend: {
            display: false,
          },
          responsive: true,
          scales: {
            yAxes: [
              {
                type: 'linear',
                ticks: {
                  beginAtZero: true,
                  callback: (value) => value,
                },
              },
            ],
          },
        }}
      />
    </div>
  );
};

BarChart.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.any),
  data: PropTypes.arrayOf(PropTypes.any),
};
export default BarChart;
