import React from 'react';
import { stringify, parse } from 'qs';
import { Table, Card } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';

import { useGetUserPointHistory } from 'redux/Hooks/user';

const UserPointHistory = ({ userId }) => {
  const history = useHistory();
  const location = useLocation();
  const parsedQuery = parse(location.search, { ignoreQueryPrefix: true });
  const [page, setPage] = React.useState(Number(parsedQuery?.pointHistoryPage) - 1 || 0);

  const { results, isFetching, currentPage, totalPages, setQuery, pageSize } = useGetUserPointHistory(userId);
  React.useEffect(() => {
    setQuery({
      page,
    });
  }, [page]);

  const columns = [
    {
      title: '日付',
      dataIndex: 'updated',
      key: 'updated',
      render: (record) => <div>{moment.utc(record).local().format('YYYY/MM/DD HH:mm')}</div>,
    },
    {
      title: 'ポイント',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: '内容',
      dataIndex: 'description',
      key: 'description',
      width: '20%',
      render: (value) => <div className="whitespace-pre-wrap break-words">{value || ''}</div>,
    },
  ];

  const handleChangePage = (pageNumber) => {
    const query = new URLSearchParams(history.location.search);
    const queryString = stringify({
      ...query,
      ...parsedQuery,
      pointHistoryPage: pageNumber === 1 ? undefined : pageNumber,
    });

    setPage(pageNumber - 1);
    history.push({
      pathname: location.pathname,
      search: queryString,
    });
  };
  const pagination = {
    onChange: handleChangePage,
    current: currentPage + 1,
    pageSize,
    total: totalPages * pageSize,
    showSizeChanger: false,
    hideOnSinglePage: true,
  };

  return (
    <div className="flex flex-col my-4">
      <Card title="ポイント履歴">
        <Table
          className="bg-white overflow-x-scroll"
          rowClassName="align-top whitespace-pre-wrap break-words fixBreakWordsJapan text-center"
          components={{
            header: {
              cell: ({ className, style, ...restProps }) => {
                return <th className={className} style={{ ...style, textAlign: 'center' }} {...restProps} />;
              },
            },
          }}
          bordered
          loading={isFetching}
          dataSource={results}
          pagination={pagination}
          columns={columns}
          rowKey="id"
        />
      </Card>
    </div>
  );
};

UserPointHistory.propTypes = {
  userId: PropTypes.number,
};

export default UserPointHistory;
