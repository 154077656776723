import React, { useState, useEffect, useCallback } from 'react';
import { Layout, Typography, Table, Input, Button, Space, Avatar, Tag, notification, message, Modal } from 'antd';
import { SearchOutlined, CloseOutlined, UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useUsers, useEditUser } from 'redux/Hooks/user';
import { USER_TYPES } from 'configs/Constants';
import AddPointModal from 'containers/Point/components/AddPointModal';
import { useAddPoint } from 'redux/Hooks/pointHistory';

const DEFAULT_PAGE_SIZE = 30;

const ManageInvition = () => {
  const [modal, contextHolder] = Modal.useModal({});
  const [searchForm, setSearchForm] = useState({});
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [page, setPage] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [target, setTarget] = useState(null);
  const [refererId, setRefererId] = useState(null);

  const formik = useFormik({
    initialValues: searchForm,
    validationSchema: Yup.object(),
    enableReinitialize: true,
    onSubmit: (values) => {
      setSearchForm(values);
    },
  });

  const { results, isFetching, currentPage, totalPages, refresh, setQuery } = useUsers();

  const {
    prevFetching: prevIsFetchingAdd,
    isFetching: isFetchingAdd,
    success: successAdd,
    message: errorResponse,
    onSave: onSaveAdd,
  } = useAddPoint();

  const { onSave: onSaveEdit } = useEditUser(
    ':id',
    (res) => {
      if (res?.success) {
        message.success('削除しました');
        refresh();
      } else {
        message.error('ユーザーの削除が完了しました');
      }
    },
    (err) => {
      message.error('ユーザーの削除が完了しました');
    }
  );

  const onDelete = (id) => id && onSaveEdit({ status: 'deleted' }, id);

  const filterMapping = () => {
    return Object.entries(searchForm).reduce(
      (filter, [key, value]) => ({
        ...filter,
        ...(value !== (undefined || null) ? { [key]: value } : {}),
      }),
      {}
    );
  };

  const onOkDelete = useCallback(
    (close, id) => {
      onDelete(id, refresh);
      close();
    },
    [onDelete, refresh]
  );

  const modalDeleteConfig = (id) => {
    return {
      className: '',
      title: '確認',
      content: <h1>削除しますか？</h1>,
      onOk: (close) => onOkDelete(close, id),
      onCancel: (close) => {
        close();
      },
      okButtonProps: {},
      cancelButtonProps: {},
      okText: '削除',
      okType: 'primary',
      cancelText: 'キャンセル',
      icon: null,
      mask: true,
      maskClosable: true,
      style: {},
      maskStyle: {},
      keyboard: true,
      autoFocusButton: 'ok',
    };
  };

  const handleChangePage = (pageParam, pageSizeParam) => {
    setQuery((prevQuery) => ({ ...prevQuery, page: pageParam - 1, pageSize: pageSizeParam }));
    setPage(pageParam - 1);
    setPageSize(pageSizeParam);
  };

  const onOpenModal = (t, record) => {
    setModalVisible(true);
    setTarget(t);
    setRefererId(record?.id);
  };

  const onCancel = () => {
    setModalVisible(false);
    setTarget(null);
    setRefererId(null);
  };

  const handleSubmit = async (values) => {
    onSaveAdd(
      values,
      null,
      () => {
        refresh();
        message.success('ポイント付与しました');
        onCancel();
      },
      () => {
        refresh();
        message.error('後でもう一度やり直してください');
      }
    );
  };

  useEffect(() => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      ...filterMapping(),
      referral: true,
      pageSize,
      page,
    }));
  }, [pageSize, page, searchForm]);

  const pagination = {
    onChange: handleChangePage,
    current: currentPage + 1 || 1,
    pageSize: DEFAULT_PAGE_SIZE,
    total: totalPages * DEFAULT_PAGE_SIZE || 0,
  };

  const columns = [
    {
      title: <p className="text-center">ユーザー名</p>,
      className: 'w-2/12',
      render: ({ id, avatar: avt, avatars, fullName, userType }) => {
        return (
          <div className="flex flex-col items-center">
            <Button
              type="text"
              size="large"
              className="bg-transparent"
              onClick={() => {}}
              block
              icon={
                <Avatar
                  className="flex justify-center items-center"
                  src={[avt].concat(avatars?.[0]?.url)?.filter((url) => url)?.[0]}
                  icon={<UserOutlined />}
                />
              }
            />
            <div>{fullName}</div>
            <Tag color={userType === USER_TYPES.USER ? 'blue' : 'red'}>
              {userType === USER_TYPES.USER ? 'ユーザー' : 'トレーナー'}
            </Tag>
          </div>
        );
      },
    },
    {
      title: <p className="text-center">紹介者</p>,
      className: 'w-2/12',
      render: ({ referral }) => {
        const { avatar: avt, avatars, fullName, userType } = referral || {};
        return (
          <div className="flex flex-col items-center">
            <Button
              type="text"
              size="large"
              className="bg-transparent"
              onClick={() => {}}
              block
              icon={
                <Avatar
                  className="flex justify-center items-center"
                  src={[avt].concat(avatars?.[0]?.url)?.filter((url) => url)?.[0]}
                  icon={<UserOutlined />}
                />
              }
            />
            <div>{fullName}</div>
            <Tag color={userType === USER_TYPES.USER ? 'blue' : 'red'}>
              {userType === USER_TYPES.USER ? 'ユーザー' : 'トレーナー'}
            </Tag>
          </div>
        );
      },
    },
    {
      className: 'w-2/12 text-center',
      render: (_, item) => {
        return (
          <Button
            disabled={item?.referralPointHistory || !item?.referral}
            onClick={() => onOpenModal({ label: item?.referral?.fullName, value: item?.referral?.id }, item)}
            className="text-blue-500 border-blue-500"
          >
            ポイント付与
          </Button>
        );
      },
    },
    {
      title: '授与したポイント',
      className: 'w-2/12 text-center',
      dataIndex: ['referralPointHistory', 'amount'],
    },
    {
      title: '授与した日時',
      className: 'w-2/12 text-center',
      dataIndex: ['referralPointHistory', 'created'],
      render: (value) => {
        return value && moment.utc(value).local().format('LLL');
      },
    },
    {
      title: '',
      className: 'w-2/12 relative',
      render: (_, item) => (
        <Space className=" flex justify-center items-center">
          <Button
            onClick={() => modal.confirm(modalDeleteConfig(item.id))}
            icon={<CloseOutlined />}
            className="text-red-500 border-red-500"
          />
        </Space>
      ),
    },
  ];

  return (
    <form onSubmit={formik.handleSubmit}>
      <Layout.Content className="flex flex-col space-y-4 ">
        <Typography.Title level={3}>紹介管理</Typography.Title>
        <div className="flex flex-col space-y-4">
          <Input
            placeholder="名前"
            value={formik?.values?.search}
            onChange={(e) => formik.setFieldValue('search', e.target.value)}
            allowClear
          />
          <Button
            className="flex justify-center items-center w-48 rounded-md border boder-solid"
            style={{ background: '#C3DAFF', color: '#3B86FF', borderColor: '#3B86FF' }}
            type="default"
            size="large"
            icon={<SearchOutlined size={32} />}
            htmlType="submit"
          >
            検索
          </Button>
        </div>
        <Table
          className="bg-white overflow-x-scroll"
          rowClassName="align-top whitespace-pre-wrap break-words fixBreakWordsJapan text-center"
          components={{
            header: {
              cell: ({ className, style, ...restProps }) => {
                return <th className={className} style={{ ...style, textAlign: 'center' }} {...restProps} />;
              },
            },
          }}
          bordered
          loading={isFetching}
          dataSource={results}
          columns={columns}
          pagination={pagination}
          rowKey="id"
        />
        {modalVisible && (
          <AddPointModal
            target={target}
            refererId={refererId}
            onSubmit={handleSubmit}
            onCancel={onCancel}
            visible={modalVisible}
          />
        )}
      </Layout.Content>
      {contextHolder}
    </form>
  );
};

export default ManageInvition;
