import React, { useEffect } from 'react';
import { Form, Input, Modal, Select } from 'antd';
import { useUpdateTrainerRequest } from 'redux/Hooks/verifyTrainer';

const { Option } = Select;

const FormItem = Form.Item;

const AdminForm = ({ form, roles, data, onCancel, onSubmit, visible }) => {
  useEffect(() => {
    form.resetFields();
  }, [data?.id]);

  const isEdit = !!data;
  return (
    <Modal
      visible={visible}
      title="アドミン"
      okText={isEdit ? '保存' : '追加'}
      onCancel={onCancel}
      onOk={() => onSubmit(data)}
    >
      <Form layout="vertical" form={form} initialValues={isEdit ? data : {}}>
        <FormItem name="username" label="ユーザー名" rules={[{ required: useUpdateTrainerRequest }]}>
          <Input disabled={isEdit} placeholder="ユーザー名" />
        </FormItem>
        <FormItem name="email" label="メール" rules={[{ type: 'email' }, { required: true }]}>
          <Input disabled={isEdit} placeholder="メール" />
        </FormItem>
        <FormItem name="password" label="パスワード" rules={[{ required: !isEdit }]}>
          <Input.Password placeholder="パスワード" />
        </FormItem>
        <Form.Item labelAlign="left" className="flex flex-col pb-4 mb-4" name="roleId" label="役割">
          <Select style={{ width: 150 }} placeholder="">
            {roles?.map((role) => (
              <Option key={role.id} value={role.id}>
                {role.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AdminForm;
