import React, { useEffect, useState, useRef } from 'react';
import { Layout, Table, Tag, Space, Button, Modal, message, Select, Input, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useOffLivestream, useSearchLivestream, useEditLivestream } from 'redux/Hooks/livestream';
import moment from 'moment';
import PropTypes from 'prop-types';
import qs from 'qs';

import { useFilter } from 'utils/hooks';
import EditModal from 'components/EditModal';
import InputDateTime from 'components/Input/InputDateTime';
import { API } from 'configs';
import LivestreamSearchForm from './components/LivestreamSearchForm';
import ViewStream from './components/ViewStream';
import ViewRecorded from './components/ViewRecorded';

const DEFAULT_PAGE_SIZE = 30;

const Livestream = (props) => {
  const { location, history } = props;
  const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
  const action = parsedQuery?.action;
  const id = parsedQuery?.id;
  // eslint-disable-next-line no-unused-vars
  const [modal, contextHolder] = Modal.useModal({});
  const [visibleEdit, setVisibleEdit] = useState(false);
  const fileEditRef = useRef();

  const { results, currentPage, totalPages, refresh, setQuery } = useSearchLivestream();

  const [filter, setFilter] = useFilter(setQuery);

  // eslint-disable-next-line no-unused-vars
  const [{ data: offstreamResponse }, doOffstream] = useOffLivestream(id);

  const {
    data: dataLivestreamById,
    prevFetching: prevIsFetching,
    isFetching,
    success,
    message: successResponse,
  } = useEditLivestream(id);

  const onHiddenEdit = () => {
    setVisibleEdit(false);
    props.history.push({
      pathname: props.location.pathname,
      search: '',
    });
  };

  useEffect(() => {
    if (isFetching !== prevIsFetching && prevIsFetching) {
      if (success) {
        if (visibleEdit) {
          onHiddenEdit();
        }
        message.success('実行完了');
        refresh();
        props.history.push({
          pathname: props.location.pathname,
        });
      } else {
        message.error('エラー');
        console.error(successResponse?.message);
      }
    }
  }, [isFetching]);

  useEffect(() => {
    if (id && action === 'edit') {
      if (dataLivestreamById) {
        setVisibleEdit(true);
      }
    }
  }, [id, action]);

  const handleSearch = (f) => {
    setFilter({ ...filter, ...f });
  };

  const handleChangePage = (page, pageSize) => {
    setFilter({ ...filter, page: page - 1, pageSize });
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      width: '10%',
    },
    {
      title: 'チャンネル名',
      dataIndex: ['title'],
      width: '20%',
      render: (value) => <div className="">{value}</div>,
    },
    {
      title: 'フルネーム',
      dataIndex: ['creator', 'fullName'],
      width: '20%',
      render: (value) => <div className="">{value || ''}</div>,
    },
    {
      title: 'ユーザー数',
      dataIndex: ['totalUsers'],
      width: '10%',
    },
    {
      title: '開始日',
      dataIndex: ['created'],
      width: '10%',
      render: (value, record) => {
        return moment.utc(value).local().format('DD/MM/Y HH:mm');
      },
    },
    {
      title: '終了日',
      dataIndex: ['updated'],
      width: '10%',
      render: (value, record) => {
        return moment.utc(value).local().format('DD/MM/Y HH:mm');
      },
    },
    {
      title: 'ステータス',
      dataIndex: ['livestreaming'],
      width: '10%',
      render: (value, record) => {
        return (
          <Tag style={{ cursor: value === true ? 'default' : 'pointer' }} color={value === true ? 'green' : '#f50'}>
            {value ? '生放送' : 'オフライン'}
          </Tag>
        );
      },
    },
    {
      title: 'アクション',
      dataIndex: 'status',
      width: '10%',
      render: (value, record) => {
        return (
          <Space size="middle">
            <Button
              onClick={() => {
                const query = new URLSearchParams(history.location.search);
                query.set('action', 'edit');
                query.set('id', record.id);
                history.push({
                  pathname: location.pathname,
                  search: query.toString(),
                });
              }}
              icon={<EditOutlined />}
            />
          </Space>
        );
      },
    },
  ];

  const editInputs = [
    {
      title: 'メディア',
      key: 'media',
      name: ['media', 'link'],
      InputComponent: dataLivestreamById?.livestream?.livestreaming ? ViewStream : ViewRecorded,
      inputProps: {
        key: 'media',
        name: 'media',
        className: 'flex flex-col flex-grow items-center justify-center',
        size: '12rem',
        customUploadProps: {
          isImageUrl: true,
        },
        livestream: dataLivestreamById,
        ref: fileEditRef,
      },
      formItemProps: {},
    },
    {
      key: 'id',
      name: ['id'],
      InputComponent: Input,
      inputProps: {
        type: 'hidden',
      },
    },
    {
      title: 'レッスン名',
      key: 'title',
      name: ['title'],
      InputComponent: Input,
      inputProps: {
        disabled: true,
      },
      formItemProps: {
        className: 'my-4',
        rules: [{ required: true }],
      },
    },
    {
      title: 'ホスト',
      key: 'creator',
      name: ['creator', 'fullName'],
      InputComponent: Input,
      inputProps: {
        disabled: true,
      },
      formItemProps: {
        className: 'my-4',
        rules: [{ required: true }],
      },
    },
    {
      title: 'ステータス',
      key: 'livestreaming',
      name: ['livestreaming'],
      InputComponent: Select,
      inputProps: {
        disabled: true,
        options: [
          { label: 'オフライン', value: false },
          { label: '生放送', value: true },
        ],
      },
      formItemProps: {
        className: 'my-4',
        rules: [{ required: true }],
      },
    },
    {
      key: 'created',
      name: ['created'],
      title: '始まる時間',
      InputComponent: InputDateTime,
      inputProps: {
        disabled: true,
      },
    },
    {
      key: 'updated',
      name: ['updated'],
      title: '終了時間',
      InputComponent: InputDateTime,
      inputProps: {
        disabled: true,
      },
    },
    {
      key: 'type',
      name: ['type'],
      InputComponent: Input,
      inputProps: {
        className: 'hidden',
      },
      formItemProps: {
        hidden: true,
      },
    },
  ];

  const pagination = {
    onChange: handleChangePage,
    current: currentPage + 1 || 1,
    pageSize: DEFAULT_PAGE_SIZE,
    total: totalPages * DEFAULT_PAGE_SIZE || 0,
  };

  return (
    <Layout.Content className="flex flex-col p-4">
      <Typography.Title level={3}>ホストレライブ管理</Typography.Title>
      <div className="formControl py-4 justify-between">
        <LivestreamSearchForm onSubmit={handleSearch} />
      </div>
      <Table
        className="bg-white overflow-x-scroll"
        rowClassName="align-top whitespace-pre-wrap break-words fixBreakWordsJapan text-center"
        components={{
          header: {
            cell: ({ className, style, ...restProps }) => {
              return <th className={className} style={{ ...style, textAlign: 'center' }} {...restProps} />;
            },
          },
        }}
        bordered
        dataSource={results || []}
        columns={columns}
        rowKey="id"
        pagination={pagination}
      />
      <EditModal
        visible={visibleEdit}
        onCancel={() => {
          onHiddenEdit();
        }}
        record={dataLivestreamById?.livestream}
        loading={isFetching}
        editInputs={editInputs}
        headerTitle="編集"
        modalProps={{
          width: '80%',
          okText: '生放送をやめる',
          okButtonProps: {
            danger: true,
            disabled: !dataLivestreamById?.livestream?.livestreaming,
          },
          onOk: (e) => {
            doOffstream({ url: API.ADMIN.LIVESTREAM.OFFSTREAM.replace(':id', dataLivestreamById.livestream?.id) });
            onHiddenEdit();
            refresh();
          },
        }}
        formProps={{
          className: 'overflow-y-auto',
          style: { maxHeight: '60vh' },
        }}
      />
      {contextHolder}
    </Layout.Content>
  );
};

Livestream.propTypes = {
  location: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
};

export default Livestream;
