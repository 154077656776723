import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';
import { useGetCompany } from 'redux/Hooks/company';
import { API } from 'configs';
import View from './components/View';
import CreateAndEdit from './components/CreateAndEdit';

export const ACTION = {
  EDIT: 'edit',
  NEW: 'new',
  VIEW: 'view',
};

const Company = () => {
  const history = useHistory();
  const location = useLocation();
  const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [{ data: dataCompany, isLoading }, doRequest] = useGetCompany(parsedQuery?.id);
  useEffect(() => {
    try {
      if (parsedQuery?.id) {
        doRequest({
          url: API.ADMIN.COMPANY.DETAIL.replace(':id', Number(parsedQuery?.id)),
        });
      }
    } catch (error) {
      setTimeout(() => history.goBack(), 1000);
    }
  }, [parsedQuery?.id]);
  const renderAction = (action) => {
    switch (action) {
      case ACTION.EDIT:
        return <CreateAndEdit initialValues={dataCompany} loading={isLoading} />;
      case ACTION.NEW:
        return <CreateAndEdit />;
      case ACTION.VIEW:
      default:
        return <View />;
    }
  };

  return renderAction(parsedQuery?.action);
};

export default Company;
