import React from 'react';
import {} from 'antd';
import { useGetRank } from 'redux/Hooks/rank';
import TrainerRankingTable from './TrainerRankingTable';

const TrainerRankingRegion = () => {
  const [rankData] = useGetRank();

  return (
    <div className="flex flex-row space-x-8">
      <div className="bg-white w-full p-6 relative my-6">
        <div className="flex items-center justify-between mb-4">
          <div className="py-2 font-semibold">ポイントランキング</div>
        </div>
        <TrainerRankingTable showType="points" rankData={rankData} />
      </div>
      <div className="bg-white w-full p-6 relative my-6">
        <div className="flex items-center justify-between mb-4">
          <div className="py-2 font-semibold">評価ランキング</div>
        </div>
        <TrainerRankingTable showType="ratings" rankData={rankData} />
      </div>
    </div>
  );
};

export default TrainerRankingRegion;
