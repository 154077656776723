import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import renderRoutes from './renderRoutes';

function RouterComponent(props) {
  const { history, ...renderRoutesProps } = props;

  return <Router>{renderRoutes(renderRoutesProps)}</Router>;
}

export default RouterComponent;
