import React, { useEffect } from 'react';
import { Layout, Table, Button, Typography } from 'antd';
import { parse } from 'qs';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { usePaymentRequestsCsv, useUpdatePayment } from 'redux/Hooks/payment';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { useFilter } from 'utils/hooks';

const DEFAULT_PAGE_SIZE = 30;

const ListDownload = () => {
  const location = useLocation();
  const { results, currentPage, totalPages, refresh, setQuery } = usePaymentRequestsCsv();

  const [filter, setFilter] = useFilter(setQuery);

  const { onSave, prevFetching, isFetching } = useUpdatePayment({
    onSuccess: (res) => {},
    onFail: (err) => {},
  });

  const parsedQuery = parse(location.search, { ignoreQueryPrefix: true });
  useEffect(() => {
    if (!isFetching && prevFetching) {
      refresh();
    }
  }, [isFetching]);

  useEffect(() => {
    if (Number(parsedQuery?.paymentPending) === 1) setFilter({ ...filter, status: 'pending' });
  }, [parsedQuery?.paymentPending]);

  const handleChangePage = (page, pageSize) => {
    setFilter({ ...filter, page: page - 1, pageSize });
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
    },

    {
      title: '作成日',
      dataIndex: ['created'],
      render: (value) => {
        return moment.utc(value).local().format('DD/MM/Y');
      },
    },
    {
      title: 'ダウンロード',
      dataIndex: 'url',
      render: (value) => {
        return (
          <Button type="primary" href={value}>
            <CloudDownloadOutlined size={24} /> ダウンロード
          </Button>
        );
      },
    },
  ];

  const pagination = {
    showSizeChanger: false,
    showQuickJumper: false,
    onChange: handleChangePage,
    current: currentPage + 1 || 1,
    pageSize: DEFAULT_PAGE_SIZE,
    total: totalPages * DEFAULT_PAGE_SIZE || 0,
  };

  return (
    <Layout.Content className="flex flex-col p-4">
      <Typography.Title level={3}>CSVダウンロード</Typography.Title>
      <Table
        className="bg-white overflow-x-scroll"
        rowClassName="align-top whitespace-pre-wrap break-words fixBreakWordsJapan text-center"
        components={{
          header: {
            cell: ({ className, style, ...restProps }) => {
              return <th className={className} style={{ ...style, textAlign: 'center' }} {...restProps} />;
            },
          },
        }}
        bordered
        dataSource={results || []}
        columns={columns}
        rowKey={(record) => record.id}
        pagination={pagination}
        scroll={{ x: 375 }}
      />
    </Layout.Content>
  );
};

export default ListDownload;
