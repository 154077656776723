import { Input, Form } from 'antd';
import React from 'react';
import ItemMap from './map';

const FormItem = Form.Item;

const getFormItemOptions = ({ onChange, defaultValue, customProps = {}, rules }) => {
  const options = {
    rules: rules || customProps.rules,
  };
  if (onChange) {
    options.onChange = onChange;
  }
  if (defaultValue) {
    options.initialValue = defaultValue;
  }
  return options;
};

const LoginItem = (props) => {
  const { onChange, customProps, defaultValue, rules, name, ...restProps } = props;

  // get getFieldDecorator props
  const options = getFormItemOptions(props);
  const otherProps = restProps || {};

  return (
    <FormItem name={name} {...options}>
      <Input {...customProps} {...otherProps} />
    </FormItem>
  );
};

export const UserName = (props) => (
  <LoginItem customProps={ItemMap.UserName.props} rules={ItemMap.UserName.rules} {...props} type="UserName" />
);

export const Password = (props) => (
  <LoginItem customProps={ItemMap.Password.props} rules={ItemMap.Password.rules} {...props} type="Password" />
);

export default LoginItem;
