import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import moment from 'moment';
import groupBy from 'lodash/groupBy';
import { useGetTransactions } from 'redux/Hooks/transaction';
import { getDayOfMonth, getMonthOfYear } from 'utils/helpers';
import BarChart from 'components/BarChart';

import StatisticProfit from './StatisticProfit';
import CustomMonthControl from './CustomMonthControl';

const TIME_TYPE = {
  DATE: 'date',
  MONTH: 'month',
  YEAR: 'year',
};

const TIME_MENU = {
  date: '日',
  month: '月',
  year: '年',
};

const TIME_FORMAT = {
  date: 'MM月DD',
  month: 'MM月',
  year: 'YYYY',
};

const TIME_FILTER = {
  date: 'months',
  month: 'years',
};
const ChartIncomeRegion = () => {
  const { results, isFetching, setQuery } = useGetTransactions();
  const [typeTime, setTypeTime] = useState(TIME_TYPE.DATE);
  const [timeSelect, setTimeSelect] = useState(moment().format());

  const arrDaysOfMonth = getDayOfMonth(moment(timeSelect).format(), TIME_FORMAT[timeSelect]);
  const arrMonthsOfYear = getMonthOfYear(moment(timeSelect).format('YYYY'), TIME_FORMAT[timeSelect]);

  useEffect(() => {
    const generatorFilterTime = () => {
      if (typeTime === TIME_TYPE.YEAR) {
        return {};
      }
      return {
        start: moment(timeSelect).utc().startOf(TIME_FILTER[typeTime]).format('YYYY-MM-DD'),
        end: moment(timeSelect).utc().endOf(TIME_FILTER[typeTime]).format('YYYY-MM-DD'),
      };
    };
    setQuery({
      pageSize: 1000,
      ...generatorFilterTime(),
    });
  }, [typeTime, timeSelect]);

  const dataGroupByDateTime = groupBy(results, (c) => moment.utc(c.updated).local().format(TIME_FORMAT[typeTime]));

  const orderKeyObject = (unordered) => {
    const ordered = {};
    Object.keys(unordered)
      .sort()
      .forEach((key) => {
        ordered[key] = unordered[key];
      });
    return ordered;
  };

  const getDataTransactionByDateTime = (dateTime) => {
    let dataTrans = {};
    for (const key in dateTime) {
      if ({}.hasOwnProperty.call(dateTime, key)) {
        dataTrans = {
          ...{
            [key]:
              (dataGroupByDateTime[key] &&
                dataGroupByDateTime[key].reduce((total, current) => total + current.point, 0)) ||
              0,
          },
          ...dataTrans,
        };
      }
    }
    return orderKeyObject(dataTrans);
  };

  const getDataTransactionDefault = () => {
    let dataTrans = {};
    for (const key in dataGroupByDateTime) {
      if ({}.hasOwnProperty.call(dataGroupByDateTime, key)) {
        dataTrans = {
          [key]: dataGroupByDateTime[key].reduce((total, current) => total + current.point, 0),
        };
      }
    }
    return dataTrans;
  };

  const getData = (type) => {
    if (type === TIME_TYPE.DATE) return getDataTransactionByDateTime(arrDaysOfMonth);
    if (type === TIME_TYPE.MONTH) return getDataTransactionByDateTime(arrMonthsOfYear);
    return getDataTransactionDefault();
  };

  const onChangeSelect = (value) => {
    setTypeTime(value);
    setTimeSelect(moment().format());
  };

  return (
    <div className="bg-white p-6 relative my-2 space-y-4">
      <div className="flex items-center justify-between mb-4">
        <div className="py-2 font-semibold">集計</div>
        <Select
          className="w-40 rounded-lg top-0 m-6 absolute right-0 shadow"
          defaultValue="date"
          onChange={onChangeSelect}
        >
          {Object.values(TIME_TYPE).map((c) => (
            <Select.Option key={c}>{TIME_MENU[c]}</Select.Option>
          ))}
        </Select>
      </div>

      <div className="flex flex-row items-center justify-center">
        <div className="flex flex-row items-center">
          <div className="h-3 w-6 rounded-lg" style={{ background: '#F0F2F8' }} />
          <p className="pl-4 font-semibold">売上</p>
        </div>
        <StatisticProfit
          isLoading={isFetching}
          title="利益"
          unit=""
          totalValue={Object.values(getData(typeTime)).reduce((total, current) => total + current, 0)}
        />
        <CustomMonthControl
          isMonthControl={typeTime === TIME_TYPE.DATE}
          disabled={typeTime === TIME_TYPE.YEAR}
          timeSelect={timeSelect}
          setTimeSelect={setTimeSelect}
        />
      </div>

      <div className="px-6 pb-4">
        <BarChart labels={Object.keys(getData(typeTime))} data={Object.values(getData(typeTime))} />
      </div>
    </div>
  );
};

export default ChartIncomeRegion;
