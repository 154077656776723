import React from 'react';
import { Form, Button, Spin, Result, message, Input, Radio, Select } from 'antd';
import { useGetSetting, useUpdateSetting } from 'redux/Hooks/myBank';
import { SaveOutlined } from '@ant-design/icons';
import BankSelect from 'components/BankSelect';
import BranchSelect from 'components/BranchSelect';

function MyBank() {
  const [form] = Form.useForm();
  const [{ data, isLoading, isSuccess }, doRequest] = useGetSetting();
  const [updateSetting] = useUpdateSetting({});
  if (isLoading) {
    return <Spin />;
  }

  if (!isSuccess) {
    return <Result status="error" title="フェッチに失敗する" />;
  }

  return (
    <div className="flex flex-col space-y-8">
      <Form
        form={form}
        layout="horizontal"
        className="flex flex-col p-8 shadow-lg rounded-xl border"
        initialValues={data?.value || {}}
        onFinish={async (values) => {
          try {
            await updateSetting({
              body: values,
            });
            message.success('更新成功');
          } catch (e) {
            console.log(e);
            message.error('更新に失敗する');
          }
        }}
      >
        <div className="uppercase text-xl pb-8">運営銀行情報</div>
        <Form.Item name="bankNumber" label="金融機関名" rules={[{ required: true }]}>
          <BankSelect form={form} valueprop="bankNumber" />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => {
            if (prevValues.bankNumber !== currentValues.bankNumber) {
              form.resetFields(['branchNumber']);
            }
            return prevValues.bankNumber !== currentValues.bankNumber;
          }}
          rules={[{ required: true }, { pattern: new RegExp(/^[0-9]*$/g), message: '数字を入力してください' }]}
        >
          {() => (
            <Form.Item name="branchNumber" label="支店名" rules={[{ required: true }]}>
              <BranchSelect
                placeholder="入力してください"
                currentBank={form.getFieldValue('currentBank')}
                valueprop="branchNumber"
              />
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item
          name="bankAccountNumber"
          label="口座番号"
          rules={[
            { required: true },
            { whitespace: true },
            { pattern: new RegExp(/^[0-9]*$/g), message: '数字を入力してください' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="bankAccountName"
          label="口座名義人名（カナ）"
          rules={[
            {
              pattern: new RegExp(/^(?:([ァ-ン]|ー)|([ｧ-ﾝﾞﾟ]))+$/),
              message: '無効な値',
            },
            { required: true },
            { whitespace: true },
          ]}
        >
          <Input placeholder="入力してください" />
        </Form.Item>
        <Form.Item
          name="delegateName"
          label="委託者名"
          rules={[
            { required: true },
            { whitespace: true },
            {
              pattern: new RegExp(/^(?:([ァ-ン]|ー)|([ｧ-ﾝﾞﾟ]))+$/),
              message: '無効な値',
            },
          ]}
        >
          <Input placeholder="入力してください" />
        </Form.Item>
        <Form.Item name="delegateCode" label="委託コード" rules={[{ required: true }, { whitespace: true }]}>
          <Input placeholder="入力してください" />
        </Form.Item>
        <Form.Item name="bankAccountType" label="口座種別" rules={[{ required: true }]}>
          <Radio.Group
            options={[
              {
                label: '当座',
                value: '当座',
              },
              {
                label: '普通',
                value: '普通',
              },
            ]}
            placeholder="口座種別"
          />
        </Form.Item>
        <Button className="rounded w-32" icon={<SaveOutlined />} type="primary" htmlType="submit" size="large">
          保存
        </Button>
      </Form>
    </div>
  );
}

export default MyBank;
