import { API } from 'configs';
import { usePaging, useAddEdit, useDelete } from 'utils/AxiosHooks';

const useAdmins = () => usePaging(API.ADMIN.ADMIN.LIST);

const useEditAdmin = (onSuccess, onFail) => useAddEdit(API.ADMIN.ADMIN.EDIT, 'edit', onSuccess, onFail);
const useAddAdmin = (onSuccess, onFail) => useAddEdit(API.ADMIN.ADMIN.ADD, 'add', onSuccess, onFail);
const useDeleteAdmin = (onSuccess, onFail) => useDelete(API.ADMIN.ADMIN.EDIT, onSuccess, onFail);

export { useAdmins, useEditAdmin, useAddAdmin, useDeleteAdmin };
