import React from 'react';
import { List, Avatar, Modal, message } from 'antd';
import moment from 'moment';
import { UserOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroller';
import { parse } from 'qs';
import { API } from 'configs';
import { useLocation } from 'react-router-dom';
import { useGetMessage } from 'redux/Hooks/conversation';
import { request } from 'utils/Request';

import Message from '../Message';

const MessageBox = () => {
  const [modal, contextHolder] = Modal.useModal({});
  const location = useLocation();
  const parseQuery = parse(location.search, { ignoreQueryPrefix: true }) || {};

  const [messages, isFetching, getMessage, refreshQuery] = useGetMessage(Number(parseQuery?.id));

  React.useEffect(() => {
    getMessage();
  }, []);

  const deleteMessage = (id) => {
    request({
      method: 'delete',
      url: API.ADMIN.CONVERSATION.DELETE.replace(':id', id),
      success: (response) => {
        if (response) {
          refreshQuery(Number(parseQuery?.id));
          message.success('メッセージ削除が完了しました');
        }
      },
      failure: (response) => {
        //
      },
    });
  };

  const modalDeleteConfig = (idMess) => {
    return {
      title: '確認',
      content: <h1>削除しますか？</h1>,
      onOk: (close) => {
        deleteMessage(idMess);
        close();
      },
      onCancel: (close) => {
        close();
      },
      okText: '削除',
      okType: 'primary',
      cancelText: 'キャンセル',
      autoFocusButton: 'ok',
    };
  };

  const _renderMessageItem = (item, isLeft) => {
    return (
      <List.Item
        className="flex flex-col px-5"
        style={{
          alignItems: isLeft ? 'flex-start' : 'flex-end',
        }}
      >
        <div
          className={`flex flex-row relative justify-start items-center flex-wrap ${
            isLeft ? 'flex-row' : 'flex-row-reverse'
          }`}
        >
          <Avatar
            src={item?.sender?.avatar}
            size="large"
            icon={<UserOutlined />}
            className="flex justify-center items-center mb-2"
          />
          <div className="px-4 font-bold whitespace-pre-wrap break-words fixBreakWords">{item?.sender?.fullName}</div>
        </div>
        <Message
          isAttachment={!!item?.attachmentId}
          isLeft={isLeft}
          item={item}
          isDelete
          deleteMessage={() => modal.confirm(modalDeleteConfig(item?.id))}
        />
        <div className="text-xs text-gray-600 ml-2">{moment(item?.updated).format('MM月DD日（ddd）HH:mm')}</div>
      </List.Item>
    );
  };

  return (
    <div className="flex flex-row flex-grow mb-12" style={{ overflowX: 'hidden' }}>
      <InfiniteScroll
        pageStart={0}
        loadMore={() => {}}
        hasMore
        useWindow={false}
        className="flex-grow w-full"
        style={{ height: 284 }}
      >
        <List
          dataSource={messages}
          loading={isFetching}
          itemLayout="horizontal"
          className="flex-grow"
          renderItem={(item) => _renderMessageItem(item, item?.sender?.userType === 'trainer')}
          rowKey="id"
        />
      </InfiniteScroll>
      {contextHolder}
    </div>
  );
};

export default MessageBox;
