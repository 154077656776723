import React from 'react';

import NewUsersTable from './NewUsersTable';

const NewUsersRegion = () => {
  return (
    <div className="bg-white p-6 relative my-6">
      <div className="flex items-center justify-between mb-4">
        <div className="py-2 font-semibold">新着ユーザー</div>
      </div>
      <NewUsersTable defaultQuery={{ userType: 'training' }} />
    </div>
  );
};

export default NewUsersRegion;
