import React, { useState, useEffect, useRef } from 'react';
import { Layout, Space, Button, Table, Modal, message, Tag, Image, Select } from 'antd';
import { CloseOutlined, EditOutlined, PictureOutlined } from '@ant-design/icons';

import { useTutorials, useEditTutorial, useAddTutorial, useDeleteTutorial } from 'redux/Hooks/tutorial';
import { useTweets, useDeleteTweet } from 'redux/Hooks/tweet';
import UploadImage from 'components/UploadImage';
import EditModal from 'components/EditModal';
import UploadImageFormItem from 'components/UploadImageInput';
import { omit } from 'lodash';
import { SortableItem, SortableContainer, DragHandle } from 'components/Sortable';
import { useHistory } from 'react-router-dom';
import { LexoRank } from 'lexorank';
import { USER_TYPES, USER_TYPES_JP } from 'configs/Constants';

const Tutorial = () => {
  const fileEditRef = useRef();
  const history = useHistory();
  const { results, currentPage, totalPages, refresh, setQuery } = useTutorials();

  const { prevFetching: prevIsFetching, isFetching, success, message: successResponse, onSave } = useEditTutorial(
    () => {
      message.success('実行完了');
      refresh();
    },
    (error) => {
      message.error('エラー');
      console.error(error?.message);
    }
  );
  const {
    prevFetching: prevIsFetchingAdd,
    isFetching: isFetchingAdd,
    success: successAdd,
    message: errorResponse,
    onSave: onAdd,
  } = useAddTutorial(
    () => {
      message.success('実行完了');
      refresh();
    },
    (error) => {
      message.error('エラー');
      console.error(error?.message);
    }
  );

  const { onDelete } = useDeleteTutorial(
    () => {
      message.success('実行完了');
      refresh();
    },
    (error) => {
      message.error('エラー');
      console.error(error?.message);
    }
  );

  const [visibleEdit, setVisibleEdit] = useState(false);
  const [editSelected, setEditSelected] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [modal, contextHolder] = Modal.useModal({});

  const onOk = async (close, record) => {
    onDelete(record?.id);
    const query = new URLSearchParams(history.location.search);
    query.set('page', 1);
    close();
  };

  const onCancel = (close) => {
    close();
  };

  const modalDeleteConfig = (record) => {
    return {
      className: '',
      title: '確認',
      content: <h1>削除しますか？</h1>,
      onOk: (close) => onOk(close, record),
      onCancel,
      okButtonProps: {},
      cancelButtonProps: {},
      okText: '削除',
      okType: 'primary',
      cancelText: 'キャンセル',
      icon: null,
      mask: true,
      maskClosable: true,
      style: {},
      maskStyle: {},
      keyboard: true,
      autoFocusButton: 'ok',
    };
  };

  const dataSource = results || [];

  const onChangeItem = async (values, recordId) => {
    try {
      const formData = new FormData();
      for (const [key, value] of Object.entries(omit(values, ['image']))) {
        formData.append(key, value);
      }
      if (fileEditRef?.current?.files?.[0]) {
        formData.append('image', fileEditRef?.current?.files?.[0]);
      }
      if (recordId) {
        onSave(formData, recordId);
      } else {
        formData.append(
          'ordering',
          dataSource[0]?.ordering ? LexoRank.parse(dataSource[0]?.ordering).genPrev() : LexoRank.middle()
        );
        onAdd(formData);
      }
    } catch (createError) {
      message.error('エラー');
      console.error(createError?.message);
    } finally {
      setVisibleEdit(false);
      setEditSelected(null);
    }
  };

  const onSort = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newOrder = dataSource?.[newIndex]?.ordering;
      const newRank = newOrder ? LexoRank.parse(newOrder) : LexoRank.middle();
      let resultRank = null;
      if (newIndex === 0) {
        resultRank = newRank.genPrev();
      } else if (newIndex === dataSource?.length - 1) {
        resultRank = newRank.genNext();
      } else {
        const nearOrder = dataSource?.[newIndex - oldIndex > 0 ? newIndex + 1 : newIndex - 1]?.ordering;
        const nearRank = nearOrder ? LexoRank.parse(nearOrder) : LexoRank.middle();
        if (newRank.equals(nearRank)) {
          resultRank = newIndex - oldIndex > 0 ? newRank.genNext() : newRank.genPrev();
        } else {
          resultRank = newRank.between(nearRank);
        }
      }
      if (dataSource?.[oldIndex] && resultRank) {
        onSave({ ordering: resultRank.toString() }, dataSource?.[oldIndex]?.id);
      }
    }
  };

  const _targetType = (targetType) => {
    return <Tag color={targetType === USER_TYPES.USER ? 'blue' : 'red'}>{USER_TYPES_JP[targetType]}</Tag>;
  };

  const _renderAction = (record) => (
    <Space size="middle">
      <Button
        onClick={() => {
          setVisibleEdit(true);
          setEditSelected(record);
        }}
        icon={<EditOutlined />}
      />
      <Button
        onClick={() => {
          modal.confirm(modalDeleteConfig(record));
        }}
        icon={<CloseOutlined />}
      />
    </Space>
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource.findIndex((x) => x.id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  const DraggableContainer = (containerProps) => (
    <SortableContainer
      lockAxis="y"
      useDragHandle
      disableAutoscroll
      helperClass="flex flex-row items-center justify-between border bg-white pr-2 pl-2"
      onSortEnd={onSort}
      {...containerProps}
    />
  );

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      className: 'w-1/12 text-center',
    },
    {
      title: <p className="text-center whitespace-no-wrap">移動</p>,
      dataIndex: 'order',
      key: 'order',
      className: 'text-center w-2/12',
      render: () => <DragHandle />,
    },
    {
      title: '画像',
      dataIndex: 'image',
      key: 'image',
      className: 'w-5/12',
      render: (record) => {
        if (!record) {
          return (
            <div className="flex justify-center">
              <PictureOutlined style={{ fontSize: '6rem' }} />
            </div>
          );
        }
        return (
          <div className="flex justify-center">
            <Image width="6rem" src={record} preview={false} />
          </div>
        );
      },
    },
    {
      title: '対象',
      dataIndex: 'targetType',
      key: 'targetType',
      className: 'text-center w-2/12',
      render: _targetType,
    },
    {
      title: '',
      key: 'action',
      className: 'text-center w-2/12',
      render: _renderAction,
    },
  ];

  const editInputs = [
    {
      title: '画像',
      key: 'image',
      name: ['image'],
      InputComponent: UploadImageFormItem,
      inputProps: {
        key: 'image',
        name: 'image',
        className: 'flex flex-col flex-grow items-center justify-center',
        classNameImage: '',
        size: '4rem',
        customUploadProps: {
          isImageUrl: true,
        },
        ref: fileEditRef,
      },
      formItemProps: {
        rules: [{ required: !editSelected?.id }],
      },
    },
    {
      title: '対象',
      key: 'targetType',
      name: ['targetType'],
      InputComponent: Select,
      inputProps: {
        key: 'targetType',
        name: 'targetType',
        options: [
          { value: USER_TYPES.TRAINER, label: USER_TYPES_JP[USER_TYPES.TRAINER] },
          { value: USER_TYPES.USER, label: USER_TYPES_JP[USER_TYPES.USER] },
        ],
      },
      formItemProps: {
        rules: [{ required: !editSelected?.id }],
      },
    },
  ];

  return (
    <Layout.Content className="flex flex-col p-4">
      <div className="flex flex-row justify-end py-4">
        <Space>
          <Button
            onClick={() => {
              setVisibleEdit(true);
              setEditSelected(null);
            }}
            type="primary"
          >
            追加
          </Button>
        </Space>
      </div>
      <Table
        className="bg-white overflow-x-scroll"
        rowClassName="align-top whitespace-pre-wrap break-words fixBreakWordsJapan text-center"
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        components={{
          header: {
            cell: ({ className, style, ...restProps }) => {
              return <th className={className} style={{ ...style, textAlign: 'center' }} {...restProps} />;
            },
          },
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
        rowKey="id"
      />
      {contextHolder}
      {visibleEdit && (
        <EditModal
          visible={visibleEdit}
          onCreate={onChangeItem}
          onCancel={() => {
            setVisibleEdit(false);
            setEditSelected(null);
          }}
          modalProps={{
            width: '80%',
          }}
          record={editSelected}
          editInputs={editInputs}
          headerTitle="利用規約編集"
        />
      )}
    </Layout.Content>
  );
};

export default Tutorial;
