import { API } from 'configs';
import { useRequest, useAddEdit } from 'utils/AxiosHooks';

const useUpdateNGWord = () => useAddEdit(API.ADMIN.NGWORD, 'add');
const useGetNGWord = () => {
  const [state, doRequest] = useRequest({
    url: API.ADMIN.NGWORD,
  });

  return {
    data: state,
    fetch: doRequest,
  };
};

export { useUpdateNGWord, useGetNGWord };
