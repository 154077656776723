import React from 'react';
import { Table, Space, Button, Layout, Typography, notification, Modal } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useCompanies, useDeleteCompany } from 'redux/Hooks/company';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';

const View = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [modal, contextHolder] = Modal.useModal({});
  const { results, isFetching, isRefreshing, currentPage, totalPages, refresh, setQuery } = useCompanies();
  const { onDelete } = useDeleteCompany(
    ({ success }) => {
      if (success) {
        notification.success({
          message: '成功',
          description: '実行完了',
        });
        refresh();
      }
    },
    (fail) => {
      notification.error({
        message: 'エラー',
        description: '後でもう一度やり直してください',
      });
    }
  );

  const onDeleteItem = (close, record) => {
    onDelete(record?.id);
    close();
  };

  const modalDeleteConfig = (record) => ({
    className: '',
    title: '確認',
    content: <h1>削除しますか？</h1>,
    onOk: (close) => onDeleteItem(close, record),
    onCancel: (close) => close(),
    okButtonProps: {},
    cancelButtonProps: {},
    okText: '削除',
    okType: 'primary',
    cancelText: 'キャンセル',
    icon: null,
    mask: true,
    maskClosable: true,
    style: {},
    maskStyle: {},
    keyboard: true,
    autoFocusButton: 'ok',
  });

  const onCreate = () => {
    const query = new URLSearchParams(history.location.search);
    query.set('action', 'new');
    history.push({
      pathname: location.pathname,
      search: query.toString(),
    });
  };

  const _renderAction = (record) => (
    <Space size="middle">
      <Button
        onClick={() => {
          const query = new URLSearchParams(history.location.search);
          query.set('action', 'edit');
          query.set('id', record?.id);
          history.push({
            pathname: location.pathname,
            search: query.toString(),
          });
        }}
        icon={<EditOutlined />}
      />
      <Button
        onClick={() => {
          modal.confirm(modalDeleteConfig(record));
        }}
        icon={<CloseOutlined />}
      />
    </Space>
  );

  const columns = [
    {
      title: <p className="text-center whitespace-no-wrap">#</p>,
      dataIndex: ['id'],
      key: 'id',
      className: 'w-1/12',
      render: (value) => <span>{value}</span>,
    },
    {
      title: <p className="text-center whitespace-no-wrap">法人名</p>,
      dataIndex: ['name'],
      key: 'name',
      className: 'w-2/12',
      render: (value) => <span>{value}</span>,
    },
    {
      title: <p className="text-center whitespace-no-wrap">振込先口座情報</p>,
      className: 'w-3/12',
      render: (value) => (
        <div>
          <p>
            金融機関名：&nbsp;<strong>{value?.bankName}</strong>
          </p>
          <p>
            支店名:&nbsp;<strong>{value?.bankBranch}</strong>
          </p>
          <p>
            口座番号:&nbsp;<strong>{value?.bankNumber}</strong>
          </p>
          <p>
            口座名義人名（カナ）：&nbsp;<strong>{value?.bankAccountName}</strong>
          </p>
          <p>
            口座種別:&nbsp;<strong>{value?.bankAccountType}</strong>
          </p>
        </div>
      ),
    },
    {
      title: <p className="text-center whitespace-no-wrap">備考</p>,
      dataIndex: ['note'],
      key: 'note',
      className: 'w-4/12',
      render: (value) => <span>{value}</span>,
    },
    {
      title: '',
      key: 'action',
      className: 'text-center w-2/12',
      render: _renderAction,
    },
  ];

  return (
    <Layout.Content className="flex flex-col space-y-4">
      <Typography.Title level={3}>法人一覧</Typography.Title>
      <div className="flex flex-row justify-end">
        <Space direction="horizontal" className="flex items-stretch">
          <Space direction="vertical">
            <Button
              className="rounded-md flex items-center justify-center w-full h-10"
              type="primary"
              onClick={onCreate}
            >
              追加
            </Button>
          </Space>
        </Space>
      </div>
      <Table
        className="bg-white overflow-x-scroll"
        rowClassName="align-top whitespace-pre-wrap break-words fixBreakWordsJapan"
        components={{
          header: {
            cell: ({ className, style, ...restProps }) => {
              return <th className={className} style={{ ...style, textAlign: 'center' }} {...restProps} />;
            },
          },
        }}
        bordered
        loading={isFetching || isRefreshing}
        dataSource={results}
        columns={columns}
        rowKey="id"
      />
      {contextHolder}
    </Layout.Content>
  );
};

export default View;
