import React from 'react';
import { Form, Input, Button, Checkbox, InputNumber } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { USER_TYPES } from 'configs/Constants';

const FormItem = Form.Item;

const UserPointSearchForm = ({ onSubmit }) => {
  const [form] = Form.useForm();

  const targetOptions = [
    { label: 'ユーザー', value: USER_TYPES.USER },
    { label: 'トレーナー', value: USER_TYPES.TRAINER },
  ];

  const onFinish = (values) => {
    const filter = [];
    if (Array.isArray(values.userType) && values.userType.length === 1) {
      filter.userType = values.userType;
    }
    if (values.fullName) {
      filter.search = values.fullName;
    }
    if (values.pointFrom >= 0) {
      filter.startPoint = values.pointFrom;
    }
    if (values.pointTo >= 0) {
      filter.endPoint = values.pointTo;
    }
    onSubmit(filter);
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <div className="flex flex-row">
        <FormItem name="fullName" className="w-1/3 pr-4">
          <Input placeholder="名前、メール" />
        </FormItem>
        <FormItem name="pointFrom">
          <InputNumber placeholder="ポイント" min={0} />
        </FormItem>
        <span style={{ fontSize: 18, paddingLeft: 5, paddingRight: 5 }}> ~ </span>
        <FormItem name="pointTo">
          <InputNumber placeholder="ポイント" min={form.getFieldValue('pointFrom')} />
        </FormItem>
      </div>
      <div className="flex flex-row flex-grow justify-start">
        <FormItem name="userType">
          <Checkbox.Group options={targetOptions} />
        </FormItem>
      </div>
      <Form.Item shouldUpdate>
        {() => (
          <Button icon={<SearchOutlined />} htmlType="submit" type="ghost">
            検索
          </Button>
        )}
      </Form.Item>
    </Form>
  );
};

UserPointSearchForm.propTypes = {
  onSubmit: PropTypes.func,
};
export default UserPointSearchForm;
