import React from 'react';
import { Layout, Space, Button, Table, Modal, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useHelps, useDeleteHelp } from 'redux/Hooks/help';
import { Link } from 'react-router-dom';
import ButtonDirectToConv from 'components/ButtonDirectToConv';
import { useSyncingMe } from 'redux/Hooks/me';

const Help = () => {
  const { results, refresh } = useHelps();
  const [, , onSyncAuth] = useSyncingMe();
  const { onDelete } = useDeleteHelp(() => refresh());
  const [modal, contextHolder] = Modal.useModal({});

  const dataSource = results || [];

  const onOk = async (close, record) => {
    try {
      onDelete(record?.id);
      message.success('実行完了');
    } catch (deleteError) {
      console.log(deleteError);
      message.error('エラー');
    } finally {
      close();
    }
  };

  const onCancel = (close) => {
    close();
  };

  const modalDeleteConfig = (record) => {
    return {
      className: '',
      title: '確認',
      content: <h1>削除しますか？</h1>,
      onOk: (close) => onOk(close, record),
      onCancel,
      okButtonProps: {},
      cancelButtonProps: {},
      okText: '削除',
      okType: 'primary',
      cancelText: 'キャンセル',
      icon: null,
      mask: true,
      maskClosable: true,
      style: {},
      maskStyle: {},
      keyboard: true,
      autoFocusButton: 'ok',
    };
  };

  const _renderAction = (record) => (
    <Space size="middle">
      <Button
        onClick={() => {
          modal.confirm(modalDeleteConfig(record));
        }}
        icon={<CloseOutlined />}
        className="flex items-center justify-center text-center"
      />
    </Space>
  );

  const _renderMedia = (media) => {
    const url = media?.link;
    if (!url) return <div />;
    return (
      <a href={url} data-fancybox="gallery">
        <img src={url} width="200" alt="" />
      </a>
    );
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      className: 'w-1/12',
    },
    {
      title: '日時',
      dataIndex: 'created',
      key: 'created',
      className: 'w-1/12',
      render: (value, record) => {
        return moment.utc(value).local().format('YYYY/MM/DD HH:mm');
      },
    },
    {
      title: '名前',
      dataIndex: 'creator',
      className: 'w-3/12',
      key: 'creator',
      render: ({ id, fullName, userType }) => (
        <Link to={`/${userType === 'training' ? 'user' : 'trainer'}?action=edit&id=${id}`}>
          <span className="">{fullName}</span>
        </Link>
      ),
    },
    {
      title: '写真',
      dataIndex: 'media',
      key: 'media',
      width: 200,
      render: _renderMedia,
    },
    {
      title: '内容',
      dataIndex: 'content',
      key: 'content',
      className: 'w-4/12',
    },
    {
      title: '返信ボタン',
      className: 'w-1/12',
      render: (record) => <ButtonDirectToConv record={record} refresh={refresh} onSyncAuth={onSyncAuth} />,
    },
    {
      title: '',
      key: 'action',
      className: 'text-center w-2/12',
      render: _renderAction,
    },
  ];

  return (
    <Layout.Content className="flex flex-col p-4">
      <Table
        className="bg-white overflow-x-scroll"
        rowClassName="align-top whitespace-pre-wrap break-words fixBreakWordsJapan text-center"
        components={{
          header: {
            cell: ({ className, style, ...restProps }) => {
              return <th className={className} style={{ ...style, textAlign: 'center' }} {...restProps} />;
            },
          },
        }}
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        rowKey="id"
      />
      {contextHolder}
    </Layout.Content>
  );
};

export default Help;
