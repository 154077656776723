import React from 'react';
import { List } from 'antd';
import { stringify, parse } from 'qs';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import ConciegeItem from '../ConciergeItem';

const ConciergeList = ({
  dataSource,
  isFetching,
  currentPage,
  totalPages,
  setPage,
  pageSize,
  refresh,
  setInquiriUserId,
}) => {
  const history = useHistory();
  const location = useLocation();
  const parseQuery = parse(location.search, { ignoreQueryPrefix: true }) || {};

  const handleChangePage = (pageNumber) => {
    const query = new URLSearchParams(history.location.search);
    const queryString = stringify({
      ...query,
      ...parseQuery,
      page: pageNumber === 1 ? undefined : pageNumber,
      id: undefined,
    });

    setPage(pageNumber - 1);
    history.push({
      pathname: location.pathname,
      search: queryString,
    });
  };

  const pagination = {
    onChange: handleChangePage,
    current: currentPage,
    pageSize,
    total: totalPages * pageSize,
    showSizeChanger: false,
    simple: true,
  };

  return (
    <List
      className="h-full"
      itemLayout="vertical"
      pagination={pagination}
      dataSource={dataSource}
      loading={isFetching}
      renderItem={(item) => <ConciegeItem item={item} onClick={refresh} setInquiriUserId={setInquiriUserId} />}
      rowKey="id"
    />
  );
};

ConciergeList.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.any),
  isFetching: PropTypes.bool,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  setPage: PropTypes.func,
  pageSize: PropTypes.number,
  refresh: PropTypes.func,
};

export default ConciergeList;
