import { API } from 'configs';
import { usePaging, useAddEdit, useDelete } from 'utils/AxiosHooks';

const useGifts = (isMount = false, queryParams) =>
  usePaging(API.ADMIN.GIFT.LIST, isMount, queryParams?.pageSize, queryParams);

const useEditGift = (id) => useAddEdit(API.ADMIN.GIFT.EDIT, 'edit');
const useAddGift = (id) => useAddEdit(API.ADMIN.GIFT.ADD, 'add');
const useDeleteGift = (onSuccess) => useDelete(API.ADMIN.GIFT.EDIT, onSuccess);

export { useGifts, useEditGift, useAddGift, useDeleteGift };
