import { API } from 'configs';
import { usePaging, useAddEdit, useDelete, usePost, useRequest } from 'utils/AxiosHooks';

const useHelps = () => usePaging(API.ADMIN.HELP.LIST);

const useEditHelp = (id) => useAddEdit(API.ADMIN.HELP.EDIT, 'edit');
const useAddHelp = (id) => useAddEdit(API.ADMIN.HELP.ADD, 'add');
const useDeleteHelp = (onSuccess) => useDelete(API.ADMIN.HELP.EDIT, onSuccess);

const useResoveHelp = (id, ...prev) =>
  usePost({ url: id ? API.ADMIN.HELP.RESOLVE.replace(':id', id) : API.ADMIN.HELP.RESOLVE }, ...prev);

const useInquiryDetailByUserId = (userId, fetchOnMount = true) =>
  useRequest({
    url: userId ? API.ADMIN.HELP.DETAIL_BY_USER_ID.replace(':id', userId) : API.ADMIN.HELP.DETAIL_BY_USER_ID,
    fetchOnMount,
  });

export { useHelps, useEditHelp, useAddHelp, useDeleteHelp, useResoveHelp, useInquiryDetailByUserId };
