import React from 'react';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import classnames from 'classnames';
import { MenuOutlined } from '@ant-design/icons';

export const SortableItem = sortableElement((props) => (
  <tr {...props} className={classnames(props.className, 'w-full')} />
));
export const SortableContainer = sortableContainer((props) => (
  <tbody {...props} className={classnames(props.className, 'w-full relative')} />
));

export const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
