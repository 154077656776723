/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import { Layout, Table, Tag, Space, Button, Modal, message, Select, Input, Typography } from 'antd';
import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { useSearchLesson, useEditLesson, useDeleteLesson } from 'redux/Hooks/lesson';
import moment from 'moment';
import qs from 'qs';
import EditModal from 'components/EditModal';
import { DAY_OF_WEEK } from 'utils/constant';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import UploadImageFormItem from 'components/UploadImageInput';
import TrainingTypeItem from 'components/TrainingTypeItem';
import { API } from 'configs';
import { useFilter } from 'utils/hooks';
import LessonSearchForm from './components/LessonSearchForm';
import Schedules from './components/Schedules';

const DEFAULT_PAGE_SIZE = 30;

const statusinJP = {
  draft: 'ドラフト',
  publish: '公開する',
};

const Lesson = (props) => {
  const { location, history } = props;
  const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
  const action = parsedQuery?.action;
  const id = parsedQuery?.id;
  const [modal, contextHolder] = Modal.useModal({});
  const [visibleEdit, setVisibleEdit] = useState(false);
  const fileEditRef = useRef();
  /* eslint-disable no-unused-vars */

  const { results, currentPage, totalPages, refresh, setQuery } = useSearchLesson();

  const [filter, setFilter] = useFilter(setQuery);

  const {
    data: dataLessonById,
    refetch,
    prevFetching: prevIsFetching,
    isFetching,
    success,
    message: successResponse,
    onSave,
  } = useEditLesson(id);

  const { onDelete } = useDeleteLesson(
    id,
    // eslint-disable-next-line no-shadow
    ({ success }) => {
      if (success) {
        message.success('実行完了');
        refresh();
        return;
      }
      message.error('エラー');
    },
    (response) => {
      message.error('エラー');
      console.error(response);
    }
  );

  const onHiddenEdit = () => {
    setVisibleEdit(false);
    history.push({
      pathname: location.pathname,
      search: '',
    });
  };

  useEffect(() => {
    console.log(isFetching, prevIsFetching);
    if (isFetching !== prevIsFetching && prevIsFetching) {
      if (success) {
        if (visibleEdit) {
          onHiddenEdit();
        }
        refetch(API.ADMIN.LESSON.EDIT.replace(':id', id));
        message.success('実行完了');
        refresh();
        history.push({
          pathname: props.location.pathname,
        });
      } else {
        message.error('エラー');
        console.log(successResponse?.message);
      }
    }
  }, [isFetching]);

  useEffect(() => {
    if (id && action === 'edit') {
      setVisibleEdit(true);
    }
  }, [id, action]);

  const handleSearch = (f) => {
    setFilter({ ...filter, ...f, page: 0 });
  };

  const handleChangePage = (page, pageSize) => {
    setFilter({ ...filter, page: page - 1, pageSize });
  };

  useEffect(() => {
    if (Number(parsedQuery?.lessonOvertime) === 1) setFilter({ ...filter, overtime: 1 });
  }, [parsedQuery?.lessonOvertime]);

  // eslint-disable-next-line no-shadow
  const onOkDelete = async (close, id) => {
    onDelete(id);
    close();
  };

  const onCancel = (close) => {
    close();
  };
  // eslint-disable-next-line no-shadow
  const modalDeleteConfig = (id) => {
    return {
      className: '',
      title: '確認',
      content: <h1>削除しますか？</h1>,
      onOk: (close) => onOkDelete(close, id),
      onCancel,
      okButtonProps: {},
      cancelButtonProps: {},
      // centered: true,
      okText: '削除',
      okType: 'primary',
      cancelText: 'キャンセル',
      icon: null,
      mask: true,
      maskClosable: true,
      // zIndex: 100,
      // okCancel: true,
      style: {},
      maskStyle: {},
      // type: '',
      keyboard: true,
      autoFocusButton: 'ok',
      // direction: '',
    };
  };
  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      width: '10%',
    },
    {
      title: <p className="text-center whitespace-no-wrap">レッスン名</p>,
      dataIndex: ['title'],
      width: '15%',
      render: (value) => <div className="">{value}</div>,
    },
    {
      title: <p className="text-center whitespace-no-wrap">フルネーム</p>,
      dataIndex: ['creator', 'fullName'],
      width: '15%',
      render: (value) => <div className="">{value}</div>,
    },
    {
      title: <p className="text-center whitespace-no-wrap">ユーザー数</p>,
      dataIndex: ['totalUsers'],
      width: '8%',
    },
    {
      title: <p className="text-center whitespace-no-wrap">開始日</p>,
      dataIndex: ['startDate'],
      width: '12%',
      render: (value, record) => {
        const { startTime } = record;
        return value && startTime
          ? moment
              .utc(`${moment.utc(value).format('Y/MM/DD')} ${startTime}`)
              .local()
              .format('DD/MM/Y')
          : '';
      },
    },
    {
      title: <p className="text-center whitespace-no-wrap">終了日</p>,
      dataIndex: ['endDate'],
      width: '12%',
      render: (value, record) => {
        const { endTime } = record;
        return value && endTime
          ? moment
              .utc(`${moment.utc(value).format('Y/MM/DD')} ${endTime}`)
              .local()
              .format('DD/MM/Y')
          : '';
      },
    },
    {
      title: <p className="text-center whitespace-no-wrap">開始時刻</p>,
      dataIndex: ['startTime'],
      width: '9%',
      render: (value, record) => {
        const { startDate } = record;
        return value && startDate
          ? moment
              .utc(`${moment.utc(startDate).format('Y/MM/DD')} ${value}`)
              .local()
              .format('HH:mm:ss')
          : '';
      },
    },
    {
      title: <p className="text-center whitespace-no-wrap">終了時刻</p>,
      dataIndex: ['endTime'],
      width: '9%',
      render: (value, record) => {
        const { endDate } = record;
        return value && endDate
          ? moment
              .utc(`${moment.utc(endDate).format('Y/MM/DD')} ${value}`)
              .local()
              .format('HH:mm:ss')
          : '';
      },
    },
    {
      title: <p className="text-center whitespace-no-wrap">ステータス</p>,
      dataIndex: ['status'],
      width: '5%',
      render: (value, record) => {
        const isPaid = value !== 'draft';
        return (
          <Tag
            style={{ cursor: value === 'publish' ? 'default' : 'pointer' }}
            color={value === 'publish' ? 'green' : '#f50'}
          >
            {statusinJP[value]}
          </Tag>
        );
      },
    },
    {
      title: <p className="text-center whitespace-no-wrap">アクション</p>,
      dataIndex: 'status',
      width: '5%',
      render: (value, record) => {
        return (
          <Space size="middle">
            <Button
              onClick={() => {
                const query = new URLSearchParams(history.location.search);
                query.set('action', 'edit');
                query.set('id', record.id);
                history.push({
                  pathname: location.pathname,
                  search: query.toString(),
                });
              }}
              icon={<EditOutlined />}
            />
            <Button
              onClick={() => {
                modal.confirm(modalDeleteConfig(record.id));
              }}
              icon={<CloseOutlined />}
            />
          </Space>
        );
      },
    },
  ];

  const editInputs = [
    {
      title: 'メディア',
      key: 'media',
      name: ['media', 'link'],
      InputComponent: UploadImageFormItem,
      inputProps: {
        key: 'media',
        name: 'media',
        className: 'flex flex-col flex-grow items-center justify-center',
        size: '12rem',
        customUploadProps: {
          isImageUrl: true,
        },
        ref: fileEditRef,
      },
      formItemProps: {},
    },
    {
      title: 'レッスン名',
      key: 'title',
      name: ['title'],
      InputComponent: Input,
      inputProps: {},
      formItemProps: {
        className: 'my-4',
        rules: [{ required: true }],
      },
    },
    {
      title: '詳細',
      key: 'detail',
      name: ['detail'],
      InputComponent: Input,
      inputProps: {},
      formItemProps: {
        className: 'my-4',
        rules: [{ required: true }],
      },
    },
    {
      title: 'ステータス',
      key: 'status',
      name: ['status'],
      InputComponent: Select,
      inputProps: {
        options: Object.keys(statusinJP)?.map((key) => ({ label: statusinJP[key], value: key })),
      },
      formItemProps: {
        className: 'my-4',
        rules: [{ required: true }],
      },
    },
    {
      title: '毎週',
      key: 'dayOfWeek',
      name: ['dayOfWeek'],
      InputComponent: Select,
      inputProps: {
        options: Object.keys(DAY_OF_WEEK)?.map((key) => ({ label: DAY_OF_WEEK[key], value: Number(key) })),
      },
      formItemProps: {
        className: 'my-4',
        rules: [{ required: true }],
      },
    },
    {
      key: 'price',
      name: ['price'],
      InputComponent: Input,
      inputProps: {
        className: 'hidden',
      },
      formItemProps: {
        hidden: true,
      },
    },
    {
      key: 'startDate',
      name: ['startDate'],
      InputComponent: Input,
      inputProps: {
        className: 'hidden',
      },
      formItemProps: {
        hidden: true,
      },
    },
    {
      key: 'endDate',
      name: ['endDate'],
      InputComponent: Input,
      inputProps: {
        className: 'hidden',
      },
      formItemProps: {
        hidden: true,
      },
    },
    {
      key: 'startTime',
      name: ['startTime'],
      InputComponent: Input,
      inputProps: {
        className: 'hidden',
      },
      formItemProps: {
        hidden: true,
      },
    },
    {
      key: 'endTime',
      name: ['endTime'],
      InputComponent: Input,
      inputProps: {
        className: 'hidden',
      },
      formItemProps: {
        hidden: true,
      },
    },
    {
      key: 'type',
      name: ['type'],
      InputComponent: Input,
      inputProps: {
        className: 'hidden',
      },
      formItemProps: {
        hidden: true,
      },
    },
    {
      title: '実践できるトレーニング',
      key: 'trainingType',
      name: ['trainingType'],
      InputComponent: TrainingTypeItem,
      inputProps: {
        disabled: true,
      },
      formItemProps: {
        className: 'my-4',
      },
    },
    {
      title: 'スケジュール',
      key: 'schedules',
      name: ['schedules'],
      InputComponent: Schedules,
      inputProps: {
        className: '',
        loading: isFetching,
      },
      formItemProps: {},
    },
  ];

  const pagination = {
    showSizeChanger: false,
    showQuickJumper: false,
    hideOnSinglePage: true,
    onChange: handleChangePage,
    current: currentPage + 1 || 1,
    pageSize: DEFAULT_PAGE_SIZE,
    total: totalPages * DEFAULT_PAGE_SIZE || 0,
  };

  return (
    <Layout.Content className="flex flex-col p-4">
      <Typography.Title level={3}>レッスン</Typography.Title>
      <div className="formControl py-4 justify-between">
        <LessonSearchForm onSubmit={handleSearch} />
      </div>
      <Table
        className="bg-white overflow-x-scroll"
        rowClassName="align-top whitespace-pre-wrap break-words fixBreakWordsJapan text-center"
        components={{
          header: {
            cell: ({ className, style, ...restProps }) => {
              return <th className={className} style={{ ...style, textAlign: 'center' }} {...restProps} />;
            },
          },
        }}
        bordered
        dataSource={results || []}
        columns={columns}
        rowKey="id"
        pagination={pagination}
      />
      {visibleEdit && (
        <EditModal
          destroyOnClose
          visible={visibleEdit}
          onCreate={(values, recordId) => {
            const formData = new FormData();
            for (const [key, value] of Object.entries(omit(values, ['media']))) {
              formData.append(key, value);
            }
            if (fileEditRef?.current?.files?.[0]) {
              formData.append('image', fileEditRef?.current?.files?.[0]);
            }
            onSave(formData, recordId);
          }}
          onCancel={() => {
            onHiddenEdit();
          }}
          record={dataLessonById}
          loading={isFetching}
          editInputs={editInputs}
          headerTitle="編集"
          modalProps={{ width: '90%' }}
          formProps={{
            className: 'overflow-y-scroll',
            style: { maxHeight: '60vh' },
          }}
        />
      )}
      {contextHolder}
    </Layout.Content>
  );
};
Lesson.propTypes = {
  location: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
};
export default Lesson;
