import React, { useRef, useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { CSVReader, CSVDownloader } from 'react-papaparse';

const CSVImportGroup = ({ initialValues = {}, onImport }) => {
  const [formImport] = Form.useForm();
  const csvReaderRef = useRef();

  useEffect(() => {
    formImport.resetFields();
  }, [initialValues?.id]);

  const onFinishImport = async (values) => {
    const blob = await fetch(values?.csv).then((r) => r.blob());

    const formData = new FormData();
    formData.set('csv', blob, 'trainers.csv');

    onImport(formData, values?.id);
  };

  return (
    <Form form={formImport} onFinish={onFinishImport} initialValues={initialValues?.id ? initialValues : {}}>
      <Form.Item name="id" hidden rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="csv" hidden rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <div className="flex flex-row space-x-2">
        <Form.Item label="CSV登録" className="flex flex-grow">
          <CSVReader
            ref={csvReaderRef}
            accept=".csv"
            onFileLoad={(data, file) => {
              formImport.setFields([{ name: 'csv', value: URL.createObjectURL(file), validating: true }]);
            }}
            isReset
            addRemoveButton
            config={{
              header: true,
              skipEmptyLines: true,
            }}
          >
            {({ file }) => (
              <aside>
                <Button
                  size="large"
                  onClick={(e) => {
                    csvReaderRef.current.open(e);
                  }}
                >
                  <UploadOutlined />
                </Button>
                <div>{file && file.name}</div>
              </aside>
            )}
          </CSVReader>
        </Form.Item>
        <CSVDownloader
          data={[
            {
              フルネーム: '',
              Email: '',
              ニックネーム: '',
              誕生日: '',
              パスワード: '',
            },
          ]}
          filename="trainers"
          bom
        >
          <Button>CSVテンプレートダウンロード</Button>
        </CSVDownloader>
        <Button htmlType="submit" type="primary">
          CSVを入れる
        </Button>
      </div>
    </Form>
  );
};

export default CSVImportGroup;
