import React, { useState, useEffect } from 'react';
import { Layout, Space, Button, Table, Modal, message, Input } from 'antd';
import { CloseOutlined, EditOutlined, CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { LexoRank } from 'lexorank';

import EditModal from 'components/EditModal';
import { SortableItem, SortableContainer, DragHandle } from 'components/Sortable';
import { useAddFaq, useEditFaq, useFaq, useDeleteFaq } from 'redux/Hooks/faq';

const Faq = () => {
  const { results, refresh } = useFaq();

  const { prevFetching: prevIsFetching, isFetching, success, message: successResponse, onSave } = useEditFaq();
  const {
    prevFetching: prevIsFetchingAdd,
    isFetching: isFetchingAdd,
    success: successAdd,
    message: errorResponse,
    onSave: onAdd,
  } = useAddFaq();

  const { onDelete } = useDeleteFaq(() => refresh());

  const [visibleEdit, setVisibleEdit] = useState(false);
  const [editSelected, setEditSelected] = useState(null);

  const [modal, contextHolder] = Modal.useModal({});

  const dataSource = results || [];

  useEffect(() => {
    if (isFetching !== prevIsFetching && prevIsFetching) {
      if (success) {
        message.success('実行完了');
        refresh();
      } else {
        message.error('エラー');
        console.error(successResponse?.message);
      }
    }
  }, [isFetching]);

  useEffect(() => {
    if (isFetchingAdd !== prevIsFetchingAdd && prevIsFetchingAdd) {
      if (successAdd) {
        message.success('実行完了');
        refresh();
      } else {
        message.error('エラー');
        console.error(errorResponse?.message);
      }
    }
  }, [isFetchingAdd]);

  const onChangeItem = async (values, recordId) => {
    try {
      if (recordId) {
        onSave(values, recordId);
      } else {
        onAdd(values);
      }
      message.success('実行完了');
    } catch (createError) {
      console.log(createError);
      message.error('エラー');
    } finally {
      setVisibleEdit(false);
      setEditSelected(null);
    }
  };

  const onOk = async (close, record) => {
    try {
      onDelete(record?.id);
      message.success('実行完了');
    } catch (deleteError) {
      console.log(deleteError);
      message.error('エラー');
    } finally {
      close();
    }
  };

  const onCancel = (close) => {
    close();
  };

  const modalDeleteConfig = (record) => {
    return {
      className: '',
      title: '確認',
      content: <h1>削除しますか？</h1>,
      onOk: (close) => onOk(close, record),
      onCancel,
      okButtonProps: {},
      cancelButtonProps: {},
      okText: '削除',
      okType: 'primary',
      cancelText: 'キャンセル',
      icon: null,
      mask: true,
      maskClosable: true,
      style: {},
      maskStyle: {},
      keyboard: true,
      autoFocusButton: 'ok',
    };
  };

  const onSort = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newOrder = dataSource?.[newIndex]?.order;
      const newRank = newOrder ? LexoRank.parse(newOrder) : LexoRank.middle();
      let resultRank = null;
      if (newIndex === 0) {
        resultRank = newRank.genPrev();
      } else if (newIndex === dataSource?.length - 1) {
        resultRank = newRank.genNext();
      } else {
        const nearOrder = dataSource?.[newIndex - oldIndex > 0 ? newIndex + 1 : newIndex - 1]?.order;
        const nearRank = nearOrder ? LexoRank.parse(nearOrder) : LexoRank.middle();
        if (newRank.equals(nearRank)) {
          resultRank = newIndex - oldIndex > 0 ? newRank.genNext() : newRank.genPrev();
        } else {
          resultRank = newRank.between(nearRank);
        }
      }
      if (dataSource?.[oldIndex] && resultRank) {
        onSave({ order: resultRank.toString() }, dataSource?.[oldIndex]?.id);
      }
    }
  };

  const _renderAction = (record) => (
    <Space size="middle">
      <Button
        onClick={() => {
          setVisibleEdit(true);
          setEditSelected(record);
        }}
        icon={<EditOutlined />}
      />
      <Button
        onClick={() => {
          modal.confirm(modalDeleteConfig(record));
        }}
        icon={<CloseOutlined />}
      />
    </Space>
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource.findIndex((x) => x.id === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  const DraggableContainer = (containerProps) => (
    <SortableContainer
      lockAxis="y"
      useDragHandle
      disableAutoscroll
      helperClass="flex flex-row items-center justify-between border bg-white pr-2 pl-2"
      onSortEnd={onSort}
      {...containerProps}
    />
  );

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      className: 'w-1/12',
    },
    {
      title: <p className="text-center whitespace-no-wrap">移動</p>,
      dataIndex: 'order',
      key: 'order',
      className: 'text-center w-2/12',
      render: () => <DragHandle />,
    },
    {
      title: '質問',
      dataIndex: 'title',
      className: 'w-3/12',
      key: 'title',
    },
    {
      title: '答え',
      dataIndex: 'content',
      key: 'content',
      className: 'w-4/12',
    },
    {
      title: '',
      key: 'action',
      className: 'text-center w-2/12',
      render: _renderAction,
    },
  ];

  const editInputs = [
    {
      title: '質問',
      key: 'title',
      InputComponent: Input,
      inputProps: {},
      formItemProps: {
        rules: [{ required: true }],
      },
    },
    {
      title: '答え',
      key: 'content',
      InputComponent: Input.TextArea,
      inputProps: { rows: 4 },
      formItemProps: {
        rules: [{ required: true }],
      },
    },
  ];

  return (
    <Layout.Content className="flex flex-col p-4">
      <div className="flex flex-row justify-end py-4">
        <Space>
          <Button
            onClick={() => {
              setVisibleEdit(true);
              setEditSelected(null);
            }}
            type="primary"
          >
            追加
          </Button>
        </Space>
      </div>

      <Table
        className="bg-white overflow-x-scroll"
        rowClassName="align-top whitespace-pre-wrap break-words fixBreakWordsJapan text-center"
        components={{
          header: {
            cell: ({ className, style, ...restProps }) => {
              return <th className={className} style={{ ...style, textAlign: 'center' }} {...restProps} />;
            },
          },
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        rowKey="id"
      />
      {contextHolder}
      {visibleEdit && (
        <EditModal
          visible={visibleEdit}
          onCreate={onChangeItem}
          onCancel={() => {
            setVisibleEdit(false);
            setEditSelected(null);
          }}
          record={editSelected}
          editInputs={editInputs}
          headerTitle="ヘルプ"
        />
      )}
    </Layout.Content>
  );
};

export default Faq;
