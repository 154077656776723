import React, { useEffect } from 'react';
import { Input, Modal, Form } from 'antd';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';

const EditModal = ({
  visible,
  record,
  headerTitle,
  editInputs = [],
  hiddenVariables = {},
  onCreate = () => {},
  onCancel = () => {},
  modalProps = {},
  formProps = {},
  loading,
}) => {
  const [formEdit] = Form.useForm();
  const isEditing = !!record;

  const initialValues = omit(
    {
      ...hiddenVariables,
      ...(record || {}),
    },
    '__typename'
  );

  const editInputsValidated = editInputs?.filter((item) => item?.InputComponent) || [];

  const _renderEditInputs = (inputs) => {
    return inputs?.map(
      ({ title, key, name, InputComponent = Input, inputProps = {}, formItemProps = {}, inputChildren }) => (
        <Form.Item key={key} name={name || key} label={title} {...formItemProps}>
          <InputComponent name={name || key} {...inputProps}>
            {inputChildren}
          </InputComponent>
        </Form.Item>
      )
    );
  };

  const handleFinish = async (values) => {
    onCreate(values, record?.id);
    formEdit.resetFields();
  };

  useEffect(() => {
    if (visible && record?.id && formEdit) {
      formEdit.resetFields();
    }
  }, [visible, record?.id]);

  return (
    <Modal
      visible={visible}
      title={headerTitle}
      okText="変更"
      cancelText="キャンセル"
      onCancel={onCancel}
      onOk={() => {
        formEdit.submit();
      }}
      okButtonProps={{ form: 'edit', key: 'submit', htmlType: 'submit' }}
      {...modalProps}
    >
      {loading ? (
        <div className="flex items-center justify-center w-full">
          <LoadingOutlined />
        </div>
      ) : (
        <Form
          form={formEdit}
          layout="vertical"
          name={isEditing ? 'edit' : 'add'}
          initialValues={initialValues}
          onFinish={handleFinish}
          {...formProps}
        >
          <div className="flex flex-col">
            {Object.keys(hiddenVariables).map((key) => (
              <Form.Item key={key} name={key} className="hidden">
                <Input type="hidden" />
              </Form.Item>
            ))}
            {_renderEditInputs(editInputsValidated)}
          </div>
        </Form>
      )}
    </Modal>
  );
};

EditModal.propTypes = {
  visible: PropTypes.bool,
  record: PropTypes.object,
  headerTitle: PropTypes.string,
  editInputs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
      InputComponent: PropTypes.elementType,
      inputProps: PropTypes.object,
      formItemProps: PropTypes.object,
      inputChildren: PropTypes.element,
    })
  ),
  hiddenVariables: PropTypes.object,
  onCreate: PropTypes.func,
  onCancel: PropTypes.func,
  modalProps: PropTypes.object,
  formProps: PropTypes.object,
  loading: PropTypes.bool,
};

export default EditModal;
