import React from 'react';
import { Button, Space, Form, message, Spin } from 'antd';
import PropTypes from 'prop-types';
import { useEditUser, useGetGroupTagsV2 } from 'redux/Hooks/user';
import { pickBy, identity, pick } from 'lodash';
import { FIELDS_PROFILE } from 'configs/Constants';

import UserTags from './Tags';
import OtherInfo from './OtherInfor';
import BasicInformation from './BasicInformation';

const Profile = ({ item, history, location, refresh }) => {
  const [form] = Form.useForm();
  const [groupTagsV2] = useGetGroupTagsV2();
  const getTags = () =>
    Object.entries(groupTagsV2?.data).reduce((total, [key, category]) => {
      return {
        ...total,
        [key]: item?.tags
          ?.filter((tag) => tag?.categoryId === category.id || tag?.category === category.name)
          ?.map((tag) => tag?.id),
      };
    }, {});
  const { prevFetching: prevIsFetching, isFetching, success, message: successResponse, onSave } = useEditUser(
    item?.id,
    (res) => {
      if (res?.success) {
        refresh();
        history.go(0);
        message.success('実行完了');
      } else {
        message.error('必須項目を入力してください。');
      }
    },
    (err) => {
      message.error('必須項目を入力してください。');
    }
  );

  const initialValues = () => {
    return {
      [FIELDS_PROFILE.NICKNAME]: item?.nickname,
      [FIELDS_PROFILE.ONE_THING]: item?.title,
      [FIELDS_PROFILE.HEIGHT]: item?.height,
      [FIELDS_PROFILE.WEIGHT]: item?.weight,
      [FIELDS_PROFILE.LIVE]: item?.where_u_live,
      [FIELDS_PROFILE.HOMETOWN]: item?.hometown,
      [FIELDS_PROFILE.BLOOD_TYPE]: item?.[FIELDS_PROFILE.BLOOD_TYPE],
      // [FIELDS_PROFILE.BROTHER_SISTER]: item?.brothers_and_sisters,
      [FIELDS_PROFILE.LANGUAGE]: (item?.[FIELDS_PROFILE.LANGUAGE]?.split(',') || []).filter(identity),
      [FIELDS_PROFILE.TRAINER_HISTORY]: item?.[FIELDS_PROFILE.TRAINER_HISTORY],
      [FIELDS_PROFILE.TRAINING_TYPE_LIST]: item?.[FIELDS_PROFILE.TRAINING_TYPE_LIST],
      [FIELDS_PROFILE.TRAINING_TYPE]: item?.trainingType?.filter(Boolean),
      [FIELDS_PROFILE.CERTIFICATE]: item?.trainerProfile?.certificate,
      tags: getTags(),
    };
  };

  const handleSubmitProfile = () => {
    form.validateFields().then((values) => {
      const tags = pickBy(pick(values?.tags || {}, Object.keys(groupTagsV2?.data)), (value) =>
        Array.isArray(value) ? value?.length : identity(value)
      );

      const newValue = {
        ...values,
        trainingType: values?.arrTrainingType?.map((trainingType) => trainingType?.id)?.toString(),
        tagIds: Object.values(tags)?.toString(),
        [FIELDS_PROFILE.LANGUAGE]: values?.[FIELDS_PROFILE.LANGUAGE]?.join(',') || '',
      };

      const inputValues = pickBy(newValue, identity);
      onSave(inputValues, item?.id);
    });
  };

  if (isFetching || groupTagsV2.isLoading || !groupTagsV2.isSuccess || !groupTagsV2.data) {
    return <Spin className="flex items-center justify-center" size="large" />;
  }

  const renderFieldsProfile = () => {
    return (
      <div>
        <span className="py-2 font-bold text-lg text-blue-primary">基本情報</span>
        <BasicInformation {...item} />
        <div className="w-full flex flex-col md:flex-row mt-4">
          <div className="flex flex-col md:w-1/2 w-full mr-4">
            {/* Tags */}
            <span className="py-2 font-bold text-lg text-blue-primary">タグ</span>
            <UserTags groupTags={groupTagsV2?.data || {}} />
          </div>
          <div className="flex flex-col md:w-1/2 w-full mr-4">
            {/* Other */}
            <span className="py-2 font-bold text-lg text-blue-primary ">その他</span>
            <OtherInfo {...item} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Form
      form={form}
      name="profile"
      onFinish={handleSubmitProfile}
      validateMessages={{
        required: () => 'この項目は必須です。',
      }}
      initialValues={initialValues()}
    >
      <div className="flex flex-col">
        <div>{renderFieldsProfile()}</div>
        <div className="flex flex-row justify-around mt-12" style={{ width: 220 }}>
          <Button type="primary" className="rounded-sm" size="large" htmlType="submit">
            更新
          </Button>
          <Space direction="horizontal" size="large">
            <div />
            <div />
          </Space>
          <Button
            className="rounded-sm"
            onClick={() => {
              history.goBack();
            }}
            type="default"
            size="large"
          >
            キャンセル
          </Button>
        </div>
      </div>
    </Form>
  );
};

Profile.propTypes = {
  item: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
  location: PropTypes.objectOf(PropTypes.any),
  refresh: PropTypes.func,
};

export default Profile;
