import { useState, useEffect, useRef } from 'react';
import { API } from 'configs';
import { usePaging, useRequest, usePost } from 'utils/AxiosHooks';
import { DEFAULT_PAGE_SIZE } from 'configs/Constants';

const useConversations = (
  queryParams = {},
  { success = () => {}, failure = () => {} } = { success: () => {}, failure: () => {} }
) => {
  const res = usePaging(API.ADMIN.CONVERSATION.LIST, false, DEFAULT_PAGE_SIZE, queryParams, { success, failure });
  const { setData } = res;
  const updateRead = (id) => {
    setData((prevData) =>
      prevData?.map((item) => ({ ...item, unread: id && Number(item?.id) === Number(id) ? 0 : item?.unread }))
    );
  };
  return { ...res, updateRead };
};

const useGetMessage = (
  conversationId,
  { success = () => {}, failure = () => {} } = { success: () => {}, failure: () => {} }
) => {
  const noMoreMessages = useRef(false);
  const [message, setMessage] = useState([]);
  const updateMessages = {
    success: async (res) => {
      if (res?.totalPages <= 1) {
        noMoreMessages.current = true;
      }
      setMessage(res?.results || []);
      success(res);
    },
    failure: () => {
      setMessage([]);
      failure();
    },
  };
  const [state, doRequest] = useRequest({
    url: API.ADMIN.CONVERSATION.LIST_MESSAGE.replace(':id', conversationId),
    ...updateMessages,
  });

  const { totalPages, currentPage } = state.data || {};

  const doGet = (offset) => {
    if (!noMoreMessages.current && !state.isLoading) {
      doRequest({
        query: {
          offset,
        },
        ...updateMessages,
      });
    }
  };
  const hasMore = currentPage < totalPages - 1 || false;

  const refreshQuery = (id) => {
    doRequest({
      url: API.ADMIN.CONVERSATION.LIST_MESSAGE.replace(':id', id),
      ...updateMessages,
    });
  };

  useEffect(() => {
    doRequest({
      url: API.ADMIN.CONVERSATION.LIST_MESSAGE.replace(':id', conversationId),
      ...updateMessages,
    });
  }, [conversationId]);

  return [message, state.isLoading, doGet, refreshQuery, hasMore];
};

const useConversationDetail = (conversationId) =>
  useRequest({
    url: API.ADMIN.CONVERSATION.DETAIL.replace(':id', conversationId),
    fetchOnMount: true,
  });

const useConversationDetailByUserId = (userId, fetchOnMount = true) =>
  useRequest({
    url: userId
      ? API.ADMIN.CONVERSATION.DETAIL_BY_USER_ID.replace(':id', userId)
      : API.ADMIN.CONVERSATION.DETAIL_BY_USER_ID,
    fetchOnMount,
  });

const useSendMessage = (conversationId) => {
  const [doPost] = usePost({
    url: API.ADMIN.CONVERSATION.SEND_MESSAGE.replace(':id', conversationId),
  });
  const doSend = (body, ...prev) => doPost({ body }, ...prev);

  return [doSend];
};

const useSendMessageBulk = () => {
  // eslint-disable-next-line no-unused-vars
  const [doPost, prevLoading, requestState] = usePost({
    url: API.ADMIN.CONVERSATION.SEND_BULK,
  });
  const doSend = (body) => doPost({ body });
  return [doSend, requestState];
};

export {
  useConversations,
  useGetMessage,
  useSendMessage,
  useConversationDetail,
  useSendMessageBulk,
  useConversationDetailByUserId,
};
