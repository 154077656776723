import React, { useState, useRef } from 'react';
import { Space, Button, Spin, Badge, Modal } from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import { useConversationDetailByUserId } from 'redux/Hooks/conversation';
import { useResoveHelp } from 'redux/Hooks/help';
import ConciergeBox from 'containers/Concierge/components/ConciergeBox';

const ButtonDirectToConv = (props) => {
  const { record, onSyncAuth, refresh } = props;

  const boxRef = useRef();
  const [visibleConv, setVisibleConv] = useState(false);
  const [res, doGet] = useConversationDetailByUserId(record?.creator?.id, false);
  const [doResolve, _] = useResoveHelp(record?.id);

  const isOpenModal = res?.data?.id && visibleConv;

  return (
    <>
      <Space size="middle">
        <Badge dot count={record?.status === 'pending' ? 1 : 0}>
          <Button
            icon={res?.isLoading ? <Spin size="small" /> : <MessageOutlined className="text-center" />}
            className="flex items-center justify-center text-center"
            disabled={(!res?.data?.id && res?.isSuccess) || res?.isLoading}
            onClick={() => {
              doGet({
                success: () => {
                  if (boxRef.current?.refreshMessage) {
                    boxRef.current.refreshMessage();
                  }
                },
              });
              setVisibleConv(true);
            }}
          />
        </Badge>
      </Space>

      {isOpenModal && (
        <Modal visible={isOpenModal} onCancel={() => setVisibleConv(false)} footer={null} destroyOnClose width="80vw">
          <div style={{ height: '75vh' }}>
            <ConciergeBox
              ref={boxRef}
              convId={res?.data?.id}
              sendSuccess={() => {
                doResolve({ body: { status: 'done' } }, () => {
                  onSyncAuth();
                  refresh();
                });
              }}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default ButtonDirectToConv;
