import React from 'react';
import { Input, Button, Table, Space, Avatar, Modal, InputNumber, Tag, Select, message, Typography } from 'antd';
import { SearchOutlined, CloseOutlined, EditOutlined, UserOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useEditUser } from 'redux/Hooks/user';
import { USER_TYPES } from 'configs/Constants';
import { useFormik } from 'formik';
import { formatPoint } from 'utils/helpers';
import * as Yup from 'yup';
import classNames from 'classnames';
import styles from './styles.module.css';

const STATUS_LABEL = {
  active: '有効',
  banned: '禁止中',
  deleted: '削除',
};

const USER_STATUS_COLOR = {
  active: 'green',
  deleted: 'red',
  banned: 'yellow',
};

const ListView = (props) => {
  const {
    loading,
    dataSource,
    currentPage,
    pageSize,
    setPageSize,
    totalPage,
    setPage,
    searchForm,
    setSearchForm,
    refresh,
    userType,
  } = props;

  const [modal, contextHolder] = Modal.useModal({});
  const { prevFetching: prevIsFetching, isFetching, success, message: successResponse, onSave } = useEditUser(
    ':id',
    (res) => {
      if (res?.success) {
        refresh();
        message.success('削除しました');
      } else {
        message.error('ユーザーの削除が完了しました');
      }
    },
    (err) => {
      message.error('ユーザーの削除が完了しました');
    }
  );
  const onDelete = (id) => {
    onSave({ status: 'deleted' }, id);
  };

  const formik = useFormik({
    initialValues: searchForm,
    validationSchema: Yup.object(),
    enableReinitialize: true,
    onSubmit: async (values) => {
      setSearchForm(values);
      const query = new URLSearchParams(props.history.location.search);
      query.set('page', 1);
      setPage(0);
      props.history.push({
        pathname: props.location.pathname,
        search: query.toString(),
      });
    },
  });

  const onOkDelete = async (close, id) => {
    close();
    onDelete(id, refresh);
  };

  const onCancel = (close) => {
    close();
  };

  const modalDeleteConfig = (id) => {
    return {
      className: '',
      title: '確認',
      content: <h1>削除しますか？</h1>,
      onOk: (close) => onOkDelete(close, id),

      onCancel,
      okButtonProps: {},
      cancelButtonProps: {},
      okText: '削除',
      okType: 'primary',
      cancelText: 'キャンセル',
      icon: null,
      mask: true,
      maskClosable: true,
      style: {},
      maskStyle: {},
      keyboard: true,
      autoFocusButton: 'ok',
    };
  };

  const handleChangePage = (pageNumber) => {
    const query = new URLSearchParams(props.history.location.search);
    query.set('page', pageNumber);
    setPage(pageNumber - 1);
    props.history.push({
      pathname: props.location.pathname,
      search: query.toString(),
    });
  };

  const handleChangePageSize = (current, size) => {
    const query = new URLSearchParams(props.history.location.search);
    query.set('page', 1);
    query.set('limit', size);
    setPageSize(size);
    props.history.push({
      pathname: props.location.pathname,
      search: query.toString(),
    });
  };

  const _renderAvatars = (avatar, { avatars }) => {
    const defaultAvatar = avatars.find((item) => item.isDefault)?.url || avatar || '';
    return (
      <Avatar className="flex justify-center items-center" src={defaultAvatar} size="large" icon={<UserOutlined />} />
    );
  };
  /* eslint-disable react/prop-types */

  const _renderAction = (record) => (
    <Space size="middle">
      <Button
        className="flex justify-center items-center text-blue-primary border border-solid border-blue-primary"
        onClick={() => {
          const query = new URLSearchParams(props.history.location.search);
          query.set('action', 'edit');
          query.set('id', record.id);
          props.history.push({
            pathname: props.location.pathname,
            search: query.toString(),
          });
        }}
        icon={<EditOutlined />}
      />
      <Button
        danger
        className="flex justify-center items-center"
        onClick={() => {
          modal.confirm(modalDeleteConfig(record.id));
        }}
        icon={<CloseOutlined />}
      />
    </Space>
  );

  const columns = [
    {
      title: '',
      dataIndex: 'avatar',
      key: 'avatar',
      width: '10%',
      render: _renderAvatars,
    },
    {
      title: 'フルネーム',
      dataIndex: 'fullName',
      key: 'fullName',
      width: '20%',
      render: (value) => <div className="">{value || ''}</div>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '20%',
      render: (value) => <div className="">{value || ''}</div>,
    },
    {
      title: 'ポイント',
      dataIndex: 'point',
      key: 'point',
      width: '10%',
      render: (value) => `${formatPoint(value)}P`,
    },
    {
      title: 'メッセージ数',
      dataIndex: 'numberOfMessages',
      key: 'numberOfMessages',
      width: '10%',
      render: (record) => record || 0,
    },
    {
      title: <div className="text-center">紹介した会員</div>,
      dataIndex: 'referral',
      key: 'referral',
      width: '20%',
      render: (value) =>
        value && (
          <div className="flex flex-col items-center">
            <Avatar
              className="flex justify-center items-center"
              src={value?.avatar}
              size="large"
              icon={<UserOutlined />}
            />
            <p>{value?.fullName}</p>
          </div>
        ),
    },

    {
      title: 'ステータス',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      render: (tag) => (
        <Tag color={USER_STATUS_COLOR[tag]} className="rounded">
          {STATUS_LABEL[tag]}
        </Tag>
      ),
    },
    {
      render: _renderAction,
    },
  ];

  const pagination = {
    showSizeChanger: true,
    showQuickJumper: true,
    onChange: handleChangePage,
    onShowSizeChange: handleChangePageSize,
    current: currentPage,
    pageSize,
    total: totalPage * pageSize,
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.main}>
        <Typography.Title level={3}>
          {userType === USER_TYPES.TRAINER ? 'トレーナー管理 ' : 'ユーザー管理'}
        </Typography.Title>
        <div className={styles.formControl}>
          <div className={[styles.row].join(' ')}>
            <Input
              placeholder="名前"
              value={formik?.values?.username}
              onChange={(e) => formik.setFieldValue('username', e.target.value)}
            />
          </div>
          <div className={styles.row}>
            <InputNumber
              placeholder="ポイント"
              min={0}
              value={formik?.values?.startPoint}
              onChange={(value) => formik.setFieldValue('startPoint', value)}
            />
            <InputNumber
              placeholder="ポイント"
              min={0}
              value={formik?.values?.endPoint}
              onChange={(value) => formik.setFieldValue('endPoint', value)}
            />
            <Select
              options={[
                { label: '有効', value: 'active' },
                { label: '禁止中', value: 'banned' },
                { label: '削除', value: 'deleted' },
              ]}
              placeholder="ステータス"
              onChange={(value) => formik.setFieldValue('status', value)}
              allowClear
            />
          </div>
          <Button
            className="flex justify-center items-center w-48 rounded-md border boder-solid"
            style={{ background: '#C3DAFF', color: '#3B86FF', borderColor: '#3B86FF' }}
            type="default"
            size="large"
            icon={<SearchOutlined size={32} />}
            htmlType="submit"
          >
            検索
          </Button>
        </div>
        <div className={styles.content}>
          <div className={styles.extraControl}>
            <Space direction="vertical">
              <Button
                className="rounded-md flex items-center justify-center w-full h-10"
                onClick={() => {
                  const query = new URLSearchParams(props.history.location.search);
                  query.set('action', 'add');
                  props.history.push({
                    pathname: props.location.pathname,
                    search: query.toString(),
                  });
                }}
                type="primary"
              >
                追加
              </Button>
            </Space>
          </div>

          <Table
            className="bg-white overflow-x-scroll"
            rowClassName="align-top whitespace-pre-wrap break-words fixBreakWordsJapan text-center"
            components={{
              header: {
                cell: ({ className, style, ...restProps }) => {
                  return <th className={className} style={{ ...style, textAlign: 'center' }} {...restProps} />;
                },
              },
            }}
            bordered
            loading={loading}
            dataSource={dataSource}
            pagination={pagination}
            columns={columns}
            rowKey="id"
          />
        </div>
        {contextHolder}
      </div>
    </form>
  );
};

ListView.propTypes = {
  loading: PropTypes.bool,
  dataSource: PropTypes.array,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  totalPage: PropTypes.number,
  history: PropTypes.objectOf(PropTypes.any),
  location: PropTypes.object,
  setPageSize: PropTypes.func,
  searchForm: PropTypes.object,
  setSearchForm: PropTypes.func,
  refresh: PropTypes.func,
  setPage: PropTypes.func,
  userType: PropTypes.string,
};

export default ListView;
