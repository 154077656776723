import React from 'react';
import { Form, Input, Select } from 'antd';
import { FIELDS_PROFILE, PROFILE_JP } from 'configs/Constants';
import PropTypes from 'prop-types';
import TrainingTypeListInput from 'components/TrainingTypeListInput';

const OtherInfor = ({ userType }) => {
  return (
    <div className="flex flex-col w-full">
      <span className="py-2 font-semibold ">{PROFILE_JP[FIELDS_PROFILE.ONE_THING]}</span>
      <Form.Item name={FIELDS_PROFILE.ONE_THING} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <span className="py-2 font-semibold">{PROFILE_JP[FIELDS_PROFILE.NICKNAME]}</span>
      <Form.Item name={FIELDS_PROFILE.NICKNAME} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <span className="py-2 font-semibold ">
        {PROFILE_JP[FIELDS_PROFILE.TRAINING_TYPE_LIST] + ' (複数選択できます)'}
      </span>
      <Form.Item noStyle name={FIELDS_PROFILE.TRAINING_TYPE_LIST}>
        <TrainingTypeListInput />
      </Form.Item>

      {userType === 'trainer' && (
        <>
          <span className="py-2 font-semibold">{PROFILE_JP[FIELDS_PROFILE.CERTIFICATE]}</span>
          <Form.Item noStyle name={FIELDS_PROFILE.CERTIFICATE}>
            <Input />
          </Form.Item>
        </>
      )}
    </div>
  );
};

OtherInfor.propTypes = {
  userType: PropTypes.string,
};
export default OtherInfor;
