import React, { useEffect, useState, useRef } from 'react';
import { Layout, Table, Tag, Popconfirm, Button, message, Spin, Typography } from 'antd';
import { parse } from 'qs';
import { useLocation } from 'react-router-dom';
import { formatPoint } from 'utils/helpers';
import classNames from 'classnames';
import { useThrottledCallback } from 'use-debounce';
import { usePaymentRequests, useUpdateBulkPayment, useUpdatePayment } from 'redux/Hooks/payment';
import ButtonExportCSV from 'components/ButtonExportCSV';
import { usePermission } from 'utils/hooks/usePermission';
import { useFilter } from 'utils/hooks';
import PaymentRequestSearchForm from './components/PaymentRequestSearchForm';

import styles from './styles.module.css';

const DEFAULT_PAGE_SIZE = 20;

const statusinJP = {
  declined: '辞退',
  pending: '未払い',
  exported: 'CSV出力済',
  completed: '支払い済',
};

const Payment = () => {
  const tableRef = useRef();
  const location = useLocation();
  const [selectedRowKey, setSelectedRowKey] = useState([]);
  const {
    results,
    dataObject,
    setDataObject,
    refresh,
    setQuery,
    fetchMore,
    isRefreshing,
    isFetching,
  } = usePaymentRequests(DEFAULT_PAGE_SIZE);

  const [filter, setFilter] = useFilter(setQuery);

  const { onSave } = useUpdatePayment({
    onSuccess: (res) => {
      if (res?.success && res?.payment?.id) {
        setDataObject((prev) => ({
          ...prev,
          [res?.payment?.id]: {
            ...prev?.[res?.payment?.id],
            ...res?.payment,
          },
        }));
        message.success('実行完了');
      }
    },
    onFail: (err) => {
      message.error('エラー');
    },
  });
  const [handleUpdate] = useUpdateBulkPayment();

  usePermission('manage-payment-request');

  const parsedQuery = parse(location.search, { ignoreQueryPrefix: true });
  const debounced = useThrottledCallback(
    (event) => {
      const { scrollTop, scrollHeight, clientHeight } = event?.target;
      const winScroll = scrollTop;
      const height = scrollHeight - clientHeight;
      const scrolled = (winScroll / height) * 100;

      if (scrolled < 1) {
        refresh({ ...filter, page: 0, pageSize: DEFAULT_PAGE_SIZE });
      }
      if (scrolled > 99) {
        fetchMore();
      }
    },
    500,
    { leading: false, trailing: true }
  );

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.addEventListener('scroll', debounced);
    }
    return () => {
      if (tableRef.current) {
        tableRef.current.removeEventListener('scroll', debounced);
      }
    };
  }, [tableRef.current]);

  useEffect(() => {
    if (Number(parsedQuery?.paymentPending) === 1) setFilter({ ...filter, status: 'pending' });
  }, [parsedQuery?.paymentPending]);

  const handleSearch = async (f) => {
    // await setFilter({ ...f, page: 0, pageSize: DEFAULT_PAGE_SIZE });
    refresh({ ...f, page: 0, pageSize: DEFAULT_PAGE_SIZE }); // the same setQuery/setFilter
    tableRef.current.scrollTop = 0;
  };

  const handleMarkComplete = (selectedIds) => {
    const exportedIds = selectedIds?.filter((id) => dataObject?.[id]?.status === 'exported');
    handleUpdate(
      { ids: exportedIds, status: 'completed' },
      (res) => {
        if (res?.updated?.length) {
          refresh();
        }
        if (res?.fails?.length) {
          message.error('エラー');
        }
      },
      (err) => {
        message.error('エラー', err?.message);
      }
    );
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
    },
    {
      title: 'トレーナー名',
      dataIndex: ['user', 'fullName'],
      width: '10%',
      render: (value) => <div className="">{value || ''}</div>,
    },
    {
      title: '銀行',
      dataIndex: ['user'],
      render: (val) => {
        return val?.business?.bankName || val.banks?.[0]?.bank;
      },
    },
    {
      title: '支店',
      dataIndex: ['user'],
      render: (val) => {
        return val.business?.bankBranch || val.banks?.[0]?.branch;
      },
    },
    {
      title: '口座番号',
      dataIndex: ['user'],
      render: (val) => {
        return val.business?.bankNumber || val.banks?.[0]?.accountNo;
      },
    },
    {
      title: '金額',
      dataIndex: 'amount',
      render: (value) => formatPoint(value),
    },
    {
      title: '状態',
      dataIndex: 'status',
      render: (value, record) => {
        switch (value) {
          case 'exported':
            return (
              <Popconfirm
                title="このリクエストを完了としてマークしますか？"
                onConfirm={() => {
                  onSave(
                    {
                      status: 'completed',
                    },
                    record.id
                  );
                }}
              >
                <Tag style={{ cursor: 'pointer' }} color="#f50">
                  {statusinJP[value]}
                </Tag>
              </Popconfirm>
            );
          case 'completed':
          default:
            return (
              <Tag style={{ cursor: 'default' }} color={value === 'completed' ? 'green' : '#f50'}>
                {statusinJP[value]}
              </Tag>
            );
        }
      },
    },
  ];

  const rowSelection = {
    selectedRowKeys: selectedRowKey,
    onChange: (selectedRowKeys, selectedRow) => {
      setSelectedRowKey(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({ id: record.id, disabled: record.status === 'completed' }),
  };

  const hasSelected = selectedRowKey?.length > 0;

  return (
    <Layout.Content className="flex flex-col p-4">
      <Typography.Title level={3}>支払リクエスト</Typography.Title>
      <div className="formControl py-4 justify-between">
        <PaymentRequestSearchForm onSubmit={handleSearch} />
      </div>
      {hasSelected && (
        <div className="flex justify-end mb-4">
          <Popconfirm
            title="リクエストを完了としてマークしますか？"
            onConfirm={() => handleMarkComplete(selectedRowKey)}
          >
            <Button type="primary" className="mr-4">
              一括支払い
            </Button>
          </Popconfirm>
          <ButtonExportCSV
            ids={selectedRowKey}
            onAfter={() => {
              handleUpdate(
                { ids: selectedRowKey, status: 'exported' },
                () => {
                  refresh();
                },
                (err) => {
                  message.error('エラー', err?.message);
                }
              );
            }}
          />
        </div>
      )}

      <div ref={tableRef} className="bg-white overflow-y-scroll" style={{ height: '80vh' }}>
        <Table
          className={classNames(styles.table)}
          rowClassName="align-top whitespace-pre-wrap break-words fixBreakWords"
          loading={isRefreshing || isFetching}
          dataSource={results || []}
          columns={columns}
          rowKey={(record) => record.id}
          pagination={false}
          rowSelection={rowSelection}
        />
        {(isRefreshing || isFetching) && (
          <div className="flex items-center justify-center p-8">
            <Spin size="large" />
          </div>
        )}
      </div>
    </Layout.Content>
  );
};

export default Payment;
