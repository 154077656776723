import React, { useState, useEffect } from 'react';
import { Input, Form, List, Button, Avatar, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import { CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroller';
import { useDebouncedCallback } from 'use-debounce';
import { useUsers } from 'redux/Hooks/user';

const SelectUserFormInput = ({ name, className = '', style = {}, value, onChange, userType = 'trainer', disabled }) => {
  const [userSelected, setUserSelected] = useState(null);
  const [formSearch] = Form.useForm();

  const { results: dataSource, setQuery } = useUsers();
  const [searchForm, setSearchForm] = useState({});
  /* eslint-disable no-unused-vars */
  const [pageSize, setPageSize] = useState(30);
  const [page, setPage] = useState(0);
  const filterMapping = () => {
    const filters = {};
    Object.keys(searchForm).forEach((field) => {
      // eslint-disable-next-line no-shadow
      const value = searchForm?.[field];
      if (value !== (undefined || null)) {
        switch (field) {
          case 'fullName':
            filters.search = value;
            break;
          default:
            break;
        }
      }
      return null;
    });
    return filters;
  };

  useEffect(() => {
    if (value?.id) {
      setUserSelected(value);
    }
  }, [value?.id]);

  useEffect(() => {
    setQuery({
      ...filterMapping(),
      pageSize,
      page,
      userType,
    });
  }, [pageSize, searchForm, page]);

  const handleSearch = (values) => {
    setSearchForm(values);
  };

  const onFetchMoreDebounced = useDebouncedCallback(() => {}, 1000);

  const _renderLoadMore = () => (
    <div key="loading">
      <List.Item className="relative h-auto items-start">
        <Skeleton avatar paragraph={{ rows: 1 }} className="flex flex-row-reverse" />
      </List.Item>
    </div>
  );

  return (
    <div className={`flex flex-col ${className}`} style={style}>
      {userSelected?.id && (
        <div className="flex flex-row p-4 space-x-4 cursor-pointer border-t border-blue-primary hover:border-blue-primary">
          <div className="flex flex-row justify-start flex-grow flex-nowrap space-x-4">
            <div className="w-4/12 whitespace-pre-wrap break-words fixBreakWords">
              <Avatar className="justify-self-start" src={userSelected?.avatar} size="large" icon={<UserOutlined />} />
            </div>
            <div className="w-4/12 whitespace-pre-wrap break-words fixBreakWords">{userSelected?.fullName}</div>
            <div className="w-4/12 whitespace-pre-wrap break-words fixBreakWords">{userSelected?.email}</div>
          </div>
          <div className="">
            <Button
              type="text"
              icon={<CheckCircleOutlined className="text-2xl" />}
              className="text-blue-primary hover:text-blue-primary"
            />
          </div>
        </div>
      )}
      <div className="hidden">
        <input hidden name={name} defaultValue={userSelected?.id} />
      </div>
      {!disabled && (
        <div className="flex flex-col flex-40-percent">
          <Form form={formSearch} layout="vertical" name="search" initialValues={{}} onFinish={handleSearch}>
            <Form.Item name="fullName" className="w-full px-12">
              <Input.Search onSearch={() => formSearch.submit()} placeholder="ホスト名" />
            </Form.Item>
          </Form>
          <div className="overflow-y-scroll" style={{ height: '30vh' }}>
            <InfiniteScroll
              pageStart={0}
              hasMore={false}
              loader={_renderLoadMore()}
              loadMore={() => onFetchMoreDebounced.callback()}
              useWindow={false}
              className="flex flex-col flex-grow flex-shrink-0 w-full max-h-full px-12"
            >
              <List
                itemLayout="vertical"
                dataSource={dataSource}
                renderItem={(item, index) => (
                  <List.Item
                    key={index}
                    className={`flex flex-row p-4 space-x-4 cursor-pointer border-t ${
                      userSelected?.id === item?.id ? 'border-blue-primary' : 'border-transparent'
                    } hover:border-blue-primary `}
                    onClick={() => {
                      setUserSelected(item);
                      onChange(item);
                    }}
                  >
                    <div className="flex flex-row justify-start flex-grow flex-nowrap space-x-4">
                      <div className="w-4/12 whitespace-pre-wrap break-words fixBreakWords">
                        <Avatar
                          className="justify-self-start"
                          src={item?.avatar}
                          size="large"
                          icon={<UserOutlined />}
                        />
                      </div>
                      <div className="w-4/12 whitespace-pre-wrap break-words fixBreakWords">{item.fullName}</div>
                      <div className="w-4/12 whitespace-pre-wrap break-words fixBreakWords">{item.email}</div>
                    </div>
                    <div className="">
                      <Button
                        onClick={() => {
                          setUserSelected(item);
                          onChange(item);
                        }}
                        type="text"
                        icon={<CheckCircleOutlined className="text-2xl" />}
                        className={`${
                          userSelected?.id === item?.id ? 'text-blue-primary' : 'text-gray-400'
                        } hover:text-blue-primary`}
                      />
                    </div>
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </div>
        </div>
      )}
    </div>
  );
};

SelectUserFormInput.propTypes = {
  name: PropTypes.array,
  className: PropTypes.string,
  style: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  userType: PropTypes.string,
  disabled: PropTypes.bool,
};

export default SelectUserFormInput;
