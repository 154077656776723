import React from 'react';
import { RecoilRoot, useRecoilTransactionObserver_UNSTABLE as useRecoilObserver } from 'recoil';
import routes from 'common/routes';
import { ConfigProvider } from 'antd';
import RouterComponent from 'common/router/Router';

import { setDefaultHeaders } from 'utils/Axios';
import moment from 'moment';
import { RECOIL_KEY } from 'utils/constant';
import IntlProvider from 'common/IntlProvider';
import EmptyData from 'components/Empty';
import registerPersistAtoms from 'store/atom';
import jaJP from 'antd/lib/locale/ja_JP';

import 'moment/locale/ja';

// Stylesheets
import 'theme/antd.css';
import './index.css';

const Storage = window.localStorage;
moment.locale('ja');

const getToken = () => {
  try {
    const auth = window.localStorage.getItem(RECOIL_KEY.AUTH);
    return auth ? JSON.parse(auth)?.token : '';
  } catch (error) {
    return '';
  }
};

function PersistenceObserver() {
  useRecoilObserver(({ snapshot }) => {
    registerPersistAtoms.forEach((atom) => {
      const atomValue = snapshot.getLoadable(atom).contents;
      const storage = Storage.getItem(atom.key);
      try {
        Storage.setItem(atom.key, JSON.stringify({ ...JSON.parse(storage), ...atomValue }));
      } catch (error) {
        Storage.setItem(atom.key, JSON.stringify(atomValue));
        console.log('Parsed err', error);
      }
    });
  });

  return null;
}

const App = () => {
  const initializeState = ({ set }) => {
    for (const [key, value] of Object.entries(Storage)) {
      if (Object.values(RECOIL_KEY).includes(key)) {
        try {
          set({ key }, JSON.parse(value));
        } catch (err) {
          console.log('Parsed err', err);
        }
      }
    }
  };

  setDefaultHeaders({
    Authorization: `Bearer ${getToken()}`,
  });
  const customizeRenderEmpty = () => <EmptyData description="検索結果がありません。" />;
  return (
    <RecoilRoot initializeState={initializeState}>
      <PersistenceObserver />
      <IntlProvider>
        <ConfigProvider locale={jaJP} renderEmpty={customizeRenderEmpty}>
          <RouterComponent routes={routes} defaultTitle="HosuTore" />
        </ConfigProvider>
      </IntlProvider>
    </RecoilRoot>
  );
};

export default App;
